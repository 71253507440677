import { HStack, Tag, Text, useTheme } from '@chakra-ui/react'
import { faCreditCardBlank } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CamiproNeededTag = () => {
  const theme = useTheme()

  return (
    <Tag
      fontFamily={theme.fonts.modal}
      w='full'
      p={4}
      border='1px solid'
      borderColor='orange.400'
      bg='orange.50'>
      <HStack align='middle' w='full'>
        <FontAwesomeIcon icon={faCreditCardBlank} size='lg' color={theme.colors.orange[400]} />
        <Text variant='small'>Camipro card needed to open the parking gate</Text>
      </HStack>
    </Tag>
  )
}

export default CamiproNeededTag
