import Newsletter from '../../components/Newsletter'
import { logSentryException } from '../loggingHelpers/sentryHelper'

export const handleMiscModules = (id: string) => {
  switch (id) {
    case 'module_misc_footer_newsletter':
      return <Newsletter />
    default:
      logSentryException('handleMiscModules no supported module', 'MODULE', id)
  }
}
