const SearchEmptyResults = () => {
  return (
    <div className='search-result-empty-block'>
      <div>
        <div className='display-h4'>
          0 result matched
          <br />
          your research.
        </div>
      </div>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        src='https://global-uploads.webflow.com/627cb49ee9c8c230cfca9d37/62a850b72bce0c06aad0919b_no_result.png'
        loading='lazy'
        alt=''
        className='search-result-empty-img'
      />
    </div>
  )
}

export default SearchEmptyResults
