import { ROUTE_PORTAL } from '@/constants/Routes'
import { Flex, SimpleGrid, useBreakpointValue, VStack } from '@chakra-ui/react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import userStore from '../../stores/userStore'
import CreateForm from './components/ForgotForm'
import LoginForm from './components/LoginForm'
import ResetPassword from './components/ResetForm'

export type LoginPages = 'login' | 'reset' | 'forgot'

const Login = () => {
  return <LoginContent />
}

const LoginContent = () => {
  const { query, push } = useRouter()
  const isMobile = useBreakpointValue([true, null, false], 'md')
  const [loginPage, setLoginPage] = useState<LoginPages>('login')
  const isLoggedIn = userStore(state => state.isLoggedIn)

  useEffect(() => {
    if (query.token) {
      setLoginPage('reset')
    }
  }, [query])

  useEffect(() => {
    const redirect = async () => {
      await push(ROUTE_PORTAL)
    }
    if (isLoggedIn) {
      redirect()
    }
  }, [isLoggedIn, push])

  return (
    <Flex align='center' w='full' justify='center'>
      <SimpleGrid maxW={['full', null, 'full']} bg='white' columns={[1, null, 2]} spacing={0}>
        {!isMobile && (
          <Flex position='relative'>
            <Image
              fill
              quality={100}
              objectFit='cover'
              objectPosition='20%'
              src='/eip_login.jpg'
              alt='login-image'
            />
          </Flex>
        )}
        <VStack w={['full', null, '375px']} bg='white'>
          {loginPage === 'login' && <LoginForm setPage={setLoginPage} />}
          {loginPage === 'reset' && query.token && (
            <ResetPassword setPage={setLoginPage} token={query.token as string} />
          )}
          {loginPage === 'forgot' && <CreateForm setPage={setLoginPage} />}
        </VStack>
      </SimpleGrid>
    </Flex>
  )
}

// noinspection JSUnusedGlobalSymbols
export default Login
