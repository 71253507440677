import {
  getCompanyLocationFilters,
  getCompanySectorFilters,
  getCompanyTypeFilters
} from '@/constants/FieldsCompany'
import { FRONT_COMPANIES_PAGINATED } from '@/constants/Routes'
import { FilterItem } from '@/models/EudonetModels'
import { PublicCompany } from '@/models/company/PublicCompany'
import { Button, Center, ChakraProvider, Spinner, useBoolean } from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { useState } from 'react'
import useSWR from 'swr'
import useQueryFilters from '../../hooks/useQueryFilters'
import baseFetcher from '../../utils/connectionHelpers/swrFetchers'
import Checkbox from '../shared/Checkbox'
import SimpleListDisplay from '../shared/SimpleListDisplay'
import ListFetchingHandling from '../shared/dataFetching/ListFetchingHandling'
import FilterSection, { FilterType } from '../shared/filters/FilterSection'
import CompanyCard from './components/CompanyCard'

const filters: FilterType[] = [
  { name: 'Type', filterList: getCompanyTypeFilters() },
  { name: 'Sector of activity', filterList: getCompanySectorFilters() },
  { name: 'Locations', filterList: getCompanyLocationFilters() }
]

interface Props {
  enabled: boolean
  setEnabled: (value: boolean) => void
}

const CustomFilters = ({ enabled, setEnabled }: Props) => {
  return <Checkbox label='EPFL startups' enabled={enabled} setEnabled={setEnabled} />
}

const CompaniesList = () => {
  const [selectedFilters, setSelectedFilters] = useState<FilterItem[]>([])
  const [queryFilters, addQueryFilter, removeQueryFilter] = useQueryFilters('')
  const [queryText, setQueryText] = useState<string>('')
  const [location, setLocation] = useState<string>('')
  const [isEPFL, setISEPFL] = useState<boolean>(false)
  const [fetchAll, setFetchAll] = useBoolean(false)

  const { data, error, isLoading } = useSWR<{ data: PublicCompany[]; items: number }, AxiosError>(
    FRONT_COMPANIES_PAGINATED(fetchAll, queryFilters, queryText, isEPFL, location),
    baseFetcher,
    { keepPreviousData: true, revalidateOnFocus: false }
  )

  const addFilter = async (item: FilterItem) => {
    if (!data) {
      return
    }

    if (selectedFilters.some(temp => temp.Id === item.Id)) {
      return
    }

    setSelectedFilters([...selectedFilters, item])
    if (item.CatalogName === 'Location') {
      const tempLocation = location.split(',')
      tempLocation.push(item.DisplayValue)
      setLocation(tempLocation.join(','))
    } else {
      addQueryFilter(selectedFilters, item)
    }
  }

  const removeFilter = (item: FilterItem) => {
    setSelectedFilters(selectedFilters.filter(filter => item.Id !== filter.Id))
    if (item.CatalogName === 'Location') {
      const tempLocation = location.split(',')
      const removeIndex = tempLocation.indexOf(item.DisplayValue)
      tempLocation.splice(removeIndex, 1)
      setLocation(tempLocation.join(','))
    } else {
      removeQueryFilter(selectedFilters, item)
    }
  }

  return (
    <ChakraProvider>
      <FilterSection
        filterTypes={filters}
        selectedFilters={selectedFilters}
        addFilter={addFilter}
        removeFilter={removeFilter}
        searchText={setQueryText}
        additionalButtons={<CustomFilters enabled={isEPFL} setEnabled={setISEPFL} />}
      />
      <ListFetchingHandling data={data} error={error} />
      {data && data.data.length > 0 && (
        <>
          <SimpleListDisplay<PublicCompany>
            listItems={data.data}
            card={(item, key) => <CompanyCard company={item} key={key} />}
          />
          {!fetchAll && (
            <Center>
              <Button
                colorScheme='blackAlpha'
                borderRadius='full'
                bgColor='black'
                mb='5'
                onClick={() => setFetchAll.on()}>
                View all
              </Button>
            </Center>
          )}
        </>
      )}
      {isLoading && (
        <Center>
          <Spinner />
        </Center>
      )}
    </ChakraProvider>
  )
}

export default CompaniesList
