import { Divider, Heading } from '@chakra-ui/react'

interface Props {
  label: string
  hideDivider?: boolean
}

const FormHeading = ({ label, hideDivider = false }: Props) => {
  return (
    <>
      {!hideDivider && <Divider />}
      <Heading as='h3' size='lg' textAlign='left' w='full'>
        {label}
      </Heading>
    </>
  )
}

export default FormHeading
