import {
  PARKING_CREDIT_AM,
  PARKING_CREDIT_PM,
  PARKING_NAME,
  PARKING_NEED_ACCESS,
  PARKING_TYPE
} from '@/constants/FieldsParking'
import { eudo } from '../zod-eudo'

export enum ParkingType {
  O = 'O',
  N = 'N',
  AB = 'AB',
  D = 'D',
  G = 'G',
  H = 'H'
}

export const ParkingLot = eudo.object({
  id: eudo.id(),
  name: eudo.string(PARKING_NAME),
  type: eudo.nativeEnum(PARKING_TYPE, ParkingType),
  creditAM: eudo.int(PARKING_CREDIT_AM),
  creditPM: eudo.int(PARKING_CREDIT_PM),
  needAccess: eudo.boolean(PARKING_NEED_ACCESS)
})

export type ParkingLot = eudo.infer<typeof ParkingLot>
