import React from 'react'
import { ROUTE_ECOSYSTEM_EVENTS, ROUTE_EVENTS_CALENDAR } from '@/constants/Routes'
import Checkbox from '../../shared/Checkbox'
import userStore from '../../../stores/userStore'

interface Props {
  memberEventsToggled: boolean
  onMembersEvents: (enabled: boolean) => void
  calendarToggled: boolean
}

const FilterToggles = ({
  memberEventsToggled,
  onMembersEvents,
  calendarToggled = false
}: Props) => {
  const [isKnovaMember] = userStore(e => [e.isKnovaMember])

  return (
    <>
      {isKnovaMember && (
        <Checkbox
          label='Members only'
          enabled={memberEventsToggled}
          setEnabled={onMembersEvents}
          colored
        />
      )}
      <a
        href={calendarToggled ? ROUTE_ECOSYSTEM_EVENTS : ROUTE_EVENTS_CALENDAR()}
        style={{ textDecoration: 'none' }}>
        <Checkbox label='Show Calendar' enabled={calendarToggled} setEnabled={() => {}} />
      </a>
    </>
  )
}

export default FilterToggles
