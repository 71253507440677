import { BookingDuration } from '@/constants/FieldsParking'
import { FRONT_USER_BOOKINGS } from '@/constants/Routes'
import { ParkingBooking } from '@/models/parking/ParkingBooking'
import { bookingPastCurrentDayJs } from '@/utils/dataHelpers/parkBookingHelpers'
import { Container, Divider, Spinner, VStack } from '@chakra-ui/react'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import useSWR from 'swr'
import useErrorToaster from '../../hooks/useErrorToaster'
import baseFetcher from '../../utils/connectionHelpers/swrFetchers'
import BookingList from './myBookings/BookingList'
import ParkshareHeading from './shared/ParkshareHeading'

const BookingsContainer = () => {
  return <BookingsContainerContent />
}

const BookingsContainerContent = () => {
  const { data: bookingList, error: bookingListError } = useSWR<ParkingBooking[], AxiosError>(
    FRONT_USER_BOOKINGS,
    baseFetcher,
    { revalidateOnFocus: false }
  )

  useErrorToaster(
    bookingListError,
    'Could not retrieve the bookings from server. Cannot show your bookings.'
  )

  const formatBookings = (bookings: ParkingBooking[]) => {
    //Sort the bookings
    const sortedBookings = bookings.sort((a, b) => (a.date < b.date ? 1 : -1))

    const finalList = [] as ParkingBooking[]

    //Recognize them in pairs. Convert to whole day if necessary
    for (let i = sortedBookings.length - 1; i >= 0; i--) {
      const booking = sortedBookings[i]

      if (
        i === 0 ||
        booking.spotId !== sortedBookings[i - 1].spotId ||
        booking.date !== sortedBookings[i - 1].date
      ) {
        finalList.push(booking)
      } else {
        booking.duration = BookingDuration.allDay
        finalList.push(booking)
        i -= 1
      }
    }

    return finalList
  }

  const bookingsToCome: Array<ParkingBooking> = []
  const bookingPast: Array<ParkingBooking> = []

  const sorter = (a: ParkingBooking, b: ParkingBooking) => {
    const aDate = dayjs(a.date)
    const bDate = dayjs(b.date)
    return bDate.millisecond > aDate.millisecond ? 1 : -1
  }

  formatBookings(bookingList || [])
    .sort(sorter)
    .forEach((booking: ParkingBooking) => {
      if (bookingPastCurrentDayJs(booking)) {
        bookingPast.push(booking)
      } else {
        bookingsToCome.push(booking)
      }
    })

  return (
    <Container maxW='container.xl'>
      <ParkshareHeading title='Your bookings' />
      {!bookingList && !bookingListError ? (
        <VStack>
          <Spinner size='lg' />
        </VStack>
      ) : (
        <>
          <BookingList
            header='To come'
            emptyContainerText="You don't have any upcoming booking"
            bookings={bookingsToCome}
          />
          <Divider my={10} />
          <BookingList
            header='Past'
            emptyContainerText="You don't have older bookings"
            bookings={bookingPast}
          />
        </>
      )}
    </Container>
  )
}

export default BookingsContainer
