import { BookingDuration } from '@/constants/FieldsParking'
import { Container } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useState } from 'react'
import DateAndTimeSelection from './newBookings/DateAndTimeSelection'
import ParkingSelection from './newBookings/ParkingSelection'
import ParkshareHeading from './shared/ParkshareHeading'
import ParkshareSubHeading from './shared/ParkshareSubHeading'

const HomeContainer = () => {
  return <HomeContainerContent />
}

const HomeContainerContent = () => {
  const [selectedDate, setDate] = useState(dayjs(new Date()))
  const [selectedTime, setTime] = useState(BookingDuration.allDay)

  return (
    <Container maxW='container.xl'>
      <ParkshareHeading title='Book a parking spot quickly' />
      <ParkshareSubHeading text='1. Select a date time' />
      <DateAndTimeSelection
        selectedDate={selectedDate}
        onDateChange={setDate}
        selectedTime={selectedTime}
        onTimeChange={setTime}
      />

      <ParkshareSubHeading text='2. Select a parking type' />
      <ParkingSelection selectedDate={selectedDate} selectedTimeOfDay={selectedTime} />
    </Container>
  )
}

export default HomeContainer
