import Spotlight from '../../components/Spotlight'
import { logSentryException } from '../loggingHelpers/sentryHelper'

export const handleHighLightsModule = (id: string) => {
  switch (id) {
    case 'module_highlights_home':
      return <Spotlight />
    default:
      logSentryException('handleHighLightsModule no supported module', 'MODULE', id)
  }
}
