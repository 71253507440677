import { z } from 'zod'

export const MenuItem = z.object({
  dish: z.string(),
  price: z.string(),
  nutriScore: z.string().optional(),
  ecoScore: z.string().optional(),
  category: z.string() // vege
})

export type MenuItem = z.infer<typeof MenuItem>

export const Menu = MenuItem.array()
export type Menu = z.infer<typeof Menu>

export const VEGETARIAN = 'vegetarian'
export const PRICE_PER_100G = 'PricePer100gr'
