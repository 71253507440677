import { DependencyList, RefObject, useEffect, useRef, useState } from 'react'

export function useIsInViewport(ref: RefObject<Element>) {
  const [isIntersecting, setIsIntersecting] = useState(false)

  useEffect(() => {
    if (ref.current) {
      const observer = new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting)
      )
      observer.observe(ref.current)

      return () => {
        observer.disconnect()
      }
    }
  }, [ref])

  return isIntersecting
}

export function useOnEnterViewport(
  ref: RefObject<Element>,
  fn: () => Promise<void>,
  deps?: DependencyList
) {
  const isIntersecting = useRef(false)
  const [isLoading, setLoading] = useState(false)

  useEffect(
    () => {
      if (ref.current) {
        const observer = new IntersectionObserver(([entry]) => {
          const isInt = isIntersecting.current
          isIntersecting.current = entry.isIntersecting
          if (entry.isIntersecting && !isInt && !isLoading) {
            setLoading(true)
            fn().finally(() => setLoading(false))
          }
        })
        observer.observe(ref.current)

        return () => {
          observer.disconnect()
        }
      }
    },
    deps ? [...deps, isLoading] : [isLoading]
  )

  return isLoading
}
