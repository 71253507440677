import { createRef, useEffect } from 'react'
import { ParkingLayoutProps } from './SingleParkingMap'

export const ParkingG = (props: ParkingLayoutProps) => {
  const ref = createRef<any>()
  useEffect(() => {
    props.available.forEach(available => {
      const spot = ref.current && ref.current.parentNode.getElementById(`space_${available}`)
      if (!spot) {
        return
      }
      spot.classList.add('spot-available')
    })
  }, [ref, props.available])
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 275 568'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'>
      <desc>Created with Sketch.</desc>
      <g id='ParkingG' stroke='none' strokeWidth='1' fill='none' ref={ref} fillRule='evenodd'>
        <g id='legends' transform='translate(4.000000, 4.000000)'>
          <path
            d='M259.5,462.5 L210.710957,462.5 L0.5,462.5 L0.5,0.5 L259.5,0.5 L259.5,462.5 Z'
            id='Rectangle'
            stroke='#353535'
            fill='#FFFFFF'
          />
          <path
            d='M198.787837,362.515354 L198.787837,449 L190.344986,449 L190.344986,412.812825 L35,412.812825 L35,482.028289 L0.5,482.028289 L0.5,0.5 L198.787837,0.5 L198.787837,62.5039864 L190.344986,62.5039864 L190.344986,25.9201483 L110.935325,25.9201483 L110.935325,142.091719 L190.344986,142.091719 L190.344986,105.480172 L198.787837,105.480172 L198.787837,191.5 L190.344986,191.5 L190.344986,154.910606 L44.8231918,154.910606 L44.8231918,271.198084 L190.344986,271.198084 L190.344986,234.5 L198.787837,234.5 L198.787837,320.5 L190.344986,320.5 L190.344986,283.769937 L25.7294235,283.769937 L25.7294235,320.5 L15.7588006,320.5 L15.7588006,399.189815 L190.344986,399.189815 L190.344986,362.515354 L198.787837,362.515354 Z'
            id='Path-4'
            stroke='#E6E6E6'
            fill='#F8F8F8'
          />
          <path
            d='M16.5,286.533971 L16.5,317.466029 C16.1692112,317.488556 15.8354951,317.5 15.4991953,317.5 C11.3551744,317.5 7.6026393,315.763754 4.88665282,312.954113 C2.17669763,310.150711 0.499195338,306.278217 0.499195338,302 C0.499195338,297.721783 2.17669763,293.849289 4.88665282,291.045887 C7.6026393,288.236246 11.3551744,286.5 15.4991953,286.5 C15.8354951,286.5 16.1692112,286.511444 16.5,286.533971 Z'
            id='Combined-Shape'
            stroke='#9DD135'
            transform='translate(8.499598, 302.000000) rotate(180.000000) translate(-8.499598, -302.000000) '
          />
          <g
            onClick={props.onSpotClick}
            className='spot'
            id='space_18'
            transform='translate(56.000000, 166.000000) rotate(-90.000000) translate(-56.000000, -166.000000) translate(49.500000, 159.500000)'
            stroke='#979797'>
            <path d='M12,12 L1,12 L1,1 L12,1 L12,12 Z' id='Rectangle' />
          </g>
          <path
            d='M260.5,0.5 L260.5,482.5 L242.5,482.5 L242.5,0.5 L260.5,0.5 Z'
            id='Rectangle'
            stroke='#E6E6E6'
            fill='#F8F8F8'
          />
          <polygon
            id='Path-2-Copy-2'
            fill='#D8D8D8'
            transform='translate(229.532894, 150.216725) rotate(-180.000000) translate(-229.532894, -150.216725) '
            points='228.69178 129 230.436352 129 230.436352 155.45414 233.065788 155.45414 229.664114 171.433451 226 155.483558 228.69178 155.45414'
          />
          <polygon
            id='Path-2'
            fill='#D8D8D8'
            points='212.69178 130 214.436352 130 214.436352 156.45414 217.065788 156.45414 213.664114 172.433451 210 156.483558 212.69178 156.45414'
          />
          <polygon
            id='Path-2-Copy-2'
            fill='#D8D8D8'
            transform='translate(229.532894, 405.216725) rotate(-180.000000) translate(-229.532894, -405.216725) '
            points='228.69178 384 230.436352 384 230.436352 410.45414 233.065788 410.45414 229.664114 426.433451 226 410.483558 228.69178 410.45414'
          />
          <polygon
            id='Path-2'
            fill='#D8D8D8'
            points='212.69178 385 214.436352 385 214.436352 411.45414 217.065788 411.45414 213.664114 427.433451 210 411.483558 212.69178 411.45414'
          />
          <path
            d='M260.5,482.5 L211.521384,482.5 L0.5,482.5 L0.5,0.5 L260.5,0.5 L260.5,482.5 Z'
            id='Rectangle'
            stroke='#353535'
          />
          <circle id='Oval' stroke='#9DD135' cx='93' cy='84' r='12' />
          <circle id='Oval' stroke='#9DD135' cx='26.5' cy='69.5' r='15.5' />
          <line
            x1='50.5'
            y1='175.5'
            x2='61.5'
            y2='175.5'
            id='Line'
            stroke='#979797'
            strokeLinecap='square'
          />
          <line
            x1='50.5'
            y1='178.5'
            x2='61.5'
            y2='178.5'
            id='Line'
            stroke='#979797'
            strokeLinecap='square'
          />
          <line
            x1='50.5'
            y1='181.5'
            x2='61.5'
            y2='181.5'
            id='Line'
            stroke='#979797'
            strokeLinecap='square'
          />
          <line
            x1='50.5'
            y1='184.5'
            x2='61.5'
            y2='184.5'
            id='Line'
            stroke='#979797'
            strokeLinecap='square'
          />
          <line
            x1='50.5'
            y1='187.5'
            x2='61.5'
            y2='187.5'
            id='Line'
            stroke='#979797'
            strokeLinecap='square'
          />
          <circle id='Oval' stroke='#9DD135' cx='60.5' cy='27.5' r='15.5' />
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_83'
          transform='translate(183.000000, 49.500000) rotate(-90.000000) translate(-183.000000, -49.500000) translate(166.000000, 41.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='83'
            transform='translate(17.500000, 8.500000) rotate(90.000000) translate(-17.500000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.9384766' y='11.5'>
              83
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_84'
          transform='translate(164.000000, 49.500000) rotate(-90.000000) translate(-164.000000, -49.500000) translate(147.000000, 41.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='84'
            transform='translate(17.500000, 8.500000) rotate(90.000000) translate(-17.500000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.9384766' y='11.5'>
              84
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_85'
          transform='translate(145.000000, 49.500000) rotate(-90.000000) translate(-145.000000, -49.500000) translate(128.000000, 41.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='85'
            transform='translate(17.500000, 8.500000) rotate(90.000000) translate(-17.500000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.9384766' y='11.5'>
              85
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_86'
          transform='translate(126.000000, 49.500000) rotate(-90.000000) translate(-126.000000, -49.500000) translate(109.000000, 41.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='86'
            transform='translate(17.500000, 8.500000) rotate(90.000000) translate(-17.500000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.9384766' y='11.5'>
              86
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_90'
          transform='translate(183.000000, 126.500000) rotate(-90.000000) translate(-183.000000, -126.500000) translate(166.000000, 118.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='90'
            transform='translate(17.500000, 8.500000) rotate(90.000000) translate(-17.500000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.9384766' y='11.5'>
              90
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_89'
          transform='translate(164.000000, 126.500000) rotate(-90.000000) translate(-164.000000, -126.500000) translate(147.000000, 118.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='89'
            transform='translate(17.500000, 8.500000) rotate(90.000000) translate(-17.500000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.9384766' y='11.5'>
              89
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_88'
          transform='translate(145.000000, 126.500000) rotate(-90.000000) translate(-145.000000, -126.500000) translate(128.000000, 118.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='88'
            transform='translate(17.500000, 8.500000) rotate(90.000000) translate(-17.500000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.9384766' y='11.5'>
              88
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_87'
          transform='translate(126.000000, 126.500000) rotate(-90.000000) translate(-126.000000, -126.500000) translate(109.000000, 118.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='87'
            transform='translate(17.500000, 8.500000) rotate(90.000000) translate(-17.500000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.9384766' y='11.5'>
              87
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_102'
          transform='translate(155.000000, 255.500000) rotate(-90.000000) translate(-155.000000, -255.500000) translate(138.000000, 247.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='102'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              102
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_101'
          transform='translate(136.000000, 255.500000) rotate(-90.000000) translate(-136.000000, -255.500000) translate(119.000000, 247.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='101'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              101
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_100'
          transform='translate(117.000000, 255.500000) rotate(-90.000000) translate(-117.000000, -255.500000) translate(100.000000, 247.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='100'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              100
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_99'
          transform='translate(98.000000, 255.500000) rotate(-90.000000) translate(-98.000000, -255.500000) translate(81.000000, 247.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='99'
            transform='translate(17.500000, 8.500000) rotate(90.000000) translate(-17.500000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.9384766' y='11.5'>
              99
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_98'
          transform='translate(79.000000, 255.500000) rotate(-90.000000) translate(-79.000000, -255.500000) translate(62.000000, 247.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='98'
            transform='translate(17.500000, 8.500000) rotate(90.000000) translate(-17.500000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.9384766' y='11.5'>
              98
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_97'
          transform='translate(60.000000, 255.500000) rotate(-90.000000) translate(-60.000000, -255.500000) translate(43.000000, 247.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='97'
            transform='translate(17.500000, 8.500000) rotate(90.000000) translate(-17.500000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.9384766' y='11.5'>
              97
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_105'
          transform='translate(155.000000, 307.500000) rotate(-90.000000) translate(-155.000000, -307.500000) translate(138.000000, 299.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='105'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              105
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_106'
          transform='translate(136.000000, 307.500000) rotate(-90.000000) translate(-136.000000, -307.500000) translate(119.000000, 299.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='106'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              106
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_107'
          transform='translate(117.000000, 307.500000) rotate(-90.000000) translate(-117.000000, -307.500000) translate(100.000000, 299.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='107'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              107
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_108'
          transform='translate(98.000000, 307.500000) rotate(-90.000000) translate(-98.000000, -307.500000) translate(81.000000, 299.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='108'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              108
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_109'
          transform='translate(79.000000, 307.500000) rotate(-90.000000) translate(-79.000000, -307.500000) translate(62.000000, 299.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='109'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              109
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_110'
          transform='translate(60.000000, 307.500000) rotate(-90.000000) translate(-60.000000, -307.500000) translate(43.000000, 299.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='110'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              110
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_111'
          transform='translate(41.000000, 307.500000) rotate(-90.000000) translate(-41.000000, -307.500000) translate(24.000000, 299.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='111'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              111
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_120'
          transform='translate(183.000000, 383.500000) rotate(-90.000000) translate(-183.000000, -383.500000) translate(166.000000, 375.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='120'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              120
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_119'
          transform='translate(164.000000, 383.500000) rotate(-90.000000) translate(-164.000000, -383.500000) translate(147.000000, 375.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='119'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              119
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_118'
          transform='translate(145.000000, 383.500000) rotate(-90.000000) translate(-145.000000, -383.500000) translate(128.000000, 375.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='118'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              118
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_116'
          transform='translate(107.000000, 383.500000) rotate(-90.000000) translate(-107.000000, -383.500000) translate(90.000000, 375.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='116'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              116
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_115'
          transform='translate(88.000000, 383.500000) rotate(-90.000000) translate(-88.000000, -383.500000) translate(71.000000, 375.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='115'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              115
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_114'
          transform='translate(69.000000, 383.500000) rotate(-90.000000) translate(-69.000000, -383.500000) translate(52.000000, 375.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='114'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              114
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_117'
          transform='translate(126.000000, 383.500000) rotate(-90.000000) translate(-126.000000, -383.500000) translate(109.000000, 375.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='117'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              117
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_113'
          transform='translate(50.000000, 383.500000) rotate(-90.000000) translate(-50.000000, -383.500000) translate(33.000000, 375.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='113'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              113
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_121'
          transform='translate(183.000000, 436.500000) rotate(-90.000000) translate(-183.000000, -436.500000) translate(166.000000, 428.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='121'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              121
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_122'
          transform='translate(164.000000, 436.500000) rotate(-90.000000) translate(-164.000000, -436.500000) translate(147.000000, 428.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='122'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              122
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_123'
          transform='translate(145.000000, 436.500000) rotate(-90.000000) translate(-145.000000, -436.500000) translate(128.000000, 428.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='123'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              123
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_125'
          transform='translate(107.000000, 436.500000) rotate(-90.000000) translate(-107.000000, -436.500000) translate(90.000000, 428.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='125'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              125
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_126'
          transform='translate(88.000000, 436.500000) rotate(-90.000000) translate(-88.000000, -436.500000) translate(71.000000, 428.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='126'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              126
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_127'
          transform='translate(69.000000, 436.500000) rotate(-90.000000) translate(-69.000000, -436.500000) translate(52.000000, 428.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='127'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              127
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_124'
          transform='translate(126.000000, 436.500000) rotate(-90.000000) translate(-126.000000, -436.500000) translate(109.000000, 428.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='124'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              124
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_128'
          transform='translate(50.000000, 436.500000) rotate(-90.000000) translate(-50.000000, -436.500000) translate(33.000000, 428.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='128'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              128
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_112'
          transform='translate(31.000000, 383.500000) rotate(-90.000000) translate(-31.000000, -383.500000) translate(14.000000, 375.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='112'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              112
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_94'
          transform='translate(117.000000, 178.500000) rotate(-90.000000) translate(-117.000000, -178.500000) translate(100.000000, 170.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='94'
            transform='translate(17.500000, 8.500000) rotate(90.000000) translate(-17.500000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.9384766' y='11.5'>
              94
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_95'
          transform='translate(98.000000, 178.500000) rotate(-90.000000) translate(-98.000000, -178.500000) translate(81.000000, 170.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='95'
            transform='translate(17.500000, 8.500000) rotate(90.000000) translate(-17.500000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.9384766' y='11.5'>
              95
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_96'
          transform='translate(79.000000, 178.500000) rotate(-90.000000) translate(-79.000000, -178.500000) translate(62.000000, 170.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='96'
            transform='translate(17.500000, 8.500000) rotate(90.000000) translate(-17.500000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.9384766' y='11.5'>
              96
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_93'
          transform='translate(136.000000, 178.500000) rotate(-90.000000) translate(-136.000000, -178.500000) translate(119.000000, 170.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='93'
            transform='translate(17.500000, 8.500000) rotate(90.000000) translate(-17.500000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.9384766' y='11.5'>
              93
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_92'
          transform='translate(155.000000, 178.500000) rotate(-90.000000) translate(-155.000000, -178.500000) translate(138.000000, 170.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='92'
            transform='translate(17.500000, 8.500000) rotate(90.000000) translate(-17.500000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.9384766' y='11.5'>
              92
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_91'
          transform='translate(178.500000, 178.500000) rotate(90.000000) translate(-178.500000, -178.500000) translate(161.000000, 165.000000)'>
          <g
            onClick={props.onSpotClick}
            className='spot'
            id='space_18'
            fill='#DADADA'
            stroke='#D3D3D3'>
            <polygon id='Rectangle' points='0.5 0.5 34.5 0.5 34.5 26.5 0.5 26.5' />
          </g>
          <path
            d='M19.8381955,15.9504391 L20.8610988,15.4627316 C20.9847713,15.4044529 21.0352626,15.2620727 20.9738765,15.1446898 L20.7515713,14.7196058 C20.6901852,14.6022229 20.5401608,14.5542994 20.4164883,14.6125636 L19.851196,14.8846805 L18.9525296,13.0693369 C18.8780057,12.9187884 18.6752622,12.7965827 18.5,12.7965827 L16.4336547,12.7965827 L16.362213,12.3220111 L18.2499998,12.3220111 C18.3880765,12.3220111 18.5,12.2157799 18.5,12.0847253 L18.5,11.6101537 C18.5,11.479099 18.3880765,11.3728678 18.2499998,11.3728678 L16.2193603,11.3728678 L16.1463927,10.8880569 C16.6378936,10.8196113 17.0131987,10.4119211 16.9996337,9.92351843 C16.9859313,9.42971367 16.5675506,9.02396419 16.0476836,9.00105244 C15.4737243,8.97575103 15,9.4099302 15,9.94913846 C15.0059357,10.0387724 14.9755096,9.82022726 15.5050201,13.3382531 C15.5402221,13.5720485 15.7511749,13.7457115 16,13.7457115 L18.1824796,13.7457115 L19.1633756,15.7271726 C19.2826383,15.9681516 19.5874325,16.0685896 19.8381955,15.9504391 Z M15.6104212,17 C16.6763943,17 17.5950186,16.3621651 18,15.4507259 L17.6180486,14.643519 L17.2545647,14.643519 C17.1390668,15.4418373 16.4458185,16.0574076 15.6104212,16.0574076 C14.6944476,16.0574076 13.9492441,15.3174259 13.9492441,14.4078709 C13.9492441,13.7965867 14.2858451,13.2619054 14.7846414,12.976953 C14.7295865,12.5945426 14.6831353,12.2720798 14.6439118,12 C13.6816174,12.3822378 13,13.3174518 13,14.4078709 C13,15.8371779 14.1710279,17 15.6104212,17 Z'
            id='w'
            fill='#FFFFFF'
            fillRule='nonzero'
            transform='translate(17.000000, 13.000000) rotate(270.000000) translate(-17.000000, -13.000000) '
          />
        </g>
        <g
          id='spage_103'
          transform='translate(178.500000, 255.500000) rotate(90.000000) translate(-178.500000, -255.500000) translate(161.000000, 242.000000)'>
          <g
            onClick={props.onSpotClick}
            className='spot'
            id='space_18'
            fill='#DADADA'
            stroke='#D3D3D3'>
            <polygon id='Rectangle' points='0.5 0.5 34.5 0.5 34.5 26.5 0.5 26.5' />
          </g>
          <path
            d='M19.8381955,15.9504391 L20.8610988,15.4627316 C20.9847713,15.4044529 21.0352626,15.2620727 20.9738765,15.1446898 L20.7515713,14.7196058 C20.6901852,14.6022229 20.5401608,14.5542994 20.4164883,14.6125636 L19.851196,14.8846805 L18.9525296,13.0693369 C18.8780057,12.9187884 18.6752622,12.7965827 18.5,12.7965827 L16.4336547,12.7965827 L16.362213,12.3220111 L18.2499998,12.3220111 C18.3880765,12.3220111 18.5,12.2157799 18.5,12.0847253 L18.5,11.6101537 C18.5,11.479099 18.3880765,11.3728678 18.2499998,11.3728678 L16.2193603,11.3728678 L16.1463927,10.8880569 C16.6378936,10.8196113 17.0131987,10.4119211 16.9996337,9.92351843 C16.9859313,9.42971367 16.5675506,9.02396419 16.0476836,9.00105244 C15.4737243,8.97575103 15,9.4099302 15,9.94913846 C15.0059357,10.0387724 14.9755096,9.82022726 15.5050201,13.3382531 C15.5402221,13.5720485 15.7511749,13.7457115 16,13.7457115 L18.1824796,13.7457115 L19.1633756,15.7271726 C19.2826383,15.9681516 19.5874325,16.0685896 19.8381955,15.9504391 Z M15.6104212,17 C16.6763943,17 17.5950186,16.3621651 18,15.4507259 L17.6180486,14.643519 L17.2545647,14.643519 C17.1390668,15.4418373 16.4458185,16.0574076 15.6104212,16.0574076 C14.6944476,16.0574076 13.9492441,15.3174259 13.9492441,14.4078709 C13.9492441,13.7965867 14.2858451,13.2619054 14.7846414,12.976953 C14.7295865,12.5945426 14.6831353,12.2720798 14.6439118,12 C13.6816174,12.3822378 13,13.3174518 13,14.4078709 C13,15.8371779 14.1710279,17 15.6104212,17 Z'
            id='w'
            fill='#FFFFFF'
            fillRule='nonzero'
            transform='translate(17.000000, 13.000000) rotate(270.000000) translate(-17.000000, -13.000000) '
          />
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_104'
          transform='translate(178.500000, 307.500000) rotate(90.000000) translate(-178.500000, -307.500000) translate(161.000000, 294.000000)'>
          <g
            onClick={props.onSpotClick}
            className='spot'
            id='space_18'
            fill='#DADADA'
            stroke='#D3D3D3'>
            <polygon id='Rectangle' points='0.5 0.5 34.5 0.5 34.5 26.5 0.5 26.5' />
          </g>
          <path
            d='M19.8381955,15.9504391 L20.8610988,15.4627316 C20.9847713,15.4044529 21.0352626,15.2620727 20.9738765,15.1446898 L20.7515713,14.7196058 C20.6901852,14.6022229 20.5401608,14.5542994 20.4164883,14.6125636 L19.851196,14.8846805 L18.9525296,13.0693369 C18.8780057,12.9187884 18.6752622,12.7965827 18.5,12.7965827 L16.4336547,12.7965827 L16.362213,12.3220111 L18.2499998,12.3220111 C18.3880765,12.3220111 18.5,12.2157799 18.5,12.0847253 L18.5,11.6101537 C18.5,11.479099 18.3880765,11.3728678 18.2499998,11.3728678 L16.2193603,11.3728678 L16.1463927,10.8880569 C16.6378936,10.8196113 17.0131987,10.4119211 16.9996337,9.92351843 C16.9859313,9.42971367 16.5675506,9.02396419 16.0476836,9.00105244 C15.4737243,8.97575103 15,9.4099302 15,9.94913846 C15.0059357,10.0387724 14.9755096,9.82022726 15.5050201,13.3382531 C15.5402221,13.5720485 15.7511749,13.7457115 16,13.7457115 L18.1824796,13.7457115 L19.1633756,15.7271726 C19.2826383,15.9681516 19.5874325,16.0685896 19.8381955,15.9504391 Z M15.6104212,17 C16.6763943,17 17.5950186,16.3621651 18,15.4507259 L17.6180486,14.643519 L17.2545647,14.643519 C17.1390668,15.4418373 16.4458185,16.0574076 15.6104212,16.0574076 C14.6944476,16.0574076 13.9492441,15.3174259 13.9492441,14.4078709 C13.9492441,13.7965867 14.2858451,13.2619054 14.7846414,12.976953 C14.7295865,12.5945426 14.6831353,12.2720798 14.6439118,12 C13.6816174,12.3822378 13,13.3174518 13,14.4078709 C13,15.8371779 14.1710279,17 15.6104212,17 Z'
            id='w'
            fill='#FFFFFF'
            fillRule='nonzero'
            transform='translate(17.000000, 13.000000) rotate(270.000000) translate(-17.000000, -13.000000) '
          />
        </g>
      </g>
    </svg>
  )
}
