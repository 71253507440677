import { JOB_TYPE_STRING_VALUES } from '@/constants/FieldsJobs'
import { FRONT_JOBS_COMPANY, FRONT_JOBS_POST, FRONT_JOB_DETAILS } from '@/constants/Routes'
import useErrorToaster from '@/hooks/useErrorToaster'
import { PublicJob } from '@/models/job/PublicJob'
import { axiosVercelAPI } from '@/utils/connectionHelpers/axios'
import baseFetcher from '@/utils/connectionHelpers/swrFetchers'
import { handleUpdateErrors } from '@/utils/dataHelpers/apiResponseHelper'
import { urlValidator } from '@/utils/dataHelpers/validators'
import { showToast } from '@/utils/showToast'
import { Divider, HStack, Heading, VStack, useBoolean, useTheme } from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { SubmitHandler, useForm } from 'react-hook-form'
import useSWR from 'swr'
import FormBottomButtons from '../shared/form/FormBottomButtons'
import FormInput from '../shared/form/FormInput'
import PortalJobsList from './components/PortalJobsList'

type FormData = {
  title: string
  link: string
}

interface JobsLists {
  extracted: PublicJob[]
  manual: PublicJob[]
}

const ManageJobs = () => {
  return <ManageJobsContent />
}

const fetcher = async (url: string): Promise<JobsLists> => {
  const data = await baseFetcher<PublicJob[]>(url)

  return {
    extracted: data.filter(x => x.type === JOB_TYPE_STRING_VALUES.extracted),
    manual: data.filter(x => x.type === JOB_TYPE_STRING_VALUES.manual)
  }
}

const ManageJobsContent = () => {
  const theme = useTheme()
  const [submitting, setSubmitting] = useBoolean()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<FormData>()

  const {
    data: jobs,
    error: jobsError,
    mutate: jobsUpdate
  } = useSWR<JobsLists, AxiosError>(FRONT_JOBS_COMPANY, fetcher, {
    revalidateOnFocus: false
  })
  useErrorToaster(jobsError)

  const onSubmit: SubmitHandler<FormData> = async data => {
    setSubmitting.on()

    try {
      await axiosVercelAPI.post(FRONT_JOBS_POST, data)

      const newJobsList = { ...jobs } as JobsLists
      newJobsList?.manual?.push({ title: data.title, URL: data.link } as PublicJob)

      await jobsUpdate(newJobsList)
      reset()
      showToast('The new job was successfully posted')
    } catch (e) {
      handleUpdateErrors(e)
    } finally {
      setSubmitting.off()
    }
  }

  const updateJobsHidden = async (id: number, hidden: boolean) => {
    const newJobsList = { ...jobs } as JobsLists
    const jobIndex: number = jobs?.extracted.findIndex(x => x.id === id) || -1

    if (jobIndex > -1) {
      newJobsList.extracted[jobIndex].hidden = hidden
    }

    await jobsUpdate(newJobsList)
  }

  const onDisableAll = async (manual: boolean) => {
    setSubmitting.on()
    try {
      if (manual) {
        await axiosVercelAPI.delete(FRONT_JOBS_COMPANY + '?type=archive')

        const newJobsList = { ...jobs } as JobsLists
        newJobsList.manual = []

        await jobsUpdate(newJobsList)
        showToast('All jobs were deleted')
      } else {
        await axiosVercelAPI.delete(FRONT_JOBS_COMPANY + '?type=hide')
        const newJobsList = { ...jobs } as JobsLists
        newJobsList.extracted = newJobsList.extracted.map(x => ({ ...x, hidden: true }))

        await jobsUpdate(newJobsList)
        showToast('All jobs were hidden')
      }
    } catch (e) {
      handleUpdateErrors(e)
    } finally {
      setSubmitting.off()
    }
  }

  const onToggle = async (job: PublicJob, manual: boolean) => {
    try {
      if (manual) {
        await axiosVercelAPI.delete(
          FRONT_JOB_DETAILS(parseInt(job.id.toString())) + '?type=archive'
        )

        const newJobsList = { ...jobs } as JobsLists
        newJobsList.manual = newJobsList?.manual?.filter(x => x.id !== job.id)

        await jobsUpdate(newJobsList)
        showToast('The job was deleted')
      } else {
        if (!job.hidden) {
          await axiosVercelAPI.delete(FRONT_JOB_DETAILS(parseInt(job.id.toString())) + '?type=hide')
          await updateJobsHidden(job.id, true)
          showToast('The job was hidden')
        } else {
          await axiosVercelAPI.put(FRONT_JOB_DETAILS(parseInt(job.id.toString())), {
            hidden: false
          })
          await updateJobsHidden(job.id, false)
          showToast('The job was made visible')
        }
      }
    } catch (e) {
      handleUpdateErrors(e)
    } finally {
      setSubmitting.off()
    }
  }

  return (
    <VStack w='full' spacing={5} align='start'>
      <Heading>Manage Jobs</Heading>
      <VStack w='full' spacing={8}>
        {jobs && (
          <>
            <PortalJobsList
              title='Automatically published jobs'
              jobs={jobs.extracted}
              onToggle={onToggle}
              onDisableAll={onDisableAll}
            />
            <Divider />
            <PortalJobsList
              title='Manually published jobs'
              jobs={jobs.manual}
              onToggle={onToggle}
              onDisableAll={onDisableAll}
              manual
            />
          </>
        )}
        <VStack w='full' align='start' spacing={5}>
          <Heading size='md'>Publish a new job</Heading>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <VStack align='start' w='full' background={theme.colors.white} p={5} spacing={5}>
              <HStack w='full' spacing={5}>
                <FormInput<FormData>
                  name='title'
                  label='Job title'
                  register={register}
                  errors={errors}
                  rules={{
                    required: 'Job title is missing'
                  }}
                />
                <FormInput<FormData>
                  name='link'
                  label='Link'
                  register={register}
                  errors={errors}
                  rules={{
                    required: 'Job link is missing',
                    pattern: {
                      value: urlValidator,
                      message: 'The job link is not a valid url'
                    }
                  }}
                />
              </HStack>
              <FormBottomButtons loading={submitting} />
            </VStack>
          </form>
        </VStack>
      </VStack>
    </VStack>
  )
}

// noinspection JSUnusedGlobalSymbols
export default ManageJobs
