// noinspection JSUnusedGlobalSymbols

import { Crop } from 'react-image-crop'

export function toBase64(file: File): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

interface CroppedImg {
  dataUrl: string
  file: File
}

export async function getCroppedImg(
  image: HTMLImageElement | undefined,
  crop: Crop,
  name: string
): Promise<CroppedImg> {
  if (!image) {
    throw Error('No image supplied')
  }

  const canvas = document.createElement('canvas')
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  canvas.width = crop.width
  canvas.height = crop.height
  const ctx = canvas.getContext('2d')

  ctx!.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  )

  return new Promise<CroppedImg>((resolve, reject) => {
    canvas.toBlob(file => {
      if (!file) {
        reject()
        return
      }

      const reader = new FileReader()
      reader.onload = () =>
        resolve({ file: new File([file], name), dataUrl: reader.result as string })

      reader.onerror = () => {
        reject()
      }

      reader.readAsDataURL(file)

      //  resolve(file)
    }, 'image/jpeg')
  })
}
