import { DATE_FORMAT_API, FRONT_DATE_FORMAT } from '@/constants/DateConstants'
import { getEventCategoryFilters, getEventLocationFilters } from '@/constants/FieldsEvent'
import { EVENT_CALENDER_MEMBER_ONLY_TAG } from '@/constants/RequestConstants'
import { FRONT_EVENTS_FROM_RANGE } from '@/constants/Routes'
import useQueryFilters from '@/hooks/useQueryFilters'
import { FilterItem } from '@/models/EudonetModels'
import { Calendar } from '@/models/calendar/Calendar'
import { Event } from '@/models/calendar/Event'
import userStore from '@/stores/userStore'
import baseFetcher from '@/utils/connectionHelpers/swrFetchers'
import { createCalendar } from '@/utils/dataHelpers/calendarHelpers'
import { isKnovaEvent } from '@/utils/dataHelpers/validateEvents'
import { HStack } from '@chakra-ui/react'
import dayjs from 'dayjs'
import en from 'dayjs/locale/en'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import useSWR from 'swr'
import SimpleListDisplay from '../../shared/SimpleListDisplay'
import FilterSection, { FilterType } from '../../shared/filters/FilterSection'
import EventCard from './EventCard'
import EventPreviewModal from './EventPreviewModal'
import FilterToggles from './FilterToggles'

const calendar = require('dayjs/plugin/calendar')
const weekday = require('dayjs/plugin/weekday')
const weekOfYear = require('dayjs/plugin/weekOfYear')
dayjs.locale({ ...en, weekStart: 1 })
dayjs.extend(calendar)
dayjs.extend(weekday)
dayjs.extend(weekOfYear)

const filters: FilterType[] = [
  { name: 'Categories', ...getEventCategoryFilters() },
  { name: 'Locations', filterList: getEventLocationFilters() }
]

const EventsCalendar = () => {
  const router = useRouter()

  const [currentMonth, setCurrentMonth] = useState<number>(dayjs().month())
  const [currentYear, setCurrenYear] = useState<number>(dayjs().year())
  const [calendar, setCalendar] = useState<Calendar[]>([])
  const [events, setEvents] = useState<Event[]>([])
  const [selectedFilters, setSelectedFilters] = useState<FilterItem[]>([])
  const [queryFilters, addQueryFilter, removeQueryFilter] = useQueryFilters('')
  const [membersOnly, setMembersOnly] = useState<boolean>(
    router.query[EVENT_CALENDER_MEMBER_ONLY_TAG] === 'true'
  )
  const [isKnovaMember] = userStore(state => [state.isKnovaMember])
  const [selectedEvent, setSelectedEvent] = useState<Event | undefined>(undefined)

  const { data } = useSWR<Event[]>(
    FRONT_EVENTS_FROM_RANGE(
      dayjs(calendar[0]?.date).format(DATE_FORMAT_API),
      dayjs(calendar[calendar?.length - 1]?.date).format(DATE_FORMAT_API),
      queryFilters,
      isKnovaMember ? (membersOnly ? 'only' : 'all') : 'none'
    ),
    baseFetcher,
    { revalidateOnFocus: false }
  )

  useEffect(() => {
    if (!data) {
      return
    }

    //Some events can take place over several weeks, this code takes care of that
    let formattedEvents = [...data]
    data.forEach((event: Event) => {
      const startDate = dayjs(event.startDate)
      const endDate = dayjs(event.endDate)
      const startWeek = startDate.week()
      const endWeek = endDate.week()
      if (startWeek !== endWeek) {
        const numberOfWeeks = endWeek - startWeek
        let newEvents = []
        for (let i = 1; i <= numberOfWeeks; i++) {
          const newEvent = Object.assign({}, event)
          newEvent.id = Math.random() * 10000
          newEvent.startDate = startDate
            .add(i, 'week')
            .subtract(startDate.weekday(), 'day')
            .toDate()
          newEvents.push(newEvent)
        }
        formattedEvents.push(...newEvents)
      }
    })
    setEvents(formattedEvents)
  }, [data])

  useEffect(() => {
    const calendar = createCalendar(currentYear, currentMonth)
    setCalendar(calendar)
  }, [currentYear, currentMonth])

  const handleMonthDecrease = () => {
    if (currentMonth - 1 < 0) {
      setCurrentMonth(11)
      setCurrenYear(currentYear - 1)
    } else {
      setCurrentMonth(currentMonth - 1)
    }
  }

  const handleMonthIncrease = () => {
    if (currentMonth + 1 > 11) {
      setCurrentMonth(0)
      setCurrenYear(currentYear + 1)
    } else {
      setCurrentMonth(currentMonth + 1)
    }
  }

  const addFilter = async (item: FilterItem) => {
    if (selectedFilters.some(temp => temp.Id === item.Id)) {
      return
    }
    setSelectedFilters([...selectedFilters, item])

    addQueryFilter(selectedFilters, item)
  }

  const removeFilter = (item: FilterItem) => {
    setSelectedFilters(selectedFilters.filter(filter => item.Id !== filter.Id))

    removeQueryFilter(selectedFilters, item)
  }

  const addEvents = (day: Calendar) => {
    if (!events || events.length === 0) {
      return <></>
    }

    const startDay = dayjs(day.date).format(FRONT_DATE_FORMAT)
    const eventsDisplayed = events.filter(
      (item: Event) => startDay === dayjs(item.startDate).format(FRONT_DATE_FORMAT)
    )
    if (!eventsDisplayed || eventsDisplayed.length === 0) {
      return <></>
    }
    return (
      eventsDisplayed
        ///The date here is just placeholder since we're comparing time on the same date.
        .map(event => {
          const startDate = dayjs(event.startDate)
          const endDate = dayjs(event.endDate)
          let duration = endDate.diff(startDate, 'days')
          if (duration < 0) {
            return <></>
          }
          if (duration > 6) {
            duration = 6 - startDate.weekday()
          }

          return (
            <button
              className={`cal-date-detail _${duration + 1}-day ${
                isKnovaEvent(event) && 'bg-knova'
              }`}
              key={event.id}
              onClick={() => {
                setSelectedEvent(event)
                document.body.style.overflow = 'hidden'
              }}
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'left'
              }}>
              <div className='cal-date-detail-text'>
                {event.startTime} {event.title}
              </div>
            </button>
          )
        })
    )
  }

  return (
    <>
      {selectedEvent && (
        <EventPreviewModal selectedEvent={selectedEvent} setSelectedEvent={setSelectedEvent} />
      )}
      <FilterSection
        filterTypes={filters}
        selectedFilters={selectedFilters}
        addFilter={addFilter}
        removeFilter={removeFilter}
        additionalButtons={
          <HStack ml={10}>
            <FilterToggles
              calendarToggled={true}
              memberEventsToggled={membersOnly}
              onMembersEvents={setMembersOnly}
            />
          </HStack>
        }
        bottomMargin={false}
      />
      <div className='section bg-grey wf-section margin-bottom-4x'>
        <div className='wrapper'>
          <div className='cal-wrapper'>
            <a href='#' className='button-with-icon margin-right-1x' onClick={handleMonthDecrease}>
              <div className='button-icon-content'>
                <div className='fa-l large'></div>
              </div>
            </a>
            <span className='text margin-right-1x' style={{ textTransform: 'capitalize' }}>
              {dayjs(`${currentYear}-${currentMonth + 1}`).format('MMMM')} {currentYear}
            </span>
            <a href='#' className='button-with-icon' onClick={handleMonthIncrease}>
              <div className='button-icon-content'>
                <div className='fa-l large'></div>
              </div>
            </a>
            <div className='cal-grid-header'>
              <div className='cal-header'>
                <div>MON</div>
              </div>
              <div className='cal-header'>
                <div>TUE</div>
              </div>
              <div className='cal-header'>
                <div>WED</div>
              </div>
              <div className='cal-header'>
                <div>THU</div>
              </div>
              <div className='cal-header'>
                <div>FRI</div>
              </div>
              <div className='cal-header'>
                <div>SAT</div>
              </div>
              <div className='cal-header'>
                <div>SUN</div>
              </div>
            </div>
            {calendar.length > 0 && (
              <div className='cal-grid'>
                {calendar.map((day: Calendar) => (
                  <div className={`cal-item ${!day.isCurrentMonth && 'disabled'}`} key={day.date}>
                    <>
                      <div className='cal-date'>{day.dayOfMonth}</div>
                      {events && addEvents(day)}
                    </>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='wrapper'>
        {data && data.length > 0 && (
          <SimpleListDisplay<Event>
            listItems={data}
            card={(item, key) => <EventCard event={item} key={key} />}
          />
        )}
      </div>
    </>
  )
}

export default EventsCalendar
