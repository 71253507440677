import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  Textarea,
  TextareaProps,
  useTheme,
  VStack
} from '@chakra-ui/react'
import {
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister,
  ValidationValueMessage
} from 'react-hook-form'

interface Props<TFormValues extends FieldValues> extends TextareaProps {
  name: Path<TFormValues>
  label: string
  rules?: RegisterOptions
  register: UseFormRegister<TFormValues>
  errors?: any
  watch?: string
}

const FormTextarea = <TFormValues extends FieldValues>({
  errors,
  name,
  label,
  register,
  rules,
  watch,
  ...props
}: Props<TFormValues>) => {
  const theme = useTheme()

  const errorMessages = errors[name]
  const hasError = !!(errors && errorMessages)

  const max =
    typeof rules?.maxLength === 'number'
      ? rules?.maxLength
      : (rules?.maxLength as ValidationValueMessage<number>)?.value

  return (
    <FormControl isInvalid={hasError} isRequired={!!rules?.required}>
      <VStack w='full' align='start' spacing={2}>
        <FormLabel fontSize='xs'>{label.toUpperCase()}</FormLabel>
        <Textarea
          {...register(name, { ...rules, setValueAs: v => (v === '' ? undefined : v) })}
          {...props}
        />
        {hasError && <FormErrorMessage>{errorMessages.message}</FormErrorMessage>}
        {watch && max && (
          <Text
            w='full'
            align='end'
            fontSize='xs'
            textColor={watch.length > max ? theme.colors.brand.error : 'gray'}>
            {watch.length + '/' + max}
          </Text>
        )}
      </VStack>
    </FormControl>
  )
}

export default FormTextarea
