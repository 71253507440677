import Lottie from 'lottie-react'
import animationData from '../../public/loading.json'

interface Props {
  additionalClasses?: string
}

const Loading = ({ additionalClasses }: Props) => {
  return (
    <div className={`loading ${additionalClasses}`}>
      <Lottie animationData={animationData} />
    </div>
  )
}

export default Loading
