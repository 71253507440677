import { FRONT_NEWS } from '@/constants/Routes'
import { News } from '@/models/calendar/News'
import { getPaginatedFetcher } from '@/utils/connectionHelpers/swrFetchers'
import { AxiosError } from 'axios'
import useSWR from 'swr'
import ListDisplay from '../shared/ListDisplay'
import NewsCard from './components/NewsCard'

interface Props {
  title: string
  moreTitle?: string
  moreLink: string
}

const RecentHighlightedNews = ({ title, moreTitle = 'View more', moreLink }: Props) => {
  const { data } = useSWR<{ data: News[]; items: number }, AxiosError>(
    FRONT_NEWS,
    getPaginatedFetcher(1, 3, true, '&highlight=true&linkedCompany=true'),
    { revalidateOnFocus: false }
  )

  return (
    <>
      <ListDisplay<News>
        title={title}
        moreLink={moreLink}
        moreTitle={moreTitle}
        cardsClass='anim-news'
        listItems={data?.data ?? []}
        card={(item, key) => {
          const additionalClass = `_${key + 1}`
          return <NewsCard news={item} key={key} additionalClass={additionalClass} />
        }}
      />
    </>
  )
}

// noinspection JSUnusedGlobalSymbols
export default RecentHighlightedNews
