import { DEFAULT_LIST_SIZE } from '@/constants/RequestConstants'
import { FRONT_NEWS_SUCCESS_STORIES } from '@/constants/Routes'
import { News } from '@/models/calendar/News'
import baseFetcher from '@/utils/connectionHelpers/swrFetchers'
import { AxiosError } from 'axios'
import { useState } from 'react'
import useSWR from 'swr'
import SimpleListDisplay from '../shared/SimpleListDisplay'
import ListFetchingHandling from '../shared/dataFetching/ListFetchingHandling'
import GlobalPagination from '../shared/pagination/GlobalPagination'
import SuccessStoryCard from './components/SuccessStoryCard'

const SuccessStoriesList = () => {
  const [currentPage, setCurrentPage] = useState(1)

  const { data, error } = useSWR<{ data: News[]; items: number }, AxiosError>(
    FRONT_NEWS_SUCCESS_STORIES(DEFAULT_LIST_SIZE, currentPage),
    baseFetcher,
    { revalidateOnFocus: false }
  )

  return (
    <>
      <ListFetchingHandling data={data} error={error} />
      {data && data.data.length > 0 && (
        <>
          <SimpleListDisplay<News>
            listItems={data.data}
            card={(item, key) => <SuccessStoryCard news={item} key={key} />}
          />
          <GlobalPagination
            data={data}
            currentPage={currentPage}
            pageClick={num => setCurrentPage(num)}
          />
        </>
      )}
    </>
  )
}

export default SuccessStoriesList
