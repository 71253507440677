import { BREADCRUMBS_SUCCESS_STORY_DETAILS } from '@/constants/BreadCrumbsRoute'
import {
  FRONT_NEWS,
  FRONT_NEWS_DETAILS,
  ROUTE_COMPANY_DETAILS,
  ROUTE_COMPANY_LIST,
  ROUTE_SUCCESS_STORIES
} from '@/constants/Routes'
import { News } from '@/models/calendar/News'
import baseFetcher, { getPaginatedFetcher } from '@/utils/connectionHelpers/swrFetchers'
import { stripHtmlTags } from '@/utils/dataHelpers/stringHelpers'
import { AxiosError } from 'axios'
import { useRouter } from 'next/router'
import useSWR from 'swr'
import BreadCrumbs from '../shared/BreadCrumbs'
import InjectHtml from '../shared/InjectHtml'
import LinkButton from '../shared/LinkButton'
import ListDisplay from '../shared/ListDisplay'
import DetailsFetchingHandling from '../shared/dataFetching/DetailsFetchingHandling'
import DisplayImageAndCredit from '../shared/details/DisplayImageAndCredit'
import DisplayVideoAndContent from '../shared/details/DisplayVideoAndContent'
import LinkedCompanyHeader from '../shared/details/LinkedCompanyHeader'
import SuccessStoriesKeyFigures from './components/SuccessStoriesKeyFigures'
import SuccessStoryCard from './components/SuccessStoryCard'

const custom = ['&successStories=true']

export const SuccessStoryDetails = () => {
  const { query } = useRouter()

  const { data, error } = useSWR<News>(
    query.id ? FRONT_NEWS_DETAILS(query.id.toString()) : null,
    baseFetcher,
    { revalidateOnFocus: false }
  )

  const { data: successStories } = useSWR<{ data: News[]; items: number }, AxiosError>(
    FRONT_NEWS,
    getPaginatedFetcher(1, 5, false, custom.join('')),
    { revalidateOnFocus: false }
  )

  return (
    <>
      <DetailsFetchingHandling data={data} error={error} />
      {data && query.id && data.publish && (
        <>
          <BreadCrumbs
            text={BREADCRUMBS_SUCCESS_STORY_DETAILS(data.title)}
            backLink={ROUTE_SUCCESS_STORIES}
          />
          {data.companyLink && data.companyLink > -1 && (
            <LinkedCompanyHeader companyId={data.companyLink} pageType='success-story' />
          )}
          <div className='section margin-bottom-8x wf-section'>
            <div className='wrapper'>
              <div className='grid-2col-with-offset'>
                <div className='_2col-left offset'>
                  <DisplayImageAndCredit image={data.image} imageCredit={data.imageCredit} />
                </div>
                <div className='_2col-right'>
                  <h2 className='display-h4'>{data.firstParagraph}</h2>
                  {data.firstParagraph && (
                    <div className='text large'>{stripHtmlTags(data.content)}</div>
                  )}
                  {data.companyLink && data.companyLink > -1 && (
                    <LinkButton
                      text='See company profile'
                      link={ROUTE_COMPANY_DETAILS(data.companyLink) ?? ROUTE_COMPANY_LIST}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <SuccessStoriesKeyFigures news={data} />
          <div className='bg-bottom yellow'>
            <div className='wrapper-detail-content'>
              <InjectHtml data={data.contentUnderVideo} wrapInRichText={true} />
              <DisplayVideoAndContent videoUrl={data.video} />
              {data.companyLink && (
                <LinkButton
                  text='See company profile'
                  link={ROUTE_COMPANY_DETAILS(data.companyLink) ?? ROUTE_COMPANY_LIST}
                />
              )}
            </div>
          </div>
          {successStories && (
            <ListDisplay<News>
              title='More stories'
              scrollingTitle={true}
              listItems={successStories.data
                .filter(item => item.id.toString() !== query?.id?.toString() ?? '-1')
                .splice(0, 3)}
              cardsClass='anim-success'
              card={(item, key) => {
                const additionalClass = `_${key + 1}`
                return <SuccessStoryCard news={item} key={key} additionalClass={additionalClass} />
              }}
            />
          )}
        </>
      )}
    </>
  )
}

export default SuccessStoryDetails
