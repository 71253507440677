import { Box, Grid, HStack, Text, useTheme } from '@chakra-ui/react'
import { ParkingBooking } from '@/models/parking/ParkingBooking'
import Image from 'next/image'
import GridSelectButton from '../shared/GridSelectButton'
import { useMemo } from 'react'
import dayjs from 'dayjs'
import { BookingDuration } from '@/constants/FieldsParking'
import { getParkingPicture } from '@/utils/dataHelpers/parkBookingHelpers'

interface Props {
  booking: ParkingBooking
  onClick: (booking: ParkingBooking) => void
}

const BookingCard = ({ booking, onClick }: Props) => {
  const theme = useTheme()

  const getText = useMemo(
    () => (booking: ParkingBooking) => {
      const date = dayjs(booking.date)
      return `${booking.duration} ${
        booking.duration === BookingDuration.allDay ? '' : 'of'
      } ${date.format('DD/MM/YYYY')}`
    },
    []
  )

  return (
    <Grid
      minH={20}
      px={6}
      py={[5, null, 2]}
      bg={theme.colors.brand.white}
      w='full'
      templateColumns={['repeat(2, 1fr)', null, 'repeat(3, 1fr)']}
      gap={[3, null, 0]}>
      <HStack spacing={2}>
        <Box>
          <Image height={22} src={getParkingPicture(booking.needAccess)} width={16} alt='' />
        </Box>
        <Text>{booking.parkingName}</Text>
      </HStack>
      <Text m='auto 0' textAlign={['end', null, 'center']}>
        {getText(booking)}
      </Text>
      <GridSelectButton onClick={() => onClick(booking)} />
    </Grid>
  )
}

export default BookingCard
