import { NEWS_CATEGORY_FILTER_VALUES } from '@/constants/FieldsNews'
import { FRONT_NEWS_FROM_TYPE } from '@/constants/Routes'
import { News } from '@/models/calendar/News'
import baseFetcher from '@/utils/connectionHelpers/swrFetchers'
import { AxiosError } from 'axios'
import useSWR from 'swr'
import ListDisplay from '../shared/ListDisplay'
import VerticalListDisplay from '../shared/VerticalListDisplay'
import NewsCard from './components/NewsCard'

interface Props {
  title: string
  moreTitle?: string
  moreLink?: string
  type: NEWS_CATEGORY_FILTER_VALUES
  vertical?: boolean
  currentNewsID?: string
}

const RecentNews = ({
  title,
  moreTitle = 'View more',
  moreLink,
  type,
  vertical,
  currentNewsID
}: Props) => {
  const { data } = useSWR<News[], AxiosError>(FRONT_NEWS_FROM_TYPE(type), baseFetcher, {
    revalidateOnFocus: false
  })

  return (
    <>
      {vertical ? (
        <VerticalListDisplay<News>
          title={title}
          listItems={
            data?.filter(item => item.id.toString() !== currentNewsID ?? -1).splice(0, 3) ?? []
          }
          card={(item, key) => <NewsCard news={item} key={key} vertical />}
        />
      ) : (
        <ListDisplay<News>
          title={title}
          moreLink={moreLink}
          moreTitle={moreTitle}
          listItems={
            data?.filter(item => item.id.toString() !== currentNewsID ?? -1).splice(0, 3) ?? []
          }
          cardsClass='anim-news'
          card={(item, key) => {
            const additionalClass = `_${key + 1}`
            return <NewsCard news={item} key={key} additionalClass={additionalClass} />
          }}
        />
      )}
    </>
  )
}

// noinspection JSUnusedGlobalSymbols
export default RecentNews
