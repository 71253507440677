import { axiosVercelAPI } from '@/utils/connectionHelpers/axios'
import { FRONT_EVENT_DETAILS_ICAL } from '@/constants/Routes'
import { save } from '@/utils/fileStore'
import { AxiosError } from 'axios'
import { showToast } from '@/utils/showToast'
import { VStack } from '@chakra-ui/react'
import { isDevOrPreview } from '@/utils/isDev'
import { Event } from '@/models/calendar/Event'
import { isKnovaEvent } from '@/utils/dataHelpers/validateEvents'

interface Props {
  event: Event
  dark?: boolean
}

function AddToCalendar({ event, dark }: Props) {
  const getICSCalendarEvent = async () => {
    try {
      const response = await axiosVercelAPI.get(FRONT_EVENT_DETAILS_ICAL(event.id.toString()))

      await save({
        blob: new Blob([response.data], { type: 'text/plain' }),
        filename: event.title
      })
    } catch (error: any) {
      if (!(error instanceof AxiosError)) {
        showToast(
          'There was a problem storing the file to your file system. Are we allowed to store files?',
          'error'
        )
        return
      }

      switch (error.response?.status) {
        case 405:
          showToast(
            'Sorry, but you do not have access to this event. please ask your company admin or supervisor for assistance',
            'error'
          )
          break
        case 404:
          showToast(
            'There was a problem finding the event. Are you connected to the internet?',
            'error'
          )
          break
        default:
          showToast(
            'We encountered a problem on our end. Please contact EPFL to notify us of the error',
            'error'
          )
          break
      }
    }
  }

  return (
    <>
      {event.linkToJoin ? (
        <a
          href={event.linkToJoin}
          target='_blank'
          className={`button-with-icon ${dark ? 'dark' : ''} w-inline-block`}
          rel='noopener noreferrer'>
          <div className='button-icon-content'>
            <div className='button-icon-text'>Join this event</div>
          </div>
        </a>
      ) : (
        <VStack align='start' gap={10}>
          <button
            className={`button-with-icon ${dark ? 'dark' : ''} w-inline-block`}
            onClick={getICSCalendarEvent}>
            <div className='button-icon-content'>
              <div className='button-icon-text'>Add to calendar</div>
            </div>
          </button>
          {isKnovaEvent(event) && (
            <>
              {isDevOrPreview ? (
                <p>
                  To prevent spam, development builds like this one send emails to
                  tech@coteries.com. They are sent to knova@coteries.com for production builds.
                </p>
              ) : (
                <p>
                  Adding the event to your calendar acts as a registration to the event. To cancel
                  your participation please send an email to knova@epfl.ch
                </p>
              )}
            </>
          )}
        </VStack>
      )}
    </>
  )
}

export default AddToCalendar
