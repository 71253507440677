export const BREADCRUMBS_COMPANY_DETAILS = (companyName: string) =>
  `Ecosystem / Company List / ${companyName}`

export const BREADCRUMBS_EVENT_DETAILS = (eventTitle: string) =>
  `Ecosystem / Events / ${eventTitle}`

export const BREADCRUMBS_NEWS_DETAILS = (newsTitle: string) => `Ecosystem / News / ${newsTitle}`

export const BREADCRUMBS_SUCCESS_STORY_DETAILS = (newsTitle: string) =>
  `Ecosystem / Success Stories / ${newsTitle}`
