import { Box, Button, Flex, Heading, useTheme, VStack } from '@chakra-ui/react'
import { faArrowLeft, faArrowRight, faCalendar } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DatePicker, { DayValue } from '@hassanmojab/react-modern-calendar-datepicker'
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css'
import { Dayjs } from 'dayjs'
import {
  BookingDuration,
  BookingTextTimeOfDay,
  BookingTextTimes
} from '@/constants/FieldsParking'
import {
  calcDateIsDisabled,
  startOfToday,
  toDateFromPicker,
  toDatePickerDate
} from '@/utils/dataHelpers/dateHelpers'
import LargeParkshareCheckbox from '../shared/LargeParkshareCheckbox'

interface Props {
  selectedDate: Dayjs
  onDateChange: (date: Dayjs) => void
  selectedTime: String
  onTimeChange: (time: BookingDuration) => void
}

const DateAndTimeSelection = ({
  selectedDate,
  onDateChange,
  selectedTime,
  onTimeChange
}: Props) => {
  const theme = useTheme()

  const renderCustomInput = ({ ref }: any) => (
    <Heading as='button' fontSize={['20px', null, '32px']} fontWeight='600' ref={ref}>
      {selectedDate.format('DD/MM/YYYY')}
      <FontAwesomeIcon icon={faCalendar} style={{ marginLeft: '10px' }} size='1x' />
    </Heading>
  )

  return (
    <VStack w='full' spacing={3} bg='rgba(25, 25, 25, 0.1)' p={4}>
      <Flex w='full' justify='space-between' placeItems='center'>
        <Button
          variant='parkshareDarkButtons'
          onClick={() => onDateChange(selectedDate.clone().subtract(1, 'day'))}
          disabled={calcDateIsDisabled(selectedDate)}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
        <Box flexGrow={1} textAlign='center'>
          <DatePicker
            colorPrimary={theme.colors.brand.primary}
            minimumDate={toDatePickerDate(startOfToday)}
            value={toDatePickerDate(selectedDate)}
            renderInput={renderCustomInput}
            onChange={(newDate: DayValue) =>
              onDateChange(newDate ? toDateFromPicker(newDate) : startOfToday)
            }
            shouldHighlightWeekends
          />
        </Box>
        <Button
          variant='parkshareDarkButtons'
          onClick={() => onDateChange(selectedDate.clone().add(1, 'day'))}>
          <FontAwesomeIcon icon={faArrowRight} />
        </Button>
      </Flex>

      <Flex w='full' flexDirection={['column', null, 'row']} gap='1px'>
        <LargeParkshareCheckbox
          selected={selectedTime === BookingDuration.allDay}
          title={BookingTextTimeOfDay.allDay}
          subTitle={BookingTextTimes.allDay}
          onClick={() => onTimeChange(BookingDuration.allDay)}
        />
        <LargeParkshareCheckbox
          selected={selectedTime === BookingDuration.morning}
          title={BookingTextTimeOfDay.morning}
          subTitle={BookingTextTimes.morning}
          onClick={() => onTimeChange(BookingDuration.morning)}
        />
        <LargeParkshareCheckbox
          selected={selectedTime === BookingDuration.afternoon}
          title={BookingTextTimeOfDay.afternoon}
          subTitle={BookingTextTimes.afternoon}
          onClick={() => onTimeChange(BookingDuration.afternoon)}
        />
      </Flex>
    </VStack>
  )
}

export default DateAndTimeSelection
