import {
  FRONT_COMPANIES_PAGINATED,
  FRONT_EVENTS_PAGINATED,
  FRONT_NEWS_PAGINATED,
  FRONT_WEBFLOW_SEARCH
} from '@/constants/Routes'
import type { Event } from '@/models/calendar/Event'
import { News } from '@/models/calendar/News'
import { Company } from '@/models/company/Company'
import baseFetcher from '@/utils/connectionHelpers/swrFetchers'
import { AxiosError } from 'axios'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import useSWR from 'swr'
import InjectHtml from '../shared/InjectHtml'
import Loading from '../shared/Loading'
import CompanySearchCard from './components/CompanySearchCard'
import EventSearchCard from './components/EventSearchCard'
import NewsSearchCard from './components/NewsSearchCard'
import ResultsWrapper from './components/ResultsWrapper'
import SearchEmptyResults from './components/SearchEmptyResults'

const SearchContainer = () => {
  const { query } = useRouter()

  useEffect(() => {
    if (!query.query) {
      return
    }
    const input = document.getElementById('search_page') as HTMLInputElement
    if (!input) {
      return
    }
    input.value = query.query.toString()
  }, [query])

  const { data: dataEvents, error: errorEvents } = useSWR<
    { data: Event[]; items: number },
    AxiosError
  >(
    query.query ? FRONT_EVENTS_PAGINATED(false, '', query.query.toString(), false) : null,
    baseFetcher,
    { revalidateOnFocus: false }
  )

  const { data: dataNews, error: errorNews } = useSWR<{ data: News[]; items: number }, AxiosError>(
    query.query ? FRONT_NEWS_PAGINATED(false, '', query.query.toString(), 6) : null,
    baseFetcher,
    { revalidateOnFocus: false }
  )
  const { data: dataCompanies, error: errorCompanies } = useSWR<
    { data: Company[]; items: number },
    AxiosError
  >(
    query.query ? FRONT_COMPANIES_PAGINATED(false, '', query.query.toString(), false, '') : null,
    baseFetcher,
    { revalidateOnFocus: false }
  )

  const { data: dataWebflow, error: errorWebflow } = useSWR<any | AxiosError>(
    query.query ? FRONT_WEBFLOW_SEARCH(query.query.toString()) : null,
    baseFetcher,
    { revalidateOnFocus: false }
  )

  return (
    <>
      {!dataEvents && !dataNews && !dataWebflow && !dataCompanies && (
        <Loading additionalClasses='margin-bottom-8x' />
      )}
      {!dataEvents &&
        !dataNews &&
        !dataWebflow &&
        !dataCompanies &&
        !errorEvents &&
        !errorNews &&
        !errorWebflow &&
        !errorCompanies && <SearchEmptyResults />}
      <ResultsWrapper>
        {dataCompanies && (
          <>
            {dataCompanies.data.map((item, key) => (
              <CompanySearchCard company={item} key={key} />
            ))}
          </>
        )}

        {dataEvents && (
          <>
            {dataEvents.data.map((item, key) => (
              <EventSearchCard event={item} key={key} />
            ))}
          </>
        )}

        {dataNews && (
          <>
            {dataNews.data.map((item, key) => (
              <NewsSearchCard news={item} key={key} />
            ))}
          </>
        )}

        {dataWebflow && <InjectHtml data={dataWebflow} wrapInRichText={false} />}
      </ResultsWrapper>
    </>
  )
}

export default SearchContainer
