export const colors = {
  brand: {
    primary: '#179F7C',
    primaryLight: '#b9e2d8',
    primaryDard: '#127f63',
    black: '#191919',
    white: '#ffffff',
    error: '#D43736'
  }
}
