import { FRONT_DATE_FORMAT } from '@/constants/DateConstants'
import { ROUTE_EVENT_DETAIL } from '@/constants/Routes'
import type { Event } from '@/models/calendar/Event'
import dayjs from 'dayjs'

interface Props {
  event: Event
}

const EventSearchCard = ({ event }: Props) => {
  return (
    <div className='search-result-item'>
      <div>
        <a href={ROUTE_EVENT_DETAIL(event.id)} className='display-h5 margin-bottom-025x'>
          {event.title}
        </a>
        <div className='flex-content-horizontal'>
          <div className='text color-light no-margin margin-right-050'>Event</div>
          <div className='fa-l color-events-2 margin-right-050'></div>
          <div className='text color-light no-margin'>{`${event.location}${
            event.location && event.locationFilter ? ', ' : ''
          }${event.locationFilter}`}</div>
          <div className='fa-l color-events-2 margin-right-050 margin-left-050'></div>
          <div className='text color-light no-margin'>
            {dayjs(event.startDate).format(FRONT_DATE_FORMAT)}
          </div>
          {event.endDate && (
            <>
              <div className='fa-l small center color-light margin-right-050 margin-left-050'>
                →
              </div>
              <div className='text color-light no-margin'>
                {dayjs(event.endDate).format(FRONT_DATE_FORMAT)}
              </div>
            </>
          )}
        </div>
      </div>
      <div>
        <a href={ROUTE_EVENT_DETAIL(event.id)} className='button small w-button'>
          Details
        </a>
      </div>
    </div>
  )
}

export default EventSearchCard
