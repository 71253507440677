import {
  FRONT_REDEPLOY_WEBSITE,
  ROUTE_PORTAL_EVENT_CREATE,
  ROUTE_PORTAL_LOGO_EDIT,
  ROUTE_PORTAL_NEWS_CREATE
} from '@/constants/Routes'
import userStore from '@/stores/userStore'
import { showToast } from '@/utils/showToast'
import { Heading, SimpleGrid, TabPanel, VStack } from '@chakra-ui/react'
import { faBuilding, faCalendar, faGlobe, faNewspaper } from '@fortawesome/pro-light-svg-icons'
import ControlButton from './ControlButton'

export type AdminToolsTabProps = {}
export const AdminToolsTab = ({}: AdminToolsTabProps) => {
  const user = userStore(state => state.user)

  const forceRedeploy = async () => {
    try {
      await fetch(FRONT_REDEPLOY_WEBSITE).then(res => res.json())
    } catch (e: any) {
      showToast('An error occured, please try again', 'error')
    }
  }

  return (
    <TabPanel>
      <VStack align='stretch'>
        <Heading size='md' mt='0'>
          Admin Tools
        </Heading>
        <SimpleGrid columns={[2, 4]} gap='5'>
          {user?.canManageEventNews && (
            <>
              <ControlButton
                text='Events'
                link={ROUTE_PORTAL_EVENT_CREATE}
                icon={faCalendar}
                info='Publish an event on our website. If you have any questions, please contact support@epfl-innovationpark.ch'
              />
              <ControlButton
                text='News'
                link={ROUTE_PORTAL_NEWS_CREATE}
                icon={faNewspaper}
                info='Publish a news on our website. If you have any questions, please contact support@epfl-innovationpark.ch'
              />
            </>
          )}
          {user?.canChangeCompany && (
            <ControlButton
              text='Edit Company Logo'
              link={ROUTE_PORTAL_LOGO_EDIT}
              icon={faBuilding}
              info='Edit and publish a company logo on our Companies page. If you have any questions, please contact support@epfl-innovationpark.ch'
            />
          )}
          {user?.canRedeploy && (
            <ControlButton
              text='Redeploy website'
              link='#'
              onClick={forceRedeploy}
              icon={faGlobe}
              info='Clear the server cache of our website so that it can be updated quickly. If you have any questions, please contact support@epfl-innovationpark.ch'
            />
          )}
        </SimpleGrid>
      </VStack>
    </TabPanel>
  )
}
