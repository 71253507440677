import { PARKING_SPOT_NAME, PARKING_SPOT_NUMBER, PARKING_TYPE } from '@/constants/FieldsParking'
import { eudo } from '../zod-eudo'
import { ParkingType } from './ParkingLot'

export const SPOT_ID_PREFIX = 'space_'

export const ParkingSpot = eudo.object({
  id: eudo.id(),
  number: eudo.int(PARKING_SPOT_NUMBER),
  name: eudo.string(PARKING_SPOT_NAME),
  parkingType: eudo.nativeEnum(PARKING_TYPE, ParkingType)
})

export type ParkingSpot = eudo.infer<typeof ParkingSpot>
