import { BREADCRUMBS_COMPANY_DETAILS } from '@/constants/BreadCrumbsRoute'
import {
  FRONT_COMPANY_DETAILS,
  FRONT_NEWS_FROM_COMPANY,
  ROUTE_COMPANY_LIST
} from '@/constants/Routes'
import { News } from '@/models/calendar/News'
import { PublicCompany } from '@/models/company/PublicCompany'
import baseFetcher, { getPaginatedFetcher } from '@/utils/connectionHelpers/swrFetchers'
import { AxiosError } from 'axios'
import { useRouter } from 'next/router'
import useSWR from 'swr'
import NewsCard from '../news/components/NewsCard'
import BreadCrumbs from '../shared/BreadCrumbs'
import InjectHtml from '../shared/InjectHtml'
import ListDisplay from '../shared/ListDisplay'
import DetailsFetchingHandling from '../shared/dataFetching/DetailsFetchingHandling'
import DisplayVideoAndContent from '../shared/details/DisplayVideoAndContent'
import LinkedCompanyHeader from '../shared/details/LinkedCompanyHeader'
import CompanyInformation from './components/CompanyInformation'

const CompanyDetails = () => {
  const { query } = useRouter()

  const { data, error } = useSWR<PublicCompany>(
    query.id ? FRONT_COMPANY_DETAILS(parseInt(query.id as string)) : null,
    baseFetcher,
    { revalidateOnFocus: false }
  )

  const { data: news } = useSWR<{ data: News[]; items: number }, AxiosError>(
    query.id ? FRONT_NEWS_FROM_COMPANY(parseInt(query.id as string)) : null,
    getPaginatedFetcher(1, 3),
    { revalidateOnFocus: false }
  )

  return (
    <>
      <DetailsFetchingHandling data={data} error={error} />
      {data && query.id && (
        <>
          <BreadCrumbs
            text={BREADCRUMBS_COMPANY_DETAILS(data.signage)}
            backLink={ROUTE_COMPANY_LIST}
          />
          <LinkedCompanyHeader companyId={parseInt(query.id as string)} pageType='company' />
          <div className='wrapper'>
            <div className='grid-detail margin-bottom-8x'>
              <div className='block-content'>
                <div className='wrapper-detail-content'>
                  <h2 className='display-h4 margin-bottom-2x no-margin-top'>
                    {data.headerDescription}
                  </h2>
                  <div className='margin-bottom-2x'>
                    <InjectHtml data={data.description} wrapInRichText={true} />
                  </div>
                  <DisplayVideoAndContent videoUrl={data.video} contentUnder={data.productLegend} />
                  {data.productPicture && (
                    // eslint-disable-next-line @next/next/no-img-element
                    <img
                      src={data.productPicture}
                      loading='lazy'
                      alt=''
                      className='img margin-bottom-2x'
                    />
                  )}
                </div>
              </div>
              <CompanyInformation company={data} />
            </div>
          </div>
          <div className='bg-bottom blue'>
            <ListDisplay<News>
              scrollingTitle
              title='Company news'
              moreTitle=''
              listItems={news?.data ?? []}
              cardsClass='anim-news'
              card={(item, key) => {
                const additionalClass = `_${key + 1}`
                return <NewsCard news={item} key={key} additionalClass={additionalClass} />
              }}
            />
          </div>
        </>
      )}
    </>
  )
}

export default CompanyDetails
