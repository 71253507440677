import { AxiosError } from 'axios'
import { axiosErrorToast, showToast } from '../showToast'

export const handleUpdateErrors = (e: any) => {
  const error = e as AxiosError
  if (error.response != null) {
    axiosErrorToast(error, 'error')
  } else {
    showToast('A problem occurred, please try again later', 'error')
  }
}
