import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Tag,
  TagCloseButton,
  VStack
} from '@chakra-ui/react'
import { useState } from 'react'
import { FieldValues, Path, RegisterOptions } from 'react-hook-form'
import { showToast } from '@/utils/showToast'

interface Props<TFormValues extends FieldValues> extends InputProps {
  name: Path<TFormValues>
  label: string
  rules?: RegisterOptions
  errors?: any
  tags?: string[]
  onUpdate: (tags: string[]) => void
  maxNumberOfTags?: number
}

const FormTagsInput = <TFormValues extends FieldValues>({
  name,
  label,
  rules,
  errors,
  tags,
  onUpdate,
  maxNumberOfTags,
  ...props
}: Props<TFormValues>) => {
  const [newTag, setNewTag] = useState<string>('')

  const errorMessages = errors[name]
  const hasError = !!(errors && errorMessages)

  const handleAddTag = () => {
    if (maxNumberOfTags && tags && tags.length >= maxNumberOfTags) {
      return showToast('You added the maximum number of tags', 'error')
    }

    if (tags && tags.some(item => item === newTag)) {
      return showToast('The tag is already present in the list', 'error')
    }

    if (newTag.length > 0) {
      onUpdate([...(tags ?? []), newTag])
      setNewTag('')
    }
  }

  const handleTagRemove = (tag: string) => {
    onUpdate(tags?.filter(temp => temp !== tag) ?? [])
  }

  return (
    <FormControl isInvalid={hasError} isRequired={!!rules?.required}>
      <FormLabel fontSize='xs'>{label.toUpperCase()}</FormLabel>
      <VStack align='start'>
        <HStack>
          {tags &&
            tags.map(tag => (
              <Tag key={tag}>
                {tag}
                <TagCloseButton onClick={() => handleTagRemove(tag)} />
              </Tag>
            ))}
        </HStack>
        <InputGroup>
          <Input
            value={newTag}
            w='full'
            placeholder={`Add a tag ${maxNumberOfTags ? ', ' + maxNumberOfTags + ' max' : ''}`}
            onChange={(e: any) => setNewTag(e.target.value)}
            {...props}
          />
          <InputRightElement width='4.5rem'>
            <Button
              h='1.75rem'
              size='sm'
              onClick={() => handleAddTag()}
              disabled={newTag.length === 0}>
              Add
            </Button>
          </InputRightElement>
        </InputGroup>
      </VStack>
    </FormControl>
  )
}

export default FormTagsInput
