interface Props {
  data?: string
  wrapInRichText: boolean
}

interface InjectionProps {
  data: string
}

const Injection = ({ data }: InjectionProps) => {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: data
      }}></span>
  )
}

const InjectHtml = ({ data, wrapInRichText }: Props) => {
  const formattedData = data?.replaceAll('<a', '<a target="_blank"rel="noreferrer noopener"')
  return (
    <>
      {formattedData &&
        (wrapInRichText ? (
          <div className='text w-richtext'>
            <Injection data={formattedData} />
          </div>
        ) : (
          <Injection data={formattedData} />
        ))}
    </>
  )
}
export default InjectHtml
