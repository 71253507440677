import userStore from '../../stores/userStore'
import { ROUTE_PORTAL_EVENT_EDIT, ROUTE_PORTAL_NEWS_EDIT } from '@/constants/Routes'

interface Props {
  id: string
  type: 'event' | 'news'
}

const EditDuplicateButtons = ({ id, type }: Props) => {
  const user = userStore(state => state.user)

  return (
    <div className='breadcrumb'>
      {user?.canManageEventNews && (
        <>
          <a
            href={type === 'event' ? ROUTE_PORTAL_EVENT_EDIT(id) : ROUTE_PORTAL_NEWS_EDIT(id)}
            className='button icon w-inline-block margin-right-050'>
            <div className='button-icon-content'>
              <div>Edit</div>
              <div className='button-icon small'></div>
            </div>
          </a>
          <a
            href={
              type === 'event'
                ? `${ROUTE_PORTAL_EVENT_EDIT(id)}&duplicate=true`
                : `${ROUTE_PORTAL_NEWS_EDIT(id)}&duplicate=true`
            }
            className='button icon w-inline-block margin-right-050'>
            <div className='button-icon-content'>
              <div>Duplicate</div>
              <div className='button-icon small'></div>
            </div>
          </a>
        </>
      )}
    </div>
  )
}

export default EditDuplicateButtons
