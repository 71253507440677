import { FRONT_SPOTLIGHT } from '@/constants/Routes'
import { Highlights } from '@/models/calendar/Highlights'
import baseFetcher from '@/utils/connectionHelpers/swrFetchers'
import useSWR from 'swr'
import EventCard from './events/components/EventCard'
import NewsCard from './news/components/NewsCard'
import SuccessStoryCard from './successStory/components/SuccessStoryCard'

const Spotlight = () => {
  const { data } = useSWR<Highlights>(FRONT_SPOTLIGHT, baseFetcher, {
    revalidateOnFocus: false
  })

  return (
    <>
      <div className='wrapper'>
        <div className='block-content'>
          <div className='grid-card trigger-anim-multi-style'>
            {data?.successStory && (
              <SuccessStoryCard
                news={data.successStory}
                bottom={
                  <a
                    href='/success-stories'
                    className='button-with-icon w-inline-block'
                    style={{ margin: '1.2rem' }}>
                    <div className='button-icon-content'>
                      <div className='button-icon-text'>All Successes</div>
                      <div className='button-icon'></div>
                    </div>
                  </a>
                }
              />
            )}
            {data?.event && (
              <EventCard
                event={data.event}
                bottom={
                  <a
                    href='/events'
                    className='button-with-icon w-inline-block'
                    style={{ marginTop: '1rem' }}>
                    <div className='button-icon-content'>
                      <div className='button-icon-text'>All Events</div>
                      <div className='button-icon'></div>
                    </div>
                  </a>
                }
              />
            )}
            {data?.news && (
              <NewsCard
                news={data.news}
                bottom={
                  <a
                    href='/news'
                    className='button-with-icon w-inline-block'
                    style={{ margin: '1.2rem' }}>
                    <div className='button-icon-content'>
                      <div className='button-icon-text'>All news</div>
                      <div className='button-icon'></div>
                    </div>
                  </a>
                }
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Spotlight
