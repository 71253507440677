import { ALLOWED_CORS_SOURCE } from '@/constants/APIConstants'
import { UserRole, UserServicePermission } from '@/constants/FieldsUser'
import { COOKIE_EMPLOYEE } from '@/constants/RequestConstants'
import { FRONT_USER, FRONT_USERS, ROUTE_PORTAL } from '@/constants/Routes'
import { PublicEmployee } from '@/models/company/PublicEmployee'
import { getRoleSubordinates, hasPermission } from '@/utils/apiHelpers/userPermissions'
import { axiosVercelAPI } from '@/utils/connectionHelpers/axios'
import { handleUpdateErrors } from '@/utils/dataHelpers/apiResponseHelper'
import { toBase64 } from '@/utils/dataHelpers/imageHelpers'
import { emailValidator } from '@/utils/dataHelpers/validators'
import { axiosErrorToast, showToast } from '@/utils/showToast'
import { Divider, FormControl, FormLabel, HStack, Text, VStack, useBoolean } from '@chakra-ui/react'
import { AxiosError } from 'axios'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import userStore, { updateUserInfo } from '../../stores/userStore'
import UploadImage from '../shared/UploadImage'
import DefaultFormWrapper from '../shared/form/DefaultFormWrapper'
import FormInput from '../shared/form/FormInput'
import FormSelect from '../shared/form/FormSelect'

type FormData = {
  email: string
  firstName: string
  lastName: string
  role: string
}

const ManageCollaborator = () => {
  return <ManageCollaboratorContent />
}

const ManageCollaboratorContent = () => {
  const { push } = useRouter()

  const user = userStore(state => state.user)
  const [editMode, setEditMode] = useBoolean(false)
  const [roleList, setRoleList] = useState<{ label: string; value: string }[]>([])
  const [advancedEdit, setAdvancedEdit] = useBoolean(false)
  const [targetId, setTargetId] = useState<string | undefined>(undefined)
  const [targetHash, setTargetHash] = useState<string | undefined>(undefined)
  const [pictureURL, setPictureURL] = useState<string | undefined>(undefined)
  const [picture, setPicture] = useState<string | undefined>(undefined)
  const [uploading, setUploading] = useBoolean(false)

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<FormData>()

  useEffect(() => {
    if (!user) {
      return
    }

    const list = getRoleSubordinates(user.role as UserRole)
    setRoleList(
      list.map(role => {
        return { label: role, value: role }
      })
    )

    if (hasPermission(UserServicePermission.Collaborator_Manage, user?.role as UserRole)) {
      setAdvancedEdit.on()
    } else {
      setAdvancedEdit.off()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    const employeeString = Cookies.get(COOKIE_EMPLOYEE)
    if (employeeString) {
      try {
        const employee = JSON.parse(employeeString) as PublicEmployee
        setValue('lastName', employee.lastName)
        setValue('firstName', employee.firstName)
        setValue('email', employee.email)
        setValue('role', employee.role)
        setPictureURL(employee.picture)
        setPicture(employee.picture)
        setTargetId(employee.encryptedId)
        setTargetHash(employee.hashedId)

        setEditMode.on()
      } catch (e) {
        setEditMode.off()
        showToast('Something went wrong when trying to get the employee', 'warning')
      }
    } else {
      setEditMode.off()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const preparePicture = async (file: File) => {
    setPicture((await toBase64(file)) as string)
  }

  const createNewUser = async (newUserData: FormData) => {
    try {
      await axiosVercelAPI.post(FRONT_USERS, {
        ...newUserData,
        picture: picture
      })

      showToast('The new collaborator has been successfully created')
      await push(ROUTE_PORTAL)
    } catch (e) {
      axiosErrorToast(e as AxiosError, 'error')
    }
  }

  const updateUser = async (updateUserData: FormData) => {
    if (!user || !targetId) {
      return
    }

    try {
      await axiosVercelAPI.put(FRONT_USER(targetId), {
        ...updateUserData,
        picture: picture && picture !== pictureURL ? picture : undefined,
        deletePicture: !picture && !!pictureURL,
        source: ALLOWED_CORS_SOURCE.PORTAL,
        targetId
      })

      if (targetHash === user.hashedId) {
        await updateUserInfo({ ...updateUserData, targetId: -1 })
      }
      showToast('The collaborator has been successfully updated')
    } catch (e) {
      handleUpdateErrors(e)
    }
  }

  const onSubmit: SubmitHandler<FormData> = async data => {
    if (data) {
      setUploading.on()

      try {
        if (targetId) {
          await updateUser(data)
        } else {
          await createNewUser(data)
        }
      } finally {
        setUploading.off()
      }
    }
  }

  return (
    <DefaultFormWrapper
      title={editMode ? 'Edit collaborator' : 'Add new collaborator'}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      loading={uploading}>
      <VStack w='full' textAlign='start'>
        <FormControl>
          <FormLabel fontSize='xs' w='full'>
            Picture
          </FormLabel>
          <UploadImage
            pictureUrl={pictureURL}
            handleDelete={() => setPicture(undefined)}
            handleImageProcessing={preparePicture}
          />
        </FormControl>
      </VStack>
      <FormInput<FormData>
        name='lastName'
        label='Family name'
        register={register}
        errors={errors}
        rules={{
          required: 'Please add a family name'
        }}
      />
      <FormInput<FormData>
        name='firstName'
        label='First name'
        register={register}
        errors={errors}
        isDisabled={!advancedEdit}
        rules={{
          required: 'Please add a first name'
        }}
      />
      <FormInput<FormData>
        name='email'
        label='Professional email'
        register={register}
        errors={errors}
        isDisabled={!advancedEdit}
        rules={{
          pattern: {
            value: emailValidator,
            message: 'Please insert a valid email'
          },
          required: 'A collaborator email is required'
        }}
      />

      {advancedEdit && (
        <>
          <HStack w='full'>
            <Divider />
            <Text>Role</Text>
            <Divider />
          </HStack>
          <VStack align='start' gridGap={4}>
            <Text>
              <b>Company supervisor</b> = access to all functionalities with the responsibility to
              define the Administrator profiles
            </Text>
            <Text>
              <b>Company administrator</b> = access to all functionalities with the possibility to
              define the Employee profiles
            </Text>
            <Text>
              <b>Employee</b> = limited access to certain applications
            </Text>
            <Text>
              <b>Facilities</b> = employee access + book a room and ticketing
            </Text>
          </VStack>
          <FormSelect<FormData>
            name='role'
            label='Role'
            errors={errors}
            placeholder='Select a role'
            register={register}
            rules={{ required: 'A collaborator must have a company role' }}
            options={roleList}
          />
        </>
      )}
    </DefaultFormWrapper>
  )
}

// noinspection JSUnusedGlobalSymbols
export default ManageCollaborator
