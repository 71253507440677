import { FRONT_DATE_FORMAT } from '@/constants/DateConstants'
import { ROUTE_NEWS_DETAIL } from '@/constants/Routes'
import type { News } from '@/models/calendar/News'
import dayjs from 'dayjs'

interface Props {
  news: News
}

const NewsSearchCard = ({ news }: Props) => {
  return (
    <div className='search-result-item'>
      <div>
        <a href={ROUTE_NEWS_DETAIL(news.id)} className='display-h5 margin-bottom-025x'>
          {news.title}
        </a>
        <div className='flex-content-horizontal'>
          <div className='text color-light no-margin margin-right-050'>News</div>
          <div className='fa-l color-events-2 margin-right-050'></div>
          <div className='text color-light no-margin'>
            {dayjs(news.publicationDate).format(FRONT_DATE_FORMAT)}
          </div>
        </div>
      </div>
      <div>
        <a href={ROUTE_NEWS_DETAIL(news.id)} className='button small w-button'>
          Details
        </a>
      </div>
    </div>
  )
}

export default NewsSearchCard
