import { ROUTE_PARKSHARE_MY_BOOKINGS } from '@/constants/Routes'
import { ParkingBooking } from '@/models/parking/ParkingBooking'
import { ParkingType } from '@/models/parking/ParkingLot'
import { getBookingTextFromDuration } from '@/utils/dataHelpers/parkBookingHelpers'
import { Container, Divider, Flex, Text, VStack } from '@chakra-ui/react'
import { faCalendarCheck, faClock, faParking } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import BookingDetailsMap from './myBookings/BookingDetailsMap'
import CamiproNeededTag from './shared/CamiproNeededTag'
import ParkshareHeading from './shared/ParkshareHeading'
import ParkshareSubHeading from './shared/ParkshareSubHeading'

const BookingDetailsContainer = () => {
  return <BookingDetailsContainerContent />
}

const BookingDetailsContainerContent = () => {
  const { query, replace } = useRouter()
  const [booking, setBooking] = useState<ParkingBooking | undefined>(undefined)

  useEffect(() => {
    if (!query.booking || (Array.isArray(query.booking) && query.booking.length === 0)) {
      replace(ROUTE_PARKSHARE_MY_BOOKINGS)
    } else {
      const serializedBooking = Array.isArray(query.booking)
        ? query.booking[0]
        : (query.booking as string)
      setBooking(JSON.parse(serializedBooking))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  return (
    <Container maxW='container.xl'>
      <ParkshareHeading title='Details of your booking' />
      {booking && (
        <VStack mt={10}>
          <>
            {booking.needAccess && <CamiproNeededTag />}
            <VStack pt={booking.needAccess ? 4 : 10} w='full' align='start'>
              <ParkshareSubHeading text='You booked:' />
              <VStack placeItems='start' w='full' gap={2}>
                <Flex gap={2} placeItems='center'>
                  <FontAwesomeIcon icon={faParking} size='lg' />
                  <Text>
                    The spot n° {booking.spotNumber} in {booking.parkingName}
                  </Text>
                </Flex>

                <Flex gap={2} placeItems='center'>
                  <FontAwesomeIcon icon={faCalendarCheck} size='lg' />
                  <Text>On {dayjs(booking.date).format('dddd DD MMMM')}</Text>
                </Flex>

                <Flex gap={2} placeItems='center'>
                  <FontAwesomeIcon icon={faClock} size='lg' />
                  <Text textTransform='capitalize'>{getBookingTextFromDuration(booking)}</Text>
                </Flex>
              </VStack>
            </VStack>
            <Divider />
            <BookingDetailsMap
              parkingType={booking.parkingType as ParkingType}
              spotNumber={booking.spotNumber}
            />
          </>
        </VStack>
      )}
    </Container>
  )
}

export default BookingDetailsContainer
