import { FRONT_DATE_FORMAT } from '@/constants/DateConstants'
import type { Agenda, AgendaForCreation } from '@/models/calendar/Agenda'
import {
  startOfToday,
  toDateFromPicker,
  toDatePickerDateSafe
} from '@/utils/dataHelpers/dateHelpers'
import { timeValidator } from '@/utils/dataHelpers/validators'
import { showToast } from '@/utils/showToast'
import {
  Button,
  HStack,
  IconButton,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  useTheme
} from '@chakra-ui/react'
import { faTrash } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DatePicker, { DayValue } from '@hassanmojab/react-modern-calendar-datepicker'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { useState } from 'react'
import FormDatePickerRender from '../../shared/form/FormDatePickerRender'

interface Props {
  calendar: AgendaForCreation[]
  onChange: (item: AgendaForCreation[]) => void
}

const ManageEventCalendarItem = ({ calendar, onChange }: Props) => {
  const theme = useTheme()

  const [description, setDescription] = useState<string>('')
  const [startTime, setStartTime] = useState<string>('')
  const [endTime, setEndTime] = useState<string>('')
  const [startDate, setStartDate] = useState<Dayjs | undefined>()

  const renderCustomDate = ({ ref }: any) => (
    <FormDatePickerRender pickerProp={ref} date={startDate} />
  )

  const onSubmit = async () => {
    if (!startDate || !description) {
      return showToast('Please fill the start date and description fields', 'error')
    }

    if (
      (startTime && !startTime.match(timeValidator)) ||
      (endTime && !endTime.match(timeValidator))
    ) {
      return showToast('Data formatting issue, please validate your data', 'error')
    }

    const obj = { startDate: startDate.toDate(), description, startTime, endTime } as Agenda
    onChange([...(calendar ?? []), obj])
    setEndTime('')
    setStartTime('')
    setDescription('')
    setStartDate(undefined)
  }

  const onDelete = (toDelete: AgendaForCreation) => {
    onChange([...calendar.filter(item => item !== toDelete)])
  }

  return (
    <VStack w='full' alignItems='start' gridGap={8}>
      <TableContainer w='full'>
        <Table>
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>Start time</Th>
              <Th>End time</Th>
              <Th>Description</Th>
              <Th>Remove</Th>
            </Tr>
          </Thead>
          {calendar && (
            <Tbody>
              {calendar.map((item, index) => (
                <Tr key={index}>
                  <Td>{dayjs(item.startDate).format(FRONT_DATE_FORMAT)}</Td>
                  <Td>{item.startTime}</Td>
                  <Td>{item.endTime}</Td>
                  <Td>{item.description}</Td>
                  <Td>
                    <IconButton
                      aria-label='delete'
                      icon={<FontAwesomeIcon icon={faTrash} />}
                      onClick={() => onDelete(item)}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      <VStack w='full'>
        <DatePicker
          colorPrimary={theme.colors.blue['300']}
          renderInput={renderCustomDate}
          value={toDatePickerDateSafe(startDate)}
          onChange={(newDate: DayValue) =>
            setStartDate(newDate ? toDateFromPicker(newDate) : startOfToday)
          }
          shouldHighlightWeekends
        />
        <HStack w='full'>
          <Input
            w='full'
            placeholder='Start time (hh:mm)'
            value={startTime}
            onChange={e => setStartTime(e.target.value)}
          />
          <Input
            w='full'
            placeholder='End time (hh:mm)'
            value={endTime}
            onChange={e => setEndTime(e.target.value)}
          />
        </HStack>
        <Input
          placeholder='Description'
          value={description}
          onChange={e => setDescription(e.target.value)}
        />
        <Button onClick={() => onSubmit()} alignSelf='end'>
          Add agenda item
        </Button>
      </VStack>
    </VStack>
  )
}
export default ManageEventCalendarItem
