import { EnumLike } from 'zod'
import { EudoFieldAny, EudoObject, EudoRawShape } from './EudonetField'
import {
  EudoBoolean,
  EudoDate,
  EudoEnum,
  EudoId,
  EudoInt,
  EudoRef,
  EudoString,
  GetFromEudonetResult
} from './EudonetPrimitive'

export const object = <T extends EudoRawShape>(shape: T): EudoObject<T> => new EudoObject(shape)
export const string = (fieldID: GetFromEudonetResult<string>) => new EudoString(fieldID)
export const nativeEnum = <E extends EnumLike>(fieldID: number, e: E) => new EudoEnum(fieldID, e)
export const boolean = (fieldID: number) => new EudoBoolean(fieldID)
export const int = (fieldID: GetFromEudonetResult<number>) => new EudoInt(fieldID)
export const date = (fieldID: number) => new EudoDate(fieldID)
export const dbRef = (fieldID: number) => new EudoRef(fieldID, true)
export const ref = (fieldID: number) => new EudoRef(fieldID)
export const id = () => new EudoId()
export type infer<T extends EudoFieldAny> = T['Output']
