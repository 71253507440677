import {
  AGENDA_DATE,
  AGENDA_DESCRIPTION,
  AGENDA_END_TIME,
  AGENDA_START_TIME,
  EVENT_CATEGORY_HIDDEN
} from '@/constants/FieldsEvent'
import { eudo } from '../zod-eudo'

export const Agenda = eudo.object({
  id: eudo.id(),
  startDate: eudo.date(AGENDA_DATE),
  startTime: eudo.string(AGENDA_START_TIME).optional(),
  endTime: eudo.string(AGENDA_END_TIME).optional(),
  description: eudo.string(AGENDA_DESCRIPTION),
  categoryHidden: eudo.string(EVENT_CATEGORY_HIDDEN).optional()
})

export const AgendaForCreation = eudo.object({
  id: eudo.id().optional(),
  startDate: eudo.date(AGENDA_DATE),
  startTime: eudo.string(AGENDA_START_TIME).optional(),
  endTime: eudo.string(AGENDA_END_TIME).optional(),
  description: eudo.string(AGENDA_DESCRIPTION),
  categoryHidden: eudo.string(EVENT_CATEGORY_HIDDEN).optional()
})

export type Agenda = eudo.infer<typeof Agenda>
export type AgendaForCreation = eudo.infer<typeof AgendaForCreation>
