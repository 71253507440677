// noinspection JSUnusedGlobalSymbols

import { createFilterListFromEnum } from '@/utils/dataHelpers/filtersHelpers'

export const NEWS_CONTENT = 9302
export const NEWS_PUBLISHED = 9303
export const NEWS_IMAGE_CREDIT = 9304
export const NEWS_PUBLICATION_DATE = 9305
export const NEWS_FIRST_PARAGRAPH = 9306
export const NEWS_IMAGE = 9307
export const NEWS_VIDEO = 9308
export const NEWS_CONTENT_UNDER_VIDEO = 9309
export const NEWS_TAGS = 9310
export const NEWS_CATEGORY_FILTER = 9311
export const NEWS_COMPANY_LINK = 9312
export const NEWS_CATEGORY_HIDDEN = 9313
export const NEWS_SPOTLIGHT = 9314
export const NEWS_HIGHLIGHT = 9315
export const NEWS_KEY_FIGURES_1 = 9317
export const NEWS_KEY_TEXT_FIGURES_1 = 9318
export const NEWS_KEY_FIGURES_2 = 9320
export const NEWS_KEY_TEXT_FIGURES_2 = 9321
export const NEWS_KEY_FIGURES_3 = 9322
export const NEWS_KEY_TEXT_FIGURES_3 = 9323
export const NEWS_CONTENT_UNDER_KEY_FIGURES = 9324
export const NEWS_CREATED_BY = 9325
export const NEWS_TITLE = 9326
export const NEWS_REF_ID = 9327

export enum NEWS_CATEGORY_FILTER_VALUES {
  'Award and competition' = 5538,
  Background = 5537,
  Events = 5536,
  Expansion = 5535,
  Financing = 5534,
  'Industry collaboration' = 5533,
  Launch = 5532,
  Other = 5531,
  Resources = 5530,
  Success = 5529,
  Sustainability = 5528
}

export const getNewsCategoryFilters = () => {
  return createFilterListFromEnum(NEWS_CATEGORY_FILTER_VALUES, 'Categories', NEWS_CATEGORY_FILTER)
}
