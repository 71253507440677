import { afternoonEndTime, morningEndTime } from '@/constants/DateConstants'
import { Agenda } from '@/models/calendar/Agenda'
import { Day } from '@hassanmojab/react-modern-calendar-datepicker'
import dayjs, { Dayjs } from 'dayjs'
import { timeValidator } from './validators'

export const dayjsPastThisMorning = (selectedDayJs: Dayjs) => {
  const currentDayJs = dayjs()

  if (currentDayJs.isBefore(selectedDayJs, 'date')) {
    return false
  }
  return currentDayJs.isSame(selectedDayJs, 'date') && currentDayJs.isAfter(morningEndTime)
}

//Check if the given time is later than the end time of the current date's afternoon period.
export const dayjsPastThisAfternoon = (selectedDayJs: Dayjs) => {
  const currentDayJs = dayjs()

  if (currentDayJs.isBefore(selectedDayJs, 'date')) {
    return false
  }
  return currentDayJs.isSame(selectedDayJs, 'date') && currentDayJs.isAfter(afternoonEndTime)
}

export const toDatePickerDate = (date: Dayjs) => {
  return {
    day: date.date(),
    month: date.month() + 1,
    year: date.year()
  } as Day
}

export const toDatePickerDateSafe = (date?: Dayjs) => {
  if (!date) {
    return undefined
  }

  return {
    day: date.date(),
    month: date.month() + 1,
    year: date.year()
  } as Day
}

export const toDateFromPicker = (date: Day) => {
  return dayjs(new Date(date.year, date.month - 1, date.day))
}

export const startOfToday = dayjs().startOf('day')
export const endOfToday = dayjs().startOf('day')

export const calcDateIsDisabled = (current: any) => {
  return current && current.clone().subtract(1, 'day') < startOfToday
}

export const formatQueryTextToEudonetCompatibleDate = (queryText: string) => {
  return queryText.replaceAll('.', '/')
}

//Sort the agenda items depending on the start date
export const compareAgendaDate = (a: Agenda, b: Agenda) => {
  const { startTime: aStart = undefined } = a
  const { startTime: bStart = undefined } = b

  //startTime can be anything, must validate if it looks like an hour
  if (aStart && bStart && aStart.match(timeValidator) && bStart.match(timeValidator)) {
    const aX = parseInt(aStart.slice(0, 2))
    const bX = parseInt(bStart.slice(0, 2))
    const aY = parseInt(aStart.slice(3, 5))
    const bY = parseInt(bStart.slice(3, 5))

    if (bX > aX) {
      return -1
    } else if (bX === aX) {
      return bY < aY ? 1 : -1
    }
  }
  return 1
}
