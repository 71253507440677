import {
  Box,
  Center,
  HStack,
  Heading,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  chakra,
  useBoolean
} from '@chakra-ui/react'
import { useMemo } from 'react'

const Iframe = chakra('iframe')

export type BusTabsProps = {
  busURL: string
  mapsURL: string
  title: string
}
let currentId = 0
const MAX_WIDTH_XL = '520px'
const MAX_WIDTH_2XL = '600px'

export const BusTabs = ({ busURL, mapsURL, title }: BusTabsProps) => {
  const id = useMemo(() => `iframe-${++currentId}`, [])
  const [isLoaded, setLoaded] = useBoolean()
  return (
    <Tabs
      align='end'
      size='sm'
      variant='unstyled'
      colorScheme='gray'
      w={{ base: 'full', xl: 'inherit' }}>
      <HStack align='start' pl='4'>
        <Heading size={{ base: 'sm', xl: 'md' }} textAlign='start'>
          {title}
        </Heading>
      </HStack>
      <TabList mt={{ base: '0', xl: '-10' }}>
        <Tab _selected={{ borderBottom: '2px solid', borderBottomColor: 'gray.500' }}>Schedule</Tab>
        <Tab _selected={{ borderBottom: '2px solid', borderBottomColor: 'gray.500' }}>Map</Tab>
      </TabList>
      <HStack></HStack>
      <TabPanels>
        <TabPanel>
          <Box
            h={{ base: '250px', xl: '300px' }}
            w={{ base: 'full', xl: MAX_WIDTH_XL, '2xl': MAX_WIDTH_2XL }}
            overflow='hidden'>
            {!isLoaded && (
              <Center>
                <Spinner />
              </Center>
            )}
            <Iframe
              style={{ visibility: isLoaded ? 'visible' : 'hidden' }}
              w={{ base: 'full', xl: '' }}
              height={300}
              src={busURL}
              id={id}
              onLoad={() => {
                let checkList = {
                  clock: false,
                  header: false,
                  overrideCss: false
                }
                const removeUselessElement = () => {
                  const doc = document.querySelector<HTMLIFrameElement>(`#${id}`)?.contentWindow
                    ?.document
                  if (!checkList.clock) {
                    const clock = doc?.querySelector('museho-clock')
                    if (clock) {
                      clock.remove()
                      checkList.clock = true
                    }
                  }
                  if (!checkList.header) {
                    const header = doc?.querySelector('museho-header')
                    if (header) {
                      header.remove()
                      checkList.header = true
                    }
                  }

                  if (doc && !checkList.overrideCss) {
                    const new_style_element = doc?.createElement('style')
                    new_style_element.textContent =
                      '.col-right { display: none !important; } body {background: white;} museho-vehicle-icon {display: none !important;}'
                    doc?.head.appendChild(new_style_element)
                    checkList.overrideCss = true
                  }
                  if (Object.values(checkList).includes(false)) {
                    setTimeout(() => removeUselessElement(), 100)
                  } else {
                    setLoaded.on()
                  }
                }
                setTimeout(() => {
                  removeUselessElement()
                }, 100)
              }}
            />
          </Box>
        </TabPanel>
        <TabPanel>
          <Box boxShadow='md'>
            <Iframe
              src={mapsURL}
              h={{ base: '250px', xl: '300px' }}
              w={{ base: 'full', xl: MAX_WIDTH_XL, '2xl': MAX_WIDTH_2XL }}
              loading='lazy'
              referrerPolicy='no-referrer-when-downgrade'
            />
          </Box>
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
