import { COOKIE_AUTH_TOKEN } from '@/constants/RequestConstants'
import useIsMounted from '@/hooks/useIsMounted'
import { Box } from '@chakra-ui/react'
import Cookies from 'js-cookie'
import Script from 'next/script'
import { useMemo } from 'react'

export type BookARoomProps = {}
export const BookARoom = ({}: BookARoomProps) => {
  const token = Cookies.get(COOKIE_AUTH_TOKEN)
  const url = useMemo(
    () => `${process.env.NEXT_PUBLIC_MINSH_APP}?accessToken=${token}&page=rooms`,
    []
  )
  const isMounted = useIsMounted()
  if (!isMounted) return <></>
  return (
    <>
      <Box
        data-pym-src={url}
        minH='50vh'
        sx={{
          '> iframe': {
            minH: '50vh'
          }
        }}>
        Loading...
      </Box>
      <Script src='https://pym.nprapps.org/pym.v1.min.js' />
    </>
  )
}
