import { Button } from '@chakra-ui/react'
import type { Dayjs } from 'dayjs'

import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css'

interface Props {
  pickerProp: any
  date?: Dayjs
}

const FormDatePickerRender = ({ pickerProp, date }: Props) => {
  return (
    <Button
      placeholder='Select a day'
      ref={pickerProp}
      fontWeight='regular'
      variant='outline'
      w='full'>
      {date ? date.format('MMM DD, YYYY') : 'Select a date'}
    </Button>
  )
}

export default FormDatePickerRender
