import { UserServicePermission } from '@/constants/FieldsUser'
import { ROUTE_COMPANY_DETAILS, ROUTE_PORTAL_COMPANY_EDIT } from '@/constants/Routes'
import userStore from '@/stores/userStore'
import { getCanCompanyRequestSpace, hasPermissionUser } from '@/utils/apiHelpers/userPermissions'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Heading,
  SimpleGrid,
  TabPanel,
  VStack
} from '@chakra-ui/react'
import ControlButton from './ControlButton'

export type CompanyTabProps = {}
export const CompanyTab = ({}: CompanyTabProps) => {
  const [user, company] = userStore(state => [state.user, state.company])
  return (
    <TabPanel>
      <VStack align='stretch'>
        <Heading size='md' mt='0'>
          Company
        </Heading>
        <SimpleGrid columns={[2, 3]} gap='5'>
          {hasPermissionUser(user, UserServicePermission.CompanyList_Read) && company && (
            <ControlButton
              text='View Company Profile'
              target='_blank'
              link={ROUTE_COMPANY_DETAILS(company.companyId)}
              icon='/icons/company.svg'
              info='Access your company’s details published on our Companies page'
            />
          )}
          {hasPermissionUser(user, UserServicePermission.CompanyList_Edit) && (
            <ControlButton
              text='Edit Company Profile'
              link={ROUTE_PORTAL_COMPANY_EDIT}
              icon='/icons/company_manage.svg'
              info='Modify your company’s details published on our Companies page'
            />
          )}
          {hasPermissionUser(user, UserServicePermission.Space_Needs) &&
            getCanCompanyRequestSpace(company) && (
              <ControlButton
                text='Request More Space'
                icon='/icons/space_needs.svg'
                target='_blank'
                link='https://forms.gle/QJ31aAHyBXjPMkNq5'
                info='Let us know if you need additional space to expand your premises so that we can check availability'
              />
            )}
        </SimpleGrid>
        <Alert status='info' colorScheme='green' bg='#E7FBF6' p='5' mt='8'>
          <AlertIcon color='black' />
          <AlertDescription>
            If you wish to edit your company profile please contact one of your company profile
            supervisors or administrators.
          </AlertDescription>
        </Alert>
      </VStack>
    </TabPanel>
  )
}
