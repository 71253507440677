import { ChakraProvider, createStandaloneToast } from '@chakra-ui/react'
import type { ReactNode } from 'react'
import portalTheme from '../../../styles/portalTheme'

interface Props {
  children: ReactNode
}

const { ToastContainer, toast } = createStandaloneToast()

const PortalThemedProvider = ({ children }: Props) => {
  return (
    <ChakraProvider theme={portalTheme}>
      <ToastContainer />
      {children}
    </ChakraProvider>
  )
}

export { toast }

export default PortalThemedProvider
