import { getNewsCategoryFilters } from '@/constants/FieldsNews'
import { FRONT_NEWS_PAGINATED } from '@/constants/Routes'
import useQueryFilters from '@/hooks/useQueryFilters'
import { FilterItem } from '@/models/EudonetModels'
import { News } from '@/models/calendar/News'
import baseFetcher from '@/utils/connectionHelpers/swrFetchers'
import { useIsInViewport } from '@/utils/useIsInViewport'
import { useBoolean } from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import useSWR from 'swr'
import SimpleListDisplay from '../shared/SimpleListDisplay'
import ListFetchingHandling from '../shared/dataFetching/ListFetchingHandling'
import FilterSection, { FilterType } from '../shared/filters/FilterSection'
import NewsCard from './components/NewsCard'

const filters: FilterType[] = [
  {
    name: 'Categories',
    filterList: getNewsCategoryFilters()
  }
]

const SuccessStoriesList = () => {
  const [selectedFilters, setSelectedFilters] = useState<FilterItem[]>([])
  const [queryFilters, addQueryFilter, removeQueryFilter] = useQueryFilters('')
  const [queryText, setQueryText] = useState<string>('')
  const router = useRouter()
  const loadMoreRef = useRef<HTMLDivElement>(null)
  const [fetchAll, setFetchAll] = useBoolean(false)

  const shouldLoadMore = useIsInViewport(loadMoreRef)

  useEffect(() => {
    if (router.query && router.query.news) {
      const newsFilter = router.query.news
      const filterToApply = getNewsCategoryFilters().find(
        item => item.DisplayValue.toLowerCase() === newsFilter
      )

      if (filterToApply) {
        addFilter(filterToApply)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query])

  const { data, error, isLoading } = useSWR<{ data: News[]; items: number }, AxiosError>(
    FRONT_NEWS_PAGINATED(false, queryFilters, queryText, 15, fetchAll),
    baseFetcher,
    {
      keepPreviousData: true,
      revalidateOnFocus: false
    }
  )

  useEffect(() => {
    if (!fetchAll && shouldLoadMore && data && data.data.length < data.items) {
      setFetchAll.on()
    }
  }, [fetchAll, shouldLoadMore])

  const addFilter = (item: FilterItem) => {
    if (selectedFilters.some(temp => temp.Id === item.Id)) {
      return
    }
    setSelectedFilters([...selectedFilters, item])
    setFetchAll.off()
    addQueryFilter(selectedFilters, item)
  }

  const removeFilter = (item: FilterItem) => {
    setSelectedFilters(selectedFilters.filter(filter => item.Id !== filter.Id))
    setFetchAll.off()
    removeQueryFilter(selectedFilters, item)
  }

  return (
    <>
      <FilterSection
        filterTypes={filters}
        selectedFilters={selectedFilters}
        addFilter={addFilter}
        removeFilter={removeFilter}
        searchText={setQueryText}
      />
      <ListFetchingHandling data={data} error={error} />
      {data && data.data.length > 0 && (
        <>
          <SimpleListDisplay<News>
            listItems={data.data}
            card={(item, key) => <NewsCard news={item} key={key} />}
            isLoading={isLoading}
            loadingText='Loading all the news, this might take a while'
          />
        </>
      )}
      <div ref={loadMoreRef}></div>
    </>
  )
}

export default SuccessStoriesList
