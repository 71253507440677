import userStore from '@/stores/userStore'
import {
  HStack,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useBreakpointValue
} from '@chakra-ui/react'
import { faBuilding, faGrip, faScrewdriverWrench, faUser } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AdminToolsTab } from './components/AdminToolsTab'
import { CollaboratorsTab } from './components/CollaboratorsTab'
import { CompanyTab } from './components/CompanyTab'
import PortalHeading from './components/PortalHeading'
import { ResourceTab } from './components/ResourceTab'

const PortalHub = () => {
  return <PortalHubContent />
}

const PortalHubContent = () => {
  const hasCompany = userStore(s => !!s.company)
  const hasAdminRights = userStore(
    ({ user }) => user?.canChangeCompany || user?.canManageEventNews || user?.canRedeploy
  )

  const resourceTabName = useBreakpointValue({ base: 'Res.', lg: 'Resources' })
  const collabsTabName = useBreakpointValue({ base: 'Collab.', lg: 'Collaborators' })
  const companyTabName = useBreakpointValue({ base: 'Comp.', lg: 'Company' })
  const adminTabName = useBreakpointValue({ base: '', lg: 'Admin Tools' })
  return (
    <>
      <VStack mb={[4, 8]}>
        <PortalHeading />
      </VStack>
      <Tabs colorScheme='teal'>
        <TabList borderBottomColor='transparent'>
          <Tab>
            <HStack alignItems='center'>
              <FontAwesomeIcon icon={faGrip} />
              <Text mb='0'>{resourceTabName}</Text>
            </HStack>
          </Tab>
          <Tab>
            <HStack alignItems='center'>
              <FontAwesomeIcon icon={faUser} />
              <Text mb='0'>{collabsTabName}</Text>
            </HStack>
          </Tab>
          {hasCompany && (
            <Tab>
              <HStack alignItems='center'>
                <FontAwesomeIcon icon={faBuilding} />
                <Text mb='0'>{companyTabName}</Text>
              </HStack>
            </Tab>
          )}
          {hasAdminRights && (
            <Tab>
              <HStack alignItems='center'>
                <FontAwesomeIcon icon={faScrewdriverWrench} />
                <Text mb='0'>{adminTabName}</Text>
              </HStack>
            </Tab>
          )}
        </TabList>
        <VStack background='blackAlpha.200' w='full' py={[4, 8]} px='1' spacing={8}>
          <TabPanels>
            <ResourceTab />
            <CollaboratorsTab />
            {hasCompany && <CompanyTab />}
            {hasAdminRights && <AdminToolsTab />}
          </TabPanels>
        </VStack>
      </Tabs>
    </>
  )
}

// noinspection JSUnusedGlobalSymbols
export default PortalHub
