interface Props {
  text: string
  backLink: string
}

const BreadCrumbs = ({ text, backLink }: Props) => {
  return (
    <div className='breadcrumb'>
      <div className='wrapper'>
        <div className='block-content'>
          <div className='breadcrumb-block'>
            <a href={backLink} className='button-with-icon margin-right-1x w-inline-block'>
              <div className='button-icon-content'>
                <div className='fa-l large margin-right-050'></div>
                <div>Back</div>
              </div>
            </a>
            <div className='breadcrunmb-text'>{text}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BreadCrumbs
