import { FRONT_DATE_FORMAT } from '@/constants/DateConstants'
import { FRONT_EVENT_DETAILS_AGENDA } from '@/constants/Routes'
import { Agenda } from '@/models/calendar/Agenda'
import baseFetcher from '@/utils/connectionHelpers/swrFetchers'
import { groupBy } from '@/utils/dataHelpers/collectionHelpers'
import { compareAgendaDate } from '@/utils/dataHelpers/dateHelpers'
import dayjs from 'dayjs'
import useSWR from 'swr'

interface Props {
  eventId: number
}

const EventDetailsAgenda = ({ eventId }: Props) => {
  const { data } = useSWR<Agenda[]>(FRONT_EVENT_DETAILS_AGENDA(eventId), baseFetcher, {
    revalidateOnFocus: false
  })

  return (
    <>
      {data && data.length > 0 && (
        <div className='detail-block-agenda margin-bottom-2x'>
          <div className='separator fill-event-2' />
          <h2 className='display-h4 color-events-2'>Agenda</h2>
          <div className='detail-grid-agenda'>
            {Object.values(groupBy(data, e => e.startDate)).map((items: Agenda[], index) => (
              <div key={index}>
                <div className='text no-margin margin-bottom-1x color-light'>
                  {dayjs(items[0].startDate).format(FRONT_DATE_FORMAT)}
                </div>
                {items.sort(compareAgendaDate).map(item => (
                  <div className='detail-item-agenda margin-bottom-1x' key={item.description}>
                    {item.startTime && (
                      <div className='text large no-margin'>
                        {item.startTime} {item.endTime && ` - ${item.endTime}`}
                      </div>
                    )}
                    <div className='text large no-margin' style={{ gridColumnStart: 2 }}>
                      {item.description}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default EventDetailsAgenda
