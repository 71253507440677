import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack
} from '@chakra-ui/react'
import { faEye, faPlus, faTrash } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LegacyRef, useEffect, useRef, useState } from 'react'
import ReactCrop, { Crop } from 'react-image-crop'

//import 'react-image-crop/dist/ReactCrop.css'
import ReactImageUploading, { ImageListType, ImageType } from 'react-images-uploading'
import { getCroppedImg } from '@/utils/dataHelpers/imageHelpers'

interface UploadProps {
  pictureUrl: string | undefined
  handleDelete: () => void
  handleImageProcessing: (img: File) => void
}

const UploadImage = ({ pictureUrl, handleDelete, handleImageProcessing }: UploadProps) => {
  const initCrop: Crop = { x: 0, y: 0, height: 150, width: 150, unit: 'px' }

  const [previewVisible, setPreviewVisible] = useState(false)
  const [cropModalVisible, setCropModalVisible] = useState(false)
  const [images, setImages] = useState<ImageListType>([])
  const [crop, setCrop] = useState<Crop>(initCrop)
  const imgRef = useRef<HTMLImageElement>()

  //Reset picker when a new image is supplied
  useEffect(() => {
    setCrop(initCrop)
    if (pictureUrl) {
      setImages([{ dataURL: pictureUrl }])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pictureUrl])

  const addToCropper = async (inputFiles: ImageListType) => {
    setImages(inputFiles)
    //Disable the cropping step for now and just send the file.
    /*   if (inputFiles.length > 0) {
     setCropModalVisible(true)
     } else {
     setCropModalVisible(false)
     }*/
    if (inputFiles.length > 0 && inputFiles[0].file) {
      handleImageProcessing(inputFiles[0].file)
    }
  }

  const handleCropping = async () => {
    const croppedImg = await getCroppedImg(imgRef.current, crop, 'user-logo')
    setImages([croppedImg as ImageType])
    setCropModalVisible(false)
    if (images[0].file) {
      handleImageProcessing(images[0].file)
    }
  }

  return (
    <>
      {images.length > 0 && (
        <>
          <Modal isOpen={cropModalVisible} onClose={() => setCropModalVisible(false)}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader />
              <ModalCloseButton />
              <ModalBody>
                <ReactCrop aspect={1} crop={crop} onChange={setCrop}>
                  {/* eslint-disable-next-line @next/next/no-img-element */}
                  <img
                    ref={imgRef as LegacyRef<HTMLImageElement>}
                    src={images[0].dataURL}
                    alt='full-image'
                  />
                </ReactCrop>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme='green' onClick={handleCropping}>
                  Ok
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Modal
            isOpen={previewVisible}
            onClose={() => setPreviewVisible(false)}
            closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent>
              <ModalBody>
                <VStack>
                  {/* eslint-disable-next-line @next/next/no-img-element */}
                  <img src={images[0].dataURL} alt='user-logo' />
                </VStack>
              </ModalBody>
              <ModalFooter>
                <HStack justify='end'>
                  <Button colorScheme='green' onClick={() => setPreviewVisible(false)}>
                    Ok
                  </Button>
                </HStack>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      )}
      <VStack align='center' w='full' spacing={5}>
        <ReactImageUploading value={images} onChange={addToCropper}>
          {({ imageList, onImageUpload, onImageRemoveAll, isDragging, dragProps }) =>
            imageList.length > 0 ? (
              <Box
                position='relative'
                w={40}
                h={40}
                border='1px dotted'
                borderRadius='md'
                overflow='hidden'>
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img src={imageList[0].dataURL} alt='uploadImage' />
                <VStack
                  position='absolute'
                  top={0}
                  bottom={0}
                  left={0}
                  right={0}
                  opacity={0}
                  transition='0.15s all'
                  _hover={{ bgColor: 'gray.600', opacity: '0.7' }}
                  justify='center'>
                  <HStack spacing={4}>
                    <Button
                      variant='link'
                      h={5}
                      w={5}
                      color='white'
                      onClick={() => {
                        onImageRemoveAll()
                        handleDelete()
                      }}>
                      <Box h={5} w={5}>
                        <FontAwesomeIcon icon={faTrash} />
                      </Box>
                    </Button>
                    <Button
                      variant='link'
                      h={5}
                      w={5}
                      color='white'
                      onClick={() => setPreviewVisible(true)}>
                      <Box h={5} w={5}>
                        <FontAwesomeIcon icon={faEye} />
                      </Box>
                    </Button>
                  </HStack>
                </VStack>
              </Box>
            ) : (
              <Button
                w={40}
                h={40}
                border='1px dotted'
                justifyItems='center'
                alignItems='center'
                onClick={onImageUpload}
                {...dragProps}>
                {isDragging ? (
                  <Text>Drop image here</Text>
                ) : (
                  <HStack>
                    <Box w={5} h={5}>
                      <FontAwesomeIcon icon={faPlus} />
                    </Box>
                    <Text> Upload</Text>
                  </HStack>
                )}
              </Button>
            )
          }
        </ReactImageUploading>
      </VStack>
    </>
  )
}

export default UploadImage
