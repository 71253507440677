interface Props {
  text: string
  link: string
}

//TODO use this button when needed in the modules
const LinkButton = ({ text, link }: Props) => {
  return (
    <a href={link} className='button-with-icon dark w-inline-block'>
      <div className='button-icon-content'>
        <div className='button-icon-text'>{text}</div>
        <div className='button-icon small'></div>
      </div>
    </a>
  )
}

export default LinkButton
