import { COOKIE_AUTH_TOKEN } from '@/constants/RequestConstants'
import { BoxProps, chakra } from '@chakra-ui/react'
import Cookies from 'js-cookie'
import { useMemo } from 'react'

const Iframe = chakra('iframe')

export const MinshIframe = ({ page, ...boxProps }: BoxProps & { page: string }) => {
  const token = Cookies.get(COOKIE_AUTH_TOKEN)
  const url = useMemo(
    () => `${process.env.NEXT_PUBLIC_MINSH_APP}?accessToken=${token}&page=${page}`,
    [page]
  )

  return <Iframe src={url} w='full' h={{ base: '80vh', lg: '1000px' }} {...boxProps} />
}
