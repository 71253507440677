import type { ReactNode } from 'react'

interface Props<T> {
  title?: string
  moreTitle?: string
  moreLink?: string
  listItems: T[]
  scrollingTitle?: boolean
  card: (item: T, key: number) => ReactNode
  cardsClass?: string
}

//The module must be injected in the page to have working animations.
//This is why it's display to none if there aren't any data, this way it's not visible but have working animations
function ListDisplay<T>({
  title,
  moreTitle,
  moreLink,
  listItems,
  scrollingTitle,
  card,
  cardsClass
}: Props<T>) {
  return (
    <div style={{ display: listItems.length === 0 ? 'none' : 'block' }}>
      {scrollingTitle && (
        <div className='section-title section-full-w'>
          <div className='section-title-block'>
            <div className='section-title-wrapper'>
              <div className='section-title-text'> · {title} ·</div>
              <h2 className='display-h2 no-margin text-color'>{title}</h2>
              <div className='section-title-text'>
                · {title} · {title} · {title} · {title}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='section'>
        <div className='wrapper margin-bottom-4x'>
          <div className='block-content'>
            <div
              className={title && !scrollingTitle ? 'header-w-button margin-bottom-8x' : undefined}>
              {title && !scrollingTitle && (
                <h2 className='display-h2 no-margin margin-bottom-8x'>{title}</h2>
              )}
              {moreLink && moreTitle && (
                <a
                  href={moreLink}
                  className='button icon button-dark margin-left-050 w-inline-block'>
                  <div className='button-icon-content'>
                    <div className='button-icon-text'>{moreTitle}</div>
                    <div className='button-icon'></div>
                  </div>
                </a>
              )}
            </div>
          </div>
          <div className={`grid-card ${cardsClass ? cardsClass : ''}`}>
            {listItems.map((item, key) => card(item, key))}
          </div>
        </div>
      </div>
    </div>
  )
}

// noinspection JSUnusedGlobalSymbols
export default ListDisplay
