import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  useBoolean,
  useDisclosure,
  useTheme,
  VStack
} from '@chakra-ui/react'
import { faEye, faEyeSlash, faLock } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Image from 'next/image'
import { SubmitHandler, useForm } from 'react-hook-form'
import { resetPassword } from '@/stores/userStore'
import { axiosErrorToast, showToast } from '@/utils/showToast'
import { LoginPages } from '../Login'

interface Props {
  setPage: (newState: LoginPages) => void
  token: string
}

type FormData = {
  password: string
}

const ResetPassword = ({ setPage, token }: Props) => {
  const theme = useTheme()
  const [requestingPassword, setRequestingPassword] = useBoolean()
  const { isOpen: passwordShown, onToggle: onTogglePasswordShown } = useDisclosure()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>()

  const onSubmit: SubmitHandler<FormData> = async data => {
    setRequestingPassword.on()
    const error = await resetPassword(data.password, token)

    if (error) {
      axiosErrorToast(error, 'warning')
      setRequestingPassword.off()
    } else {
      showToast('You can now log in with your new password')
      setPage('login')
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack w={['full', null, '375px']} p={[5, 10]} spacing={5} align='start'>
        <Image width={110} height={35} src='/logo.svg' objectFit='cover' alt='login-logo' />
        <Heading size='md'>Reset your password</Heading>
        <Text>Please enter your new password.</Text>
        <FormControl isInvalid={'password' in errors}>
          <InputGroup>
            <InputLeftElement pointerEvents='none' color={theme.colors.gray['300']}>
              <Box w={5}>
                <FontAwesomeIcon icon={faLock} />
              </Box>
            </InputLeftElement>
            <Input
              type={passwordShown ? 'text' : 'password'}
              placeholder='Password'
              {...register('password', {
                required: 'Please enter a new password',
                maxLength: 100
              })}
            />
            <InputRightElement h='full'>
              <Button h='full' size='sm' variant='ghost' onClick={onTogglePasswordShown}>
                <FontAwesomeIcon icon={passwordShown ? faEye : faEyeSlash} />
              </Button>
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage>{errors.password && errors.password?.message}</FormErrorMessage>
        </FormControl>

        <HStack justify='space-between' w='full'>
          <Button colorScheme='green' type='submit' isLoading={requestingPassword}>
            Reset
          </Button>
          <Button size='sm' colorScheme='green' variant='link' onClick={() => setPage('login')}>
            Back to login
          </Button>
        </HStack>
      </VStack>
    </form>
  )
}

// noinspection JSUnusedGlobalSymbols
export default ResetPassword
