import { createFilterListFromEnum } from '@/utils/dataHelpers/filtersHelpers'

export const APPROVED_SUPPLIER_CATEGORY = 9503
export const APPROVED_SUPPLIER_SERVICE = 9504
export const APPROVED_SUPPLIER_NAME = 9502
export const APPROVED_SUPPLIER_WEBSITE = 9508
export const APPROVED_SUPPLIER_CONTACT_NAME = 9507
export const APPROVED_SUPPLIER_PHONE_NUMBER = 9505
export const APPROVED_SUPPLIER_EMAIL = 9506
export const APPROVED_SUPPLIER_MORE_INFO = 9509
export const APPROVED_SUPPLIER_SUSTAINABLE = 9515

export enum APPROVED_SUPPLIERS_CATEGORY_VALUES {
  Consulting = 5518,
  Finance = 5519,
  Hospitality = 5521,
  'HR/Administration' = 5520,
  Legal = 5522,
  Maintenance = 5501,
  Marketing = 5523,
  Office = 5524,
  'Personal services' = 5525
}

export const getSuppliersCategoriesFilter = () => {
  return createFilterListFromEnum(
    APPROVED_SUPPLIERS_CATEGORY_VALUES,
    'Category',
    APPROVED_SUPPLIER_CATEGORY
  )
}
