import {
  getAllEventCategoryFilters,
  getEventCategoryFilters,
  getEventLocationFilters
} from '@/constants/FieldsEvent'
import { FRONT_EVENTS_PAGINATED } from '@/constants/Routes'
import useQueryFilters from '@/hooks/useQueryFilters'
import { FilterItem } from '@/models/EudonetModels'
import { Event } from '@/models/calendar/Event'
import userStore from '@/stores/userStore'
import baseFetcher from '@/utils/connectionHelpers/swrFetchers'
import { Button, Center, ChakraProvider, HStack, useBoolean } from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import useSWR from 'swr'
import SimpleListDisplay from '../shared/SimpleListDisplay'
import ListFetchingHandling from '../shared/dataFetching/ListFetchingHandling'
import FilterSection, { FilterType } from '../shared/filters/FilterSection'
import EventCard from './components/EventCard'
import FilterToggles from './components/FilterToggles'

const filters: FilterType[] = [
  { name: 'Categories', ...getEventCategoryFilters() },
  { name: 'Locations', filterList: getEventLocationFilters() }
]

// noinspection JSIgnoredPromiseFromCall
const EventsList = () => {
  const [selectedFilters, setSelectedFilters] = useState<FilterItem[]>([])
  const [queryFilters, addQueryFilter, removeQueryFilter] = useQueryFilters('')
  const [queryText, setQueryText] = useState<string>('')
  const router = useRouter()
  const [isKnovaMember] = userStore(state => [state.isKnovaMember])
  const [membersOnly, setMembersOnly] = useState(false)
  const [fetchAll, setFetchAll] = useBoolean(false)

  useEffect(() => {
    if (router.query && router.query.events) {
      const eventFilter = router.query.events
      let filterToApply = getAllEventCategoryFilters().find(
        filter => filter.DisplayValue.toLowerCase() === eventFilter
      )

      if (filterToApply) {
        // noinspection JSIgnoredPromiseFromCall
        addFilter(filterToApply)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query])

  const { data, error } = useSWR<{ data: Event[]; items: number }, AxiosError>(
    FRONT_EVENTS_PAGINATED(
      fetchAll,
      queryFilters,
      queryText,
      true,
      isKnovaMember ? (membersOnly ? 'only' : 'all') : 'none'
    ),
    baseFetcher,
    { revalidateOnFocus: false }
  )

  useEffect(() => {
    getEventCategoryFilters()
  }, [])

  const addFilter = async (item: FilterItem) => {
    if (selectedFilters.some(temp => temp.Id === item.Id)) {
      return
    }
    setSelectedFilters([...selectedFilters, item])

    addQueryFilter(selectedFilters, item)
  }

  const removeFilter = (item: FilterItem) => {
    setSelectedFilters(selectedFilters.filter(filter => item.Id !== filter.Id))

    removeQueryFilter(selectedFilters, item)
  }

  return (
    <ChakraProvider>
      <FilterSection
        filterTypes={filters}
        selectedFilters={selectedFilters}
        addFilter={addFilter}
        removeFilter={removeFilter}
        searchText={setQueryText}
        additionalButtons={
          <HStack ml={10}>
            <FilterToggles
              calendarToggled={false}
              memberEventsToggled={membersOnly}
              onMembersEvents={setMembersOnly}
            />
          </HStack>
        }
      />
      <ListFetchingHandling data={data} error={error} />
      {data && data.data.length > 0 && (
        <>
          <SimpleListDisplay<Event>
            listItems={data.data}
            card={(item, key) => <EventCard event={item} key={key} />}
          />
          {!fetchAll && (
            <Center>
              <Button
                colorScheme='blackAlpha'
                borderRadius='full'
                bgColor='black'
                mb='5'
                onClick={() => setFetchAll.on()}>
                View all
              </Button>
            </Center>
          )}
        </>
      )}
    </ChakraProvider>
  )
}

export default EventsList
