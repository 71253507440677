import { incrementalTimeoutHandler } from '../connectionHelpers/incrementalTimeout'
import axios from 'axios'

export const emailValidator =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
//Courtesy of https://stackoverflow.com/questions/1410311/regular-expression-for-url-validation-in-javascript
export const urlValidator = /(https?:\/\/)?([\da-z\.-]+)\.([a-z]{2,6})([\/\w.-]*)*\/?/
export const timeValidator = /^\d{2}.\d{2}/

export const workingURLValidator = async (URL = '') => {
  //Check the url is not an invalid format:
  if (!URL.match(urlValidator)) {
    return false
  }

  //Check that url returns valid webpage.
  try {
    const validity = await incrementalTimeoutHandler(async timeout =>
      axios.get(URL, { timeout: timeout })
    )

    return validity.status === 200
  } catch (error) {
    return false
  }
}
