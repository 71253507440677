import { ROUTE_TENANT_PORTAL } from '@/constants/Routes'
import { emailValidator } from '@/utils/dataHelpers/validators'
import { axiosErrorToast } from '@/utils/showToast'
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  useBoolean,
  useTheme,
  VStack
} from '@chakra-ui/react'
import { faEnvelope, faEye, faEyeSlash, faLock } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { SubmitHandler, useForm } from 'react-hook-form'
import userStore from '../../../stores/userStore'
import { LoginPages } from '../Login'

interface Props {
  setPage: (newState: LoginPages) => void
}

type FormData = {
  email: string
  password: string
}

const LoginForm = ({ setPage }: Props) => {
  const theme = useTheme()
  const router = useRouter()
  const [passwordShown, setPasswordShown] = useBoolean()
  const [loggingIn, setLoggingIn] = useBoolean()
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>()
  const login = userStore(state => state.login)

  const onSubmit: SubmitHandler<FormData> = async data => {
    setLoggingIn.on()
    const error = await login(data.email, data.password)
    router.push('/portal')
    if (error) {
      axiosErrorToast('Error while signing in... Please check your credentials', 'warning')
      setLoggingIn.off()
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack w={['full', null, '375px']} p={[5, 10]} spacing={5} align='start'>
        <Image width={110} height={35} src='/logo.svg' alt='login-logo' />
        <Heading size='md'>Login</Heading>
        <Text>Enter your login/password</Text>
        <FormControl isInvalid={'email' in errors}>
          <InputGroup>
            <InputLeftElement pointerEvents='none' color={theme.colors.gray['300']}>
              <Box w={5}>
                <FontAwesomeIcon icon={faEnvelope} />
              </Box>
            </InputLeftElement>
            <Input
              placeholder='Email'
              {...register('email', {
                pattern: {
                  value: emailValidator,
                  message: 'Please insert a valid email'
                },
                required: 'Please enter your email address'
              })}
            />
          </InputGroup>
          <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={'password' in errors}>
          <InputGroup>
            <InputLeftElement pointerEvents='none' color={theme.colors.gray['300']}>
              <Box w={5}>
                <FontAwesomeIcon icon={faLock} />
              </Box>
            </InputLeftElement>
            <Input
              type={passwordShown ? 'text' : 'password'}
              placeholder='Password'
              {...register('password', {
                required: 'Please enter your password',
                maxLength: 100
              })}
            />
            <InputRightElement h='full'>
              <Button variant='ghost' onClick={setPasswordShown.toggle}>
                <FontAwesomeIcon icon={passwordShown ? faEye : faEyeSlash} />
              </Button>
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
        </FormControl>

        <HStack justify='space-between' w='full'>
          <Button colorScheme='green' type='submit' isLoading={loggingIn}>
            Login
          </Button>
          <VStack align='start' spacing={0}>
            <Link href={ROUTE_TENANT_PORTAL} passHref>
              <Button size='sm' colorScheme='green' variant='link'>
                What's my login?
              </Button>
            </Link>
            <Button size='sm' colorScheme='green' variant='link' onClick={() => setPage('forgot')}>
              Create or reset password
            </Button>
          </VStack>
        </HStack>
      </VStack>
    </form>
  )
}

// noinspection JSUnusedGlobalSymbols
export default LoginForm
