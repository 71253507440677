import { COMPANY_MAIN_SECTOR, COMPANY_NUMBER_OF_EMPLOYEES } from '@/constants/FieldsCompany'
import { FRONT_CATALOG, FRONT_COMPANY_DETAILS } from '@/constants/Routes'
import type { Catalog } from '@/models/calendar/Catalog'
import { UpdateCompany } from '@/models/company/UpdateCompany'
import userStore from '@/stores/userStore'
import { axiosVercelAPI } from '@/utils/connectionHelpers/axios'
import baseFetcher from '@/utils/connectionHelpers/swrFetchers'
import { handleUpdateErrors } from '@/utils/dataHelpers/apiResponseHelper'
import { urlValidator } from '@/utils/dataHelpers/validators'
import { showToast } from '@/utils/showToast'
import { Divider, Link, Text, useBoolean } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import useSWR from 'swr'
import DefaultFormWrapper from '../shared/form/DefaultFormWrapper'
import FormCheckbox from '../shared/form/FormCheckbox'
import FormHeading from '../shared/form/FormHeading'
import FormInput from '../shared/form/FormInput'
import FormSelect from '../shared/form/FormSelect'
import FormTagsInput from '../shared/form/FormTagsInput'
import FormTextarea from '../shared/form/FormTextarea'
import ImageUpload from '../shared/imageUpload/ImageUpload'

const ManageCompany = () => {
  return <UpdateCompanyContent />
}

const UpdateCompanyContent = () => {
  const company = userStore(state => state.company)
  const updateCompany = userStore(state => state.updateCompany)

  const [loading, setLoading] = useBoolean(false)

  const { data: mainSectors } = useSWR<Catalog[]>(FRONT_CATALOG(COMPANY_MAIN_SECTOR), baseFetcher, {
    revalidateOnFocus: false
  })
  const { data: employees } = useSWR<Catalog[]>(
    FRONT_CATALOG(COMPANY_NUMBER_OF_EMPLOYEES),
    baseFetcher,
    { revalidateOnFocus: false }
  )

  useEffect(() => {
    if (company) {
      setValue('foundingYear', company.foundingYear)
      setValue('headerDescription', company.headerDescription)
      setValue('description', company.description)
      setValue('video', company.video)
      setValue('contactPerson', company.contactPerson)
      setValue('contactEmail', company.contactEmail)
      setValue('contactPhone', company.contactPhone)
      setValue('website', company.website)
      setValue('productLegend', company.productLegend)
      setValue('tags', company.tags)
      setValue('productPicture', company.productPicture)

      if (employees) {
        const val = employees.find(temp => temp.DisplayValue === company.numberOfEmployees)
        if (val) {
          setValue('numberOfEmployees', val.Id.toString())
        }
      }
      if (mainSectors) {
        const val = mainSectors.find(temp => temp.DisplayValue === company.mainSector)
        if (val) {
          setValue('mainSector', val.Id.toString())
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, employees, mainSectors])

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm<UpdateCompany>()

  const onSubmit: SubmitHandler<UpdateCompany> = async data => {
    if (!company) {
      return
    }
    setLoading.on()

    try {
      await axiosVercelAPI.put(FRONT_COMPANY_DETAILS(company.companyId), {
        ...data
      })
      showToast('Company successfully updated')
    } catch (e) {
      handleUpdateErrors(e)
    } finally {
      setLoading.off()
      await updateCompany()
    }
  }

  return (
    <DefaultFormWrapper
      title='Edit company details'
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      loading={loading}>
      <Text size='xs'>
        To modify your logo, please send an image with transparent background (PNG format) to the
        following email:{' '}
        <Link href='mailto:support@epfl-innovationpark.ch' color='teal.500'>
          support@epfl-innovationpark.ch
        </Link>
      </Text>
      <Divider />
      {company && (
        <>
          <Text textAlign='start' w='full' fontSize='2xl'>
            {company.signage}
          </Text>
          <FormInput<UpdateCompany>
            name='foundingYear'
            label='Founding year'
            placeholder='Company funding year'
            type='number'
            register={register}
            errors={errors}
            maxLength={4}
            rules={{
              maxLength: 4,
              max: dayjs().year()
            }}
          />
          <FormSelect<UpdateCompany>
            name='mainSector'
            label='Main Sector'
            errors={errors}
            register={register}
            options={mainSectors?.map(item => {
              return { value: item.Id.toString(), label: item.DisplayValue }
            })}
          />
          <FormTagsInput<UpdateCompany>
            name='tags'
            label='Tags'
            errors={errors}
            onUpdate={val => setValue('tags', val)}
            tags={watch('tags')}
            maxNumberOfTags={4}
          />
          <FormInput<UpdateCompany>
            name='headerDescription'
            label='Introduction of the description'
            placeholder='Introduction of the description about your company'
            register={register}
            errors={errors}
            watch={watch('headerDescription')}
            rules={{
              maxLength: 100
            }}
          />
          <FormTextarea<UpdateCompany>
            register={register}
            errors={errors}
            label='Description'
            name='description'
            resize='vertical'
            placeholder='Provide a description of your company'
          />
          <FormInput<UpdateCompany>
            name='video'
            label='Company video (URL address)'
            placeholder='Video presenting your company'
            register={register}
            errors={errors}
            rules={{
              pattern: {
                value: urlValidator,
                message: 'Please enter a valid URL'
              }
            }}
          />

          <Divider />
          <FormInput<UpdateCompany>
            name='contactPerson'
            label='Contact person'
            placeholder='Contact person'
            register={register}
            errors={errors}
            watch={watch('contactPerson')}
            rules={{
              maxLength: 60
            }}
          />
          <FormInput<UpdateCompany>
            name='contactEmail'
            label='Contact email'
            placeholder='Contact email'
            register={register}
            errors={errors}
          />
          <FormInput<UpdateCompany>
            name='contactPhone'
            label='Contact phone'
            placeholder='Contact phone'
            register={register}
            errors={errors}
          />
          <FormInput<UpdateCompany>
            name='website'
            label='Company website'
            placeholder='Company website'
            register={register}
            errors={errors}
          />

          <Divider />
          <FormSelect<UpdateCompany>
            name='numberOfEmployees'
            label='Company size'
            errors={errors}
            register={register}
            options={employees?.map(item => {
              return { value: item.Id.toString(), label: item.DisplayValue }
            })}
          />
          <FormCheckbox<UpdateCompany>
            errors={errors}
            register={register}
            name='epflSpinOff'
            label='EPFL Startup'
            watch={!!watch('epflSpinOff')}
            defaultChecked={company.epflStartups}
          />
          <FormCheckbox<UpdateCompany>
            errors={errors}
            register={register}
            name='scaleUpVaud'
            label='Scale-up Vaud'
            watch={!!watch('scaleUpVaud')}
            defaultChecked={company.scaleUpVaud}
          />
          <FormHeading label='Product image' />
          <ImageUpload
            pictureUrl={company.productPicture}
            base64ImageChange={data => setValue('productPicture', data)}
          />
          <FormTextarea<UpdateCompany>
            register={register}
            errors={errors}
            label='Product description'
            name='productLegend'
            resize='vertical'
            placeholder='Describe your product'
          />
        </>
      )}
    </DefaultFormWrapper>
  )
}

export default ManageCompany
