import { ROUTE_EPFL_STARTUPS, ROUTE_SCALE_UP_VAUD } from '@/constants/Routes'
import { PublicCompany } from '@/models/company/PublicCompany'

interface Props {
  company: PublicCompany
}

const CompanyInformation = ({ company }: Props) => {
  const getCompanySizeText = () => {
    if (company.numberOfEmployees === '+250') {
      return 'More than 250'
    } else {
      const temp = company.numberOfEmployees.split('-')
      return `Between ${temp[0]} and ${temp[1]}`
    }
  }

  return (
    <div className='block-content'>
      {(company.contactPerson ||
        company.contactPhone ||
        company.contactEmail ||
        company.website) && (
        <div className='card margin-bottom-2x'>
          <div className='separator fill-event-1'></div>
          <div className='text small color-light'>Contact</div>
          {company.contactPerson && (
            <div className='text bold no-margin'>{company.contactPerson}</div>
          )}
          {company.contactPhone ? (
            <div className='text color-light'>
              {company.contactPhone} {company.contactEmail && ` / ${company.contactEmail}`}
            </div>
          ) : (
            company.contactEmail && <div className='text color-light'>{company.contactEmail}</div>
          )}
          {company.website && (
            <a
              href={company.website}
              target='_blank'
              className='text color-secondary'
              rel='noreferrer'>
              {company.website}
            </a>
          )}
        </div>
      )}
      {company.location && company.building && (
        <div className='card margin-bottom-2x'>
          <div className='separator fill-event-1'></div>
          <>
            <div className='text small color-light'>Location</div>
            <div className='text bold no-margin'>
              {company.location}, {company.building}
            </div>
          </>
        </div>
      )}

      {company.size && company.mainSector && (
        <div className='card margin-bottom-2x'>
          <div className='separator fill-event-1'></div>
          <div className='text small color-light'>Type</div>
          <div className='text bold no-margin'>
            {company.size} in {company.mainSector}
          </div>
        </div>
      )}
      {company.numberOfEmployees && (
        <div className='card margin-bottom-2x'>
          <div className='separator fill-event-1'></div>
          <div className='text small color-light'>Employees</div>
          <div className='text bold no-margin'>{getCompanySizeText()}</div>
        </div>
      )}
      <div className='flex-content-vertical gap1x'>
        {company.scaleUpVaud && (
          <a
            href={ROUTE_SCALE_UP_VAUD}
            target='_blank'
            rel='noreferrer noopener'
            className='button icon button-dark w-inline-block'>
            <div className='button-icon-content'>
              <div className='button-icon-text'>Part of Scale-Up Vaud</div>
              <div className='button-icon small'></div>
            </div>
          </a>
        )}
        {company.epflStartups && (
          <a
            href={ROUTE_EPFL_STARTUPS}
            target='_blank'
            rel='noreferrer noopener'
            className='button icon button-dark w-inline-block'>
            <div className='button-icon-content'>
              <div className='button-icon-text'>EPFL startup</div>
              <div className='button-icon small'></div>
            </div>
          </a>
        )}
      </div>
    </div>
  )
}

export default CompanyInformation
