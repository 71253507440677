import { MinshIframe } from '@/components/minsh/MinshIframe'
import { UserServicePermission } from '@/constants/FieldsUser'
import { COOKIE_AUTH_TOKEN } from '@/constants/RequestConstants'
import {
  ROUTE_BOOK_A_ROOM,
  ROUTE_CAMIPRO,
  ROUTE_ECOSYSTEM_APPROVED_SUPPLIERS,
  ROUTE_EPFL_NEWS,
  ROUTE_FAIRMOVE_CAR_POOLING,
  ROUTE_FAIRMOVE_PARKING,
  ROUTE_PORTAL_JOBS,
  ROUTE_SMART_GUIDES_ADMIN,
  ROUTE_SMART_GUIDES_EMPLOYEE,
  ROUTE_SMART_GUIDES_SUPERVISOR,
  ROUTE_TEMPLATES,
  ROUTE_TICKETING
} from '@/constants/Routes'
import { Menu, MenuItem, VEGETARIAN } from '@/models/menu'
import userStore from '@/stores/userStore'
import { getCanShareKNOVAFiles, hasPermissionUser } from '@/utils/apiHelpers/userPermissions'
import {
  Box,
  Button,
  Center,
  HStack,
  Heading,
  SimpleGrid,
  Spacer,
  Spinner,
  Stack,
  TabPanel,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr,
  VStack
} from '@chakra-ui/react'
import { TypedFetch } from '@coteries/utils/api-utils'
import {
  faArrowUpRightFromSquare,
  faFileLines,
  faInfoCircle,
  faLeaf,
  faNewspaper
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Cookies from 'js-cookie'
import Image from 'next/image'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import { BusTabs } from './BusTabs'
import ControlButton from './ControlButton'
import { EventsCarousel } from './EventsCarousel'
import { MemberEvents } from './MemberEvents'

const stops = {
  epfl: {
    map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d890.2897585859679!2d6.56177492789564!3d46.5178839133183!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478c30fcf9e0f155%3A0xf4770112a81f1843!2sEcublens%20VD%2C%20EPFL%2FColladon!5e0!3m2!1sfr!2sch!4v1692889276882!5m2!1sfr!2sch',
    bus: '/api/proxy/museho/bus?roid1=1972987716698369&name1=Ecublens%20VD,%20EPFL%2FColladon&displayPlatform1=false'
  },
  parcScientific: {
    map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1372.7859513592796!2d6.5624945391773375!3d46.51658383852603!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478c30fc7c22d02b%3A0x1deaa42ea78e28c5!2sSt%20Sulpice%20VD%2C%20Parc%20Scient.!5e0!3m2!1sfr!2sch!4v1692974610764!5m2!1sfr!2sch',
    bus: '/api/proxy/museho/bus?roid1=1970518110562812&name1=St-Sulpice%20VD,%20Parc%20Scient&displayPlatform1=false'
  }
}

const PUURMenuRow = ({ item }: { item: MenuItem }) => (
  <Tr>
    <Td p='2'>
      <VStack align='stretch' fontSize='1rem'>
        <Text fontWeight='bold' m='0'>
          {item.dish}
        </Text>
        {item.category === VEGETARIAN && (
          <Text color='green.500' fontSize='75%' m='0'>
            <FontAwesomeIcon icon={faLeaf} />
            <Text ml='1' as='span'>
              Vegetarian
            </Text>
          </Text>
        )}
        <Text m='0' fontSize='.875rem'>
          {item.price}
        </Text>
      </VStack>
    </Td>
    <Td p='2'>
      {item.nutriScore && (
        <Image
          width={68}
          height={55}
          src={`/api/proxy/nutri-images/nutriMenu_score_${item.nutriScore.toLocaleLowerCase()}.svg`}
          alt={`nutriScore ${item.nutriScore}`}
        />
      )}
      {item.ecoScore && (
        <Image
          width={68}
          height={55}
          src={`/api/proxy/nutri-images/ecoMenu_score_${item.ecoScore.toLocaleLowerCase()}.svg`}
          alt={`ecoScore ${item.ecoScore}`}
        />
      )}
    </Td>
  </Tr>
)

export type ResourceTabProps = {}
export const ResourceTab = ({}: ResourceTabProps) => {
  const [user, company] = userStore(state => [state.user, state.company])
  const [puurMenu, setPuurMenu] = useState<Menu>()
  const token = Cookies.get(COOKIE_AUTH_TOKEN)

  useEffect(() => {
    const fn = async () => {
      try {
        setPuurMenu(await TypedFetch.get<Menu>('/api/puur/menu'))
      } catch (e) {
        console.log('error:', e)
        setPuurMenu([])
      }
    }
    fn()
  }, [])
  return (
    <>
      <TabPanel>
        <VStack>
          <Stack
            direction={{ base: 'column', xl: 'row' }}
            align='start'
            justify='start'
            w='full'
            spacing={{ base: 0, xl: 8 }}>
            <VStack align='start' flex={1} flexBasis={{ base: '100%', xl: '50%' }} w='full'>
              <Box display={{ base: 'block', xl: 'none' }}>
                <MemberEvents user={user} company={company} />
              </Box>
              <Heading size='md' m='0' mb={{ base: 2, lg: 4 }}>
                Resources
              </Heading>
              <SimpleGrid columns={{ base: 2, md: 3, xl: 3 }} w='full' gap='3'>
                {hasPermissionUser(user, UserServicePermission.BookARoom_Book) && (
                  <ControlButton
                    text='Book a room'
                    link={ROUTE_BOOK_A_ROOM}
                    icon='/icons/bookaroom.svg'
                    info='Book a conference room at the EPFL Innovation Park'
                  />
                )}
                {token && hasPermissionUser(user, UserServicePermission.Ticketing_Create) && (
                  <ControlButton
                    text='Ticketing'
                    link={ROUTE_TICKETING(token)}
                    icon='/icons/ticketing.svg'
                    target='_blank'
                    info='Report any requests or problems related to technical issues in your premises'
                  />
                )}
                {hasPermissionUser(user, UserServicePermission.Smart_Guides) && (
                  <ControlButton
                    text='Smart Guides'
                    link={
                      hasPermissionUser(user, UserServicePermission.Smart_Guides_Employee)
                        ? ROUTE_SMART_GUIDES_EMPLOYEE
                        : hasPermissionUser(user, UserServicePermission.Smart_Guides_Admin)
                        ? ROUTE_SMART_GUIDES_ADMIN
                        : ROUTE_SMART_GUIDES_SUPERVISOR
                    }
                    icon='/icons/smart_guide.svg'
                    info='Find easily summarized information about the offer of the EPFL Innovation Park'
                  />
                )}
                {hasPermissionUser(user, UserServicePermission.Parkshare_Read) && (
                  <ControlButton
                    text='Fairmove parking'
                    link={ROUTE_FAIRMOVE_PARKING}
                    icon='/icons/parkshare.svg'
                    target='_blank'
                    info='Book a daily parking space for CHF 14.00 (CHF18.00 with an electrical charging station)'
                  />
                )}
                <ControlButton
                  text='Fairmove carpooling'
                  link={ROUTE_FAIRMOVE_CAR_POOLING}
                  icon='/icons/car-sharing.png'
                  target='_blank'
                  info='Carpool with our community and meet your neighbour'
                />
                {hasPermissionUser(user, UserServicePermission.Jobs_Create) && (
                  <ControlButton
                    text='Jobs'
                    link={ROUTE_PORTAL_JOBS}
                    icon='/icons/jobs_manage.svg'
                    info='Publish a job offer on our Jobs page'
                  />
                )}

                {hasPermissionUser(user, UserServicePermission.Suppliers_Read) && (
                  <ControlButton
                    text='Approved Suppliers'
                    link={ROUTE_ECOSYSTEM_APPROVED_SUPPLIERS}
                    icon='/icons/approved_suppliers.svg'
                    info='Access a list of service providers recommended by the EPFL Innovation Park and the companies located in the Park'
                  />
                )}
                {hasPermissionUser(user, UserServicePermission.Camipro) && (
                  <ControlButton
                    text='Camipro'
                    link={ROUTE_CAMIPRO}
                    icon='/icons/camipro.svg'
                    target='_blank'
                    info='Access to the order form for new camipro badges for your collaborators'
                  />
                )}

                {hasPermissionUser(user, UserServicePermission.Templates) && (
                  <ControlButton
                    text='Templates'
                    link={ROUTE_TEMPLATES}
                    icon={faFileLines}
                    info='Download templates provided by EPFL Innovation Park that you can adapt to your company'
                  />
                )}
                <ControlButton
                  text='EPFL News'
                  link={ROUTE_EPFL_NEWS}
                  icon={faNewspaper}
                  target='_blank'
                  info='Access to the EPFL Campus news'
                />
                {getCanShareKNOVAFiles(user, company) && (
                  <ControlButton
                    text='Shared Files'
                    link={process.env.NEXT_PUBLIC_SHARE_FILES_URL || ''}
                    icon='/icons/switch_drive.svg'
                    info='Access to KNOVA shared documents (e.g contact list, PowerPoint presentations..)'
                  />
                )}
              </SimpleGrid>
              <HStack justify='space-between' w='full' align='center' py='2'>
                <Heading size='md' m='0'>
                  Daily Menus @Puur
                </Heading>
                <Button
                  p='0'
                  h='fit-content'
                  size='sm'
                  rightIcon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
                  variant='link'
                  color='gray.400'
                  fontWeight='light'
                  as={Link}
                  href='https://www.epfl.ch/campus/restaurants-shops-hotels/fr/offre-du-jour-de-tous-les-points-de-restauration/'>
                  More restaurants
                </Button>
              </HStack>
              <Box overflow='hidden' padding='3' bg='white' w='full'>
                {typeof puurMenu === 'undefined' ? (
                  <Center>
                    <Spinner />
                  </Center>
                ) : puurMenu.length === 0 ? (
                  <Text>No menu today</Text>
                ) : (
                  <TableContainer whiteSpace='normal'>
                    <Table size='sm'>
                      <Tbody>
                        {puurMenu?.map(i => (
                          <PUURMenuRow key={i.dish} item={i} />
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                )}
              </Box>
            </VStack>
            <VStack
              align='start'
              flex='1'
              w='full'
              flexBasis={{ base: '100%', xl: '50%' }}
              alignItems='start'>
              <Box display={{ base: 'none', xl: 'block' }}>
                <MemberEvents user={user} company={company} />
              </Box>
              <VStack w='full'>
                <HStack align='center' w='full'>
                  <Heading size='md' m='0' mb={{ base: 2, lg: 4 }}>
                    Community
                  </Heading>
                  <Spacer />
                  <Tooltip label='View Everyday life news and access our community discussion groups'>
                    <Button
                      p='0'
                      h='fit-content'
                      size='sm'
                      rightIcon={<FontAwesomeIcon icon={faInfoCircle} />}
                      variant='link'
                      color='gray.400'
                      fontWeight='light'>
                      More Info
                    </Button>
                  </Tooltip>
                </HStack>
                <Box overflow='hidden' w='full'>
                  <MinshIframe page='chats' />
                </Box>
              </VStack>
            </VStack>
          </Stack>
          <Box w='full'>
            <HStack justify='space-between' w='full' align='center' py='2'>
              <Heading size='md' m='0'>
                Next events
              </Heading>
              <Button
                p='0'
                h='fit-content'
                size='sm'
                rightIcon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
                variant='link'
                color='gray.400'
                fontWeight='light'
                as={Link}
                href='/events'>
                All events
              </Button>
            </HStack>
            <Box bg='white'>
              <EventsCarousel />
            </Box>
          </Box>
          <Box w='full'>
            <Heading size='md'>Next Bus</Heading>
            <Box bg='white'>
              <Stack
                direction={{ base: 'column', xl: 'row' }}
                alignItems='center'
                justify='space-between'
                p='3'>
                <BusTabs
                  busURL={stops.epfl.bus}
                  mapsURL={stops.epfl.map}
                  title='Ecublens VD, EPFL/Colladon'
                />
                <BusTabs
                  busURL={stops.parcScientific.bus}
                  mapsURL={stops.parcScientific.map}
                  title='St Sulpice VD, Parc Scient.'
                />
              </Stack>
            </Box>
          </Box>
        </VStack>
      </TabPanel>
    </>
  )
}
