import {
  Button,
  Divider,
  HStack,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
  useBreakpointValue,
  useTheme,
  VStack
} from '@chakra-ui/react'
import {
  BookingDuration,
  BookingTextTimeOfDay,
  BookingTextTimes
} from '@/constants/FieldsParking'
import { bookingStore } from '@/stores/bookingStore'
import CamiproNeededTag from '../shared/CamiproNeededTag'

interface Props {
  onClose: () => void
  onOk: () => void
  visible: boolean
  needAccess: boolean
  cost: number
  list: SelectedParkingInfo
}

interface SelectedParkingInfo {
  parkingName: string
  parkingDate: string
  parkingOption: BookingDuration
}

const ConfirmBookingModal = ({ list, visible, onClose, onOk, needAccess, cost }: Props) => {
  const theme = useTheme()
  const isBooking = bookingStore(state => state.isBooking)
  const modalSize = useBreakpointValue({ base: 'xs', md: 'xl' })

  const renderParkingOption = () => {
    switch (list.parkingOption) {
      case BookingDuration.allDay:
        return `${BookingTextTimeOfDay.allDay} (${BookingTextTimes.allDay})`
      case BookingDuration.morning:
        return `${BookingTextTimeOfDay.morning} (${BookingTextTimes.morning})`
      case BookingDuration.afternoon:
        return `${BookingTextTimeOfDay.afternoon} (${BookingTextTimes.afternoon})`
    }
  }

  return (
    <Modal isOpen={visible} onClose={onClose} size={modalSize} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontFamily={theme.fonts.modal}>Confirmation</ModalHeader>
        <Divider />
        <ModalBody p={4} fontFamily={theme.fonts.modal}>
          <VStack spacing={4} align='start'>
            {needAccess && <CamiproNeededTag />}
            <Text>You have selected the following spot :</Text>
            <UnorderedList pl={4}>
              <ListItem>{list.parkingName}</ListItem>
              <ListItem>{list.parkingDate}</ListItem>
              <ListItem>{renderParkingOption()}</ListItem>
            </UnorderedList>
          </VStack>
        </ModalBody>
        <Divider />
        <ModalFooter>
          <HStack>
            <Button onClick={onClose} variant='parkshareLightButtons'>
              Cancel
            </Button>
            <Button isLoading={isBooking} onClick={onOk} variant='parksharePrimaryButton'>
              Book for {cost} points
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ConfirmBookingModal
