import { ROUTE_COMPANY_DETAILS } from '@/constants/Routes'
import { Company } from '@/models/company/Company'
import { take } from '@coteries/utils'

interface Props {
  company: Company
}

const CompanySearchCard = ({ company }: Props) => {
  const descriptionPart = take(company.description.split(' '), 50)
  const description = `${descriptionPart.join(' ')}${descriptionPart.length < 50 ? '' : '...'}`
  return (
    <div className='search-result-item'>
      <div>
        <a
          href={ROUTE_COMPANY_DETAILS(company.companyId)}
          className='display-h5 margin-bottom-025x'>
          {company.companyName}
        </a>
        <div className='flex-content-horizontal'>
          <div className='text color-light no-margin margin-right-050'>Company</div>
        </div>
        <p className='text'>{description}</p>
      </div>
      <div>
        <a href={ROUTE_COMPANY_DETAILS(company.companyId)} className='button small w-button'>
          Details
        </a>
      </div>
    </div>
  )
}

export default CompanySearchCard
