import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import { Calendar } from '@/models/calendar/Calendar'
import { CALENDAR_DATE_FORMAT } from '@/constants/DateConstants'

dayjs.extend(calendar)
dayjs.extend(weekday)
dayjs.extend(weekOfYear)

const getCalendarDate = (year: number, month: number, day?: number) =>
  `${year}-${month}-${day ?? '01'}`

const getWeekday = (date: string) => {
  return dayjs(date).weekday()
}

const getNumberOfDaysInMonth = (year: number, month: number) => {
  return dayjs(getCalendarDate(year, month)).daysInMonth()
}

const createDaysForCurrentMonth = (year: number, month: number): Calendar[] => {
  return [...Array(getNumberOfDaysInMonth(year, month))].map((day, index) => {
    return {
      date: dayjs(getCalendarDate(year, month, index + 1)).format(CALENDAR_DATE_FORMAT),
      dayOfMonth: index + 1,
      isCurrentMonth: true
    }
  })
}

export const createDaysForPreviousMonth = (
  year: number,
  month: number,
  currentMonth: Calendar[]
): Calendar[] => {
  const firstDayOfCurrentMonthWeekday = getWeekday(currentMonth[0].date)

  const previousMonth = dayjs(getCalendarDate(year, month))

  const visibleNumberOfDaysFromPreviousMonth =
    firstDayOfCurrentMonthWeekday === 0 ? 0 : firstDayOfCurrentMonthWeekday

  const previousMonthLastMondayDayOfMonth = dayjs(currentMonth[0].date)
    .subtract(visibleNumberOfDaysFromPreviousMonth, 'day')
    .date()

  return [...Array(visibleNumberOfDaysFromPreviousMonth)].map((day, index) => {
    return {
      date: dayjs(
        getCalendarDate(
          previousMonth.year(),
          previousMonth.month(),
          previousMonthLastMondayDayOfMonth + index
        )
      ).format(CALENDAR_DATE_FORMAT),
      dayOfMonth: previousMonthLastMondayDayOfMonth + index,
      isCurrentMonth: false
    }
  })
}

export const createDaysForNextMonth = (
  year: number,
  month: number,
  currentMonth: Calendar[]
): Calendar[] => {
  const lastDayOfTheMonthWeekday = getWeekday(getCalendarDate(year, month, currentMonth.length))

  const nextMonth = dayjs(getCalendarDate(year, month)).add(1, 'month')

  const visibleNumberOfDaysFromNextMonth = lastDayOfTheMonthWeekday
    ? 7 - lastDayOfTheMonthWeekday
    : lastDayOfTheMonthWeekday

  return [...Array(visibleNumberOfDaysFromNextMonth)].map((day, index) => {
    return {
      date: dayjs(getCalendarDate(nextMonth.year(), nextMonth.month() + 1, index + 1)).format(
        CALENDAR_DATE_FORMAT
      ),
      dayOfMonth: index + 1,
      isCurrentMonth: false
    }
  })
}

export const createCalendar = (year: number, month: number): Calendar[] => {
  const currentMonth = createDaysForCurrentMonth(year, month + 1)
  const previousMonth = createDaysForPreviousMonth(year, month + 1, currentMonth)
  const nextMonthDays = createDaysForNextMonth(year, month + 1, currentMonth)

  return [...previousMonth, ...currentMonth, ...nextMonthDays]
}
