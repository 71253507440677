import { ROUTE_COMPANY_DETAILS } from '@/constants/Routes'
import { PublicCompany } from '@/models/company/PublicCompany'
import CompaniesCardImage from './CompaniesCardImage'

interface Props {
  company: PublicCompany
  additionalClass?: string
}

const CompanyCard = ({ company, additionalClass }: Props) => {
  return (
    <a
      href={ROUTE_COMPANY_DETAILS(company.companyId)}
      className={`card company-type ${additionalClass ? additionalClass : ''} w-inline-block`}>
      {company.logo && (
        <CompaniesCardImage imgSrc={company.logo} containerClassName='logo-bg-img' />
      )}
      <div className='separator fill-primary' />
      <h3 className='card-title-medium '>{company.signage}</h3>
      <div className='tag-list color-primary'>
        {company.mainSector && <div className='tag fill-primary'>{company.mainSector}</div>}
        {company.tags?.length > 0 &&
          company.tags.slice(0, 4).map(tag => (
            <div className='tag outlined margin-bottom-025x' key={tag}>
              {tag}
            </div>
          ))}
      </div>
    </a>
  )
}

// noinspection JSUnusedGlobalSymbols
export default CompanyCard
