export const components = {
  Heading: {
    baseStyle: {
      fontWeight: 400
    }
  },
  Text: {
    baseStyle: {
      fontWeight: 400
    }
  },
  Button: {
    variants: {
      parkshareDarkButtons: {
        bg: 'brand.black',
        color: 'brand.white',
        borderRadius: '140px',
        transition: 'all .2s ease-in-out',
        _hover: {
          bg: 'brand.white',
          color: 'brand.black'
        }
      },
      parkshareLightButtons: {
        fontWeight: 'normal',
        bg: 'brand.white',
        border: '1px solid rgba(25, 25, 25, 0.2)',
        color: 'brand.black',
        borderRadius: '140px',
        transition: 'all .2s ease-in-out',
        _hover: {
          bg: 'brand.black',
          color: 'brand.white'
        }
      },
      parksharePrimaryButton: {
        bg: 'brand.primary',
        color: 'brand.white',
        fontWeight: 'normal',
        borderRadius: '140px',
        transition: 'all .2s ease-in-out',
        _hover: {
          bg: 'brand.primaryDard'
        }
      }
    }
  }
}
