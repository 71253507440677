import { ParkingType } from '@/models/parking/ParkingLot'
import { createRef, useEffect } from 'react'

interface Props {
  type: ParkingType
}

const ParkingLotMap = ({ type }: Props) => {
  const ref = createRef<any>()

  useEffect(() => {
    if (ref.current) {
      Object.keys(ParkingType).forEach(pType => {
        const lot = ref.current && ref.current.parentNode.getElementById(`parking${pType}`)

        if (!lot) {
          return
        }

        if (pType === type) {
          lot.classList.remove('turn-off')
        } else {
          lot.classList.add('turn-off')
        }
      })
    }
  }, [ref, type])

  return (
    <svg viewBox='0 0 435 475' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <title>general_map</title>
      <g id='general_map' stroke='none' strokeWidth='1' fill='none' ref={ref} fillRule='evenodd'>
        <rect
          id='Rectangle'
          stroke='#353535'
          strokeWidth='2'
          fill='#F1F1F1'
          x='5'
          y='5'
          width='425'
          height='465'
        />
        <g id='building_k' transform='translate(324.000000, 354.000000)'>
          <polygon
            id='Fill-11'
            fill='#4E4E4E'
            points='-6.03961325e-14 57.9918 57.9959 57.9918 57.9959 7.10542736e-15 -6.03961325e-14 7.10542736e-15'
          />
          <text
            id='K'
            fontFamily='LucidaGrande-Bold, Lucida Grande'
            fontSize='20'
            fontWeight='bold'
            fill='#FFFFFF'>
            <tspan x='7.633' y='47.4310125'>
              K
            </tspan>
          </text>
        </g>
        <g id='building_f' transform='translate(169.000000, 284.000000)'>
          <polygon
            id='Fill-12'
            fill='#4E4E4E'
            points='-3.55271368e-15 57.9918 57.9959 57.9918 57.9959 0 -3.55271368e-15 0'
          />
          <text
            id='F'
            fontFamily='LucidaGrande-Bold, Lucida Grande'
            fontSize='20'
            fontWeight='bold'
            fill='#FFFFFF'>
            <tspan x='7.515' y='49.5621125'>
              F
            </tspan>
          </text>
        </g>
        <g id='building_e' transform='translate(270.000000, 262.000000)'>
          <polygon
            id='Fill-13'
            fill='#4E4E4E'
            points='5.32907052e-14 57.9959 57.9959 57.9959 57.9959 -6.03961325e-14 5.32907052e-14 -6.03961325e-14'
          />
          <text
            id='E'
            fontFamily='LucidaGrande-Bold, Lucida Grande'
            fontSize='20'
            fontWeight='bold'
            fill='#FFFFFF'>
            <tspan x='7.082' y='49.5132125'>
              E
            </tspan>
          </text>
        </g>
        <g id='Group-8' transform='translate(110.000000, 59.000000)'>
          <polygon id='Fill-14' fill='#4E4E4E' points='0 124 44 124 44 0 0 0' />
          <text
            id='G'
            fontFamily='LucidaGrande-Bold, Lucida Grande'
            fontSize='20'
            fontWeight='bold'
            fill='#FFFFFF'>
            <tspan x='9' y='112'>
              G
            </tspan>
          </text>
        </g>
        <g id='building_j' transform='translate(220.000000, 378.000000)'>
          <polygon
            id='Fill-15'
            fill='#4E4E4E'
            points='-3.55271368e-15 58.0001 57.9959 58.0001 57.9959 -1.42108547e-14 -3.55271368e-15 -1.42108547e-14'
          />
          <text
            id='J'
            fontFamily='LucidaGrande-Bold, Lucida Grande'
            fontSize='20'
            fontWeight='bold'
            fill='#FFFFFF'>
            <tspan x='10.598' y='45.4669125'>
              J
            </tspan>
          </text>
        </g>
        <g id='building_i' transform='translate(116.000000, 397.000000)'>
          <polygon
            id='Fill-16'
            fill='#4E4E4E'
            points='-2.48689958e-14 57.9918 58.0083 57.9918 58.0083 7.10542736e-15 -2.48689958e-14 7.10542736e-15'
          />
          <text
            id='I'
            fontFamily='LucidaGrande-Bold, Lucida Grande'
            fontSize='20'
            fontWeight='bold'
            fill='#FFFFFF'>
            <tspan x='8.5967' y='48.4067125'>
              I
            </tspan>
          </text>
        </g>
        <g id='building_h' transform='translate(21.000000, 207.000000)'>
          <polygon
            id='shape_bulding_1'
            fill='#4E4E4E'
            points='1.0658141e-14 127.124 44.1711 127.124 44.1711 7.10542736e-14 1.0658141e-14 7.10542736e-14'
          />
          <text
            id='H'
            fontFamily='LucidaGrande-Bold, Lucida Grande'
            fontSize='20'
            fontWeight='bold'
            fill='#FFFFFF'>
            <tspan x='7.0132' y='117.555313'>
              H
            </tspan>
          </text>
        </g>
        <g id='building_c' transform='translate(350.000000, 173.000000)'>
          <polygon
            id='Fill-18'
            fill='#4E4E4E'
            points='0.0067 98.926 38.0067 98.926 38.0067 0.926 0.0067 0.926'
          />
          <text
            id='C'
            fontFamily='LucidaGrande-Bold, Lucida Grande'
            fontSize='20'
            fontWeight='bold'
            fill='#FFFFFF'>
            <tspan x='7.535' y='89.2523125'>
              C
            </tspan>
          </text>
        </g>
        <g id='building_b' transform='translate(203.000000, 87.000000)'>
          <polygon id='Fill-19' fill='#4E4E4E' points='0 38 73 38 73 0 0 0' />
          <text
            id='B'
            fontFamily='LucidaGrande-Bold, Lucida Grande'
            fontSize='20'
            fontWeight='bold'
            fill='#FFFFFF'>
            <tspan x='8' y='26'>
              B
            </tspan>
          </text>
        </g>
        <g id='building_a' transform='translate(317.000000, 87.000000)'>
          <polygon id='Fill-20' fill='#4E4E4E' points='0 38 73 38 73 0 0 0' />
          <text
            id='A'
            fontFamily='LucidaGrande-Bold, Lucida Grande'
            fontSize='20'
            fontWeight='bold'
            fill='#FFFFFF'>
            <tspan x='8' y='26'>
              A
            </tspan>
          </text>
        </g>
        <g id='building_d' transform='translate(211.000000, 174.000000)'>
          <polygon
            id='Fill-21'
            fill='#4E4E4E'
            points='0 57.9959 57.9918 57.9959 57.9918 1.42108547e-14 0 1.42108547e-14'
          />
          <text
            id='D'
            fontFamily='LucidaGrande-Bold, Lucida Grande'
            fontSize='20'
            fontWeight='bold'
            fill='#FFFFFF'>
            <tspan x='8.288' y='48.4222125'>
              D
            </tspan>
          </text>
        </g>
        <g id='streets' transform='translate(5.000000, 5.000000)'>
          <path
            d='M398,0 L410,0 L410,313 C410,316.313708 407.313708,319 404,319 C400.686292,319 398,316.313708 398,313 L398,0 L398,0 Z'
            id='Rectangle'
            fill='#FFFFFF'
          />
          <path
            d='M77,0 L89,0 L89,324 C89,327.313708 86.3137085,330 83,330 C79.6862915,330 77,327.313708 77,324 L77,0 L77,0 Z'
            id='Rectangle'
            fill='#FFFFFF'
          />
          <path
            d='M286,48 L298,48 L298,78 C298,81.3137085 295.313708,84 292,84 C288.686292,84 286,81.3137085 286,78 L286,48 L286,48 Z'
            id='Rectangle'
            fill='#FFFFFF'
          />
          <rect id='Rectangle' fill='#FFFFFF' x='171' y='54' width='12' height='88' />
          <rect id='Rectangle' fill='#FFFFFF' x='0' y='11' width='410' height='12' />
          <path
            d='M164,47 L410,47 L410,47 L410,59 L164,59 C160.686292,59 158,56.3137085 158,53 C158,49.6862915 160.686292,47 164,47 Z'
            id='Rectangle'
            fill='#FFFFFF'
          />
          <polygon
            id='Rectangle'
            fill='#FFFFFF'
            transform='translate(328.634484, 446.279866) rotate(-13.000000) translate(-328.634484, -446.279866) '
            points='228.41237 442.354318 428.856599 440.554564 426.450809 450.975186 270.214794 452.005168'
          />
          <rect id='Rectangle' fill='#FFFFFF' x='171' y='132' width='239' height='12' />
          <path
            d='M83,205 C127.140603,205 164.162861,215.242421 194.066772,235.727264 C229.132176,259.747844 250.776585,297.172089 259,348'
            id='Line-2'
            stroke='#FFFFFF'
            strokeWidth='8'
            strokeLinecap='round'
            strokeDasharray='0,15'
          />
          <path
            d='M231,252 C265.949343,249.66758 289.146325,238.642913 300.590947,218.926 C310.596384,201.688531 310.596384,177.837937 300.590947,147.374219'
            id='Line-2'
            stroke='#FFFFFF'
            strokeWidth='8'
            strokeLinecap='round'
            strokeDasharray='0,15'
          />
          <path
            d='M95,332.971956 C122.368951,341.193927 143.947422,346.635566 159.735415,349.296873 C180.123094,352.733528 221.337915,355.505753 260.841447,349.373853 C292.36864,344.480073 322.602844,331.566286 341.773767,325.958602 C353.332834,322.577462 371.741578,319.257927 397,316'
            id='Line-2'
            stroke='#FFFFFF'
            strokeWidth='8'
            strokeLinecap='round'
            strokeDasharray='0,15'
          />
        </g>
        <g
          id='parkingO'
          transform='translate(161.000000, 33.000000)'
          fill='#179F7C'
          fillRule='nonzero'>
          <path
            d='M9,0 C4.02428571,0 0,3.9125 0,8.75 C0,15.3125 9,25 9,25 C9,25 18,15.3125 18,8.75 C18,3.9125 13.9757143,0 9,0 Z M9,11.875 C7.22571429,11.875 5.78571429,10.475 5.78571429,8.75 C5.78571429,7.025 7.22571429,5.625 9,5.625 C10.7742857,5.625 12.2142857,7.025 12.2142857,8.75 C12.2142857,10.475 10.7742857,11.875 9,11.875 Z'
            id='p6'
          />
        </g>
        <g
          id='parkingN'
          transform='translate(288.000000, 59.000000)'
          fill='#179F7C'
          fillRule='nonzero'>
          <path
            d='M9,0 C4.02428571,0 0,3.9125 0,8.75 C0,15.3125 9,25 9,25 C9,25 18,15.3125 18,8.75 C18,3.9125 13.9757143,0 9,0 Z M9,11.875 C7.22571429,11.875 5.78571429,10.475 5.78571429,8.75 C5.78571429,7.025 7.22571429,5.625 9,5.625 C10.7742857,5.625 12.2142857,7.025 12.2142857,8.75 C12.2142857,10.475 10.7742857,11.875 9,11.875 Z'
            id='p5'
          />
        </g>
        <g
          id='parkingH'
          transform='translate(110.000000, 237.000000)'
          stroke='#179F7C'
          strokeWidth='5'>
          <rect id='p4' x='2.5' y='2.5' width='39' height='78' rx='2' />
        </g>
        <g
          id='parkingG'
          transform='translate(21.000000, 73.000000)'
          stroke='#179F7C'
          strokeWidth='5'>
          <rect id='p3' x='2.5' y='2.5' width='39' height='106' rx='2' />
        </g>
        <g
          id='parkingD'
          transform='translate(205.000000, 161.000000)'
          stroke='#179F7C'
          strokeLinecap='round'
          strokeWidth='5'>
          <line x1='119.5' y1='0.5' x2='181.5' y2='0.5' id='p2_2' />
          <line x1='0.5' y1='0.5' x2='85.5' y2='0.5' id='p2_1' />
        </g>
        <g
          id='parkingAB'
          transform='translate(205.000000, 75.000000)'
          stroke='#179F7C'
          strokeLinecap='round'
          strokeWidth='5'>
          <line x1='114.5' y1='0.5' x2='183.5' y2='0.5' id='p1_2' />
          <line x1='0.5' y1='0.5' x2='69.5' y2='0.5' id='p1_1' />
        </g>
        <rect
          id='Rectangle'
          stroke='#353535'
          strokeWidth='2'
          x='5'
          y='5'
          width='425'
          height='465'
        />
      </g>
    </svg>
  )
}

export default ParkingLotMap
