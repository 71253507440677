import {
  EVENT_CATEGORY_FILTER,
  EVENT_CATEGORY_HIDDEN,
  EVENT_CREATED_BY,
  EVENT_LOCATION_FILTER,
  EventHiddenCategories
} from '@/constants/FieldsEvent'
import {
  FRONT_CATALOG,
  FRONT_EVENTS,
  FRONT_EVENT_DETAILS,
  FRONT_EVENT_DETAILS_AGENDA,
  ROUTE_PORTAL,
  forceAPICacheRefresh
} from '@/constants/Routes'
import { Agenda } from '@/models/calendar/Agenda'
import type { Catalog } from '@/models/calendar/Catalog'
import { Event } from '@/models/calendar/Event'
import { EventEdit } from '@/models/calendar/EventEdit'
import { axiosVercelAPI } from '@/utils/connectionHelpers/axios'
import baseFetcher from '@/utils/connectionHelpers/swrFetchers'
import { handleUpdateErrors } from '@/utils/dataHelpers/apiResponseHelper'
import {
  startOfToday,
  toDateFromPicker,
  toDatePickerDateSafe
} from '@/utils/dataHelpers/dateHelpers'
import { timeValidator, urlValidator } from '@/utils/dataHelpers/validators'
import { showToast } from '@/utils/showToast'
import { Box, Divider, HStack, useBoolean } from '@chakra-ui/react'
import type { DayValue } from '@hassanmojab/react-modern-calendar-datepicker'
import { zodResolver } from '@hookform/resolvers/zod'
import dayjs from 'dayjs'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import useSWR from 'swr'
import Loading from '../shared/Loading'
import DefaultFormWrapper from '../shared/form/DefaultFormWrapper'
import FormAutoFieldsCreation from '../shared/form/FormAutoFieldsCreation'
import FormCheckbox from '../shared/form/FormCheckbox'
import FormDatePicker from '../shared/form/FormDatePicker'
import FormDatePickerRender from '../shared/form/FormDatePickerRender'
import FormHeading from '../shared/form/FormHeading'
import FormInput from '../shared/form/FormInput'
import FormRichText from '../shared/form/FormRichText'
import FormSelect from '../shared/form/FormSelect'
import FormTagsInput from '../shared/form/FormTagsInput'
import ImageUpload from '../shared/imageUpload/ImageUpload'
import ManageEventCalendarItem from './components/ManageEventCalendarItem'

const ManageEvents = () => {
  return <EventEditContent />
}

const EventEditContent = () => {
  const { query, push } = useRouter()

  const [loading, setLoading] = useBoolean(false)

  const { data: createdBy } = useSWR<Catalog[]>(FRONT_CATALOG(EVENT_CREATED_BY), baseFetcher)
  const { data: locationFilter } = useSWR<Catalog[]>(
    FRONT_CATALOG(EVENT_LOCATION_FILTER),
    baseFetcher
  )
  const { data: categoryFilter } = useSWR<Catalog[]>(
    FRONT_CATALOG(EVENT_CATEGORY_FILTER),
    baseFetcher
  )
  const { data: categoryHidden } = useSWR<Catalog[]>(
    FRONT_CATALOG(EVENT_CATEGORY_HIDDEN),
    baseFetcher
  )
  const { data: eventDetails, error: eventError } = useSWR<Event>(
    query.id ? forceAPICacheRefresh(FRONT_EVENT_DETAILS(query.id.toString())) : null,
    baseFetcher,
    { revalidateOnFocus: false }
  )

  const { data: agendaItems } = useSWR<Agenda[]>(
    query.id ? FRONT_EVENT_DETAILS_AGENDA(parseInt(query.id.toString())) : null,
    baseFetcher,
    { revalidateOnFocus: false }
  )

  useEffect(() => {
    if (!eventDetails) {
      reset({
        startDate: new Date(),
        endDate: new Date()
      })
      return
    }

    setValue('title', eventDetails.title)
    setValue('subtitle', eventDetails.subtitle)
    setValue('firstParagraph', eventDetails.firstParagraph)
    setValue('content', eventDetails.content)
    setValue('motivationItems', eventDetails.motivationItems)
    setValue('tags', eventDetails.tags)
    setValue('googleMapLink', eventDetails.googleMapLink)
    setValue('linkToJoin', eventDetails.linkToJoin)
    setValue('image', eventDetails.image)
    setValue('imageCredit', eventDetails.imageCredit)
    setValue('startDate', eventDetails.startDate)
    setValue('endDate', eventDetails.endDate)
    setValue('organizerName', eventDetails.organizerName)
    setValue('organizerWebsite', eventDetails.organizerWebsite)
    setValue('organizerEmail', eventDetails.organizerEmail)
    setValue('organizerPhone', eventDetails.organizerPhone)
    setValue('location', eventDetails.location)

    if (eventDetails.startTime.match(timeValidator)) {
      setValue('startTime', eventDetails.startTime)
    }
    if (eventDetails.endTime.match(timeValidator)) {
      setValue('endTime', eventDetails.endTime)
    }
    if (eventDetails.categoryHidden.includes(EventHiddenCategories.membersOnly)) {
      setValue('membersOnly', true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventDetails])

  useEffect(() => {
    if (!eventDetails) {
      return
    }

    if (createdBy) {
      const val = createdBy.find(temp => temp.DisplayValue === eventDetails.createdBy)
      if (val) {
        setValue('createdBy', val.Id.toString())
      }
    }
    if (locationFilter) {
      const val = locationFilter.find(temp => temp.DisplayValue === eventDetails.locationFilter)
      if (val) {
        setValue('locationFilter', val.Id.toString())
      }
    }
    if (categoryFilter) {
      const val = categoryFilter.find(temp => temp.DisplayValue === eventDetails.categoryFilter)
      if (val) {
        setValue('categoryFilter', val.Id.toString())
      }
    }
    if (categoryHidden) {
      const val = categoryHidden.find(temp => temp.DisplayValue === eventDetails.categoryHidden)
      if (val) {
        setValue('categoryHidden', val.Id.toString())
      }
    }
    if (agendaItems) {
      setValue('agenda', agendaItems)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdBy, locationFilter, categoryFilter, categoryHidden, agendaItems, eventDetails])

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
    formState: { errors }
  } = useForm<EventEdit>({ resolver: zodResolver(EventEdit) })
  const onSubmit: SubmitHandler<EventEdit> = async data => {
    try {
      setLoading.on()
      if (query.id && !query.duplicate) {
        await axiosVercelAPI.put(FRONT_EVENT_DETAILS(query.id.toString()), data)
        showToast('Event successfully updated')
      } else {
        await axiosVercelAPI.post(FRONT_EVENTS, data)
        reset()
        await push(ROUTE_PORTAL)
        showToast(`Event successfully ${query.duplicate ? 'duplicated' : 'created'}`)
      }
    } catch (e) {
      handleUpdateErrors(e)
    } finally {
      setLoading.off()
    }
  }

  const renderCustomStartDate = ({ ref }: any) => (
    <FormDatePickerRender pickerProp={ref} date={dayjs(getValues('startDate'))} />
  )
  const renderCustomEndDate = ({ ref }: any) => (
    <FormDatePickerRender pickerProp={ref} date={dayjs(getValues('endDate'))} />
  )

  if (query.id && !eventDetails && !eventError) {
    return <Loading additionalClasses='margin-bottom-8x' />
  } else if (query.id && !eventDetails && eventError) {
    return <p>Error while getting the event</p>
  }

  return (
    <DefaultFormWrapper
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      title='Edit or create events'
      loading={loading}
      submitButtonText='Publish'>
      <FormSelect<EventEdit>
        name='createdBy'
        label='Created by'
        errors={errors}
        register={register}
        options={createdBy?.map(item => {
          return { value: item.Id.toString(), label: item.DisplayValue }
        })}
      />

      <HStack w='full'>
        <FormDatePicker
          name='startDate'
          label='Start date'
          errors={errors}
          renderInput={renderCustomStartDate}
          value={toDatePickerDateSafe(dayjs(getValues('startDate')))}
          onChange={(newDate: DayValue) =>
            setValue('startDate', (newDate ? toDateFromPicker(newDate) : startOfToday).toDate())
          }
        />

        <FormInput<EventEdit>
          name='startTime'
          register={register}
          label='Start time'
          placeholder='hh:mm'
          errors={errors}
          rules={{
            pattern: {
              value: timeValidator,
              message: 'Please follow the hh:mm pattern'
            }
          }}
        />
      </HStack>

      <HStack w='full'>
        <FormDatePicker
          name='endDate'
          label='End date'
          errors={errors}
          renderInput={renderCustomEndDate}
          value={toDatePickerDateSafe(dayjs(getValues('endDate')))}
          onChange={(newDate: DayValue) =>
            setValue('endDate', (newDate ? toDateFromPicker(newDate) : startOfToday).toDate())
          }
        />

        <FormInput<EventEdit>
          name='endTime'
          register={register}
          label='End time'
          placeholder='hh:mm'
          errors={errors}
          rules={{
            pattern: {
              value: timeValidator,
              message: 'Please follow the hh:mm pattern'
            }
          }}
        />
      </HStack>

      <FormHeading label='Event Content' />
      <FormInput<EventEdit>
        name='title'
        register={register}
        label='Title'
        errors={errors}
        rules={{ required: 'News must have a title' }}
      />
      <FormInput<EventEdit> name='subtitle' register={register} label='Subtitle' />
      <FormRichText<EventEdit>
        errors={errors}
        name='firstParagraph'
        label='First paragraph'
        initialContent={getValues('firstParagraph')}
        onUpdate={val => setValue('firstParagraph', val)}
      />
      <FormRichText<EventEdit>
        errors={errors}
        name='content'
        label='Content'
        initialContent={getValues('content')}
        onUpdate={val => setValue('content', val)}
      />
      <FormRichText<EventEdit>
        errors={errors}
        name='motivationItems'
        label='Motivation items'
        initialContent={getValues('motivationItems')}
        onUpdate={val => setValue('motivationItems', val)}
      />
      <FormTagsInput<EventEdit>
        name='tags'
        label='Tags'
        errors={errors}
        onUpdate={val => setValue('tags', val)}
        tags={watch('tags')}
        maxNumberOfTags={4}
      />

      <FormHeading label='Event Media' />
      <ImageUpload
        base64ImageChange={data => setValue('image', data)}
        pictureUrl={getValues('image')}
      />
      <FormInput<EventEdit> name='imageCredit' register={register} label='Picture credit' />

      <FormHeading label='Event Categories' />
      <FormSelect<EventEdit>
        name='categoryFilter'
        label='Category Filter'
        errors={errors}
        register={register}
        options={categoryFilter?.map(item => {
          return { value: item.Id.toString(), label: item.DisplayValue }
        })}
      />

      <FormHeading label='Event Location' />
      <FormInput<EventEdit>
        name='location'
        register={register}
        label='Event location'
        placeholder='Event location'
      />
      <FormSelect<EventEdit>
        name='locationFilter'
        label='Location Filter'
        errors={errors}
        register={register}
        options={locationFilter?.map(item => {
          return { value: item.Id.toString(), label: item.DisplayValue }
        })}
      />
      <FormInput<EventEdit>
        name='googleMapLink'
        register={register}
        label='Google Maps link'
        errors={errors}
        placeholder='Google Maps link'
        rules={{ pattern: { value: urlValidator, message: 'Please enter a valid URL' } }}
      />
      <FormInput<EventEdit>
        name='linkToJoin'
        register={register}
        label='Link to join'
        placeholder='Link to join'
        errors={errors}
        rules={{
          pattern: { value: urlValidator, message: 'Please enter a valid URL' }
        }}
      />

      <FormHeading label='Event Organizer' />
      <FormAutoFieldsCreation<EventEdit>
        errors={errors}
        fields={[
          {
            label: 'Organizer name',
            name: 'organizerName',
            errors,
            watch,
            register
          },
          {
            label: 'Organizer mail',
            name: 'organizerEmail',
            errors,
            watch,
            register
          },
          {
            label: 'Organizer phone',
            name: 'organizerPhone',
            errors,
            watch,
            register
          },
          {
            label: 'Organizer website',
            name: 'organizerWebsite',
            errors,
            watch,
            register
          }
        ]}
      />

      <FormHeading label='Calendar Items' />
      <ManageEventCalendarItem
        calendar={watch('agenda') ?? []}
        onChange={val => setValue('agenda', val)}
      />

      <Divider />
      <HStack w='full' justify='space-around'>
        <Box>
          <FormCheckbox<EventEdit>
            errors={errors}
            register={register}
            name='spotlight'
            label='Spotlight'
            watch={!!watch('spotlight')}
          />
        </Box>
        <Box>
          <FormCheckbox<EventEdit>
            errors={errors}
            register={register}
            name='membersOnly'
            label='Members Only'
            watch={!!watch('membersOnly')}
          />
        </Box>
      </HStack>
    </DefaultFormWrapper>
  )
}

export default ManageEvents
