interface Props {
  imgSrc?: string
  fillClass: string
}

const CardImage = ({ imgSrc, fillClass }: Props) => {
  return (
    <>
      {imgSrc && (
        <div className={`img-block ${fillClass}`}>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img src={imgSrc} loading='lazy' alt='company-image' className='img' />
        </div>
      )}
    </>
  )
}

export default CardImage
