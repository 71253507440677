import InjectHtml from '../InjectHtml'

interface Props {
  firstParagraph?: string
  content?: string
}

const DisplayContents = ({ firstParagraph, content }: Props) => {
  //The paragraph should be shown in h5. Since the editor uses p's on every line we have to replace them.
  const h5Paragraph = firstParagraph?.replace(/(<p>(.*?)<\/p>)/g, '<h5>$2</h5>')

  return (
    <div className='text w-richtext'>
      {h5Paragraph && (
        //Wrap in h5 in case there were no p's to replace.
        <h5 className='event-content-spacing'>
          <InjectHtml data={h5Paragraph} wrapInRichText={false} />
        </h5>
      )}
      <InjectHtml data={content} wrapInRichText={false} />
    </div>
  )
}

export default DisplayContents
