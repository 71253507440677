import type { ReactNode } from 'react'

interface Props<T> {
  title?: string
  listItems: T[]
  card: (item: T, key: number) => ReactNode
}

function VerticalListDisplay<T>({ title, listItems, card }: Props<T>) {
  return (
    <div className='block-content'>
      <div className='card news-type-anim margin-bottom-4x'>
        <div className='separator fill-news-1'></div>
        <div className='display-h3 no-margin'>{title}</div>
      </div>
      <>{listItems.map((item, key) => card(item, key))}</>
    </div>
  )
}

export default VerticalListDisplay
