import {
  INTEROPERATOR,
  INTEROPERATOR_AND,
  OPERATOR,
  OPERATOR_IS_IN_LIST
} from '@/constants/FieldsEudonet'
import {
  EudonetCriteria,
  EudonetField,
  EudonetFilter,
  EudonetOrderBy,
  EudonetRequest
} from '@/models/EudonetModels'

///// Request parsing
export const getStringFromEudonetField = (
  data: EudonetField[],
  fId: number,
  defaultValue: string = ''
): string => {
  return data.find(item => item.DescId === fId)?.Value ?? defaultValue
}

export const getTimeFromEudonetField = (
  data: EudonetField[],
  fId: number,
  defaultValue: string = ''
): string => {
  const temp = data.find(item => item.DescId === fId)?.Value ?? defaultValue
  const colonNumber = temp.match(/:/g || [])?.length

  // If we have two colons this means we have time as 14:00:00
  // Since we don't want seconds we remove them of the response
  if (colonNumber === 2) {
    return temp?.split(':')?.slice(0, -1)?.join(':')
  }

  return temp
}

export const getDateFromEudonetField = (
  data: EudonetField[],
  fId: number,
  defaultValue: Date = new Date()
): Date => {
  const temp = data.find(item => item.DescId === fId)?.Value
  if (!temp) {
    return defaultValue
  }

  return new Date(temp.split(' ')[0].replaceAll('/', '-'))
}

export const getBoolFromEudonetField = (
  data: EudonetField[],
  fId: number,
  defaultValue: boolean = false
): boolean => {
  return data.find(item => item.DescId == fId)?.Value === '1' ?? defaultValue
}

export const getIntFromEudonetField = (
  data: EudonetField[],
  val: number,
  defaultValue: number = -1
): number => {
  const temp = data.find(item => item.DescId === val)?.Value
  return temp ? parseInt(temp.replaceAll(' ', '')) : defaultValue
}

export const getArrayFromEudonetField = (
  data: EudonetField[],
  fId: number,
  delimiter: string = ';'
): string[] => {
  let array: string[] = []
  let dirtyArray = data.find(item => item.DescId === fId)?.Value

  if (dirtyArray) {
    dirtyArray.split(delimiter).forEach((element: any) => {
      if (!element && element.trim().length > 0) {
        array.push(element.trim())
      }
    })
  }

  return array
}

export const getRefFromEudonetField = (
  data: EudonetField[],
  fId: number,
  defaultValue: number = -1
): number => {
  return data.find(item => item.DescId === fId)?.FileId ?? defaultValue
}

export const getDbRefFromEudonetField = (
  data: EudonetField[],
  fId: number,
  defaultValue: number = -1
): number => {
  return parseInt(data.find(item => item.DescId === fId)?.DbValue ?? defaultValue.toString())
}

///// Query filter
export const getSearchQueryFilters = (queryFiltersString: string): EudonetFilter[] => {
  const filters = <EudonetFilter[]>[]
  const queryArray = queryFiltersString ? (queryFiltersString as string).split('&') : ''

  if (Array.isArray(queryArray))
    queryArray.forEach(item => {
      const formattedItem = item.split('=')
      filters.push(
        makeEudonetCriterionFilter(
          OPERATOR_IS_IN_LIST,
          parseInt(formattedItem[0]),
          formattedItem[1].replaceAll(',', ';')
        )
      )
    })

  return [
    makeEudonetFilter({
      filters,
      interOperator: INTEROPERATOR_AND
    })
  ]
}

///// Request assembly

interface RequestProps {
  cols: number[]
  orderBy?: EudonetOrderBy[]
  filters?: EudonetFilter[]
  criterion?: EudonetCriteria
  showMetaData?: boolean
  pageSize?: number
  pageNum?: number
}

interface FilterProps {
  filters?: EudonetFilter[]
  criterion?: EudonetCriteria
  interOperator?: INTEROPERATOR
}

export const makeEudonetRequest = ({
  cols,
  orderBy,
  filters,
  showMetaData,
  pageSize,
  pageNum,
  criterion
}: RequestProps): EudonetRequest => {
  return {
    ShowMetadata: showMetaData || false,
    RowsPerPage: pageSize || 500,
    NumPage: pageNum || 1,
    ListCols: cols,
    FilterId: 0,
    WhereCustom: makeEudonetFilter({ filters: filters, criterion: criterion }),
    OrderBy: orderBy || [makeOrderBy(0)]
  }
}

export const makeOrderBy = (descId: number, order?: number): EudonetOrderBy => ({
  DescId: descId,
  Order: order || 0
})

export const makeEudonetFilter = ({
  filters,
  criterion,
  interOperator
}: FilterProps): EudonetFilter =>
  filters
    ? {
        WhereCustoms: filters,
        Criteria: criterion || null,
        InterOperator: interOperator || 0
      }
    : {
        Criteria: criterion || null,
        InterOperator: interOperator || 0
      }

//Short function for making a filter with just one criterion.
export const makeEudonetCriterionFilter = (
  operator: OPERATOR,
  field: number,
  value?: string | number | Date,
  interOperator?: INTEROPERATOR
): EudonetFilter => ({
  Criteria: makeEudonetCriterion(operator, field, value),
  InterOperator: interOperator || 0
})

export const makeEudonetCriterion = (
  operator: OPERATOR,
  field: number,
  value?: string | number | Date
): EudonetCriteria =>
  value
    ? {
        Operator: operator,
        Field: field,
        Value: value
      }
    : {
        Operator: operator,
        Field: field
      }
