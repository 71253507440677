import ModularCompanies from '../../components/companies/ModularCompanies'
import { ROUTE_COMPANY_LIST } from '@/constants/Routes'
import CompaniesList from '../../components/companies/CompaniesList'
import CompanyDetails from '../../components/companies/CompanyDetails'
import SuccessStories from '../../components/successStory/SuccessStories'
import { logSentryException } from '../loggingHelpers/sentryHelper'

export const handleCompaniesModule = (id: string) => {
  switch (id) {
    case 'module_companies_modular_workspace':
      return (
        <ModularCompanies
          title='Related projects'
          moreTitle='View more'
          moreLink={ROUTE_COMPANY_LIST}
        />
      )
    case 'module_companies_list_container':
      return <CompaniesList />
    case 'module_companies_list_success_stories':
      return <SuccessStories title='Success Stories' scrolling />
    case 'module_companies_details':
      return <CompanyDetails />
    default:
      logSentryException('handleCompaniesModule no supported module', 'MODULE', id)
  }
}
