import { Button, Divider, Flex, Heading, Text, VStack } from '@chakra-ui/react'
import { faGear, faListRadio, faPlus } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import { useRouter } from 'next/router'
import {
  ROUTE_PARKSHARE,
  ROUTE_PARKSHARE_BOOKING_MANAGER,
  ROUTE_PARKSHARE_MY_BOOKINGS
} from '@/constants/Routes'
import userStore from '../../../stores/userStore'

interface Props {
  title: string
}

const ParkshareHeading = ({ title }: Props) => {
  const router = useRouter()
  const user = userStore(store => store.user)

  return (
    <Flex
      justify='space-between'
      direction={['column', null, null, 'row']}
      gap={['20px', null, null, '0']}
      mb={10}>
      <VStack align='start'>
        <Heading size='lg' as='h2'>
          {title}
        </Heading>
        <Text w='full'>You have {user?.parkshareCredits} credits left</Text>
      </VStack>
      <Divider display={['block', null, 'none']} />
      <Flex gap='10px' direction={['column', null, 'row']}>
        <Link href={ROUTE_PARKSHARE} passHref>
          <Button
            as='a'
            rightIcon={<FontAwesomeIcon icon={faPlus} size='1x' />}
            variant={
              router.asPath === ROUTE_PARKSHARE ? 'parkshareDarkButtons' : 'parkshareLightButtons'
            }>
            New booking
          </Button>
        </Link>
        <Link href={ROUTE_PARKSHARE_MY_BOOKINGS} passHref>
          <Button
            as='a'
            rightIcon={<FontAwesomeIcon icon={faListRadio} size='1x' />}
            variant={
              router.asPath === ROUTE_PARKSHARE_MY_BOOKINGS
                ? 'parkshareDarkButtons'
                : 'parkshareLightButtons'
            }>
            My Booking
          </Button>
        </Link>
        <Link href={ROUTE_PARKSHARE_BOOKING_MANAGER} passHref>
          <Button
            as='a'
            rightIcon={<FontAwesomeIcon icon={faGear} size='1x' />}
            variant={
              router.asPath === ROUTE_PARKSHARE_BOOKING_MANAGER
                ? 'parkshareDarkButtons'
                : 'parkshareLightButtons'
            }>
            Manager
          </Button>
        </Link>
      </Flex>
    </Flex>
  )
}

export default ParkshareHeading
