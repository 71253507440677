interface Props {
  imgSrc: string
  containerClassName: string
}

const CompaniesCardImage = ({ imgSrc, containerClassName }: Props) => {
  return (
    <div className='img-block company fill-secondary'>
      <div className={containerClassName}>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          src={imgSrc}
          loading='lazy'
          sizes='(max-width: 479px) 61vw, (max-width: 991px) 252px, (max-width: 1919px) 19vw, 252px'
          alt=''
          className='logo-bg-img'
        />
      </div>
    </div>
  )
}

export default CompaniesCardImage
