import { handleCompaniesModule } from './moduleInjection/modulesCompanies'
import { handleEventsModule } from './moduleInjection/modulesEvents'
import { handleNewsModule } from './moduleInjection/modulesNews'
import { handlePortalsModule } from './moduleInjection/modulesPortal'
import { handleHighLightsModule } from './moduleInjection/modulesSpotlight'
import { handleEcosystemModule } from './moduleInjection/modulesEcosystem'
import { handleMiscModules } from './moduleInjection/modulesMisc'
import { handleSearchModule } from './moduleInjection/handleSearchModule'
import { logSentryException } from './loggingHelpers/sentryHelper'
import { handleParkshareModule } from './moduleInjection/modulesParkshare'

export const handleModuleInjection = (id: string) => {
  switch (id) {
    case id.match(/portal/)?.input:
      return handlePortalsModule(id)
    case id.match(/search/)?.input:
      return handleSearchModule(id)
    case id.match(/parkshare/)?.input:
      return handleParkshareModule(id)
    case id.match(/module_events/)?.input:
      return handleEventsModule(id)
    case id.match(/module_news/)?.input:
      return handleNewsModule(id)
    case id.match(/module_success_stories/)?.input:
      return handleNewsModule(id)
    case id.match(/module_companies/)?.input:
      return handleCompaniesModule(id)
    case id.match(/module_highlight/)?.input:
      return handleHighLightsModule(id)
    case id.match(/module_ecosystem/)?.input:
      return handleEcosystemModule(id)
    case id.match(/module_misc/)?.input:
      return handleMiscModules(id)
    default:
      logSentryException('handleModuleInjection no supported module', 'MODULE', id)
  }
}
