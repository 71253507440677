import Loading from '../Loading'

interface Props {
  data: any
  error: any
}

const DetailsFetchingHandling = ({ data, error }: Props) => {
  return (
    <>
      {!data && error && (
        <div className='information-block'>
          <div>API Error</div>
        </div>
      )}
      {!data && !error && <Loading additionalClasses='wrapper margin-bottom-8x padding-top-8x' />}
    </>
  )
}

export default DetailsFetchingHandling
