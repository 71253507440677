import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useBoolean,
  useTheme,
  VStack
} from '@chakra-ui/react'
import { faEnvelope } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Image from 'next/image'
import { SubmitHandler, useForm } from 'react-hook-form'
import { forgotPassword } from '@/stores/userStore'
import { emailValidator } from '@/utils/dataHelpers/validators'
import { axiosErrorToast, showToast } from '@/utils/showToast'
import { LoginPages } from '../Login'

interface Props {
  setPage: (newState: LoginPages) => void
}

type FormData = {
  email: string
}

const LoginForm = ({ setPage }: Props) => {
  const theme = useTheme()
  const [requestingPassword, setRequestingPassword] = useBoolean()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>()

  const onSubmit: SubmitHandler<FormData> = async data => {
    setRequestingPassword.on()
    const error = await forgotPassword(data.email)

    if (error) {
      axiosErrorToast(error, 'warning')
      setRequestingPassword.off()
    } else {
      showToast(
        'A password has been sent to your email with the steps needed to reset your password'
      )
      setPage('login')
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack w={['full', null, '375px']} p={[5, 10]} spacing={5} align='start'>
        <Image width={110} height={35} src='/logo.svg' alt='login-logo' />
        <Heading size='md'>Find your account</Heading>
        <Text>Please enter your email address to search for your account.</Text>
        <FormControl isInvalid={'email' in errors}>
          <InputGroup>
            <InputLeftElement pointerEvents='none' color={theme.colors.gray['300']}>
              <Box w={5}>
                <FontAwesomeIcon icon={faEnvelope} />
              </Box>
            </InputLeftElement>
            <Input
              placeholder='Email'
              {...register('email', {
                pattern: {
                  value: emailValidator,
                  message: 'Please insert a valid email'
                },
                required: 'Please enter your email address'
              })}
            />
          </InputGroup>
          <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
        </FormControl>

        <HStack justify='space-between' w='full'>
          <Button colorScheme='green' type='submit' isLoading={requestingPassword}>
            Reset
          </Button>

          <Button size='sm' colorScheme='green' variant='link' onClick={() => setPage('login')}>
            Back
          </Button>
        </HStack>
      </VStack>
    </form>
  )
}

// noinspection JSUnusedGlobalSymbols
export default LoginForm
