import PortalThemedProvider from '@/components/shared/portal/PortalThemedProvider'
import useIsMounted from '@/hooks/useIsMounted'
import { Module } from '@/utils/Module'
import { handleModuleInjection } from '@/utils/moduleInjector'
import parseHtml from 'html-react-parser'
import type { GetStaticProps } from 'next'
import Head from 'next/head'
import { useEffect } from 'react'
import shallow from 'zustand/shallow'
import userStore from '../stores/userStore'

interface Props {
  bodyContent: any
  headContent: any
  wfDomain: any
  wfPage: any
  wfSite: any
}

function replace(node: any) {
  const attribs = node.attribs || {}

  if (attribs?.class?.includes('delete-me')) {
    return <></>
  }

  if (
    attribs?.id?.toLowerCase().startsWith('module') ||
    attribs?.id?.toLowerCase().startsWith('portal') ||
    attribs?.id?.toLowerCase().startsWith('search') ||
    attribs?.id?.toLowerCase().endsWith('search') ||
    attribs?.id?.toLowerCase().startsWith('parkshare')
  ) {
    return <Module>{handleModuleInjection(attribs.id)}</Module>
  }
}

// noinspection JSUnusedGlobalSymbols
const parseOptions = { replace }

declare global {
  const Webflow: any
}

const Home = ({ bodyContent, headContent, wfPage, wfDomain, wfSite }: Props) => {
  const isLoggedIn = userStore(state => state.isLoggedIn, shallow)
  const user = userStore(store => store.user)
  const isHydrated = useIsMounted()

  useEffect(() => {
    if (isHydrated) {
      setTimeout(() => {
        const loginButton = document.querySelectorAll(
          "a[href='/portal'] > .button-icon-content > .button-icon-text"
        )
        if (isLoggedIn) {
          loginButton[0].innerHTML = user
            ? user.firstName.charAt(0) + user.lastName.charAt(0)
            : 'Portal'
        } else {
          loginButton[0].innerHTML = 'Login'
        }
      }, 100)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, isHydrated])

  useEffect(() => {
    try {
      setTimeout(() => {
        document.documentElement.setAttribute('data-wf-page', wfPage)
        document.documentElement.setAttribute('data-wf-domain', wfDomain)
        document.documentElement.setAttribute('data-wf-site', wfSite)
      }, 100)
      if ((window as any).Webflow) {
        Webflow.destroy()
        Webflow.ready()
        Webflow.require('ix2').init()
      }
    } finally {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isHydrated) {
      try {
        setTimeout(() => {
          document.documentElement.setAttribute('data-wf-page', wfPage)
          document.documentElement.setAttribute('data-wf-domain', wfDomain)
          document.documentElement.setAttribute('data-wf-site', wfSite)
        }, 100)
        if (Webflow) {
          const anyWindow = window as any
          anyWindow.init?.()
          anyWindow.slider_home_1?.()
          anyWindow.slider_home_2?.()
          anyWindow.slider1?.()
          anyWindow.slider2?.()
          anyWindow.slider3?.()
          anyWindow.slider4?.()
          anyWindow.slider5?.()
          anyWindow.slider6?.()
          anyWindow.initFunding?.()
          anyWindow.slider_logo_home?.()
        }
      } catch (e) {}
    }
  }, [isHydrated])

  // noinspection HtmlRequiredTitleElement
  return (
    <>
      <Head>{parseHtml(headContent, parseOptions)}</Head>
      <PortalThemedProvider>{parseHtml(bodyContent, parseOptions)}</PortalThemedProvider>
    </>
  )
}

export const getStaticProps: GetStaticProps = async context => {
  const cheerio = await import(`cheerio`)
  const axios = (await import(`axios`)).default

  const path = ((context.params?.path as string[]) ?? ['/']).join('/')
  const url = path.startsWith('/') ? path : `/${path}`
  const fetchUrl = process.env.NEXT_PUBLIC_WEBFLOW_URL + url
  try {
    const res = await axios(fetchUrl)

    const html = res?.data

    const $ = cheerio.load(html)
    const bodyContent = $(`body`).html()
    const headContent = $(`head`).html()
    const htmlCheerio = $('html')
    const wfDomain = htmlCheerio.data('wfDomain')
    const wfPage = htmlCheerio.data('wfPage')
    const wfSite = htmlCheerio.data('wfSite')

    return {
      props: {
        bodyContent: bodyContent,
        headContent: headContent,
        wfDomain,
        wfPage,
        wfSite
      },
      revalidate: 10
    }
  } catch (e) {
    console.log(
      `Not found happened on page ${context.params?.path}, should revalidate in 10 seconds`
    )
    return {
      notFound: true,
      revalidate: 10
    }
  }
}

export default Home
