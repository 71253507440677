import { BREADCRUMBS_NEWS_DETAILS } from '@/constants/BreadCrumbsRoute'
import { NEWS_CATEGORY_FILTER_VALUES } from '@/constants/FieldsNews'
import { FRONT_NEWS_DETAILS, ROUTE_ECOSYSTEM_NEWS } from '@/constants/Routes'
import { News } from '@/models/calendar/News'
import baseFetcher from '@/utils/connectionHelpers/swrFetchers'
import { getCategoryFilterFromString } from '@/utils/dataHelpers/filtersHelpers'
import { Button } from '@chakra-ui/react'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import { useRouter } from 'next/router'
import useSWR from 'swr'
import EditDuplicateButtons from '../portal/EditDuplicateButtons'
import BreadCrumbs from '../shared/BreadCrumbs'
import DetailsFetchingHandling from '../shared/dataFetching/DetailsFetchingHandling'
import DisplayContents from '../shared/details/DisplayContents'
import DisplayImageAndCredit from '../shared/details/DisplayImageAndCredit'
import DisplayTitle from '../shared/details/DisplayTitle'
import DisplayVideoAndContent from '../shared/details/DisplayVideoAndContent'
import RecentNews from './RecentNews'

const NewsDetails = () => {
  const { query } = useRouter()

  const { data, error } = useSWR<News>(
    query.id ? FRONT_NEWS_DETAILS(query.id.toString()) : null,
    baseFetcher,
    { revalidateOnFocus: false }
  )

  return (
    <div className='section'>
      <DetailsFetchingHandling data={data} error={error} />
      {data && query.id && data.publish && (
        <>
          <BreadCrumbs
            text={BREADCRUMBS_NEWS_DETAILS(data.title)}
            backLink={ROUTE_ECOSYSTEM_NEWS}
          />
          <div className='wrapper'>
            <EditDuplicateButtons id={query.id.toString()} type='news' />
            <div className='grid-detail margin-bottom-8x'>
              <div className='block-content'>
                <DisplayTitle title={data.title} />
                <DisplayImageAndCredit image={data.image} imageCredit={data.imageCredit} />
                <div className='wrapper-detail-content'>
                  <DisplayContents firstParagraph={data.firstParagraph} content={data.content} />
                  <DisplayVideoAndContent
                    videoUrl={data.video}
                    contentUnder={data.contentUnderVideo}
                  />
                  {data.companyLink && data.companyLink > -1 && (
                    <Button
                      href={`/companies/details?id=${data.companyLink}`}
                      as={Link}
                      colorScheme='blackAlpha'
                      borderRadius='full'
                      bgColor='black'
                      rightIcon={<FontAwesomeIcon icon={faArrowRight} />}>
                      More on {data.companySignage}
                    </Button>
                  )}
                </div>
              </div>
              <RecentNews
                currentNewsID={query.id.toString() ?? '-1'}
                title='Similar news'
                type={getCategoryFilterFromString(
                  NEWS_CATEGORY_FILTER_VALUES,
                  NEWS_CATEGORY_FILTER_VALUES.Sustainability,
                  data.categoryFilter
                )}
                vertical
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default NewsDetails
