interface LastPageProps {
  currentPage: number
  numberOfPage: number
  pageClick: (val: number) => void
}

interface PaginationItemProps {
  val: number
  currentPage: number
  onClick: () => void
}

interface Props {
  numberOfPages: number
  currentPage: number
  pageClick: (val: number) => void
}

export const Pagination = ({ numberOfPages, currentPage, pageClick }: Props) => {
  //Only one page of data
  if (numberOfPages === 0) {
    return <PaginationItem val={1} currentPage={1} onClick={() => {}} />
    //Only two pages of data
  } else if (numberOfPages < 3) {
    return (
      <LessThanThreePages
        numberOfPages={numberOfPages}
        currentPage={currentPage}
        pageClick={pageClick}
      />
    )
    //Second to last page reached
  } else if (currentPage === numberOfPages) {
    return (
      <>
        <StandardPagination
          numberOfPages={numberOfPages}
          currentPage={currentPage}
          pageClick={pageClick}
        />
      </>
    )
    //Last page is reached
  } else if (currentPage === numberOfPages + 1) {
    return (
      <>
        <PaginationItem
          val={currentPage - 2}
          currentPage={currentPage}
          onClick={() => pageClick(currentPage - 2)}
        />
        <PaginationItem
          val={currentPage - 1}
          currentPage={currentPage}
          onClick={() => pageClick(currentPage - 1)}
        />
        <PaginationItem
          val={currentPage}
          currentPage={currentPage}
          onClick={() => pageClick(currentPage)}
        />
      </>
    )
    //First page but with more than 3 pages
  } else if (currentPage === 1) {
    return (
      <>
        <LessThanThreePages numberOfPages={2} currentPage={currentPage} pageClick={pageClick} />
        <LastPageLink
          numberOfPage={numberOfPages}
          currentPage={currentPage}
          pageClick={pageClick}
        />
      </>
    )
  }

  return (
    <>
      <StandardPagination
        numberOfPages={numberOfPages}
        currentPage={currentPage}
        pageClick={pageClick}
      />
      <LastPageLink numberOfPage={numberOfPages} currentPage={currentPage} pageClick={pageClick} />
    </>
  )
}

const LessThanThreePages = ({ numberOfPages, currentPage, pageClick }: Props) => (
  <>
    {Array.from({ length: numberOfPages + 1 }).map((_, i) => {
      const curr = i + 1
      return (
        <PaginationItem
          key={curr}
          currentPage={currentPage}
          val={curr}
          onClick={() => pageClick(curr)}
        />
      )
    })}
  </>
)

// Standard pagination, the current page is the middle
const StandardPagination = ({ currentPage, pageClick }: Props) => (
  <>
    <PaginationItem
      val={currentPage - 1}
      currentPage={currentPage}
      onClick={() => pageClick(currentPage - 1)}
    />
    <PaginationItem
      val={currentPage}
      currentPage={currentPage}
      onClick={() => pageClick(currentPage)}
    />
    <PaginationItem
      val={currentPage + 1}
      currentPage={currentPage}
      onClick={() => pageClick(currentPage + 1)}
    />
  </>
)

const LastPageLink = ({ currentPage, pageClick, numberOfPage }: LastPageProps) => (
  <>
    <div className='pagination-more'>...</div>
    <PaginationItem
      val={numberOfPage + 1}
      currentPage={currentPage}
      onClick={() => pageClick(numberOfPage + 1)}
    />
  </>
)

const PaginationItem = ({ val, currentPage, onClick }: PaginationItemProps) => (
  <a
    className={`pagination-button w-button ${currentPage === val ? 'current' : ''}`}
    onClick={onClick}>
    {val}
  </a>
)
