import dayjs from 'dayjs'
import { Event } from '@/models/calendar/Event'
import { FRONT_DATE_FORMAT } from '@/constants/DateConstants'
import { HStack } from '@chakra-ui/react'
import AddToCalendar from './AddToCalendar'

interface Props {
  event: Event
}

const EventDetailsProgramInfo = ({ event }: Props) => {
  return (
    <div className='block-content'>
      <div className='detail-info-box margin-bottom-2x'>
        {event.startDate && (
          <div className='detail-info-box-grid'>
            <div className='fa-l large color-events-2'></div>
            <div className='text large no-margin'>
              {dayjs(event.startDate).format(FRONT_DATE_FORMAT)}
            </div>
          </div>
        )}
        {(event.startTime || event.endTime) && (
          <div className='detail-info-box-grid'>
            <div className='fa-l large color-events-2'></div>
            <div className='meta-date-small'>
              {event.startTime && <div className='text large no-margin'>{event.startTime}</div>}
              {event.startTime && event.endTime && (
                <div className='fa-l small color-events-2'></div>
              )}
              {event.endTime && <div className='text large no-margin'>{event.endTime}</div>}
            </div>
          </div>
        )}
        {event.location && (
          <div className='detail-info-box-grid align-top margin-bottom-2x'>
            <div className='fa-l large color-events-2'></div>
            <div>
              <div className='text large no-margin'>{event.location}</div>
              {event.googleMapLink && (
                <a
                  href={event.googleMapLink}
                  target='_blank'
                  className='text small color-light'
                  rel='noopener noreferrer'>
                  View on map
                </a>
              )}
            </div>
          </div>
        )}
        <HStack>
          <div className='flex-content-horizontal gap-1'>
            <AddToCalendar event={event} dark />
          </div>
        </HStack>
      </div>
      {event.organizerName && (
        <div className='card'>
          <div className='separator fill-event-1'></div>
          <div className='text small color-light'>Organizer</div>
          <div className='text bold no-margin'>{event.organizerName}</div>
          {event.organizerPhone ? (
            <div className='text color-light'>
              {event.organizerPhone} {event.organizerEmail && ` / ${event.organizerEmail}`}
            </div>
          ) : (
            event.organizerEmail && <div className='text color-light'>{event.organizerEmail}</div>
          )}
          {event.organizerWebsite && (
            <a
              href={event.organizerWebsite}
              target='_blank'
              className='text color-events-2'
              rel='noopener noreferrer'>
              {event.organizerWebsite}
            </a>
          )}
        </div>
      )}
    </div>
  )
}

export default EventDetailsProgramInfo
