import { extendZodWithOpenApi } from '@anatine/zod-openapi'
import { z } from 'zod'
import { AgendaForCreation } from './Agenda'

extendZodWithOpenApi(z)

export const EventEdit = z.object({
  createdBy: z.string().optional(),
  startDate: z.coerce.date().optional(),
  startTime: z.string().optional().openapi({ format: 'time' }),
  endDate: z.coerce.date().optional(),
  endTime: z.string().optional().openapi({ format: 'time' }),
  title: z.string(),
  subtitle: z.string().optional(),
  content: z.string().optional(),
  motivationItems: z.string().optional(),
  spotlight: z.boolean().optional(),
  membersOnly: z.boolean().optional(),
  categoryFilter: z.string().optional(),
  categoryHidden: z.string().optional(),
  firstParagraph: z.string().optional(),
  organizerName: z.string().optional(),
  organizerEmail: z.string().email().optional().openapi({ format: 'email' }),
  organizerPhone: z
    .string()
    .optional()
    .openapi({ format: 'phone-number', example: '+41 12 345 67 89' }),
  organizerWebsite: z.string().optional().openapi({ format: 'uri' }),
  imageCredit: z.string().optional(),
  tags: z.string().array().max(4).optional(),
  image: z.string().url().optional().openapi({ format: 'uri' }),
  location: z.string().optional(),
  googleMapLink: z.string().url().optional().openapi({ format: 'uri' }),
  linkToJoin: z.string().url().optional().openapi({ format: 'uri' }),
  locationFilter: z.string().optional(),
  agenda: AgendaForCreation.zodType.array().default([])
})

export type EventEdit = z.infer<typeof EventEdit>

export const EventCreate = z.object({
  createdBy: z.string().optional(),
  startDate: z.coerce.date().optional(),
  startTime: z.string().optional().openapi({ format: 'time' }),
  endDate: z.coerce.date().optional(),
  endTime: z.string().optional().openapi({ format: 'time' }),
  title: z.string(),
  subtitle: z.string().optional(),
  content: z.string().optional(),
  motivationItems: z.string().optional(),
  spotlight: z.boolean().optional(),
  membersOnly: z.boolean().optional(),
  categoryFilter: z.string().optional(),
  categoryHidden: z.string().optional(),
  firstParagraph: z.string().optional(),
  organizerName: z.string().optional(),
  organizerEmail: z.string().email().optional().openapi({ format: 'email' }),
  organizerPhone: z
    .string()
    .optional()
    .openapi({ format: 'phone-number', example: '+41 12 345 67 89' }),
  organizerWebsite: z.string().optional().openapi({ format: 'uri' }),
  imageCredit: z.string().optional(),
  tags: z.string().array().max(4).optional(),
  image: z.string().optional(),
  location: z.string().optional(),
  googleMapLink: z.string().url().optional().openapi({ format: 'uri' }),
  linkToJoin: z.string().url().optional().openapi({ format: 'uri' }),
  locationFilter: z.string().optional(),
  agenda: AgendaForCreation.zodType.array().default([])
})

export type EventCreate = z.infer<typeof EventCreate>
