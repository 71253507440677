import { FRONT_NEWS } from '@/constants/Routes'
import { News } from '@/models/calendar/News'
import { getPaginatedFetcher } from '@/utils/connectionHelpers/swrFetchers'
import { AxiosError } from 'axios'
import useSWR from 'swr'
import ListDisplay from '../shared/ListDisplay'
import SuccessStoryCard from './components/SuccessStoryCard'

interface Props {
  title: string
  moreTitle?: string
  moreLink?: string
  isCoached?: boolean
  scrolling?: boolean
}

const SuccessStories = ({
  title,
  moreTitle,
  moreLink,
  scrolling = false,
  isCoached = false
}: Props) => {
  const custom = ['&successStories=true']

  if (isCoached) {
    custom.push('&isCoached=true')
  }

  const { data } = useSWR<{ data: News[]; items: number }, AxiosError>(
    FRONT_NEWS,
    getPaginatedFetcher(1, 3, false, custom.join('')),
    { revalidateOnFocus: false }
  )

  return (
    <>
      <ListDisplay<News>
        title={title}
        moreLink={moreLink}
        moreTitle={moreTitle}
        listItems={data?.data ?? []}
        scrollingTitle={scrolling}
        cardsClass='anim-news'
        card={(item, key) => {
          const additionalClass = `_${key + 1}`
          return <SuccessStoryCard news={item} key={key} additionalClass={additionalClass} />
        }}
      />
    </>
  )
}

// noinspection JSUnusedGlobalSymbols
export default SuccessStories
