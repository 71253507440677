import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  SelectProps,
  Text,
  VStack
} from '@chakra-ui/react'
import type { FieldValues, Path, RegisterOptions, UseFormRegister } from 'react-hook-form'

interface Props<TFormValues extends FieldValues> extends SelectProps {
  name: Path<TFormValues>
  label: string
  rules?: RegisterOptions
  register: UseFormRegister<TFormValues>
  errors: any
  options?: { label: string; value: string }[]
}

const FormSelect = <TFormValues extends FieldValues>({
  name,
  label,
  rules,
  register,
  errors,
  options,
  ...props
}: Props<TFormValues>) => {
  const errorMessages = errors[name]
  const hasError = !!(errors && errorMessages)

  return (
    <FormControl isInvalid={hasError} isRequired={!!rules?.required}>
      <VStack w='full' align='start' spacing={2}>
        <FormLabel fontSize='xs'>{label.toUpperCase()}</FormLabel>
        {options ? (
          <Select {...register(name, rules)} {...props}>
            {options.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        ) : (
          <Text>Loading...</Text>
        )}
        {hasError && <FormErrorMessage>{errorMessages.message}</FormErrorMessage>}
      </VStack>
    </FormControl>
  )
}

export default FormSelect
