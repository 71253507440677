import { useEffect, useState } from 'react'
import { DEFAULT_LIST_SIZE } from '@/constants/RequestConstants'
import { Pagination } from './PaginationButtons'

interface Props {
  pageClick: (pageNumber: number) => void
  currentPage: number
  data: { data: any[]; items: number } | undefined
}

//Eudonet pages start at 1 not 0
const GlobalPagination = ({ pageClick, currentPage, data }: Props) => {
  const [nextDisabled, setNextDisabled] = useState(false)

  const numberOfPages = data?.items ? Math.floor(data.items / DEFAULT_LIST_SIZE) : undefined

  useEffect(() => {
    setNextDisabled((data && data.data?.length < DEFAULT_LIST_SIZE) ?? false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const onPageClick = (val: number) => {
    window.scroll({ top: 0, behavior: 'smooth' })
    pageClick(val)
  }

  if (currentPage === 0 && nextDisabled) {
    return <></>
  }
  return (
    <div className='wrapper margin-bottom-4x'>
      <div className='pagination-block'>
        {currentPage > 1 && (
          <a className='pagination-prev w-inline-block'>
            <div className='w-embed' onClick={() => pageClick(currentPage - 1)}>
              <svg
                width='46'
                height='18'
                viewBox='0 0 46 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M7.37582 0.2552C7.66811 -0.0645754 8.1643 -0.0868529 8.48407 0.205441C8.80384 0.497734 8.82612 0.993914 8.53383 1.31369L2.56436 7.84439H45.2157C45.6489 7.84439 46.0001 8.19559 46.0001 8.62883C46.0001 9.06206 45.6489 9.41327 45.2157 9.41327H2.56435L8.53383 15.944C8.82612 16.2638 8.80384 16.7599 8.48407 17.0522C8.1643 17.3445 7.66811 17.3223 7.37582 17.0024L0.205556 9.15808C-0.068356 8.85841 -0.068356 8.39925 0.205556 8.09959L7.37582 0.2552Z'
                  fill='currentColor'
                />
              </svg>
            </div>
          </a>
        )}

        <Pagination
          numberOfPages={numberOfPages ?? 0}
          currentPage={currentPage}
          pageClick={onPageClick}
        />

        {!nextDisabled && (
          <a className='pagination-next w-inline-block'>
            <div className='w-embed' onClick={() => pageClick(currentPage + 1)}>
              <svg
                width='46'
                height='18'
                viewBox='0 0 46 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M38.6243 0.255234C38.332 -0.0645841 37.8359 -0.0868646 37.5161 0.205468C37.1963 0.497801 37.174 0.994047 37.4663 1.31387L43.4358 7.84544H0.78444C0.351205 7.84544 0 8.19669 0 8.62999C0 9.06328 0.351205 9.41453 0.78444 9.41453H43.4358L37.4663 15.9462C37.174 16.266 37.1963 16.7622 37.5161 17.0545C37.8359 17.3469 38.332 17.3246 38.6243 17.0047L45.7946 9.15931C46.0685 8.85961 46.0685 8.40038 45.7946 8.10068L38.6243 0.255234Z'
                  fill='currentColor'
                />
              </svg>
            </div>
          </a>
        )}
      </div>
    </div>
  )
}

export default GlobalPagination
