import { Box, Button, Text, Tooltip, useTheme, VStack } from '@chakra-ui/react'
import { faCircleInfo, IconDefinition } from '@fortawesome/pro-light-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import type { HTMLAttributeAnchorTarget } from 'react'

interface Props {
  text: string
  link?: string
  onClick?: () => void
  target?: HTMLAttributeAnchorTarget
  icon: IconDefinition | string
  info?: string
}

const ControlButton = ({ text, onClick, link, target = '_self', icon, info }: Props) => {
  const theme = useTheme()

  const LinkProps = link ? { as: Link, href: link } : {}

  return (
    <Box position='relative' h={28}>
      <Button
        w='full'
        h={28}
        {...LinkProps}
        target={target}
        backgroundColor={theme.colors.white}
        _hover={{ bg: theme.colors.gray['100'] }}
        border='1px solid'
        borderColor={theme.colors.gray['100']}
        onClick={onClick}>
        <VStack w='full' align='center' justify='center'>
          {typeof icon === 'string' ? (
            <>
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img src={icon} alt='' style={{ height: '35px', width: 'auto' }} />
            </>
          ) : (
            <FontAwesomeIcon icon={icon} size='2x' />
          )}
          <Text
            fontSize='sm'
            fontWeight='medium'
            overflowWrap='break-word'
            noOfLines={2}
            m='0'
            overflow='hidden'>
            {text}
          </Text>
        </VStack>
      </Button>
      {info && (
        <Tooltip hasArrow label={info}>
          <Box zIndex='popover' position='absolute' right='2' top='1' color='gray.400'>
            <FontAwesomeIcon icon={faCircleInfo} />
          </Box>
        </Tooltip>
      )}
    </Box>
  )
}

export default ControlButton
