import type { AxiosError } from 'axios'
import Loading from '../Loading'

interface Props {
  data?: {
    data: any[]
    items: number
  }
  error?: AxiosError
  forceFetching?: boolean
}

const ListFetchingHandling = ({ data, error, forceFetching = false }: Props) => {
  return (
    <>
      {!data && error && (
        <div className='section information-block'>
          <div>API Error</div>
        </div>
      )}
      {data && data.data.length === 0 && !error && (
        <div className='section placeholder-empty-result margin-bottom-2x'>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            src='https://global-uploads.webflow.com/627cb49ee9c8c230cfca9d37/6284dca9ed380f458674eea9_placeholder_empty.svg'
            loading='lazy'
            alt=''
            className='placeholder-img'
          />
          <div className='display-h4'>No results</div>
        </div>
      )}
      {((!data && !error) || forceFetching) && <Loading additionalClasses='margin-bottom-8x' />}
    </>
  )
}

export default ListFetchingHandling
