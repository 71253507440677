import {
  Button,
  Center,
  FormLabel,
  HStack,
  Image as CImage,
  Text,
  useDisclosure,
  VisuallyHidden,
  VStack
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/pro-light-svg-icons'
import { useEffect, useState } from 'react'
import { toBase64 } from '@/utils/dataHelpers/imageHelpers'
import { showToast } from '@/utils/showToast'
import { MAX_FILE_SIZE_2_MB } from '@/constants/APIConstants'
import dynamic from 'next/dynamic'

const ImagePreviewModal = dynamic(() => import('./ImagePreviewModal'))

const getImageDimensions = (src: string | File): Promise<[number, number]> => {
  const imgSrc = typeof src === 'string' ? src : URL.createObjectURL(src)
  const img = new Image()
  return new Promise<[number, number]>(res => {
    img.onload = function () {
      res([img.width, img.height])
    }
    img.src = imgSrc
  })
}

interface Props {
  base64ImageChange: (data: string) => void
  onFileChange?: (file?: File) => void
  pictureUrl?: string
  displayPreview?: boolean
}

const ImageUpload = ({
  pictureUrl,
  base64ImageChange,
  displayPreview = true,
  onFileChange
}: Props) => {
  const [picture, setPicture] = useState<any>()
  const [fileDataURL, setFileDataURL] = useState<ArrayBuffer | string | null>(null)

  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    setFileDataURL(pictureUrl ?? null)
  }, [pictureUrl])

  const handleDrop = async (event: any) => {
    const file = event.target.files[0] as File
    if (file) {
      if (file.size > MAX_FILE_SIZE_2_MB) {
        showToast('The file exceed 2MB', 'error')
        return
      }
      if ((await getImageDimensions(file)).find(x => x > 4000)) {
        showToast('Image dimensions has at least one side that exceeds 4000px', 'error')
        return
      }
      const data = (await toBase64(file)) as string
      onFileChange?.(file)
      base64ImageChange(data)
      setPicture(file)
    }
  }

  useEffect(() => {
    const fileReader = new FileReader()
    if (!picture) {
      return
    }

    fileReader.onload = e => {
      const res = e.target
      if (res) {
        setFileDataURL(res.result)
      }
    }
    fileReader.readAsDataURL(picture)

    return () => {
      fileReader.abort()
    }
  }, [picture])

  const handleImageDelete = () => {
    //Timeout needed to avoid reopening the file selector
    setTimeout(() => {
      setFileDataURL(null)
      base64ImageChange('')
      onFileChange?.(undefined)
    }, 1)
  }

  return (
    <>
      {fileDataURL && typeof fileDataURL === 'string' ? (
        <>
          <FormLabel w='full' p={6} borderWidth={2} borderStyle='dashed' rounded='lg'>
            <VStack w='full'>
              <Center>
                <CImage src={fileDataURL} maxH='300px' w='auto' alt='' />
              </Center>
              <HStack w='full'>
                {displayPreview && <Button onClick={() => onOpen()}>Preview</Button>}
                <Button onClick={handleImageDelete}>Remove</Button>
              </HStack>
            </VStack>
          </FormLabel>
          {displayPreview && isOpen && (
            <ImagePreviewModal previewVisible={isOpen} dataURL={fileDataURL} onClose={onClose} />
          )}
        </>
      ) : (
        <>
          <FormLabel
            w='full'
            htmlFor='file-upload'
            p={6}
            borderWidth={2}
            borderStyle='dashed'
            rounded='lg'
            _hover={{ cursor: 'pointer' }}>
            <VStack>
              <FontAwesomeIcon icon={faUpload} color='gray' height={32} />
              <HStack alignItems='baseline'>
                <Text m={0} color='gray.500' fontSize='lg'>
                  Upload a file
                </Text>
                <VisuallyHidden>
                  <input
                    onChange={handleDrop}
                    id='file-upload'
                    name='file-upload'
                    accept='image/*'
                    type='file'
                  />
                </VisuallyHidden>
              </HStack>
              <Text fontSize='xs' color='gray.400'>
                PNG, JPG, GIF up to 2MB
              </Text>
            </VStack>
          </FormLabel>
        </>
      )}
    </>
  )
}

export default ImageUpload
