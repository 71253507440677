import { UserServicePermission } from '@/constants/FieldsUser'
import { COOKIE_EMPLOYEE } from '@/constants/RequestConstants'
import { ROUTE_PORTAL_COLLABORATORS_CREATE } from '@/constants/Routes'
import userStore from '@/stores/userStore'
import { hasPermissionUser } from '@/utils/apiHelpers/userPermissions'
import { Box, Button, Heading, Spacer, Stack, TabPanel, Text, VStack } from '@chakra-ui/react'
import Cookies from 'js-cookie'
import Link from 'next/link'
import CollaboratorsList from '../CollaboratorsList'

export type CollaboratorsTabProps = {}
export const CollaboratorsTab = ({}: CollaboratorsTabProps) => {
  const user = userStore(state => state.user)
  return (
    <TabPanel>
      <VStack align='stretch'>
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          align='stretch'
          justify='space-between'
          alignItems='center'>
          <VStack align='start'>
            <Heading size='md' mt='0'>
              Collaborators
            </Heading>
            <Text color='gray.600'>
              Manage the employees in your company who have access to the tenant portal. Only
              profiles with an administrator or supervisor role can create a new profile or modify
              an existing one. Please note that this list is not related to accreditations for
              Camipro badges
            </Text>
          </VStack>
          <Spacer />
          {hasPermissionUser(user, UserServicePermission.Collaborator_Manage) && (
            <Box>
              <Button
                as={Link}
                onClick={() => {
                  Cookies.remove(COOKIE_EMPLOYEE, {})
                }}
                href={ROUTE_PORTAL_COLLABORATORS_CREATE}
                borderRadius='full'
                bg='black'
                colorScheme='blackAlpha'>
                Add collaborator
              </Button>
            </Box>
          )}
        </Stack>
        <CollaboratorsList />
      </VStack>
    </TabPanel>
  )
}
