import { DATE_FORMAT_API } from '@/constants/DateConstants'
import { BookingDuration } from '@/constants/FieldsParking'
import {
  FRONT_FREE_SPOTS,
  FRONT_PARKING_LOT_LIST,
  ROUTE_PARKSHARE_BOOKING_DETAILS
} from '@/constants/Routes'
import { ParkingBooking } from '@/models/parking/ParkingBooking'
import { ParkingLot } from '@/models/parking/ParkingLot'
import { bookingStore } from '@/stores/bookingStore'
import { calculateBookingCost } from '@/utils/dataHelpers/parkBookingHelpers'
import { showToast } from '@/utils/showToast'
import { HStack, Spinner, Text, VStack, useDisclosure, useTheme } from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { Dayjs } from 'dayjs'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import useSWR from 'swr'
import useErrorToaster from '../../../hooks/useErrorToaster'
import useIsMounted from '../../../hooks/useIsMounted'
import userStore from '../../../stores/userStore'
import baseFetcher, { freeSpotFetcher } from '../../../utils/connectionHelpers/swrFetchers'
import ConfirmBookingModal from './ConfirmBookingModal'
import { ParkingCard } from './ParkingCard'

const LoginModal = dynamic(() => import('../newBookings/LoginModal'))

interface Props {
  selectedDate: Dayjs
  selectedTimeOfDay: BookingDuration
}

type FreeSpotsPerLot = {
  spots: Map<number, ParkingBooking[]>
  spotCounts: Map<number, number>
}

//Retrieve and render a list of parking lots and info about their availability
const ParkingSelection = ({ selectedDate, selectedTimeOfDay }: Props) => {
  const theme = useTheme()
  const router = useRouter()
  const mounted = useIsMounted()
  const book = bookingStore(state => state.book)

  const isLoggingIn = userStore(state => state.isLoggingIn)

  const { isOpen: confirmOpen, onOpen: onConfirmOpen, onClose: onConfirmClose } = useDisclosure()
  const [selectedParkingLot, setSelectedParkingLot] = useState<ParkingLot | undefined>(undefined)
  const [selectedBookingListForLot, setSelectedBookingListForLot] = useState<ParkingBooking[]>([])
  const [freeSpotsPerLot, setFreeSpotsPerLot] = useState<FreeSpotsPerLot>({
    spots: new Map(),
    spotCounts: new Map()
  })

  const { data: parkingLots, error: parkingLotError } = useSWR<ParkingLot[], AxiosError>(
    FRONT_PARKING_LOT_LIST,
    baseFetcher,
    { revalidateOnFocus: false }
  )
  const { data: freeSpots, error: freeSpotsError } = useSWR<ParkingBooking[], AxiosError>(
    FRONT_FREE_SPOTS(selectedDate.format(DATE_FORMAT_API)),
    freeSpotFetcher,
    { revalidateOnFocus: false }
  )
  useErrorToaster(parkingLotError)
  useErrorToaster(freeSpotsError)

  //Sort the booking list with the latest booking list and duration.
  useEffect(() => {
    sortFreeSlots()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freeSpots, selectedTimeOfDay])

  //Sort the fetched booking list into lists by parking filtered by duration.
  const sortFreeSlots = () => {
    if (!Array.isArray(parkingLots) || !Array.isArray(freeSpots)) {
      return
    }

    //Sort the free spots into lists of free spots for each parking lot.
    //Initialize each lot
    const sortedSpots = new Map<number, ParkingBooking[]>()
    const sortedSpotNumbers = new Map<number, number>()

    //Sort into spots based on lot and availability.
    switch (selectedTimeOfDay) {
      case BookingDuration.afternoon:
      case BookingDuration.morning:
        //If the selected time is a single-slot, simply filter them.
        parkingLots?.forEach(lot => {
          const avails = freeSpots.filter(booking => {
            return booking.parkingId === lot.id && booking.duration === selectedTimeOfDay
          })

          sortedSpots.set(lot.id, avails)
          sortedSpotNumbers.set(lot.id, avails.length)
        })

        break
      case BookingDuration.allDay:
        //If the selected time is all day, we need to check the
        parkingLots?.forEach(lot => {
          //Get the items for each availability (optimized search)
          const mornings = [] as ParkingBooking[]
          const afternoons = [] as ParkingBooking[]

          freeSpots.forEach(booking => {
            if (booking.parkingId === lot.id) {
              if (booking.duration === BookingDuration.afternoon) {
                afternoons.push(booking)
              } else {
                mornings.push(booking)
              }
            }
          })

          //Compare the two lists to get a final list of bookings which are available all day.
          const allDays = mornings.filter(
            morning => afternoons.findIndex(afternoon => afternoon.spotId === morning.spotId) > -1
          )

          sortedSpots.set(lot.id, allDays)
          sortedSpotNumbers.set(lot.id, allDays.length)
        })

        break
    }

    setFreeSpotsPerLot({
      spots: sortedSpots,
      spotCounts: sortedSpotNumbers
    })
  }

  //One of the parking lots was clicked. Select parking lot and display confirm booking modal.
  const onParkingClicked = (lot: ParkingLot) => {
    setSelectedParkingLot(lot)
    //We resort the list since the all-day option only stores the morning objects in the sort.
    setSelectedBookingListForLot(
      freeSpots?.filter((obj: ParkingBooking) => obj.parkingId === lot.id) || []
    )
    onConfirmOpen()
  }

  //Send the booking to the server for validation
  const onBookingConfirmed = async () => {
    try {
      const booking = await book({
        bookingPrice: calculateBookingCost(selectedParkingLot, selectedTimeOfDay),
        freeSpots: selectedBookingListForLot,
        duration: selectedTimeOfDay
      })

      if (!booking) {
        showToast(
          'There was a problem making the selected booking. Please try again or book another.',
          'error'
        )
      }

      if (mounted && booking) {
        await router.push(
          {
            pathname: ROUTE_PARKSHARE_BOOKING_DETAILS,
            query: { booking: JSON.stringify(booking) }
          },
          ROUTE_PARKSHARE_BOOKING_DETAILS
        )
      }
    } catch (error: any) {
      let message = error?.message || 'Something went wrong. Please refresh and try again'

      if (error.isAxiosError) {
        switch (error?.response?.status) {
          case 402:
            message = `You don't have enough credits to book, contact EPFL Innovation Park to top up`
            break
          case 404:
            message = 'Spot no longer available, please refresh and try again'
            break
          default:
            break
        }
      }

      showToast(message, 'error')
    }

    //Check component is mounted.
    if (!mounted) {
      return
    }

    onConfirmClose()
  }

  //Create the list info for the confirm-booking modal
  const makeListInfo = () => {
    return {
      parkingName: selectedParkingLot?.name || '',
      parkingDate: selectedDate.toDate().toDateString(),
      parkingOption: selectedTimeOfDay
    }
  }

  return (
    <>
      {isLoggingIn && <LoginModal visible={isLoggingIn} />}
      {(!parkingLots && !parkingLotError) || (!freeSpots && !freeSpotsError) ? (
        <HStack w='full' justify='center'>
          <Spinner size='xl' />
        </HStack>
      ) : (
        <>
          <ConfirmBookingModal
            visible={confirmOpen}
            onClose={onConfirmClose}
            onOk={onBookingConfirmed}
            needAccess={selectedParkingLot ? selectedParkingLot.needAccess : true}
            cost={calculateBookingCost(selectedParkingLot, selectedTimeOfDay)}
            list={makeListInfo()}
          />
          <VStack w='full'>
            {!parkingLots || parkingLots.length === 0 ? (
              <>
                <Text>Please wait a few seconds if you're coming from the mobile application</Text>
                <Text color={theme.colors.brand.error}>
                  If you're not, your request raised an error. Please try again.
                </Text>
              </>
            ) : (
              parkingLots!.map((parkingLot, index) => (
                <ParkingCard
                  key={index}
                  parkingClicked={onParkingClicked}
                  freeSpots={freeSpotsPerLot.spotCounts.get(parkingLot.id) || 0}
                  parkingLot={parkingLot}
                />
              ))
            )}
          </VStack>
        </>
      )}
    </>
  )
}

export default ParkingSelection
