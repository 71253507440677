import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

interface Props {
  searchText: (queryText: string) => void
}

const FilterTextSearch = ({ searchText }: Props) => {
  const { query } = useRouter()
  const [queryText, setQueryText] = useState<string>('')

  useEffect(() => {
    const keyDownHandler = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        searchText(queryText)
      }
    }

    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryText])

  useEffect(() => {
    if (!query) {
      return
    }

    const { filter = undefined } = query
    if (!filter) {
      return
    }

    setQueryText(filter.toString())
    searchText(filter.toString())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  useEffect(() => {
    if (queryText.length < 1) {
      searchText('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryText])

  return (
    <div className='filter-block filter-form margin-left-2x'>
      <div className='filter-form'>
        <input
          type='text'
          value={queryText}
          className='text-field small no-margin margin-right-050 w-input'
          onChange={e => setQueryText(e.target.value)}
          maxLength={256}
          name='name'
          id='name'
          placeholder='Search'
        />
        <input
          onClick={() => searchText(queryText ?? '')}
          type='submit'
          value='Show result'
          className='button small button-dark w-button'
        />
      </div>
    </div>
  )
}
export default FilterTextSearch
