import { ROUTE_SUCCESS_STORY_DETAILS } from '@/constants/Routes'
import { News } from '@/models/calendar/News'
import CardImage from '../../shared/CardImage'

interface Props {
  news: News
  additionalClass?: string
  bottom?: React.ReactNode
}

const SuccessStoryCard = ({ news, additionalClass, bottom }: Props) => {
  const tags = news.tags.filter(t => t.trim() !== '')
  return (
    <div className={`card success-type ${additionalClass ? additionalClass : ''} `}>
      <a href={ROUTE_SUCCESS_STORY_DETAILS(news.id)} className='w-inline-block margin-bottom-2x'>
        <div className='separator fill-success-1' />
        {news.companySignage ? (
          <h3 className='card-title-medium'>{news.companySignage + ': ' + news.firstParagraph}</h3>
        ) : (
          <h3 className='card-title-medium'>{news.firstParagraph}</h3>
        )}

        <div className='tag-list color-success-1'>
          {news.categoryFilter && <div className='tag fill-success-1'>{news.categoryFilter}</div>}
          {tags?.length > 0 &&
            tags.slice(0, 4).map(tag => (
              <div className='tag outlined' key={tag}>
                {tags[0]}
              </div>
            ))}
        </div>
        <CardImage imgSrc={news.companyLogo} fillClass='fill-success-3' />
      </a>
      {bottom}
    </div>
  )
}

// noinspection JSUnusedGlobalSymbols
export default SuccessStoryCard
