import { Text, VStack } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { ROUTE_PARKSHARE_BOOKING_DETAILS } from '@/constants/Routes'
import { ParkingBooking } from '@/models/parking/ParkingBooking'
import ParkshareSubHeading from '../shared/ParkshareSubHeading'
import BookingCard from './BookingCard'

interface Props {
  header: string
  emptyContainerText: string
  bookings: ParkingBooking[]
}

const BookingList = ({ bookings, header, emptyContainerText }: Props) => {
  const router = useRouter()

  const handleBookingClick = async (item: ParkingBooking) => {
    await router.push(
      {
        pathname: ROUTE_PARKSHARE_BOOKING_DETAILS,
        query: { booking: JSON.stringify(item) }
      },
      ROUTE_PARKSHARE_BOOKING_DETAILS
    )
  }

  return (
    <>
      <ParkshareSubHeading text={header} />
      <VStack>
        {bookings.length === 0 ? (
          <Text textAlign='start' w='full'>
            {emptyContainerText}
          </Text>
        ) : (
          <VStack w='full'>
            {bookings.map(booking => (
              <BookingCard booking={booking} onClick={handleBookingClick} key={booking.id} />
            ))}
          </VStack>
        )}
      </VStack>
    </>
  )
}

// noinspection JSUnusedGlobalSymbols
export default BookingList
