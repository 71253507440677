import { Event } from '@/models/calendar/Event'
import DisplayContents from '../../shared/details/DisplayContents'
import dayjs from 'dayjs'
import { FRONT_DATE_FORMAT } from '@/constants/DateConstants'
import { ROUTE_EVENT_DETAIL } from '@/constants/Routes'
import JoinEvent from './AddToCalendar'

interface Props {
  selectedEvent: Event
  setSelectedEvent: (selectedEvent: Event | undefined) => void
}

const EventPreviewModal = ({ selectedEvent, setSelectedEvent }: Props) => {
  return (
    <div className='modale-popup' style={{ display: 'block' }}>
      <div className='modal-w'>
        <button
          style={{ overflowY: 'initial' }}
          onClick={() => {
            setSelectedEvent(undefined)
            document.body.style.overflow = 'scroll'
          }}
          className='modal-bg close-btn-w w-inline-block'></button>
        <div className='modal'>
          <div className='modal-content-w bg-events'>
            <div className='block-content'>
              <button
                onClick={() => {
                  setSelectedEvent(undefined)
                  document.body.style.overflow = 'initial'
                }}
                className='button small close-btn-w margin-bottom-1x w-button'>
                Back to calendar
              </button>
              <h1 className='display-h3 no-margin-top'>{selectedEvent.title}</h1>
              <div className='text large'>{selectedEvent.subtitle}</div>
              <img src={selectedEvent.image} loading='eager' sizes='100vw' alt='' className='img' />
              <div>
                <DisplayContents firstParagraph={selectedEvent.firstParagraph} />
              </div>
            </div>
            <div className='block-content'>
              <div className='detail-info-box margin-bottom-2x'>
                {selectedEvent.startDate && (
                  <div className='detail-info-box-grid'>
                    <div className='fa-l large color-events-2'></div>
                    <div className='text large no-margin'>
                      {dayjs(selectedEvent.startDate).format(FRONT_DATE_FORMAT)}
                    </div>
                  </div>
                )}
                {(selectedEvent.startTime || selectedEvent.endTime) && (
                  <div className='detail-info-box-grid'>
                    <div className='fa-l large color-events-2'></div>
                    <div className='meta-date-small'>
                      {selectedEvent.startTime && (
                        <div className='text large no-margin'>{selectedEvent.startTime}</div>
                      )}
                      {selectedEvent.startTime && selectedEvent.endTime && (
                        <div className='fa-l small color-events-2'></div>
                      )}
                      {selectedEvent.endTime && (
                        <div className='text large no-margin'>{selectedEvent.endTime}</div>
                      )}
                    </div>
                  </div>
                )}
                {selectedEvent.location && (
                  <div className='detail-info-box-grid align-top margin-bottom-2x'>
                    <div className='fa-l large color-events-2'></div>
                    <div>
                      <div className='text large no-margin'>{selectedEvent.location}</div>
                    </div>
                  </div>
                )}
                <div className='flex-content-horizontal gap-1'>
                  <a
                    href={ROUTE_EVENT_DETAIL(selectedEvent.id)}
                    className='button-with-icon dark w-inline-block'>
                    <div className='button-icon-content'>
                      <div className='button-icon-text'>Show More</div>
                    </div>
                  </a>
                </div>
                <div className='flex-content-horizontal gap-1'>
                  <JoinEvent event={selectedEvent} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EventPreviewModal
