import { ParkingType } from '@/models/parking/ParkingLot'
import { Box, Button, Flex, HStack, Text, VStack } from '@chakra-ui/react'
import { useState } from 'react'
import ParkingLotMap from '../parkingMaps/ParkingLotMap'
import SingleParkingMap from '../parkingMaps/SingleParkingMap'

type mapType = 'PARKING' | 'GENERAL'

interface Props {
  parkingType: ParkingType
  spotNumber: number
}

const BookingDetailsMap = ({ parkingType, spotNumber }: Props) => {
  const [selectedMap, setSelectedMap] = useState<mapType>('PARKING')

  return (
    <>
      <VStack w='full'>
        <HStack mt={3} spacing={0} w={['full', null, '80%']}>
          <Flex gap={[0, null, 10]} justifyContent={['space-between', null, 'center']} w='full'>
            <Button
              variant={selectedMap === 'PARKING' ? 'parkshareDarkButtons' : 'parkshareLightButtons'}
              onClick={() => setSelectedMap('PARKING')}>
              Parking map
            </Button>
            <Button
              variant={selectedMap === 'GENERAL' ? 'parkshareDarkButtons' : 'parkshareLightButtons'}
              onClick={() => setSelectedMap('GENERAL')}>
              General map
            </Button>
          </Flex>
        </HStack>
      </VStack>
      {selectedMap === 'PARKING' ? (
        <Box>
          <Box
            bg='brand.primaryLight'
            px={4}
            py={2}
            borderRadius='md'
            border='1px solid'
            marginY={4}
            textAlign='center'
            borderColor='brand.primary'>
            <Text>Your spot is displayed in green on the map</Text>
          </Box>
          <Box w={['90vw', null, '30vw']}>
            <SingleParkingMap type={parkingType} available={[spotNumber]} />
          </Box>
        </Box>
      ) : (
        <Box w={['90%', null, '40%']}>
          <ParkingLotMap type={parkingType} />
        </Box>
      )}
    </>
  )
}

export default BookingDetailsMap
