import { FRONT_DATE_FORMAT } from '@/constants/DateConstants'
import { FRONT_EVENTS, ROUTE_EVENT_DETAIL, ROUTE_EVENTS_CALENDAR } from '@/constants/Routes'
import { Event } from '@/models/calendar/Event'
import { getPaginatedFetcher } from '@/utils/connectionHelpers/swrFetchers'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import useSWR from 'swr'

const PastEvents = () => {
  const { data } = useSWR<{ data: Event[]; items: number }, AxiosError>(
    FRONT_EVENTS,
    getPaginatedFetcher(1, 5, false),
    { revalidateOnFocus: false }
  )

  return (
    <>
      {data && (
        <div id='module_events_past'>
          <div className='header-w-button margin-bottom-4x'>
            <h2 className='display-h2 no-margin'>Past events</h2>
            <a href={ROUTE_EVENTS_CALENDAR()} className='button icon button-dark w-inline-block'>
              <div className='button-icon-content'>
                <div className='button-icon-text'>View more</div>
                <div className='button-icon small'></div>
              </div>
            </a>
          </div>
          {data.data.map(event => (
            <div className='list-item-past' key={event.id}>
              <div className='flex-content-vertical justify-center'>
                <h3 className='display-h5 no-margin'>{event.title}</h3>
                <div className='flex-content-horizontal gap-1'>
                  <div className='flex-content-horizontal gap-05'>
                    {(event.location || event.locationFilter) && (
                      <>
                        <div className='fa-l small color-events-2'></div>
                        <div className='color-light-dark'>{`${event.location}${
                          event.location && event.locationFilter ? ', ' : ''
                        }${event.locationFilter}`}</div>
                      </>
                    )}
                  </div>
                  {event.startDate && (
                    <div className='flex-content-horizontal gap-05'>
                      <div className='fa-l small color-events-2'></div>
                      <div className='color-light-dark'>
                        {dayjs(event.startDate).format(FRONT_DATE_FORMAT)}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='flex-content-vertical justify-center'>
                <a
                  href={ROUTE_EVENT_DETAIL(event.id)}
                  className='button small button-dark w-button'>
                  See archive
                </a>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default PastEvents
