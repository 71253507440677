import { extendZodWithOpenApi } from '@anatine/zod-openapi'
import { z } from 'zod'

extendZodWithOpenApi(z)

export const NewsEdit = z.object({
  title: z.string(),
  publicationDate: z.coerce.date().optional(),
  firstParagraph: z.string().optional(),
  content: z.string().optional(),
  contentUnderVideo: z.string().optional(),
  categoryFilter: z.string().optional().openapi({ format: 'literal' }),
  categoryHidden: z.string().optional(),
  createdBy: z.string().optional(),
  spotlight: z.boolean().optional(),
  highlight: z.boolean().optional(),
  tags: z.array(z.string()).max(4).optional(),
  imageCredit: z.string().optional(),
  video: z.string().optional().openapi({ format: 'uri' }),
  linkedCompany: z.string().optional().openapi({ format: 'ref' }),
  contentUnderKeyFigures: z.string().optional(),
  keyFigures1: z.string().min(1).max(10).optional(),
  keyTextFigures1: z.string().optional(),
  keyFigures2: z.string().min(1).max(10).optional(),
  keyTextFigures2: z.string().optional(),
  keyFigures3: z.string().min(1).max(10).optional(),
  keyTextFigures3: z.string().optional(),
  image: z.string().url().optional().openapi({ format: 'uri' })
})

export type NewsEdit = z.infer<typeof NewsEdit>

export const NewsCreate = z.object({
  title: z.string(),
  publicationDate: z.coerce.date().optional(),
  firstParagraph: z.string().optional(),
  content: z.string().optional(),
  contentUnderVideo: z.string().optional(),
  categoryFilter: z.string().optional().openapi({ format: 'literal' }),
  categoryHidden: z.string().optional(),
  createdBy: z.string().optional(),
  spotlight: z.boolean().optional(),
  highlight: z.boolean().optional(),
  tags: z.array(z.string()).max(4).optional(),
  imageCredit: z.string().optional(),
  video: z.string().optional().openapi({ format: 'uri' }),
  linkedCompany: z.string().optional().openapi({ format: 'ref' }),
  contentUnderKeyFigures: z.string().optional(),
  keyFigures1: z.string().min(1).max(10).optional(),
  keyTextFigures1: z.string().optional(),
  keyFigures2: z.string().min(1).max(10).optional(),
  keyTextFigures2: z.string().optional(),
  keyFigures3: z.string().min(1).max(10).optional(),
  keyTextFigures3: z.string().optional(),
  image: z.string().optional()
})

export type NewsCreate = z.infer<typeof NewsCreate>
