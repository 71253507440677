import BookingDetailsContainer from '../../components/parkshare/BookingDetailsContainer'
import BookingsContainer from '../../components/parkshare/BookingsContainer'
import HomeContainer from '../../components/parkshare/HomeContainer'
import ManagerContainer from '../../components/parkshare/ManagerContainer'
import { logSentryException } from '../loggingHelpers/sentryHelper'

export const handleParkshareModule = (id: string) => {
  switch (id) {
    case 'parkshare_home':
      return <HomeContainer />
    case 'parkshare_manager':
      return <ManagerContainer />
    case 'parkshare_bookings':
      return <BookingsContainer />
    case 'parkshare_bookings_details':
      return <BookingDetailsContainer />
    default:
      logSentryException('handleParkshareModule no supported module', 'MODULE', id)
  }
}
