import { Button, Heading, HStack } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/pro-light-svg-icons'
import { useRouter } from 'next/router'

interface Props {
  title: string
}

const PortalPageTitle = ({ title }: Props) => {
  const { back } = useRouter()

  return (
    <HStack align='center' spacing={4} mb={5}>
      <Button onClick={back} variant='ghost' p={3} mt={1.25}>
        <FontAwesomeIcon icon={faAngleLeft} />
      </Button>
      <Heading size='lg'>{title}</Heading>
    </HStack>
  )
}

export default PortalPageTitle
