import { PublicJob } from '@/models/job/PublicJob'
import { Button, Heading, HStack, Text, useBoolean, VStack } from '@chakra-ui/react'
import PortalJobItem from './PortalJobItem'

interface Props {
  title: string
  jobs?: Array<PublicJob>
  manual?: boolean
  onToggle: (job: PublicJob, manual: boolean) => Promise<void>
  onDisableAll: (manual: boolean) => Promise<void>
}

const PortalJobsList = ({ title, jobs, manual = false, onToggle, onDisableAll }: Props) => {
  const [disablingAll, setDisablingAll] = useBoolean()

  return (
    <VStack w='full' align='start' spacing={2}>
      <HStack w='full' justify='space-between'>
        <Heading size='md'>{title}</Heading>
        {jobs && jobs.length > 0 && (
          <Button
            colorScheme='red'
            variant='outline'
            onClick={async () => {
              setDisablingAll.on()
              await onDisableAll(manual)
              setDisablingAll.off()
            }}
            isLoading={disablingAll}>
            {manual ? 'Delete' : 'Hide'} all jobs
          </Button>
        )}
      </HStack>
      {jobs?.map((job, key) => (
        <PortalJobItem
          key={key}
          job={job}
          manual={manual}
          onToggle={onToggle}
          loadingOverride={disablingAll}
        />
      )) || <Text>No job in this category</Text>}
    </VStack>
  )
}

// noinspection JSUnusedGlobalSymbols
export default PortalJobsList
