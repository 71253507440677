import { NEWS_KEY_FIGURE_MAX_LENGTH } from '@/constants/APIConstants'
import {
  NEWS_CATEGORY_FILTER,
  NEWS_CATEGORY_FILTER_VALUES,
  NEWS_CREATED_BY
} from '@/constants/FieldsNews'
import {
  FRONT_CATALOG,
  FRONT_NEWS,
  FRONT_NEWS_DETAILS,
  ROUTE_PORTAL,
  forceAPICacheRefresh
} from '@/constants/Routes'
import { Catalog } from '@/models/calendar/Catalog'
import { NewsEdit } from '@/models/calendar/EditNews'
import type { News } from '@/models/calendar/News'
import { axiosVercelAPI } from '@/utils/connectionHelpers/axios'
import baseFetcher from '@/utils/connectionHelpers/swrFetchers'
import { handleUpdateErrors } from '@/utils/dataHelpers/apiResponseHelper'
import {
  startOfToday,
  toDateFromPicker,
  toDatePickerDateSafe
} from '@/utils/dataHelpers/dateHelpers'
import { stripHtmlTags } from '@/utils/dataHelpers/stringHelpers'
import { urlValidator } from '@/utils/dataHelpers/validators'
import { showToast } from '@/utils/showToast'
import { Divider, FormLabel, useBoolean } from '@chakra-ui/react'
import { DayValue } from '@hassanmojab/react-modern-calendar-datepicker'
import { zodResolver } from '@hookform/resolvers/zod'
import dayjs from 'dayjs'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import useSWR from 'swr'
import Loading from '../shared/Loading'
import DefaultFormWrapper from '../shared/form/DefaultFormWrapper'
import FormAutoFieldsCreation from '../shared/form/FormAutoFieldsCreation'
import FormCheckbox from '../shared/form/FormCheckbox'
import FormDatePicker from '../shared/form/FormDatePicker'
import FormDatePickerRender from '../shared/form/FormDatePickerRender'
import FormHeading from '../shared/form/FormHeading'
import FormInput from '../shared/form/FormInput'
import FormLinkCompany from '../shared/form/FormLinkCompany'
import FormRichText from '../shared/form/FormRichText'
import FormSelect from '../shared/form/FormSelect'
import FormTagsInput from '../shared/form/FormTagsInput'
import FormTextarea from '../shared/form/FormTextarea'
import ImageUpload from '../shared/imageUpload/ImageUpload'

const ManageNews = () => {
  return <NewsEditContent />
}

const NewsEditContent = () => {
  const { query, push } = useRouter()

  const [loading, setLoading] = useBoolean(false)

  const { data: createdBy } = useSWR<Catalog[]>(FRONT_CATALOG(NEWS_CREATED_BY), baseFetcher)
  const { data: categoryFilter } = useSWR<Catalog[]>(
    FRONT_CATALOG(NEWS_CATEGORY_FILTER),
    baseFetcher,
    { revalidateOnFocus: false }
  )
  const { data: newsDetails, error: newsError } = useSWR<News>(
    query.id ? forceAPICacheRefresh(FRONT_NEWS_DETAILS(query.id.toString())) : null,
    baseFetcher,
    { revalidateOnFocus: false }
  )

  useEffect(() => {
    if (!newsDetails) {
      return
    }
    setValue('title', newsDetails.title)
    setValue('firstParagraph', stripHtmlTags(newsDetails.firstParagraph))
    setValue('content', newsDetails.content)
    setValue('video', newsDetails.video)
    setValue('contentUnderVideo', newsDetails.contentUnderVideo)
    setValue('image', newsDetails.image)
    setValue('imageCredit', newsDetails.imageCredit)
    setValue('highlight', newsDetails.highlight)
    setValue('spotlight', newsDetails.spotlight)
    setValue('tags', newsDetails.tags)
    setValue('publicationDate', newsDetails.publicationDate)
    setValue('contentUnderKeyFigures', newsDetails.contentUnderKeyFigures)
    setValue('keyFigures1', newsDetails.keyFigures1.toString())
    setValue('keyFigures2', newsDetails.keyFigures2.toString())
    setValue('keyFigures3', newsDetails.keyFigures3.toString())
    setValue('keyTextFigures1', newsDetails.keyTextFigures1)
    setValue('keyTextFigures2', newsDetails.keyTextFigures2)
    setValue('keyTextFigures3', newsDetails.keyTextFigures3)
    setValue('linkedCompany', newsDetails.companyLink?.toString())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsDetails])

  useEffect(() => {
    if (!newsDetails) {
      reset({ publicationDate: new Date() })
      return
    }

    if (createdBy) {
      const val = createdBy.find(temp => temp.DisplayValue === newsDetails.createdBy)
      if (val) {
        setValue('createdBy', val.Id.toString())
      }
    }
    if (categoryFilter) {
      const val = categoryFilter.find(temp => temp.DisplayValue === newsDetails.categoryFilter)
      if (val) {
        setValue('categoryFilter', val.Id.toString())
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdBy, categoryFilter, newsDetails])

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    getValues,
    formState: { errors }
  } = useForm<NewsEdit>({ resolver: zodResolver(NewsEdit) })

  const onSubmit: SubmitHandler<NewsEdit> = async data => {
    if (data.linkedCompany == '-1') {
      data.linkedCompany = ''
    }
    try {
      setLoading.on()
      if (query.id && !query.duplicate) {
        await axiosVercelAPI.put(FRONT_NEWS_DETAILS(query.id.toString()), data)
        showToast('News successfully updated')
        setLoading.off()
      } else {
        await axiosVercelAPI.post(FRONT_NEWS, data)
        reset()
        await push(ROUTE_PORTAL)
        showToast(`News successfully ${query.duplicate ? 'duplicated' : 'created'}`)
      }
    } catch (e) {
      handleUpdateErrors(e)
      setLoading.off()
    }
  }

  const renderCustomInput = ({ ref }: any) => (
    <FormDatePickerRender pickerProp={ref} date={dayjs(watch('publicationDate'))} />
  )

  if (query.id && !newsDetails && !newsError) {
    return <Loading additionalClasses='margin-bottom-8x' />
  } else if (query.id && !newsDetails && newsError) {
    //TODO display error state
    return <p>Error</p>
  }

  return (
    <DefaultFormWrapper
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      title='Edit or create news'
      loading={loading}
      submitButtonText='Publish'>
      <FormSelect<NewsEdit>
        name='createdBy'
        label='Created by'
        errors={errors}
        register={register}
        options={createdBy?.map(item => {
          return { value: item.Id.toString(), label: item.DisplayValue }
        })}
      />
      <FormLinkCompany<NewsEdit>
        name='linkedCompany'
        label='Select a company'
        register={register}
        errors={errors}
        placeholder='Enter a company name'
      />
      <FormDatePicker<NewsEdit>
        name='publicationDate'
        label='Publication date'
        errors={errors}
        renderInput={renderCustomInput}
        value={toDatePickerDateSafe(dayjs(getValues('publicationDate')))}
        onChange={(newDate: DayValue) =>
          setValue('publicationDate', (newDate ? toDateFromPicker(newDate) : startOfToday).toDate())
        }
      />

      <FormHeading label='News Content' />
      <FormInput<NewsEdit>
        name='title'
        label='Title'
        register={register}
        errors={errors}
        rules={{
          required: 'News must have a title'
        }}
      />
      <FormTextarea<NewsEdit>
        register={register}
        errors={errors}
        label='First paragraph'
        name='firstParagraph'
        resize='vertical'
      />
      <FormRichText<NewsEdit>
        errors={errors}
        name='content'
        label='Content'
        initialContent={getValues('content')}
        onUpdate={val => setValue('content', val)}
      />

      <FormHeading label='News Medias' />
      <FormInput<NewsEdit>
        name='video'
        label='Video (URL address)'
        register={register}
        errors={errors}
        rules={{
          pattern: { value: urlValidator, message: 'Please enter a valid URL' }
        }}
      />
      <FormRichText<NewsEdit>
        errors={errors}
        name='contentUnderVideo'
        label='Content under video'
        initialContent={getValues('contentUnderVideo')}
        onUpdate={val => setValue('contentUnderVideo', val)}
      />
      <FormLabel fontSize='xs' w='full'>
        Picture
      </FormLabel>
      <ImageUpload
        base64ImageChange={data => setValue('image', data)}
        pictureUrl={getValues('image')}
      />
      <FormInput<NewsEdit> name='imageCredit' label='Picture credit' register={register} />

      <FormHeading label='News Categories' />
      <FormSelect<NewsEdit>
        name='categoryFilter'
        label='Category Filter'
        errors={errors}
        register={register}
        options={categoryFilter?.map(item => {
          return { value: item.Id.toString(), label: item.DisplayValue }
        })}
      />
      {watch('categoryFilter') === NEWS_CATEGORY_FILTER_VALUES.Success.toString() && (
        <>
          <FormAutoFieldsCreation<NewsEdit>
            errors={errors}
            fields={[
              {
                label: 'Key figure 1',
                name: 'keyFigures1',
                type: 'number',
                rules: {
                  maxLength: {
                    value: NEWS_KEY_FIGURE_MAX_LENGTH,
                    message: `Maximum length for Key figure is ${NEWS_KEY_FIGURE_MAX_LENGTH}`
                  }
                },
                register,
                watch,
                errors
              },
              {
                label: 'Text key figure 1',
                name: 'keyTextFigures1',
                register,
                watch,
                errors
              },
              {
                label: 'Key figure 2',
                name: 'keyFigures2',
                type: 'number',
                rules: {
                  maxLength: {
                    value: NEWS_KEY_FIGURE_MAX_LENGTH,
                    message: `Maximum length for Key figure is ${NEWS_KEY_FIGURE_MAX_LENGTH}`
                  }
                },
                register,
                watch,
                errors
              },
              {
                name: 'keyTextFigures2',
                label: 'Text key figures 2',
                register,
                watch,
                errors
              },
              {
                label: 'Key figure 3',
                name: 'keyFigures3',
                type: 'number',
                rules: {
                  maxLength: {
                    value: NEWS_KEY_FIGURE_MAX_LENGTH,
                    message: `Maximum length for Key figure is ${NEWS_KEY_FIGURE_MAX_LENGTH}`
                  }
                },
                watch,
                register,
                errors
              },
              {
                name: 'keyTextFigures3',
                label: 'Text ey figures 3',
                register,
                watch,
                errors
              }
            ]}
          />
          <FormRichText<NewsEdit>
            errors={errors}
            name='contentUnderKeyFigures'
            label='Content'
            initialContent={getValues('contentUnderKeyFigures')}
            onUpdate={val => setValue('contentUnderKeyFigures', val)}
          />
        </>
      )}
      <FormTagsInput<NewsEdit>
        name='tags'
        label='Tags'
        errors={errors}
        onUpdate={val => setValue('tags', val)}
        tags={watch('tags')}
        maxNumberOfTags={4}
      />

      <Divider />
      <FormCheckbox<NewsEdit>
        errors={errors}
        register={register}
        name='spotlight'
        watch={!!watch('spotlight')}
        label='Spotlight (homepage)'
      />
      <FormCheckbox<NewsEdit>
        errors={errors}
        register={register}
        name='highlight'
        watch={!!watch('highlight')}
        label='Highlight (homepage)'
      />
    </DefaultFormWrapper>
  )
}

export default ManageNews
