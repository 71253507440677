import { Flex } from '@chakra-ui/react'
import Image from 'next/image'

interface Props {
  imageUrl?: string
}

const CompanyLogo = ({ imageUrl }: Props) => {
  return (
    <Flex mt={[4, 0]} pt={4} justifyContent='center' placeItems='center'>
      {imageUrl && (
        <Image src={imageUrl} width={212} height={133} objectFit='contain' alt='company logo' />
      )}
    </Flex>
  )
}

export default CompanyLogo
