import { useState } from 'react'
import { getQueryFiltersString } from '@/utils/dataHelpers/filtersHelpers'
import { FilterItem } from '@/models/EudonetModels'

export default function useQueryFilters(
  initialValue: string
): [
  string,
  (selectedFilters: FilterItem[], item: FilterItem) => void,
  (selectedFilters: FilterItem[], item: FilterItem) => void
] {
  const [value, setValue] = useState(initialValue)

  const addFilter = (selectedFilters: FilterItem[], item: FilterItem) => {
    if (selectedFilters.some(temp => temp.Id === item.Id)) {
      return
    }
    const queryFiltersString = getQueryFiltersString([...selectedFilters, item])
    setValue(queryFiltersString)
  }

  const removeFilter = (selectedFilters: FilterItem[], item: FilterItem) => {
    const queryFiltersString = getQueryFiltersString(
      selectedFilters.filter(filter => item.Id !== filter.Id)
    )
    setValue(queryFiltersString)
  }

  return [value, addFilter, removeFilter]
}
