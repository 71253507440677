import { ignoreEnter } from '@/utils/ignoreEnter'
import { Heading, useTheme, VStack } from '@chakra-ui/react'
import type { ReactNode } from 'react'
import { UseFormHandleSubmit } from 'react-hook-form'
import FormBottomButtons from './FormBottomButtons'

interface Props {
  handleSubmit: UseFormHandleSubmit<any>
  onSubmit: any
  title: string
  loading: boolean
  submitButtonText?: string
  children: ReactNode
}

const DefaultFormWrapper = ({
  handleSubmit,
  onSubmit,
  title,
  loading,
  submitButtonText,
  children
}: Props) => {
  const theme = useTheme()

  return (
    <VStack w='full'>
      <VStack
        w={{ base: 'full', lg: '1200px' }}
        maxW={{ base: 'full', lg: '1200px' }}
        spacing={5}
        align='start'>
        <Heading>{title}</Heading>
        <form
          onSubmit={handleSubmit(onSubmit, err => console.error(err))}
          style={{ width: '100%' }}
          onKeyDown={e => ignoreEnter(e)}>
          <VStack spacing={8} background={theme.colors.white} padding={6} w='full'>
            {children}
            <FormBottomButtons loading={loading} submitButtonText={submitButtonText} />
          </VStack>
        </form>
      </VStack>
    </VStack>
  )
}

export default DefaultFormWrapper
