import { FRONT_COMPANIES_PAGINATED } from '@/constants/Routes'
import useDebounce from '@/hooks/useDebounce'
import { PublicCompany } from '@/models/company/PublicCompany'
import baseFetcher from '@/utils/connectionHelpers/swrFetchers'
import { FormControl, FormLabel, Input, Select, Text, VStack } from '@chakra-ui/react'
import { AxiosError } from 'axios'
import { useState } from 'react'
import { FieldValues, Path, RegisterOptions, UseFormRegister } from 'react-hook-form'
import useSWR from 'swr'

interface Props<TFormValues extends FieldValues> {
  name: Path<TFormValues>
  label: string
  rules?: RegisterOptions
  register: UseFormRegister<TFormValues>
  errors?: any
  placeholder: string
  showUnpublished?: boolean
}

const FormLinkCompany = <TFormValues extends FieldValues>({
  name,
  label,
  rules,
  register,
  errors,
  placeholder,
  showUnpublished = false
}: Props<TFormValues>) => {
  const [search, setSearch] = useState<string>('')
  const queryText = useDebounce(search, 300)
  const errorMessages = errors[name]
  const hasError = !!(errors && errorMessages)

  const { data: companies } = useSWR<{ data: PublicCompany[]; items: number }, AxiosError>(
    FRONT_COMPANIES_PAGINATED(false, '', queryText, false, '', !showUnpublished),
    baseFetcher,
    { revalidateOnFocus: false }
  )

  return (
    <FormControl isInvalid={hasError} isRequired={!!rules?.required}>
      <VStack w='full' align='start' spacing={2}>
        <FormLabel fontSize='xs'>{label.toUpperCase()}</FormLabel>
        <Input w='full' placeholder={placeholder} onChange={e => setSearch(e.target.value)} />
        {companies && companies.data ? (
          <Select placeholder='Select one company' {...register(name, rules)}>
            {companies.data.map(item => (
              <option key={item.companyId} value={item.companyId}>
                {item.signage} - ({item.category ?? 'no categories'})
              </option>
            ))}
          </Select>
        ) : (
          <Text pt={2}>Loading companies...</Text>
        )}
      </VStack>
    </FormControl>
  )
}

export default FormLinkCompany
