import { SearchForm } from '@/components/search/components/SearchForm'
import SearchContainer from '../../components/search/SearchContainer'

export const handleSearchModule = (id: string) => {
  switch (id) {
    case 'search_results_eudonet_events':
      return <SearchContainer />
    case 'navbar_search':
      return <SearchForm />
    default:
      return <></>
  }
}
