import { createRef, useEffect } from 'react'
import { ParkingLayoutProps } from './SingleParkingMap'

export const ParkingH = (props: ParkingLayoutProps) => {
  const ref = createRef<any>()
  useEffect(() => {
    props.available.forEach(available => {
      const spot = ref.current && ref.current.parentNode.getElementById(`space_${available}`)
      if (!spot) {
        return
      }
      spot.classList.add('spot-available')
    })
  }, [ref, props.available])
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 275 568'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'>
      <desc>Created with Sketch.</desc>
      <g id='ParkingH' stroke='none' strokeWidth='1' fill='none' ref={ref} fillRule='evenodd'>
        <g id='legends'>
          <path
            d='M272.5,468.999598 L221.246505,468.999598 L0.5,468.999598 L0.5,0.999597669 L272.5,0.999597669 L272.5,468.999598 Z'
            id='Rectangle'
            stroke='#353535'
            fill='#FFFFFF'
          />
          <path
            d='M272.415498,0.554179788 L272.415498,469.164313 L0.5,468.9999 L0.5,336.999598 L90.4806989,336.999598 L90.4806989,379.621064 L132.514788,379.621064 L132.514788,454.097412 L218.074045,454.097412 L218.074045,425.365654 L145.011818,425.365654 L145.011818,415.991107 L240.532751,415.991107 L240.532751,300.04888 L142.425123,300.04888 L142.425123,336.458253 L133.514788,336.458253 L133.514788,251.52345 L142.425123,251.52345 L142.425123,288.048903 L240.532751,288.048903 L240.532751,172.041957 L142.425123,172.041957 L142.425123,208.522386 L133.514788,208.522386 L133.514788,122.530322 L142.425123,122.530322 L142.425123,159.011989 L240.532751,159.011989 L240.532751,43.8905032 L142.425123,43.8905032 L142.425123,80.4935731 L133.514788,80.4935731 L133.514788,0.554179788 L272.415498,0.554179788 Z'
            id='Path-4'
            stroke='#E6E6E6'
            fill='#F8F8F8'
          />
          <rect
            id='Rectangle'
            stroke='#E6E6E6'
            fill='#F8F8F8'
            x='40.5'
            y='122.999598'
            width='50'
            height='168'
          />
          <rect
            id='Rectangle'
            stroke='#E6E6E6'
            fill='#F8F8F8'
            x='40.5'
            y='0.999597669'
            width='50'
            height='79'
          />
          <rect id='Rectangle' fill='#4E4E4E' x='0' y='0.499597669' width='43' height='435' />
          <polygon
            id='Path-2-Copy-2'
            fill='#D8D8D8'
            transform='translate(119.532894, 313.716323) rotate(-180.000000) translate(-119.532894, -313.716323) '
            points='118.69178 292.499598 120.436352 292.499598 120.436352 318.953738 123.065788 318.953738 119.664114 334.933049 116 318.983156 118.69178 318.953738'
          />
          <polygon
            id='Path-2'
            fill='#D8D8D8'
            points='102.69178 293.499598 104.436352 293.499598 104.436352 319.953738 107.065788 319.953738 103.664114 335.933049 100 319.983156 102.69178 319.953738'
          />
          <polygon
            id='Path-2-Copy-2'
            fill='#D8D8D8'
            transform='translate(119.532894, 100.716323) rotate(-180.000000) translate(-119.532894, -100.716323) '
            points='118.69178 79.4995977 120.436352 79.4995977 120.436352 105.953738 123.065788 105.953738 119.664114 121.933049 116 105.983156 118.69178 105.953738'
          />
          <polygon
            id='Path-2'
            fill='#D8D8D8'
            points='102.69178 80.4995977 104.436352 80.4995977 104.436352 106.953738 107.065788 106.953738 103.664114 122.933049 100 106.983156 102.69178 106.953738'
          />
          <circle id='Oval' stroke='#9DD135' cx='239.5' cy='19.9995977' r='15.5' />
          <path
            d='M167.000402,-6.46602883 L167.000402,24.4660288 C166.669614,24.4885559 166.335897,24.5 165.999598,24.5 C161.855577,24.5 158.103042,22.7637539 155.387055,19.9541128 C152.6771,17.1507108 150.999598,13.2782173 150.999598,9 C150.999598,4.72178269 152.6771,0.849289161 155.387055,-1.95411277 C158.103042,-4.76375395 161.855577,-6.5 165.999598,-6.5 C166.335897,-6.5 166.669614,-6.48855592 167.000402,-6.46602883 Z'
            id='Combined-Shape'
            stroke='#9DD135'
            transform='translate(159.000000, 9.000000) rotate(270.000000) translate(-159.000000, -9.000000) '
          />
          <path
            d='M75.0004023,-6.46602883 L75.0004023,24.4660288 C74.6696136,24.4885559 74.3358974,24.5 73.9995977,24.5 C69.8555768,24.5 66.1030416,22.7637539 63.3870552,19.9541128 C60.6771,17.1507108 58.9995977,13.2782173 58.9995977,9 C58.9995977,4.72178269 60.6771,0.849289161 63.3870552,-1.95411277 C66.1030416,-4.76375395 69.8555768,-6.5 73.9995977,-6.5 C74.3358974,-6.5 74.6696136,-6.48855592 75.0004023,-6.46602883 Z'
            id='Combined-Shape'
            stroke='#9DD135'
            transform='translate(67.000000, 9.000000) rotate(270.000000) translate(-67.000000, -9.000000) '
          />
          <circle id='Oval' stroke='#9DD135' cx='256' cy='63.4995977' r='12' />
          <circle id='Oval' stroke='#9DD135' cx='247' cy='439.499598' r='12' />
          <path
            d='M272.5,468.5 L221.246505,468.5 L0.5,468.5 L0.5,0.5 L272.5,0.5 L272.5,468.5 Z'
            id='Rectangle'
            stroke='#353535'
          />
          <line
            x1='147'
            y1='441.05418'
            x2='147'
            y2='453.05418'
            id='Line'
            stroke='#979797'
            strokeLinecap='square'
          />
          <line
            x1='154'
            y1='441.05418'
            x2='154'
            y2='453.05418'
            id='Line'
            stroke='#979797'
            strokeLinecap='square'
          />
          <line
            x1='161'
            y1='441.05418'
            x2='161'
            y2='453.05418'
            id='Line'
            stroke='#979797'
            strokeLinecap='square'
          />
          <line
            x1='168'
            y1='441.05418'
            x2='168'
            y2='453.05418'
            id='Line'
            stroke='#979797'
            strokeLinecap='square'
          />
          <line
            x1='140'
            y1='441.05418'
            x2='140'
            y2='453.05418'
            id='Line'
            stroke='#979797'
            strokeLinecap='square'
          />
          <line
            x1='175'
            y1='441.05418'
            x2='175'
            y2='453.05418'
            id='Line'
            stroke='#979797'
            strokeLinecap='square'
          />
          <line
            x1='182'
            y1='441.05418'
            x2='182'
            y2='453.05418'
            id='Line'
            stroke='#979797'
            strokeLinecap='square'
          />
          <line
            x1='189'
            y1='441.05418'
            x2='189'
            y2='453.05418'
            id='Line'
            stroke='#979797'
            strokeLinecap='square'
          />
          <line
            x1='196'
            y1='441.05418'
            x2='196'
            y2='453.05418'
            id='Line'
            stroke='#979797'
            strokeLinecap='square'
          />
          <line
            x1='203'
            y1='441.05418'
            x2='203'
            y2='453.05418'
            id='Line'
            stroke='#979797'
            strokeLinecap='square'
          />
          <line
            x1='210'
            y1='441.05418'
            x2='210'
            y2='453.05418'
            id='Line'
            stroke='#979797'
            strokeLinecap='square'
          />
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_133'
          transform='translate(230.000000, 63.500000) rotate(-90.000000) translate(-230.000000, -63.500000) translate(213.000000, 55.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='133'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              133
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_132'
          transform='translate(211.000000, 63.500000) rotate(-90.000000) translate(-211.000000, -63.500000) translate(194.000000, 55.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='132'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              132
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_131'
          transform='translate(192.000000, 63.500000) rotate(-90.000000) translate(-192.000000, -63.500000) translate(175.000000, 55.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='131'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              131
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_130'
          transform='translate(173.000000, 63.500000) rotate(-90.000000) translate(-173.000000, -63.500000) translate(156.000000, 55.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='130'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              130
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_129'
          transform='translate(154.000000, 63.500000) rotate(-90.000000) translate(-154.000000, -63.500000) translate(137.000000, 55.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='129'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              129
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_134'
          transform='translate(230.000000, 139.500000) rotate(-90.000000) translate(-230.000000, -139.500000) translate(213.000000, 131.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='134'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              134
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_135'
          transform='translate(211.000000, 139.500000) rotate(-90.000000) translate(-211.000000, -139.500000) translate(194.000000, 131.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='135'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              135
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_136'
          transform='translate(192.000000, 139.500000) rotate(-90.000000) translate(-192.000000, -139.500000) translate(175.000000, 131.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='136'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              136
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_137'
          transform='translate(173.000000, 139.500000) rotate(-90.000000) translate(-173.000000, -139.500000) translate(156.000000, 131.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='137'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              137
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_138'
          transform='translate(154.000000, 139.500000) rotate(-90.000000) translate(-154.000000, -139.500000) translate(137.000000, 131.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
          <text
            id='138'
            transform='translate(17.000000, 8.500000) rotate(90.000000) translate(-17.000000, -8.500000) '
            fontFamily='ArialMT, Arial'
            fontSize='8'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='10.3261719' y='9.5'>
              138
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_off'
          transform='translate(230.000000, 191.500000) rotate(-90.000000) translate(-230.000000, -191.500000) translate(213.000000, 183.000000)'
          fill='#DADADA'
          stroke='#D3D3D3'>
          <polygon
            id='Rectangle'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_off'
          transform='translate(211.000000, 191.500000) rotate(-90.000000) translate(-211.000000, -191.500000) translate(194.000000, 183.000000)'
          fill='#DADADA'
          stroke='#D3D3D3'>
          <polygon
            id='Rectangle'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_off'
          transform='translate(192.000000, 191.500000) rotate(-90.000000) translate(-192.000000, -191.500000) translate(175.000000, 183.000000)'
          fill='#DADADA'
          stroke='#D3D3D3'>
          <polygon
            id='Rectangle'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_off'
          transform='translate(173.000000, 191.500000) rotate(-90.000000) translate(-173.000000, -191.500000) translate(156.000000, 183.000000)'
          fill='#DADADA'
          stroke='#D3D3D3'>
          <polygon
            id='Rectangle'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_off'
          transform='translate(154.000000, 191.500000) rotate(-90.000000) translate(-154.000000, -191.500000) translate(137.000000, 183.000000)'
          fill='#DADADA'
          stroke='#D3D3D3'>
          <polygon
            id='Rectangle'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_off'
          transform='translate(230.000000, 268.500000) rotate(-90.000000) translate(-230.000000, -268.500000) translate(213.000000, 260.000000)'
          fill='#DADADA'
          stroke='#D3D3D3'>
          <polygon
            id='Rectangle'
            points='4.68958206e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.3500312e-13 17'
          />
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_off'
          transform='translate(211.000000, 268.500000) rotate(-90.000000) translate(-211.000000, -268.500000) translate(194.000000, 260.000000)'
          fill='#DADADA'
          stroke='#D3D3D3'>
          <polygon
            id='Rectangle'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_off'
          transform='translate(192.000000, 268.500000) rotate(-90.000000) translate(-192.000000, -268.500000) translate(175.000000, 260.000000)'
          fill='#DADADA'
          stroke='#D3D3D3'>
          <polygon
            id='Rectangle'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_off'
          transform='translate(173.000000, 268.500000) rotate(-90.000000) translate(-173.000000, -268.500000) translate(156.000000, 260.000000)'
          fill='#DADADA'
          stroke='#D3D3D3'>
          <polygon
            id='Rectangle'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_off'
          transform='translate(145.000000, 251.000000)'>
          <g
            onClick={props.onSpotClick}
            className='spot'
            id='space_18'
            transform='translate(9.000000, 17.500000) rotate(-90.000000) translate(-9.000000, -17.500000) translate(-8.000000, 9.000000)'
            fill='#DADADA'
            stroke='#D3D3D3'>
            <polygon
              id='Rectangle'
              points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
            />
          </g>
          <path
            d='M12.3381955,20.4504391 L13.3610988,19.9627316 C13.4847713,19.9044529 13.5352626,19.7620727 13.4738765,19.6446898 L13.2515713,19.2196058 C13.1901852,19.1022229 13.0401608,19.0542994 12.9164883,19.1125636 L12.351196,19.3846805 L11.4525296,17.5693369 C11.3780057,17.4187884 11.1752622,17.2965827 11,17.2965827 L8.93365469,17.2965827 L8.86221304,16.8220111 L10.7499998,16.8220111 C10.8880765,16.8220111 11,16.7157799 11,16.5847253 L11,16.1101537 C11,15.979099 10.8880765,15.8728678 10.7499998,15.8728678 L8.71936027,15.8728678 L8.64639274,15.3880569 C9.13789356,15.3196113 9.51319871,14.9119211 9.49963365,14.4235184 C9.48593126,13.9297137 9.06755055,13.5239642 8.54768364,13.5010524 C7.97372433,13.475751 7.5,13.9099302 7.5,14.4491385 C7.50593567,14.5387724 7.47550965,14.3202273 8.00502011,17.8382531 C8.04022213,18.0720485 8.25117488,18.2457115 8.5,18.2457115 L10.6824796,18.2457115 L11.6633756,20.2271726 C11.7826383,20.4681516 12.0874325,20.5685896 12.3381955,20.4504391 Z M8.11042117,21.5 C9.17639433,21.5 10.0950186,20.8621651 10.5,19.9507259 L10.1180486,19.143519 L9.75456473,19.143519 C9.6390668,19.9418373 8.94581852,20.5574076 8.11042117,20.5574076 C7.19444758,20.5574076 6.44924406,19.8174259 6.44924406,18.9078709 C6.44924406,18.2965867 6.78584506,17.7619054 7.28464141,17.476953 C7.22958653,17.0945426 7.18313533,16.7720798 7.14391181,16.5 C6.18161738,16.8822378 5.5,17.8174518 5.5,18.9078709 C5.5,20.3371779 6.67102789,21.5 8.11042117,21.5 Z'
            id='w'
            fill='#FFFFFF'
            fillRule='nonzero'
          />
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_off'
          transform='translate(230.000000, 319.500000) rotate(-90.000000) translate(-230.000000, -319.500000) translate(213.000000, 311.000000)'
          fill='#DADADA'
          stroke='#D3D3D3'>
          <polygon
            id='Rectangle'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_off'
          transform='translate(211.000000, 319.500000) rotate(-90.000000) translate(-211.000000, -319.500000) translate(194.000000, 311.000000)'
          fill='#DADADA'
          stroke='#D3D3D3'>
          <polygon
            id='Rectangle'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_off'
          transform='translate(192.000000, 319.500000) rotate(-90.000000) translate(-192.000000, -319.500000) translate(175.000000, 311.000000)'
          fill='#DADADA'
          stroke='#D3D3D3'>
          <polygon
            id='Rectangle'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_off'
          transform='translate(173.000000, 319.500000) rotate(-90.000000) translate(-173.000000, -319.500000) translate(156.000000, 311.000000)'
          fill='#DADADA'
          stroke='#D3D3D3'>
          <polygon
            id='Rectangle'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_off'
          transform='translate(145.000000, 302.000000)'>
          <g
            onClick={props.onSpotClick}
            className='spot'
            id='space_18'
            transform='translate(9.000000, 17.500000) rotate(-90.000000) translate(-9.000000, -17.500000) translate(-8.000000, 9.000000)'
            fill='#DADADA'
            stroke='#D3D3D3'>
            <polygon
              id='Rectangle'
              points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
            />
          </g>
          <path
            d='M12.3381955,20.4504391 L13.3610988,19.9627316 C13.4847713,19.9044529 13.5352626,19.7620727 13.4738765,19.6446898 L13.2515713,19.2196058 C13.1901852,19.1022229 13.0401608,19.0542994 12.9164883,19.1125636 L12.351196,19.3846805 L11.4525296,17.5693369 C11.3780057,17.4187884 11.1752622,17.2965827 11,17.2965827 L8.93365469,17.2965827 L8.86221304,16.8220111 L10.7499998,16.8220111 C10.8880765,16.8220111 11,16.7157799 11,16.5847253 L11,16.1101537 C11,15.979099 10.8880765,15.8728678 10.7499998,15.8728678 L8.71936027,15.8728678 L8.64639274,15.3880569 C9.13789356,15.3196113 9.51319871,14.9119211 9.49963365,14.4235184 C9.48593126,13.9297137 9.06755055,13.5239642 8.54768364,13.5010524 C7.97372433,13.475751 7.5,13.9099302 7.5,14.4491385 C7.50593567,14.5387724 7.47550965,14.3202273 8.00502011,17.8382531 C8.04022213,18.0720485 8.25117488,18.2457115 8.5,18.2457115 L10.6824796,18.2457115 L11.6633756,20.2271726 C11.7826383,20.4681516 12.0874325,20.5685896 12.3381955,20.4504391 Z M8.11042117,21.5 C9.17639433,21.5 10.0950186,20.8621651 10.5,19.9507259 L10.1180486,19.143519 L9.75456473,19.143519 C9.6390668,19.9418373 8.94581852,20.5574076 8.11042117,20.5574076 C7.19444758,20.5574076 6.44924406,19.8174259 6.44924406,18.9078709 C6.44924406,18.2965867 6.78584506,17.7619054 7.28464141,17.476953 C7.22958653,17.0945426 7.18313533,16.7720798 7.14391181,16.5 C6.18161738,16.8822378 5.5,17.8174518 5.5,18.9078709 C5.5,20.3371779 6.67102789,21.5 8.11042117,21.5 Z'
            id='w'
            fill='#FFFFFF'
            fillRule='nonzero'
          />
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_off'
          transform='translate(230.000000, 396.500000) rotate(-90.000000) translate(-230.000000, -396.500000) translate(213.000000, 388.000000)'
          fill='#DADADA'
          stroke='#D3D3D3'>
          <polygon
            id='Rectangle'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_off'
          transform='translate(211.000000, 396.500000) rotate(-90.000000) translate(-211.000000, -396.500000) translate(194.000000, 388.000000)'
          fill='#DADADA'
          stroke='#D3D3D3'>
          <polygon
            id='Rectangle'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_off'
          transform='translate(192.000000, 396.500000) rotate(-90.000000) translate(-192.000000, -396.500000) translate(175.000000, 388.000000)'
          fill='#DADADA'
          stroke='#D3D3D3'>
          <polygon
            id='Rectangle'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_off'
          transform='translate(173.000000, 396.500000) rotate(-90.000000) translate(-173.000000, -396.500000) translate(156.000000, 388.000000)'
          fill='#DADADA'
          stroke='#D3D3D3'>
          <polygon
            id='Rectangle'
            points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
          />
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_off'
          transform='translate(145.000000, 379.000000)'>
          <g
            onClick={props.onSpotClick}
            className='spot'
            id='space_18'
            transform='translate(9.000000, 17.500000) rotate(-90.000000) translate(-9.000000, -17.500000) translate(-8.000000, 9.000000)'
            fill='#DADADA'
            stroke='#D3D3D3'>
            <polygon
              id='Rectangle'
              points='4.8316906e-13 3.23213678e-14 34 -1.41553436e-14 34 17 -1.20792265e-13 17'
            />
          </g>
          <path
            d='M12.3381955,20.4504391 L13.3610988,19.9627316 C13.4847713,19.9044529 13.5352626,19.7620727 13.4738765,19.6446898 L13.2515713,19.2196058 C13.1901852,19.1022229 13.0401608,19.0542994 12.9164883,19.1125636 L12.351196,19.3846805 L11.4525296,17.5693369 C11.3780057,17.4187884 11.1752622,17.2965827 11,17.2965827 L8.93365469,17.2965827 L8.86221304,16.8220111 L10.7499998,16.8220111 C10.8880765,16.8220111 11,16.7157799 11,16.5847253 L11,16.1101537 C11,15.979099 10.8880765,15.8728678 10.7499998,15.8728678 L8.71936027,15.8728678 L8.64639274,15.3880569 C9.13789356,15.3196113 9.51319871,14.9119211 9.49963365,14.4235184 C9.48593126,13.9297137 9.06755055,13.5239642 8.54768364,13.5010524 C7.97372433,13.475751 7.5,13.9099302 7.5,14.4491385 C7.50593567,14.5387724 7.47550965,14.3202273 8.00502011,17.8382531 C8.04022213,18.0720485 8.25117488,18.2457115 8.5,18.2457115 L10.6824796,18.2457115 L11.6633756,20.2271726 C11.7826383,20.4681516 12.0874325,20.5685896 12.3381955,20.4504391 Z M8.11042117,21.5 C9.17639433,21.5 10.0950186,20.8621651 10.5,19.9507259 L10.1180486,19.143519 L9.75456473,19.143519 C9.6390668,19.9418373 8.94581852,20.5574076 8.11042117,20.5574076 C7.19444758,20.5574076 6.44924406,19.8174259 6.44924406,18.9078709 C6.44924406,18.2965867 6.78584506,17.7619054 7.28464141,17.476953 C7.22958653,17.0945426 7.18313533,16.7720798 7.14391181,16.5 C6.18161738,16.8822378 5.5,17.8174518 5.5,18.9078709 C5.5,20.3371779 6.67102789,21.5 8.11042117,21.5 Z'
            id='w'
            fill='#FFFFFF'
            fillRule='nonzero'
          />
        </g>
        <text
          id='H'
          fontFamily='LucidaGrande, Lucida Grande'
          fontSize='22'
          fontWeight='normal'
          fill='#FFFFFF'>
          <tspan x='13.4165039' y='221'>
            H
          </tspan>
        </text>
      </g>
    </svg>
  )
}
