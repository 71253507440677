import { extendTheme } from '@chakra-ui/react'

import { colors } from './portal/colors'
import { components } from './portal/components'
import { fonts } from './portal/fonts'
import { global } from './portal/global'

const PortalTheme = extendTheme({
  styles: {
    global
  },
  colors,
  fonts,
  components
})

export default PortalTheme
