import { BookARoom } from '@/components/minsh/BookARoom'
import { Heading } from '@chakra-ui/react'
import ManageCompaniesLogo from '../../components/companies/ManageCompaniesLogo'
import ManageCompany from '../../components/companies/ManageCompany'
import ManageEvents from '../../components/events/ManageEvents'
import ManageJobs from '../../components/jobs/ManageJobs'
import Login from '../../components/login/Login'
import ManageNews from '../../components/news/ManageNews'
import CollaboratorsList from '../../components/portal/CollaboratorsList'
import ManageCollaborator from '../../components/portal/ManageCollaborator'
import PortalHub from '../../components/portal/PortalHub'
import { logSentryException } from '../loggingHelpers/sentryHelper'

export const handlePortalsModule = (id: string) => {
  switch (id) {
    case 'portal_login':
      return <Login />
    case 'portal_main_content':
      return <PortalHub />
    case 'portal_company_collaborators':
      return <CollaboratorsList />
    case 'portal_new_collaborator':
      return <ManageCollaborator />
    case 'portal_company_jobs':
      return <ManageJobs />
    case 'portal_company_edit':
      return <ManageCompany />
    //Admin section of the portal
    case 'portal_manage_news':
      return <ManageNews />
    case 'portal_manage_events':
      return <ManageEvents />
    case 'portal_manage_logo':
      return <ManageCompaniesLogo />
    case 'portal_company_bookaroom':
      return (
        <>
          <Heading id='book-a-room-title'>Book a room</Heading>
          <BookARoom />
        </>
      )
    default:
      logSentryException('handlePortalsModule no supported module', 'MODULE', id)
  }
}
