import MailchimpSubscribe from 'react-mailchimp-subscribe'
import { useForm } from 'react-hook-form'
import { emailValidator } from '@/utils/dataHelpers/validators'
import { ROUTE_MAILCHIMP_URL } from '@/constants/Routes'

type FormData = {
  email: string
}

const Newsletter = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>()

  return (
    <div className='footer-form-block'>
      <MailchimpSubscribe
        url={ROUTE_MAILCHIMP_URL}
        render={({ subscribe, status }) => (
          <div>
            <form
              className='footer-form margin-bottom-2x'
              aria-label='Email Form'
              onSubmit={handleSubmit(async data => {
                subscribe({ EMAIL: data.email })
              })}>
              <input
                className='footer-text-field w-input'
                placeholder='email address'
                {...register('email', {
                  required: true,
                  pattern: {
                    value: emailValidator,
                    message: 'Please insert a valid email'
                  }
                })}
              />
              <input type='submit' value='Subscribe' className='footer-button w-button' />
            </form>
            {status === 'sending' && (
              <div className='footer-follow-text'>Subscription in progress</div>
            )}
            {status === 'success' && (
              <div className='footer-follow-text'>
                Thank you! Your submission has been received!
              </div>
            )}
            {status === 'error' && (
              <div className='footer-follow-text'>
                Oops! Something went wrong while submitting the form.
              </div>
            )}
            {errors?.email?.message && (
              <div className='footer-follow-text'>{errors.email.message}</div>
            )}
          </div>
        )}
      />
    </div>
  )
}

export default Newsletter
