interface Props {
  image?: string
  imageCredit?: string
}

const DisplayImageAndCredit = ({ image, imageCredit }: Props) => {
  return (
    <>
      {image && (
        // eslint-disable-next-line @next/next/no-img-element
        <img
          src={image}
          loading='lazy'
          sizes='(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 97vw, (max-width: 1919px) 52vw, 957.328125px'
          srcSet={`${image} 500w, ${image} 620w`}
          alt=''
          className={`img ${!imageCredit ? 'margin-bottom-2x' : ''}`}
        />
      )}
      {imageCredit && <div className='text small color-light'>© {imageCredit}</div>}
    </>
  )
}

export default DisplayImageAndCredit
