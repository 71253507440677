// noinspection JSUnusedGlobalSymbols

import { createFilterListFromEnum } from '@/utils/dataHelpers/filtersHelpers'

export const EVENT_FIRST_PARAGRAPH = 9402
export const EVENT_CREATED_BY = 9403
export const EVENT_LINK_TO_JOIN = 9404
export const EVENT_CONTENT = 9405
export const EVENT_MOTIVATION_ITEMS = 9406
export const EVENT_PUBLISHED = 9407
export const EVENT_TITLE = 9408
export const EVENT_SUBTITLE = 9409
export const EVENT_START_DATE = 9410
export const EVENT_END_DATE = 9411
export const EVENT_START_TIME = 9412
export const EVENT_END_TIME = 9413
export const EVENT_LOCATION_FILTER = 9414
export const EVENT_LOCATION = 9415
export const EVENT_GOOGLE_MAP_LINK = 9416
export const EVENT_IMAGE = 9417
export const EVENT_IMAGE_CREDIT = 9418
export const EVENT_TAGS = 9419
export const EVENT_SPOTLIGHT = 9420
export const EVENT_CATEGORY_FILTER = 9421
export const EVENT_CATEGORY_HIDDEN = 9422
export const EVENT_REF_ID = 9424
export const EVENT_ORGANIZER_NAME = 9426
export const EVENT_ORGANIZER_PHONE = 9427
export const EVENT_ORGANIZER_EMAIL = 9428
export const EVENT_ORGANIZER_WEB_SITE = 9429

export const AGENDA_DATE = 9701
export const AGENDA_START_TIME = 9702
export const AGENDA_END_TIME = 9703
export const AGENDA_DESCRIPTION = 9704

export enum EVENT_CATEGORY_FILTER_VALUES {
  Webinar = 5554,
  Training = 5555,
  Sustainability = 5556,
  Social = 5557,
  Partnering = 5558,
  Networking = 5559,
  International = 5560,
  Fundraising = 5561,
  Conference = 5562,
  'Business development' = 5563,
  'Award and competition' = 5564
}

export enum EVENT_MEMBER_CATEGORY_FILTER_VALUES {
  Workshop = 25615,
  Visit = 25616,
  Presentation = 25617,
  Networking = 25618
}

export enum EVENT_LOCATION_FILTER_VALUES {
  Online = 5495,
  Vaud = 5496,
  Geneva = 5497,
  Fribourg = 5498,
  Neuchatel = 5499,
  Valais = 5500,
  Other = 5574
}

export enum EVENT_HIDDEN_CATEGORIES_VALUES {
  membersOnly = 15600
}

export enum EventHiddenCategories {
  membersOnly = 'Members only'
}

export const getAllEventCategoryFilters = () => {
  const filters = getEventCategoryFilters()
  return [...filters.filterList, ...filters.memberFilterList]
}

export const getEventCategoryFilters = () => {
  return {
    filterList: createFilterListFromEnum(
      EVENT_CATEGORY_FILTER_VALUES,
      'Categories',
      EVENT_CATEGORY_FILTER
    ),
    memberFilterList: createFilterListFromEnum(
      EVENT_MEMBER_CATEGORY_FILTER_VALUES,
      'Categories',
      EVENT_CATEGORY_FILTER
    )
  }
}

export const getEventLocationFilters = () => {
  return createFilterListFromEnum(EVENT_LOCATION_FILTER_VALUES, 'Location', EVENT_LOCATION_FILTER)
}
