import { FRONT_COMPANY_DETAILS } from '@/constants/Routes'
import { PublicCompany } from '@/models/company/PublicCompany'
import baseFetcher from '@/utils/connectionHelpers/swrFetchers'
import useSWR from 'swr'
import CompaniesCardImage from '../../companies/components/CompaniesCardImage'
import DetailsFetchingHandling from '../dataFetching/DetailsFetchingHandling'

interface Props {
  companyId: number
  pageType: 'company' | 'success-story'
}

const LinkedCompanyHeader = ({ companyId, pageType }: Props) => {
  const { data, error } = useSWR<PublicCompany>(FRONT_COMPANY_DETAILS(companyId), baseFetcher, {
    revalidateOnFocus: false
  })

  const getClassesForWrapper = () => {
    if (pageType === 'company') {
      return 'tag-list color-primary'
    }
    if (pageType === 'success-story') {
      return 'tag-list color-success-1'
    }
    return ''
  }

  const getClassForFilledTag = () => {
    if (pageType === 'company') {
      return 'tag fill-primary'
    }
    if (pageType === 'success-story') {
      return 'tag fill-success-1'
    }
    return ''
  }

  return (
    <>
      <DetailsFetchingHandling data={data} error={error} />
      {data && !error && (
        <div className='section-hero-detail wf-section anim-onload-1'>
          <div className='wrapper'>
            <div className='header-detail'>
              {data.logo && (
                <CompaniesCardImage imgSrc={data.logo} containerClassName='header-detail-logo' />
              )}
              <div>
                <h1 className='display-h2'>{data?.signage}</h1>
                <div className={getClassesForWrapper()}>
                  {data.mainSector && (
                    <div className={getClassForFilledTag()}>{data?.mainSector}</div>
                  )}
                  {data?.tags &&
                    data.tags.map(tag => (
                      <div className='tag outlined' key={tag}>
                        {tag}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default LinkedCompanyHeader
