import { FRONT_PARKING_LOT_LIST, FRONT_SPOTS_COMPANY } from '@/constants/Routes'
import { ParkingLot, ParkingType } from '@/models/parking/ParkingLot'
import { ParkingSpot, SPOT_ID_PREFIX } from '@/models/parking/ParkingSpot'
import {
  Container,
  Flex,
  GridItem,
  HStack,
  SimpleGrid,
  Spinner,
  Switch,
  Text,
  VStack,
  useDisclosure
} from '@chakra-ui/react'
import { AxiosError } from 'axios'
import dynamic from 'next/dynamic'
import { useState } from 'react'
import useSWR from 'swr'
import useErrorToaster from '../../hooks/useErrorToaster'
import userStore from '../../stores/userStore'
import baseFetcher from '../../utils/connectionHelpers/swrFetchers'
import SingleParkingMap from './parkingMaps/SingleParkingMap'
import ParkshareHeading from './shared/ParkshareHeading'
import ParkshareSubHeading from './shared/ParkshareSubHeading'

const SpotCalendarModal = dynamic(() => import('./manager/SpotCalendarModal'))

const ManagerContainer = () => {
  return <ManagerContainerContent />
}

const ManagerContainerContent = () => {
  const [selectedParkingId, setSelectedParkingId] = useState<number | undefined>(undefined)
  const [selectedSpot, setSelectedSpot] = useState<ParkingSpot | undefined>(undefined)
  const [filtered, setFiltered] = useState(true)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const company = userStore(state => state.company)

  const { data: parkingLots, error: parkingLotsError } = useSWR<ParkingLot[], AxiosError>(
    FRONT_PARKING_LOT_LIST,
    baseFetcher,
    { revalidateOnFocus: false }
  )
  const { data: companySpots, error: companySpotsError } = useSWR<ParkingSpot[], AxiosError>(
    FRONT_SPOTS_COMPANY,
    baseFetcher,
    { revalidateOnFocus: false }
  )
  useErrorToaster(parkingLotsError)
  useErrorToaster(companySpotsError)

  const filteredList = filtered
    ? parkingLots?.filter(item => {
        const comapniesType = companySpots?.map(item => item.parkingType)
        return comapniesType?.includes(item.type)
      })
    : parkingLots

  const onSpotClick = (type: ParkingType, spotId: string) => {
    if (!companySpots || !parkingLots) {
      return
    }
    const number = parseInt(spotId.replace(SPOT_ID_PREFIX, ''))

    const lot = parkingLots.find((lot: ParkingLot) => lot.type === type)
    const spot = companySpots.find(
      (spot: ParkingSpot) => spot.parkingType === type && spot.number === number
    )

    setSelectedSpot(spot)
    setSelectedParkingId(lot?.id)
    onOpen()
  }

  const getParkingSpotForParking = (type: ParkingType) => {
    if (!companySpots) {
      return []
    }

    return companySpots
      .filter((obj: ParkingSpot) => obj.parkingType === type)
      .map((obj: ParkingSpot) => obj.number)
  }

  const renderParkingLotMap = (lot: ParkingLot) => (
    <GridItem key={lot.id} colSpan={1} maxH='1100px' maxW='300px' alignItems='start'>
      <HStack w='full' justify='center'>
        <ParkshareSubHeading text={lot.name} />
      </HStack>
      <SingleParkingMap
        type={lot.type as ParkingType}
        onSpotClick={onSpotClick}
        available={getParkingSpotForParking(lot.type as ParkingType)}
      />
    </GridItem>
  )

  return (
    <Container maxW='container.xl'>
      <ParkshareHeading title='Manage your parking spot' />
      {(!parkingLots && !parkingLotsError) || (!companySpots && !companySpotsError) ? (
        <HStack justify='center' pt={20}>
          <Spinner size='xl' />
        </HStack>
      ) : (
        <VStack align='start'>
          <Flex gap={2}>
            <Text onClick={() => setFiltered(!filtered)} _hover={{ cursor: 'pointer' }}>
              Only parking lot where {company?.signage} has spots{' '}
            </Text>
            <Switch
              id='email-alerts'
              isChecked={filtered}
              onChange={() => setFiltered(!filtered)}
            />
          </Flex>
          {filteredList && filteredList.length === 0 && (
            <Text>Your company doesn't have any parking spot</Text>
          )}
          <SimpleGrid columns={[1, null, 2]} spacing={6} pt={6} w='full' justifyItems='center'>
            <GridItem colSpan={[1, null, 2]}></GridItem>
            {filteredList?.map(lot => renderParkingLotMap(lot))}
          </SimpleGrid>
          {isOpen && selectedParkingId && (
            <SpotCalendarModal
              spot={selectedSpot}
              visible={isOpen}
              onClose={onClose}
              parkingId={selectedParkingId}
            />
          )}
        </VStack>
      )}
    </Container>
  )
}

export default ManagerContainer
