import { createRef, useEffect } from 'react'
import { ParkingLayoutProps } from './SingleParkingMap'

export const ParkingD = (props: ParkingLayoutProps) => {
  const ref = createRef<any>()
  useEffect(() => {
    props.available.forEach(available => {
      const spot = ref.current && ref.current.parentNode.getElementById(`space_${available}`)
      if (!spot) {
        return
      }
      spot.classList.add('spot-available')
    })
  }, [ref, props.available])
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='-20 0 250 568'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'>
      <desc>Created with Sketch.</desc>
      <g id='ParkingD' stroke='none' strokeWidth='1' fill='none' ref={ref} fillRule='evenodd'>
        <g id='legends'>
          <path
            d='M196.5,0.5 L196.5,545.5 L159.654071,545.5 L0.5,545.5 L0.5,0.5 L196.5,0.5 Z'
            id='Rectangle'
            stroke='#353535'
            fill='#FFFFFF'
          />
          <path
            d='M134.469247,329.448534 L134.469247,545.5 L0.5,545.5 L0.5,0.5 L134.469247,0.5 L134.469247,233.680535 L171.001603,233.680535 L171.001603,242.619254 C171.001603,247.727888 168.93092,252.352888 165.583078,255.70073 C162.235237,259.048571 157.610237,261.119254 152.501603,261.119254 L35.9146313,261.119254 L35.9146313,303.119254 L152.501603,303.119254 C157.610237,303.119254 162.235237,305.189937 165.583078,308.537779 C168.93092,311.88562 171.001603,316.51062 171.001603,321.619254 L171.001603,329.448534 L134.469247,329.448534 Z'
            id='Path-4'
            stroke='#E6E6E6'
            fill='#F8F8F8'
          />
          <g id='Group' transform='translate(0.000000, 117.000000)' fill='#4E4E4E'>
            <rect id='Rectangle' x='0' y='0' width='59' height='187' />
            <rect id='Rectangle' x='0' y='138' width='80' height='54' />
          </g>
          <circle id='Oval' stroke='#9DD135' cx='115' cy='219' r='12' />
          <circle id='Oval' stroke='#9DD135' cx='115' cy='163' r='12' />
          <circle id='Oval' stroke='#9DD135' cx='21' cy='365' r='12' />
          <circle id='Oval' stroke='#9DD135' cx='49' cy='390' r='12' />
          <circle id='Oval' stroke='#9DD135' cx='21' cy='417' r='12' />
          <circle id='Oval' stroke='#9DD135' cx='80' cy='61' r='12' />
          <circle id='Oval' stroke='#9DD135' cx='115' cy='28' r='12' />
          <rect id='Rectangle' stroke='#353535' x='0.5' y='0.5' width='196' height='545' />
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_69'
          transform='translate(137.000000, 190.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 2.85188539e-14 34 -1.2490009e-14 34 15 -1.20792265e-13 15'
          />
          <text
            id='69'
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.4384766' y='10'>
              69
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_72'
          transform='translate(137.000000, 358.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 2.85188539e-14 34 -1.2490009e-14 34 15 -1.20792265e-13 15'
          />
          <text
            id='72'
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.4384766' y='10'>
              72
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_73'
          transform='translate(137.000000, 375.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 2.85188539e-14 34 -1.2490009e-14 34 15 -1.20792265e-13 15'
          />
          <text
            id='73'
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.4384766' y='10'>
              73
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_74'
          transform='translate(137.000000, 392.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 2.85188539e-14 34 -1.2490009e-14 34 15 -1.20792265e-13 15'
          />
          <text
            id='74'
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.4384766' y='10'>
              74
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_75'
          transform='translate(137.000000, 409.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 2.85188539e-14 34 -1.2490009e-14 34 15 -1.20792265e-13 15'
          />
          <text
            id='75'
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.4384766' y='10'>
              75
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_76'
          transform='translate(137.000000, 426.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 2.85188539e-14 34 -1.2490009e-14 34 15 -1.20792265e-13 15'
          />
          <text
            id='76'
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.4384766' y='10'>
              76
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_77'
          transform='translate(137.000000, 443.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 2.85188539e-14 34 -1.2490009e-14 34 15 -1.20792265e-13 15'
          />
          <text
            id='77'
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.4384766' y='10'>
              77
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_78'
          transform='translate(137.000000, 460.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 2.85188539e-14 34 -1.2490009e-14 34 15 -1.20792265e-13 15'
          />
          <text
            id='78'
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.4384766' y='10'>
              78
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_79'
          transform='translate(137.000000, 477.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 2.85188539e-14 34 -1.2490009e-14 34 15 -1.20792265e-13 15'
          />
          <text
            id='79'
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.4384766' y='10'>
              79
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_80'
          transform='translate(137.000000, 494.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 2.85188539e-14 34 -1.2490009e-14 34 15 -1.20792265e-13 15'
          />
          <text
            id='80'
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.4384766' y='10'>
              80
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_81'
          transform='translate(137.000000, 511.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 2.85188539e-14 34 -1.2490009e-14 34 15 -1.20792265e-13 15'
          />
          <text
            id='81'
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.4384766' y='10'>
              81
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_70'
          transform='translate(137.000000, 207.000000)'>
          <g
            onClick={props.onSpotClick}
            className='spot'
            id='space_18'
            fill='#DADADA'
            stroke='#D3D3D3'>
            <polygon
              id='Rectangle'
              points='4.8316906e-13 4.56301663e-14 34 -1.99840144e-14 34 24 -1.20792265e-13 24'
            />
          </g>
          <path
            d='M19.8381955,14.9504391 L20.8610988,14.4627316 C20.9847713,14.4044529 21.0352626,14.2620727 20.9738765,14.1446898 L20.7515713,13.7196058 C20.6901852,13.6022229 20.5401608,13.5542994 20.4164883,13.6125636 L19.851196,13.8846805 L18.9525296,12.0693369 C18.8780057,11.9187884 18.6752622,11.7965827 18.5,11.7965827 L16.4336547,11.7965827 L16.362213,11.3220111 L18.2499998,11.3220111 C18.3880765,11.3220111 18.5,11.2157799 18.5,11.0847253 L18.5,10.6101537 C18.5,10.479099 18.3880765,10.3728678 18.2499998,10.3728678 L16.2193603,10.3728678 L16.1463927,9.88805688 C16.6378936,9.81961128 17.0131987,9.41192111 16.9996337,8.92351843 C16.9859313,8.42971367 16.5675506,8.02396419 16.0476836,8.00105244 C15.4737243,7.97575103 15,8.4099302 15,8.94913846 C15.0059357,9.03877237 14.9755096,8.82022726 15.5050201,12.3382531 C15.5402221,12.5720485 15.7511749,12.7457115 16,12.7457115 L18.1824796,12.7457115 L19.1633756,14.7271726 C19.2826383,14.9681516 19.5874325,15.0685896 19.8381955,14.9504391 Z M15.6104212,16 C16.6763943,16 17.5950186,15.3621651 18,14.4507259 L17.6180486,13.643519 L17.2545647,13.643519 C17.1390668,14.4418373 16.4458185,15.0574076 15.6104212,15.0574076 C14.6944476,15.0574076 13.9492441,14.3174259 13.9492441,13.4078709 C13.9492441,12.7965867 14.2858451,12.2619054 14.7846414,11.976953 C14.7295865,11.5945426 14.6831353,11.2720798 14.6439118,11 C13.6816174,11.3822378 13,12.3174518 13,13.4078709 C13,14.8371779 14.1710279,16 15.6104212,16 Z'
            id='w'
            fill='#FFFFFF'
            fillRule='nonzero'
          />
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_68'
          transform='translate(137.000000, 173.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 5.69405634e-14 34 1.59317004e-14 34 15 -1.20792265e-13 15'
          />
          <text
            id='68'
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.4384766' y='10'>
              68
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_67'
          transform='translate(137.000000, 156.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 2.85188539e-14 34 -1.2490009e-14 34 15 -1.20792265e-13 15'
          />
          <text
            id='67'
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.4384766' y='10'>
              67
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_66'
          transform='translate(137.000000, 139.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 2.85188539e-14 34 -1.2490009e-14 34 15 -1.20792265e-13 15'
          />
          <text
            id='66'
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.4384766' y='10'>
              66
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_65'
          transform='translate(137.000000, 122.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 2.85188539e-14 34 -1.2490009e-14 34 15 -1.20792265e-13 15'
          />
          <text
            id='65'
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.4384766' y='10'>
              65
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_64'
          transform='translate(137.000000, 105.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 2.85188539e-14 34 -1.2490009e-14 34 15 -1.20792265e-13 15'
          />
          <text
            id='64'
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.4384766' y='10'>
              64
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_63'
          transform='translate(137.000000, 88.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 2.85188539e-14 34 -1.2490009e-14 34 15 -1.20792265e-13 15'
          />
          <text
            id='63'
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.4384766' y='10'>
              63
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_62'
          transform='translate(137.000000, 71.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 2.85188539e-14 34 -1.2490009e-14 34 15 -1.20792265e-13 15'
          />
          <text
            id='62'
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.4384766' y='10'>
              62
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_61'
          transform='translate(137.000000, 54.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 2.85188539e-14 34 -1.2490009e-14 34 15 -1.20792265e-13 15'
          />
          <text
            id='61'
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.4384766' y='10'>
              61
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_60'
          transform='translate(137.000000, 37.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 2.85188539e-14 34 -1.2490009e-14 34 15 -1.20792265e-13 15'
          />
          <text
            id='60'
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.4384766' y='10'>
              60
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_59'
          transform='translate(137.000000, 20.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 2.85188539e-14 34 -1.2490009e-14 34 15 -1.20792265e-13 15'
          />
          <text
            id='59'
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.4384766' y='10'>
              59
            </tspan>
          </text>
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_58'
          transform='translate(137.000000, 3.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 2.85188539e-14 34 -1.2490009e-14 34 15 -1.20792265e-13 15'
          />
          <text
            id='58'
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.4384766' y='10'>
              58
            </tspan>
          </text>
        </g>
        <g id='spage_71' transform='translate(137.000000, 332.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 4.56301663e-14 34 -1.99840144e-14 34 24 -1.20792265e-13 24'
          />
          <path
            d='M19.8381955,14.9504391 L20.8610988,14.4627316 C20.9847713,14.4044529 21.0352626,14.2620727 20.9738765,14.1446898 L20.7515713,13.7196058 C20.6901852,13.6022229 20.5401608,13.5542994 20.4164883,13.6125636 L19.851196,13.8846805 L18.9525296,12.0693369 C18.8780057,11.9187884 18.6752622,11.7965827 18.5,11.7965827 L16.4336547,11.7965827 L16.362213,11.3220111 L18.2499998,11.3220111 C18.3880765,11.3220111 18.5,11.2157799 18.5,11.0847253 L18.5,10.6101537 C18.5,10.479099 18.3880765,10.3728678 18.2499998,10.3728678 L16.2193603,10.3728678 L16.1463927,9.88805688 C16.6378936,9.81961128 17.0131987,9.41192111 16.9996337,8.92351843 C16.9859313,8.42971367 16.5675506,8.02396419 16.0476836,8.00105244 C15.4737243,7.97575103 15,8.4099302 15,8.94913846 C15.0059357,9.03877237 14.9755096,8.82022726 15.5050201,12.3382531 C15.5402221,12.5720485 15.7511749,12.7457115 16,12.7457115 L18.1824796,12.7457115 L19.1633756,14.7271726 C19.2826383,14.9681516 19.5874325,15.0685896 19.8381955,14.9504391 Z M15.6104212,16 C16.6763943,16 17.5950186,15.3621651 18,14.4507259 L17.6180486,13.643519 L17.2545647,13.643519 C17.1390668,14.4418373 16.4458185,15.0574076 15.6104212,15.0574076 C14.6944476,15.0574076 13.9492441,14.3174259 13.9492441,13.4078709 C13.9492441,12.7965867 14.2858451,12.2619054 14.7846414,11.976953 C14.7295865,11.5945426 14.6831353,11.2720798 14.6439118,11 C13.6816174,11.3822378 13,12.3174518 13,13.4078709 C13,14.8371779 14.1710279,16 15.6104212,16 Z'
            id='w'
            fill='#FFFFFF'
            fillRule='nonzero'
          />
        </g>
        <g
          onClick={props.onSpotClick}
          className='spot'
          id='space_82'
          transform='translate(137.000000, 528.000000)'>
          <polygon
            id='Rectangle'
            stroke='#D3D3D3'
            fill='#DADADA'
            points='4.8316906e-13 2.85188539e-14 34 -1.2490009e-14 34 15 -1.20792265e-13 15'
          />
          <text
            id='82'
            fontFamily='ArialMT, Arial'
            fontSize='10'
            fontWeight='normal'
            fill='#909090'>
            <tspan x='11.4384766' y='10'>
              82
            </tspan>
          </text>
        </g>
        <text
          id='D'
          fontFamily='LucidaGrande, Lucida Grande'
          fontSize='22'
          fontWeight='normal'
          fill='#FFFFFF'>
          <tspan x='31.2607422' y='284'>
            D
          </tspan>
        </text>
      </g>
    </svg>
  )
}
