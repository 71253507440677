import { ParkingBooking } from '@/models/parking/ParkingBooking'
import { axiosVercelAPI } from './axios'

const baseFetcher = async <G>(url: string) => (await axiosVercelAPI.get<G>(url)).data

export const getPaginatedFetcher = <T>(
  paginationId: number = -1,
  pageSize: number = 500,
  upcoming: boolean = false,
  custom: string = ''
) => {
  const params = new URLSearchParams()
  params.append('paginationId', paginationId + '')
  params.append('pageSize', pageSize + '')
  params.append('upcoming', upcoming + '')

  return async (url: string) =>
    (
      await axiosVercelAPI.get<T>(
        custom ? `${url}${url.includes('?') ? `&${custom}` : `?${custom}`}` : url,
        { params }
      )
    ).data
}

export const freeSpotFetcher = async (url: string) =>
  (await axiosVercelAPI.get<ParkingBooking[]>(url)).data.filter(booking => booking.userId === -1)

export default baseFetcher
