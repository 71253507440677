import { FRONT_DATE_FORMAT } from '@/constants/DateConstants'
import { ROUTE_EVENT_DETAIL } from '@/constants/Routes'
import { Event } from '@/models/calendar/Event'
import dayjs from 'dayjs'
import React from 'react'
import CardImage from '../../shared/CardImage'

interface Props {
  event: Event
  additionalClass?: string
  bottom?: React.ReactNode
}

const EventCard = ({ event, additionalClass, bottom }: Props) => {
  return (
    <div className={`card event-type ${additionalClass ? additionalClass : ''}`}>
      <a href={ROUTE_EVENT_DETAIL(event.id)} className='w-inline-block margin-bottom-2x'>
        <div className='separator fill-event-1' />
        <h3 className='card-title-medium'>{event.title}</h3>
        <div className='event-meta color-events-1'>
          {event.location && (
            <>
              <div className='fa-l color-events-2'></div>
              <div>{event.location}</div>
            </>
          )}
          <div className='fa-l color-events-2'></div>
          <div className='meta-date-small'>
            <div>{dayjs(event.startDate).format(FRONT_DATE_FORMAT)}</div>
            {event.endDate && (
              <>
                <div className='fa-l small center'>→</div>
                <div>{dayjs(event.endDate).format(FRONT_DATE_FORMAT)}</div>
              </>
            )}
          </div>
        </div>
        <div className='tag-list color-event-1'>
          {event.categoryFilter && <div className='tag fill-event-1'>{event.categoryFilter}</div>}
          {event.tags?.length > 0 &&
            event.tags.slice(0, 2).map(tag =>
              tag ? (
                <div className='tag outlined' key={tag}>
                  {tag}
                </div>
              ) : (
                <></>
              )
            )}
        </div>
        <CardImage imgSrc={event.image} fillClass='fill-event-2' />
      </a>
      {bottom}
    </div>
  )
}

// noinspection JSUnusedGlobalSymbols
export default EventCard
