import { ROUTE_EVENTS_CALENDAR } from '@/constants/Routes'
import { PublicUser } from '@/models/user/PublicUser'
import { UserCompany } from '@/models/user/UserCompany'
import { getCanViewKNOVAEvents } from '@/utils/apiHelpers/userPermissions'
import { Box, Button, HStack, Heading, Stack, Text } from '@chakra-ui/react'
import { faCalendarCircleUser } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'

export type MemberEventsProps = { user: PublicUser | null; company: UserCompany | null }
export const MemberEvents = ({ user, company }: MemberEventsProps) => {
  return user && company && getCanViewKNOVAEvents(user, company) ? (
    <>
      <Heading size='md' m='0' mb={{ base: 2, lg: 4 }}>
        Members Events
      </Heading>
      <Box bg='white' mb={{ base: 2, lg: 4 }}>
        <Stack p='5' direction={{ base: 'column', xl: 'row' }}>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <HStack>
            <FontAwesomeIcon icon={faCalendarCircleUser} size='3x' />
            <Text flex='2'>Access to the KNOVA event calendar and registration for events</Text>
          </HStack>
          <Box>
            <Button
              flex='1'
              borderRadius='full'
              bg='#19D4A3'
              color='white'
              as={Link}
              href={ROUTE_EVENTS_CALENDAR(true)}
              rightIcon={<FontAwesomeIcon icon={faCalendarCircleUser} />}>
              View Calendar
            </Button>
          </Box>
        </Stack>
      </Box>
    </>
  ) : (
    <></>
  )
}
