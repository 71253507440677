import dayjs from 'dayjs'
import { afternoonEndTime, morningEndTime } from '@/constants/DateConstants'
import {
  BookingDuration,
  BookingTextTimeOfDay,
  BookingTextTimes
} from '@/constants/FieldsParking'
import { ParkingBooking } from '@/models/parking/ParkingBooking'
import { ParkingLot } from '@/models/parking/ParkingLot'

//Calculate the booking cost for display and use on booking.
export const calculateBookingCost = (
  selectedParking: ParkingLot | undefined,
  selectedTimeOfDay: BookingDuration
) => {
  switch (selectedTimeOfDay) {
    case BookingDuration.morning:
      return selectedParking?.creditAM || 0
    case BookingDuration.afternoon:
      return selectedParking?.creditPM || 0
    case BookingDuration.allDay:
      return (selectedParking?.creditAM || 0) + (selectedParking?.creditPM || 0)
  }

  return 0
}

//Recognizes if the current dayjs is past a given registered booking.
export const bookingPastCurrentDayJs = (booking: ParkingBooking) => {
  const bookedDayJs = dayjs(booking.date)
  const currentDayJs = dayjs()

  if (bookedDayJs.isSame(currentDayJs, 'date')) {
    if (booking.duration === BookingDuration.morning && currentDayJs.isBefore(morningEndTime)) {
      return false
    }

    return !(
      (booking.duration === BookingDuration.afternoon ||
        booking.duration === BookingDuration.allDay) &&
      currentDayJs.isBefore(afternoonEndTime)
    )
  } else {
    return bookedDayJs.isBefore(currentDayJs)
  }
}

export const getParkingPicture = (needAccess: Boolean) => {
  return needAccess ? '/parkshare/parking_sign_indoor.svg' : '/parkshare/parking_sign_outdoor.svg'
}

export const getBookingTextFromDuration = (booking: ParkingBooking) => {
  switch (booking.duration) {
    case BookingDuration.morning:
      return `In the ${BookingTextTimeOfDay.morning.toLowerCase()} (${BookingTextTimes.morning})`
    case BookingDuration.afternoon:
      return `In the ${BookingTextTimeOfDay.afternoon.toLowerCase()} (${
        BookingTextTimes.afternoon
      })`
    case BookingDuration.allDay:
      return `${BookingTextTimeOfDay.allDay.toLowerCase()} (${BookingTextTimes.allDay})`
  }
}
