interface Props {
  title: string
  subtitle?: string
}

const DisplayTitle = ({ title, subtitle }: Props) => {
  return (
    <>
      <h1 className={`display-h3 no-margin-top margin-bottom-${subtitle ? '1' : '4'}x`}>{title}</h1>
      {subtitle && <h3 className='margin-bottom-4x no-margin-top color-light'>{subtitle}</h3>}
    </>
  )
}

export default DisplayTitle
