import type { EditorEvents } from '@tiptap/react'
import { BubbleMenu, EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { Link } from '@tiptap/extension-link'
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Text,
  useTheme,
  VStack,
  Wrap
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBold,
  faItalic,
  faLinkSlash,
  faListOl,
  faListUl,
  faRedo,
  faStrikethrough,
  faUndo
} from '@fortawesome/pro-light-svg-icons'
import { useEffect, useState } from 'react'
import { urlValidator } from '@/utils/dataHelpers/validators'
import { showToast } from '@/utils/showToast'

import style from '@/styles/richTextEditor.module.css'
import { FieldValues, Path, RegisterOptions } from 'react-hook-form'

interface Props<TFormValues extends FieldValues> {
  name: Path<TFormValues>
  label: string
  rules?: RegisterOptions
  errors?: any
  onUpdate: (text: string) => void
  initialContent?: string
}

const FormRichText = <TFormValues extends FieldValues>({
  name,
  label,
  rules,
  errors,
  onUpdate,
  initialContent
}: Props<TFormValues>) => {
  const theme = useTheme()
  const [link, setLink] = useState<string>('')

  const errorMessages = errors[name]
  const hasError = !!(errors && errorMessages)

  const editor = useEditor({
    extensions: [StarterKit, Link],
    content: initialContent,
    onUpdate: (props: EditorEvents['update']) => {
      onUpdate(props.editor.getHTML())
    }
  })

  useEffect(() => {
    if (editor) {
      editor.commands.setContent(initialContent ?? '')
    }
  }, [initialContent, editor])

  const handleSetLink = () => {
    if (!link) {
      return showToast('Please enter a URL', 'error')
    }
    if (!link.match(urlValidator)) {
      return showToast('Please enter a valid URL', 'error')
    }

    let cleanLink = link
    if (!cleanLink.startsWith('http')) {
      cleanLink = `https://${cleanLink}`
    }
    editor?.commands.setLink({ href: cleanLink, target: '_blank' })
    setLink('')
  }

  return (
    <FormControl isInvalid={hasError} isRequired={!!rules?.required}>
      <VStack w='full' align='start' spacing={2}>
        <FormLabel fontSize='xs'>{label.toUpperCase()}</FormLabel>
        <Box w='full' border={`1px solid ${theme.colors.gray[200]}`} borderRadius='lg' p={2}>
          <Wrap spacing={2} w='full' mb={2}>
            <IconButton
              aria-label='Bold'
              onClick={() => editor?.chain().focus().toggleBold().run()}
              bgColor={editor?.isActive('bold') && theme.colors.blue[100]}
              icon={<FontAwesomeIcon icon={faBold} height={16} width='auto' />}
            />
            <IconButton
              aria-label='Italic'
              onClick={() => editor?.chain().focus().toggleItalic().run()}
              bgColor={editor?.isActive('italic') && theme.colors.blue[100]}
              icon={<FontAwesomeIcon icon={faItalic} height={16} width='auto' />}
            />
            <IconButton
              aria-label='Strike trough'
              onClick={() => editor?.chain().focus().toggleStrike().run()}
              bgColor={editor?.isActive('strike') && theme.colors.blue[100]}
              icon={<FontAwesomeIcon icon={faStrikethrough} height={16} width='auto' />}
            />
            <IconButton
              aria-label='Bullet list'
              onClick={() => editor?.chain().focus().toggleBulletList().run()}
              bgColor={editor?.isActive('bulletList') && theme.colors.blue[100]}
              icon={<FontAwesomeIcon icon={faListUl} height={16} width='auto' />}
            />
            <IconButton
              aria-label='Numbered list'
              onClick={() => editor?.chain().focus().toggleOrderedList().run()}
              bgColor={editor?.isActive('orderedList') && theme.colors.blue[100]}
              icon={<FontAwesomeIcon icon={faListOl} height={16} width='auto' />}
            />
            <Divider orientation='vertical' />
            <IconButton
              aria-label='Undo'
              onClick={() => editor?.chain().focus().undo().run()}
              icon={<FontAwesomeIcon icon={faUndo} height={16} width='auto' />}
            />
            <IconButton
              aria-label='Redo'
              onClick={() => editor?.chain().focus().redo().run()}
              icon={<FontAwesomeIcon icon={faRedo} height={16} width='auto' />}
            />
            <Divider orientation='vertical' />
            <IconButton
              aria-label='Unlink'
              onClick={() => editor?.commands.unsetLink()}
              icon={<FontAwesomeIcon icon={faLinkSlash} height={16} width='auto' />}
            />
            {editor && (
              <BubbleMenu editor={editor} tippyOptions={{ duration: 150 }}>
                <VStack align='start' bg='gray.50' p={2} borderRadius='xl'>
                  <Text>Enter a link</Text>
                  <Input
                    placeholder='Link URL'
                    size='sm'
                    onChange={e => setLink(e.target.value)}
                    value={link}
                  />
                  <Button size='sm' onClick={handleSetLink}>
                    Submit
                  </Button>
                </VStack>
              </BubbleMenu>
            )}
          </Wrap>
          <EditorContent editor={editor} className={style.editor} />
        </Box>
        {hasError && <FormErrorMessage>{errorMessages.message}</FormErrorMessage>}
      </VStack>
    </FormControl>
  )
}

export default FormRichText
