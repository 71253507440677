import axios, { AxiosError, AxiosResponse } from 'axios'
import { NextApiResponse } from 'next'
import { APIError } from '@/models/APIError'
import { logSentryException } from '../loggingHelpers/sentryHelper'

//Eudonet internal handlers
export const handleAxiosResponse = (
  response: AxiosResponse,
  endpoint: string,
  method?: string
): any => {
  const rows = response.data.ResultData?.Rows

  if (response.status !== 200) {
    throw new APIError(`Error while posting content on ${endpoint}`, 400, {
      method: 'throwAxiosError',
      category: 'API'
    })
  }

  //When posting to Eudonet it's possible that only a FileId is returned
  if (method === 'POST' && !rows && isEmptyArray(rows)) {
    const fieldId = response.data.ResultData?.FileId
    const success = response.data.ResultInfos.Success ?? false

    if (!fieldId && !success) {
      const message = `Error while posting content on ${endpoint}`

      throw new APIError(message, 400, {
        method: 'throwAxiosError',
        category: 'API',
        message
      })
    }

    return fieldId
  }

  //Data are formatted differently when fetching catalogs
  if (method === 'GET' && endpoint.includes('/Catalog')) {
    const catalogValues = response.data.ResultData?.CatalogValues

    if (isEmptyArray(catalogValues) || !catalogValues) {
      const message = `Error while fetching catalog ${endpoint}`

      throw new APIError(message, 404, {
        method: 'throwAxiosError',
        category: 'API',
        message
      })
    }

    return catalogValues
  }

  return rows
}

export const requestErrorHandler = (e: any, endpoint: string, payload?: any) => {
  if (axios.isAxiosError(e)) {
    throwAxiosError(e)
  } else if (e instanceof APIError) {
    throw e
  } else {
    throwUnknownError(endpoint, payload, e)
  }
}

//API ERRORS

export const throwAxiosError = (e: AxiosError<any>) => {
  const message = e.response?.data || 'An error occurred with your request'

  throw new APIError(message, 500, {
    method: 'throwAxiosError',
    category: 'API',
    message
  })
}

export const throwUnknownError = (endpoint: string, payload?: any, error?: any) => {
  throw new APIError('An unexpected error occurred', 520, {
    method: 'throwUnknownError',
    category: 'API',
    message: { endpoint, payload, error }
  })
}

///API Errors
export const throwPermissionError = () => {
  throw new APIError("User doesn't have the required permissions to perform this action", 403, {
    method: 'throwPermissionsError',
    category: 'API'
  })
}

export const throwAPICORSSourceQueryError = () => {
  throw new APIError(`Missing or forbidden source parameter`, 401, {
    method: 'throwAPICORSSourceQueryError',
    category: 'API'
  })
}

export const throwAPIBodyQueryError = (bodyItem: string) => {
  throw new APIError(`Missing well-formatted ${bodyItem} parameter`, 400, {
    method: 'throwAPIBodyQueryError',
    category: 'API',
    message: { bodyItem }
  })
}

export const throwInvalidTokenError = () => {
  throw new APIError('Error while validating your auth token, please reconnect', 401, {
    method: 'throwInvalidTokenError',
    category: 'API'
  })
}

export const returnEndpointError = (res: NextApiResponse, error: any) => {
  if (error instanceof APIError) {
    if (error.messageObj) {
      const { method, category, message } = error.messageObj
      logSentryException(method, category, message)
    } else {
      logSentryException('throwAPIError', 'API', `${error.errorCode}: ${error.message}`)
    }

    return res.status(error.errorCode).send(error.message)
  }

  return res.status(500).send('An unknown server error occurred')
}

export const returnUnsupportedMethod = (res: NextApiResponse) => {
  logSentryException('returnUnsupportedMethod', 'API')

  return res.status(500).send('Unsupported method')
}

const isEmptyArray = (cont: any[]) => !cont || cont.length === null
