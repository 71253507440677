import {
  EVENT_CATEGORY_FILTER_VALUES,
  EVENT_MEMBER_CATEGORY_FILTER_VALUES
} from '@/constants/FieldsEvent'
import { FRONT_EVENTS_FROM_TYPE } from '@/constants/Routes'
import { Event } from '@/models/calendar/Event'
import userStore from '@/stores/userStore'
import baseFetcher from '@/utils/connectionHelpers/swrFetchers'
import { AxiosError } from 'axios'
import useSWR from 'swr'
import ListDisplay from '../shared/ListDisplay'
import EventCard from './components/EventCard'

interface Props {
  title: string
  moreTitle?: string
  moreLink: string
  eventType: EVENT_CATEGORY_FILTER_VALUES | EVENT_MEMBER_CATEGORY_FILTER_VALUES
}

const UpcomingEvents = ({ title, moreTitle = 'View more', moreLink, eventType }: Props) => {
  const [isKnovaMember] = userStore(e => [e.isKnovaMember])

  const { data } = useSWR<Event[], AxiosError>(
    FRONT_EVENTS_FROM_TYPE(eventType, 3, true, isKnovaMember ? 'all' : 'none'),
    baseFetcher,
    { revalidateOnFocus: false }
  )

  return (
    <>
      <ListDisplay<Event>
        title={title}
        moreLink={moreLink}
        moreTitle={moreTitle}
        cardsClass='anim-event'
        listItems={data ?? []}
        card={(item, key) => {
          const additionalClass = `_${key + 1}`
          return <EventCard event={item} key={key} additionalClass={additionalClass} />
        }}
      />
    </>
  )
} // noinspection JSUnusedGlobalSymbols
export default UpcomingEvents
