import { toast } from '@/components/shared/portal/PortalThemedProvider'
import { AxiosError } from 'axios'

export const showToast = (title: string, status: 'success' | 'warning' | 'error' = 'success') => {
  toast({
    title,
    status,
    duration: 5000,
    position: 'top',
    isClosable: false
  })
}

export const axiosErrorToast = (
  axiosError: AxiosError<any> | string,
  status: 'success' | 'warning' | 'error' = 'success'
) => {
  if (typeof axiosError === 'string') {
    showToast(axiosError, status)
  } else {
    try {
      showToast(
        axiosError?.response?.data?.message ||
          (typeof axiosError?.response?.data === 'string'
            ? axiosError.response.data
            : axiosError.message || 'unknown error'),
        status
      )
    } catch (e) {
      showToast(axiosError.message || 'unknown error', status)
    }
  }
}
