import { BookingDuration } from '@/constants/FieldsParking'
import { FRONT_BOOK } from '@/constants/Routes'
import { ParkingBooking } from '@/models/parking/ParkingBooking'
import { axiosVercelAPI } from '@/utils/connectionHelpers/axios'
import { groupBy } from '@/utils/dataHelpers/collectionHelpers'
import { create, StoreApi } from 'zustand'
import userStore, { updateUserInfo } from './userStore'

export interface StoreProps {
  booking: ParkingBooking | null
  setBooking: (booking: ParkingBooking | null) => void
  isBooking: boolean
  book: (props: BookProps) => Promise<ParkingBooking | null>
}

export interface BookProps {
  bookingPrice: number
  freeSpots: ParkingBooking[]
  duration: BookingDuration
}

export const bookingStore = create<StoreProps>((set, get) => ({
  booking: null,
  setBooking: booking => set({ booking }),
  isBooking: false,
  book: props => bookImpl(props, set, get)
}))

//Book the inputted content based on availability, price, and the duration requested.
const bookImpl = async (
  { bookingPrice, freeSpots, duration }: BookProps,
  set: StoreApi<StoreProps>['setState'],
  get: StoreApi<StoreProps>['getState']
) => {
  if (bookingPrice === -1 || get().isBooking) {
    return null
  }

  set({ isBooking: true })

  try {
    const freeSpotBySpot = groupBy(freeSpots, e => e.spotId)
    const allDayAvailabilities = Object.values(freeSpotBySpot).filter(
      availabilities =>
        availabilities.filter(booking => booking.duration === BookingDuration.morning).length > 0 &&
        availabilities.filter(booking => booking.duration === BookingDuration.afternoon).length > 0
    )

    if (allDayAvailabilities.length === 0) {
      return null
    }

    const randomFullDayBookings = allDayAvailabilities[0]

    // We avoid giving half day on a full day available spot
    let randomMorningBooking = freeSpots.filter(
      booking =>
        booking.duration === BookingDuration.morning &&
        !allDayAvailabilities.flatMap(b => b).includes(booking)
    )[0]
    let randomAfternoonBooking = freeSpots.filter(
      booking =>
        booking.duration === BookingDuration.afternoon &&
        !allDayAvailabilities.flatMap(b => b).includes(booking)
    )[0]

    const bookingIds = []
    switch (duration) {
      case BookingDuration.allDay:
        randomFullDayBookings.forEach(booking => {
          bookingIds.push(booking.id)
        })
        break
      case BookingDuration.morning:
        // Last chance
        if (!randomMorningBooking && randomFullDayBookings) {
          randomMorningBooking = randomFullDayBookings.filter(
            booking => booking.duration === BookingDuration.morning
          )[0]
        }
        if (randomMorningBooking) {
          bookingIds.push(randomMorningBooking.id)
        }
        break
      case BookingDuration.afternoon:
        // Last chance
        if (!randomAfternoonBooking && randomFullDayBookings) {
          randomAfternoonBooking = randomFullDayBookings.filter(
            booking => booking.duration === BookingDuration.afternoon
          )[0]
        }
        if (randomAfternoonBooking) {
          bookingIds.push(randomAfternoonBooking.id)
        }
        break
    }

    //Send the booking request to server and update the parkshare user credit
    const response = await axiosVercelAPI.post(FRONT_BOOK, { bookingIds })

    if (response.status === 200 && response.data.parkshareCredits >= 0) {
      const userStoreRef = userStore.getState()
      const user = userStoreRef.user

      if (user) {
        await updateUserInfo({
          targetId: user.encryptedId,
          parkshareCredits: response.data.parkshareCredits
        })
      }
    }

    switch (duration) {
      case BookingDuration.allDay:
        randomFullDayBookings[0].duration = BookingDuration.allDay
        return randomFullDayBookings[0]
      case BookingDuration.morning:
        return randomMorningBooking
      case BookingDuration.afternoon:
        return randomAfternoonBooking
    }
  } finally {
    set({ isBooking: false })
  }
}
