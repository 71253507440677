import { Box, Flex, Text } from '@chakra-ui/react'

interface Props {
  title: string
  subTitle?: string
  selected: boolean
  onClick: () => void
}

const LargeParkshareCheckbox = ({ title, subTitle, selected, onClick }: Props) => {
  return (
    <Flex
      w='full'
      gap='20px'
      placeItems='center'
      bg='brand.white'
      h={16}
      p={4}
      _hover={{ cursor: 'pointer' }}
      onClick={onClick}>
      <Box
        w={4}
        h={4}
        border={`2px solid ${selected ? 'brand.primary' : '#DBDBDB'}`}
        borderRadius='100%'
        bg={selected ? 'brand.primary' : 'transparent'}
      />
      <Box textAlign='center' flexGrow={1}>
        <Text fontWeight='600'>{title}</Text>
        <Text>{subTitle}</Text>
      </Box>
    </Flex>
  )
}

export default LargeParkshareCheckbox
