import { extendZodWithOpenApi, generateSchema } from '@anatine/zod-openapi'
import z, { ZodRawShape, ZodType } from 'zod'
import { EudonetRowResult } from '../EudonetModels'

extendZodWithOpenApi(z)

export type EudoFieldAny = EudoField<any>

export type EudoRawShape = Record<string, EudoFieldAny>

export type EudoShape<T extends EudoRawShape> = {
  [K in keyof T]: T[K]['Output']
}

export abstract class EudoField<T, O = T> {
  Output!: O
  Input!: T

  abstract parse(t: EudonetRowResult): O

  abstract get zodType(): ZodType<O>
}

export class EudoArray<T extends EudoFieldAny> extends EudoField<T['Input'][], T['Output'][]> {
  constructor(readonly underlying: T) {
    super()
  }

  parse(t: EudonetRowResult | EudonetRowResult[]) {
    return Array.isArray(t) ? t.map(elem => this.underlying.parse(elem)) : this.underlying.parse(t)
  }

  get zodType() {
    return z.array(this.underlying.zodType)
  }
}

type ObjectTransform<T extends EudoRawShape, O> = (val: EudoShape<T>) => O
export class EudoObject<T extends EudoRawShape, O = EudoShape<T>> extends EudoField<T, O> {
  constructor(readonly shape: T, private transformer: ObjectTransform<T, O> = s => s as O) {
    super()
  }

  parse(t: EudonetRowResult): O {
    let parsed = Object.entries(this.shape).reduce(
      (acc, [k, field]) => ({ ...acc, [k]: field.parse(t) }),
      {} as EudoShape<T>
    )
    return this.transformer(parsed)
  }

  array(): EudoArray<typeof this> {
    return new EudoArray<typeof this>(this)
  }

  get zodType() {
    return (
      z.object(
        Object.entries(this.shape).reduce(
          (acc, [k, field]) => ({ ...acc, [k]: field.zodType }),
          {} as ZodRawShape
        )
      ) as ZodType<EudoShape<T>>
    ).transform(o => this.transformer(o)) as unknown as ZodType<O>
  }

  extend<O extends EudoRawShape>(obj: O): EudoObject<T & O> {
    return new EudoObject({ ...this.shape, ...obj })
  }

  transform<O>(transformFn: ObjectTransform<T, O>) {
    return new EudoObject<T, O>(this.shape, transformFn)
  }

  get schema() {
    return generateSchema(this.zodType)
  }
}
