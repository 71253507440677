import {
  getBoolFromEudonetField,
  getDateFromEudonetField,
  getDbRefFromEudonetField,
  getIntFromEudonetField,
  getRefFromEudonetField,
  getStringFromEudonetField
} from '@/utils/apiHelpers/fieldsHelpers'
import { extendZodWithOpenApi } from '@anatine/zod-openapi'
import z, { EnumLike, ZodNumber, ZodString, ZodType, util } from 'zod'
import { EudonetField, EudonetRowResult } from '../EudonetModels'
import { EudoArray, EudoField } from './EudonetField'

extendZodWithOpenApi(z)

export type GetFromEudonetResult<T> = number | ((row: EudonetRowResult) => T | undefined)

export class EudoPrimitive<T, O = T> extends EudoField<T, O> {
  protected constructor(
    protected readonly _zodType: ZodType<O>,
    readonly eudoRetriever: GetFromEudonetResult<T>,
    readonly simpleEudonetFieldParser: (fields: EudonetField[], fieldId: number) => T
  ) {
    super()
  }

  parse(t: EudonetRowResult) {
    return this.zodType.parse(
      typeof this.eudoRetriever === 'number'
        ? this.simpleEudonetFieldParser(t.Fields, this.eudoRetriever)
        : this.eudoRetriever(t)
    )
  }

  array(): EudoArray<typeof this> {
    return new EudoArray<typeof this>(this)
  }

  get zodType() {
    return this._zodType
  }

  default<U extends util.noUndefined<O>>(val: U): EudoPrimitive<T, O> {
    return new EudoPrimitive<T, O>(
      this.zodType.default(val) as ZodType<O>,
      this.eudoRetriever,
      this.simpleEudonetFieldParser
    )
  }

  optional() {
    return new EudoOptional(this)
  }

  nullable() {
    return new EudoNullable(this)
  }

  transform<NO>(fn: (o: O) => NO, newZodType?: ZodType<NO>) {
    const mapped = this.zodType.transform(o => fn(o))
    return new EudoPrimitive<T, NO>(
      (newZodType ? z.union([newZodType, mapped]) : mapped) as unknown as ZodType<NO>,
      this.eudoRetriever,
      this.simpleEudonetFieldParser
    )
  }
}

export class EudoOptional<F extends EudoPrimitive<any>> extends EudoPrimitive<
  F['Output'] | undefined
> {
  constructor(readonly underlying: F) {
    super(
      underlying.zodType.optional(),
      underlying.eudoRetriever,
      underlying.simpleEudonetFieldParser
    )
  }
}

export class EudoNullable<F extends EudoPrimitive<any>> extends EudoPrimitive<F['Output'] | null> {
  constructor(readonly underlying: F) {
    super(
      underlying.zodType.nullable(),
      underlying.eudoRetriever,
      underlying.simpleEudonetFieldParser
    )
  }
}

export class EudoString extends EudoPrimitive<string> {
  constructor(readonly eudoRetriever: GetFromEudonetResult<string>, zodType?: ZodString) {
    super(zodType ?? z.string(), eudoRetriever, getStringFromEudonetField)
  }

  min(minLength: number, message?: string) {
    return new EudoString(
      this.eudoRetriever,
      (this.zodType as ZodString).min(minLength, { message })
    )
  }

  max(maxLength: number, message?: string) {
    return new EudoString(
      this.eudoRetriever,
      (this.zodType as ZodString).min(maxLength, { message })
    )
  }

  splitAsArray(separator: string = ',') {
    return this.transform(s => s.split(separator).map(s => s.trim()), z.string().array()).default(
      []
    )
  }
}

export class EudoInt extends EudoPrimitive<number> {
  constructor(readonly eudoRetriever: GetFromEudonetResult<number>, zodType?: ZodNumber) {
    super(zodType ?? z.number().openapi({ type: 'integer' }), eudoRetriever, getIntFromEudonetField)
  }

  min(minLength: number, message?: string) {
    return new EudoInt(this.eudoRetriever, (this.zodType as ZodNumber).min(minLength, { message }))
  }

  max(maxLength: number, message?: string) {
    return new EudoInt(this.eudoRetriever, (this.zodType as ZodNumber).min(maxLength, { message }))
  }
}

export class EudoEnum<E extends EnumLike> extends EudoPrimitive<E[keyof E]> {
  constructor(readonly fieldID: number, readonly e: E) {
    super(z.nativeEnum(e).openapi({ format: 'literal' }), fieldID, getStringFromEudonetField as any)
  }
}

export class EudoBoolean extends EudoPrimitive<boolean> {
  constructor(readonly eudoRetriever: GetFromEudonetResult<boolean>) {
    super(z.boolean(), eudoRetriever, getBoolFromEudonetField)
  }
}

export class EudoDate extends EudoPrimitive<Date> {
  constructor(readonly fieldID: number) {
    super(z.coerce.date(), fieldID, getDateFromEudonetField)
  }
}

export class EudoRef extends EudoPrimitive<number> {
  constructor(readonly fieldID: number, dbRef: boolean = false) {
    super(
      z.number().openapi({ type: 'integer', format: 'ref' }),
      fieldID,
      dbRef ? getDbRefFromEudonetField : getRefFromEudonetField
    )
  }
}

export class EudoId extends EudoInt {
  constructor() {
    super(t => t.FileId, z.number().openapi({ type: 'integer', format: 'uid' }))
  }
}
