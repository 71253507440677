import { Text, useTheme } from '@chakra-ui/react'
import { ParkingType } from '@/models/parking/ParkingLot'
import { SPOT_ID_PREFIX } from '@/models/parking/ParkingSpot'
import { ParkingAB } from './ParkingAB'
import { ParkingD } from './ParkingD'
import { ParkingG } from './ParkingG'
import { ParkingH } from './ParkingH'
import { ParkingN } from './ParkingN'
import { ParkingO } from './ParkingO'

export interface ParkingLayoutProps {
  onSpotClick: (element: any) => void
  available: number[]
}

export interface ParkingProps {
  available: number[]
  type: ParkingType
  onSpotClick?: (type: ParkingType, spot: string) => void
}

const SingleParkingMap = ({ available, type, onSpotClick }: ParkingProps) => {
  const theme = useTheme()

  const onSpotClickImpl = (element: any) => {
    let closestG
    let e = element.target
    for (; e && e !== document; e = e.parentNode) {
      if (e instanceof SVGGElement && e.id.includes(SPOT_ID_PREFIX)) {
        closestG = e
        break
      }
    }
    if (!closestG) {
      return
    }
    if (!closestG.classList.contains('spot-available')) {
      return
    }

    if (onSpotClick) {
      onSpotClick(type, closestG.id)
    }
  }

  switch (type) {
    case ParkingType.AB:
      return <ParkingAB onSpotClick={onSpotClickImpl} available={available} />
    case ParkingType.O:
      return <ParkingO onSpotClick={onSpotClickImpl} available={available} />
    case ParkingType.N:
      return <ParkingN onSpotClick={onSpotClickImpl} available={available} />
    case ParkingType.D:
      return <ParkingD onSpotClick={onSpotClickImpl} available={available} />
    case ParkingType.G:
      return <ParkingG onSpotClick={onSpotClickImpl} available={available} />
    case ParkingType.H:
      return <ParkingH onSpotClick={onSpotClickImpl} available={available} />
    default:
      return <Text color={theme.colors.brand.error}>Could not find map for parking lot</Text>
  }
}

export default SingleParkingMap
