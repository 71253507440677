// noinspection JSUnusedGlobalSymbols

export const PARKING_NAME = 7801
export const PARKING_TYPE = 7803
export const PARKING_CREDIT_AM = 7805
export const PARKING_CREDIT_PM = 7804
export const PARKING_NEED_ACCESS = 7802
export const PARKING_BOOKING_RENTER_COMPANY = 8008
export const PARKING_BOOKING_RENTER = 8007
export const PARKING_BOOKING_LOT = 8005
export const PARKING_BOOKING_DURATION = 8004
export const PARKING_BOOKING_DATE = 8003
export const PARKING_SPOT_NAME = 10401
export const PARKING_SPOT_NUMBER = 10413
export const PARKING_SPOT_AVAILABLE_ON_PARKSHARE = 10453
export const PARKING_SPOT_PARKING = 10454

export type Recurrence = 'ONCE' | 'EVERY_DAY' | 'EVERY_WEEK'
export type Availability = 'MORNING' | 'AFTERNOON'

export enum BookingDuration {
  allDay = 'All day',
  morning = 'Morning',
  afternoon = 'Afternoon'
}

export const BookingTextTimeOfDay = {
  allDay: 'All day long',
  morning: 'Morning',
  afternoon: 'Afternoon'
}
export const BookingTextTimes = {
  allDay: '07:00-18:00',
  morning: '07:00-12:30',
  afternoon: '13:00-18:00'
}
