interface Props {
  text: string
  opened: boolean
  onClick: () => void
}

const FilterTypeButton = ({ text, opened, onClick }: Props) => {
  return (
    <a
      href='#'
      className={`filters-button first w-inline-block ${opened ? 'open' : ''}`}
      onClick={onClick}>
      <div>{text}</div>
    </a>
  )
}

export default FilterTypeButton
