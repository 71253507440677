import { FormControl, FormErrorMessage, FormLabel, useTheme } from '@chakra-ui/react'
import DatePicker, { DayValue } from '@hassanmojab/react-modern-calendar-datepicker'
import type { FC } from 'react'
import { FieldValues, Path } from 'react-hook-form'
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css'

interface Props<TFormValues extends FieldValues> {
  name: Path<TFormValues>
  label: string
  errors?: any
  renderInput: FC
  value: DayValue
  onChange: (val: DayValue) => void
}

const FormDatePicker = <TFormValues extends FieldValues>({
  name,
  label,
  errors,
  renderInput,
  value,
  onChange
}: Props<TFormValues>) => {
  const theme = useTheme()

  const errorMessages = errors[name]
  const hasError = !!(errors && errorMessages)

  return (
    <FormControl isInvalid={hasError}>
      <FormLabel fontSize='xs'>{label.toUpperCase()}</FormLabel>
      <DatePicker
        colorPrimary={theme.colors.blue['300']}
        renderInput={renderInput}
        value={value}
        onChange={onChange}
        wrapperClassName='custom-calendar'
        shouldHighlightWeekends
      />
      {hasError && <FormErrorMessage>{errorMessages.message}</FormErrorMessage>}
    </FormControl>
  )
}

export default FormDatePicker
