import { FRONT_COMPANIES } from '@/constants/Routes'
import { PublicCompany } from '@/models/company/PublicCompany'
import { getPaginatedFetcher } from '@/utils/connectionHelpers/swrFetchers'
import { AxiosError } from 'axios'
import useSWR from 'swr'
import ListDisplay from '../shared/ListDisplay'
import CompanyCard from './components/CompanyCard'

interface Props {
  title: string
  moreTitle?: string
  moreLink: string
}

const ModularCompanies = ({ title, moreTitle = 'View more', moreLink }: Props) => {
  const { data } = useSWR<{ data: PublicCompany[]; items: number }, AxiosError>(
    FRONT_COMPANIES,
    getPaginatedFetcher(1, 3, false, '&isFEIP=true&isModular=true'),
    { revalidateOnFocus: false }
  )

  return (
    <>
      <ListDisplay<PublicCompany>
        title={title}
        moreLink={moreLink}
        moreTitle={moreTitle}
        cardsClass='anim-company'
        listItems={data?.data ?? []}
        card={(item, key) => {
          const additionalClass = `_${key + 1}`
          return <CompanyCard company={item} key={key} additionalClass={additionalClass} />
        }}
      />
    </>
  )
}

// noinspection JSUnusedGlobalSymbols
export default ModularCompanies
