import { Box, Grid, HStack, Text, useTheme } from '@chakra-ui/react'
import Image from 'next/image'
import { ParkingLot } from '@/models/parking/ParkingLot'
import { getParkingPicture } from '@/utils/dataHelpers/parkBookingHelpers'
import GridSelectButton from '../shared/GridSelectButton'

interface Props {
  parkingClicked: (lot: ParkingLot) => void
  freeSpots: number
  parkingLot: ParkingLot
}

//Render a single parking-lot card which displays availability in free spot-count and color.
export const ParkingCard = ({ parkingLot, freeSpots, parkingClicked }: Props) => {
  const theme = useTheme()

  const getParkingColor = () => {
    return freeSpots > 0 ? theme.colors.brand.primary : theme.colors.brand.error
  }

  const getImageFilter = () => {
    return freeSpots > 0
      ? 'invert(50%) sepia(89%) saturate(392%) hue-rotate(114deg) brightness(84%) contrast(90%)'
      : 'invert(33%) sepia(50%) saturate(1991%) hue-rotate(340deg) brightness(99%) contrast(82%)'
  }

  return (
    <Grid
      minH={20}
      px={6}
      py={[5, null, 2]}
      bg={theme.colors.brand.white}
      w='full'
      templateColumns={['repeat(2, 1fr)', null, 'repeat(3, 1fr)']}
      gap={[3, null, 0]}
      borderLeft={`2px solid ${getParkingColor()}`}>
      <HStack spacing={2}>
        <Box filter={getImageFilter()}>
          <Image height={22} src={getParkingPicture(parkingLot.needAccess)} width={16} alt='' />
        </Box>
        <Text>{parkingLot.name}</Text>
      </HStack>
      <Text
        m='auto 0'
        color={getParkingColor()}
        fontWeight='600'
        textAlign={['end', null, 'center']}>
        {freeSpots > 0 ? `${freeSpots} free` : 'Full'}
      </Text>

      <GridSelectButton onClick={() => parkingClicked(parkingLot)} disabled={freeSpots === 0} />
    </Grid>
  )
}
