import { Checkbox, CheckboxProps, FormControl, FormErrorMessage, VStack } from '@chakra-ui/react'
import { FieldValues, Path, RegisterOptions, UseFormRegister } from 'react-hook-form'

interface Props<TFormValues extends FieldValues> extends CheckboxProps {
  name: Path<TFormValues>
  label: string
  rules?: RegisterOptions
  register: UseFormRegister<TFormValues>
  errors?: any
  watch: boolean
}

const FormCheckbox = <TFormValues extends FieldValues>({
  errors,
  name,
  label,
  register,
  rules,
  watch,
  ...props
}: Props<TFormValues>) => {
  const errorMessages = errors[name]
  const hasError = !!(errors && errorMessages)

  return (
    <FormControl isInvalid={hasError} isRequired={!!rules?.required}>
      <VStack w='full' align='start' spacing={2}>
        <Checkbox {...register(name, rules as any)} {...props} isChecked={watch}>
          {label}
        </Checkbox>
        {hasError && <FormErrorMessage>{errorMessages.message}</FormErrorMessage>}
      </VStack>
    </FormControl>
  )
}

export default FormCheckbox
