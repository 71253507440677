import { Button, HStack } from '@chakra-ui/react'
import { useRouter } from 'next/router'

interface Props {
  loading: boolean
  submitButtonText?: string
}

const FormBottomButtons = ({ loading, submitButtonText }: Props) => {
  const { back } = useRouter()

  return (
    <HStack w='full' justify='end' align='center' spacing={6}>
      <Button variant='link' onClick={back}>
        Back
      </Button>
      <Button colorScheme='green' isLoading={loading} type='submit'>
        {submitButtonText ?? 'Confirm'}
      </Button>
    </HStack>
  )
}

export default FormBottomButtons
