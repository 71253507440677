import FormCheckbox from '@/components/shared/form/FormCheckbox'
import FormHeading from '@/components/shared/form/FormHeading'
import FormLinkCompany from '@/components/shared/form/FormLinkCompany'
import ImageUpload from '@/components/shared/imageUpload/ImageUpload'
import PortalPageTitle from '@/components/shared/portal/PortalPageTitle'
import { FRONT_COMPANY_LOGO, ROUTE_PORTAL } from '@/constants/Routes'
import { EditCompanyLogo } from '@/models/company/EditCompanyLogo'
import { axiosVercelAPI } from '@/utils/connectionHelpers/axios'
import { ignoreEnter } from '@/utils/ignoreEnter'
import {
  AspectRatio,
  Box,
  Button,
  Flex,
  FormLabel,
  HStack,
  Image,
  SimpleGrid,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  VStack,
  useBoolean,
  useTheme
} from '@chakra-ui/react'
import { faArrowDownToLine } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FinalColor, extractColors } from 'extract-colors'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
// @ts-ignore
import { getHSLAString, hexToHSL } from '@/utils/colorsUtils'
import { handleUpdateErrors } from '@/utils/dataHelpers/apiResponseHelper'
import { showToast } from '@/utils/showToast'
import { take } from '@coteries/utils'
import { createFileName, useScreenshot } from 'use-react-screenshot'

const ManageCompaniesLogo = () => {
  return <EditCompaniesLogo />
}

interface BlocProps {
  color?: string
  setColor: (val?: string) => void
}

const ColorBloc = ({ color, setColor }: BlocProps) => {
  //The background is at a 40% opacity to be consistent with colors options of the intensity slider
  return (
    <Box
      as='button'
      bg={`${color}66`}
      border='1px solid'
      borderColor='gray.200'
      height='80px'
      width='80px'
      borderRadius='lg'
      onClick={() => setColor(color)}
    />
  )
}

const EditCompaniesLogo = () => {
  const { push } = useRouter()

  const theme = useTheme()
  const { back } = useRouter()

  const logoRef = useRef<HTMLDivElement | null>(null)
  const [, takeScreenshot] = useScreenshot()

  const [loading, setLoading] = useBoolean(false)
  const [selectedAccent, setSelectedAccent] = useState<string>('transparent')
  const [formattedAccent, setFormattedAccent] = useState<string>('transparent')
  const [hue, setHue] = useState(0)
  const [pictureFile, setPictureFile] = useState<File>()
  const [palette, setPalette] = useState<FinalColor[]>()

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors }
  } = useForm<EditCompanyLogo>()

  const onSubmit: SubmitHandler<EditCompanyLogo> = async data => {
    setLoading.on()

    try {
      await axiosVercelAPI.put(FRONT_COMPANY_LOGO(parseInt(data.linkedCompany)), {
        picture: await takeScreen()
      })

      await push(ROUTE_PORTAL)
      showToast('The logo has been successfully updated')
    } catch (e) {
      handleUpdateErrors(e)
    } finally {
      setLoading.off()
    }
  }

  useEffect(() => {
    const hslColor = hexToHSL(selectedAccent)
    const formatted = getHSLAString(hslColor, hue)
    setFormattedAccent(formatted)
  }, [selectedAccent, hue])

  useEffect(() => {
    const fn = async () => {
      if (pictureFile) {
        const objectUrl = URL.createObjectURL(pictureFile)
        const img = new window.Image()
        img.addEventListener('load', async function () {
          const palette = await extractColors(img)
          setPalette(palette)
        })
        img.src = objectUrl
      } else {
      }
    }
    fn()
  }, [pictureFile])

  useEffect(() => {
    if (!getValues('image') || getValues('transparentBackground')) {
      setSelectedAccent('transparent')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('image'), watch('transparentBackground')])

  const downloadImage = async () => {
    const a = document.createElement('a')
    a.href = await takeScreen()
    a.download = createFileName('test.jpg')
    a.click()
  }

  const handleSetColor = (color?: string) => {
    setSelectedAccent(color ?? '#ffffff')
    setHue(10)
  }

  const takeScreen = async () => (logoRef.current ? takeScreenshot(logoRef.current) : '')

  return (
    <>
      <VStack w='full'>
        <VStack w={['full', '80%', '66%', null, null, '50%']} spacing={5} align='start'>
          <PortalPageTitle title='Edit company logo' />
          <VStack spacing={8} background={theme.colors.white} padding={6} w='full'>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ width: '100%' }}
              onKeyDown={e => ignoreEnter(e)}>
              <VStack spacing={4}>
                <FormHeading label='Select logo to upload' hideDivider />
                <ImageUpload
                  base64ImageChange={data => setValue('image', data)}
                  onFileChange={setPictureFile}
                  pictureUrl={getValues('image')}
                  displayPreview={false}
                />
                <FormLinkCompany<EditCompanyLogo>
                  name='linkedCompany'
                  label='Select a company'
                  register={register}
                  errors={errors}
                  placeholder='Enter a company name'
                  showUnpublished={true}
                />
                <FormCheckbox<EditCompanyLogo>
                  errors={errors}
                  register={register}
                  watch={!!watch('transparentBackground')}
                  name='transparentBackground'
                  label='Add a background to the picture'
                />
              </VStack>
            </form>
            {palette && palette.length > 0 && watch('linkedCompany') && (
              <>
                {watch('transparentBackground') && (
                  <>
                    <FormHeading label='Select a background color' />
                    <SimpleGrid columns={6} spacingX='40px' spacingY='20px'>
                      <>
                        {palette &&
                          take(palette, 6).map(p => (
                            <ColorBloc color={p.hex} key={p.hex} setColor={handleSetColor} />
                          ))}
                      </>
                    </SimpleGrid>
                    <FormLabel textAlign='left' w='full'>
                      Change color intensity
                    </FormLabel>
                    <Slider onChange={setHue} value={hue} max={40}>
                      <SliderTrack>
                        <SliderFilledTrack />
                      </SliderTrack>
                      <SliderThumb />
                    </Slider>
                  </>
                )}

                <AspectRatio h='400px' w='650px' ratio={325 / 200} border='1px solid #a0aec0'>
                  <Flex w='100%' h='100%' bgColor={formattedAccent} ref={logoRef}>
                    <Flex w='80%' h='60%' placeItems='center' justifyContent='center'>
                      <Image src={getValues('image')} maxH='100%' w='auto' alt='' />
                    </Flex>
                  </Flex>
                </AspectRatio>
                <Text color='gray.400'>The border won't appear in the final image</Text>
              </>
            )}
            <HStack w='full' justify='end' align='center' spacing={3}>
              <Button variant='link' onClick={back}>
                Back
              </Button>
              <Button
                fontWeight='normal'
                variant='outline'
                onClick={downloadImage}
                leftIcon={<FontAwesomeIcon icon={faArrowDownToLine} color='#191919' />}>
                Download
              </Button>
              <Button colorScheme='green' isLoading={loading} onClick={handleSubmit(onSubmit)}>
                Confirm
              </Button>
            </HStack>
          </VStack>
        </VStack>
      </VStack>
    </>
  )
}

export default ManageCompaniesLogo
