import { Heading } from '@chakra-ui/react'

interface Props {
  text: string
}

const ParkshareSubHeading = ({ text }: Props) => {
  return (
    <Heading as='h3' fontSize='xl' fontWeight='600' my={4}>
      {text}
    </Heading>
  )
}

export default ParkshareSubHeading
