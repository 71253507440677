///Method retrieved from https://itnext.io/export-to-the-file-system-save-as-fallback-in-typescript-6561eba853cb
export const save = async ({ filename, blob }: { blob: Blob; filename: string }) => {
  const a: HTMLAnchorElement = document.createElement('a')
  a.style.display = 'none'
  document.body.appendChild(a)

  const url: string = window.URL.createObjectURL(blob)

  a.href = url
  a.download = `${filename}.ics`

  a.click()

  window.URL.revokeObjectURL(url)
  a.parentElement?.removeChild(a)
}
