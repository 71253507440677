import { NextApiRequest } from 'next'
import { throwAPIBodyQueryError } from '../apiHelpers/responseHelpers'
import { timeValidator, urlValidator } from './validators'
import { APIError } from '@/models/APIError'
import { EventHiddenCategories } from '@/constants/FieldsEvent'
import { Event } from '@/models/calendar/Event'

export const validateEventBody = (req: NextApiRequest) => {
  const {
    startTime = undefined,
    endTime = undefined,
    title = undefined,
    tags = undefined,
    googleMapLink = undefined,
    linkToJoin = undefined
  } = req.body
  if (!title || Array.isArray(title)) {
    return throwAPIBodyQueryError('title')
  }

  if (startTime && !startTime.match(timeValidator)) {
    return throwAPIBodyQueryError('startTime')
  }
  if (endTime && !endTime.match(timeValidator)) {
    return throwAPIBodyQueryError('endTime')
  }
  if (linkToJoin && !linkToJoin.match(urlValidator)) {
    return throwAPIBodyQueryError('linkToJoin')
  }
  if (tags && tags.length > 4) {
    throw new APIError('More than 4 tags present on the list', 500)
  }
  if (googleMapLink && !googleMapLink.match(urlValidator)) {
    return throwAPIBodyQueryError('googleMapLink')
  }
}

export const isKnovaEvent = (event: Event) => {
  return event.categoryHidden.includes(EventHiddenCategories.membersOnly)
}
