import { FilterItem } from '@/models/EudonetModels'
import { groupBy } from './collectionHelpers'

export const getQueryFiltersString = (selectedFilters: FilterItem[]) => {
  const filtersGroup = groupBy(selectedFilters, e => e.CatalogId)
  return Object.entries(filtersGroup)
    .map(([key, value]) => {
      return `${key}=${value.map((item: FilterItem) => item.DBValue)}`
    })
    .join('&')
}

export const createFilterListFromEnum = (
  enumToFormat: any,
  catalogName: string,
  catalogId: number
) => {
  const filters: FilterItem[] = []
  Object.entries(enumToFormat).forEach(([key, value]: [string, any]) => {
    if (typeof enumToFormat[value] === 'number') {
      filters.push({
        Id: parseInt(key),
        DBValue: parseInt(key),
        DisplayValue: value,
        CatalogName: catalogName,
        CatalogId: catalogId
      })
    }
  })

  filters.sort((a, b) => a.DisplayValue.localeCompare(b.DisplayValue))

  return filters
}

export const getCategoryFilterFromString = (
  enumToSearch: any,
  defaultValue: number,
  category: string
) => {
  const value = Object.entries(enumToSearch).find(item => item[0] === category)
  if (value && typeof value[1] === 'number') {
    return value[1]
  }

  return defaultValue
}
