import type { FieldErrors, FieldValues } from 'react-hook-form'
import { InputProps } from '@chakra-ui/react'
import { FieldsRegistration } from '@/models/FormModel'
import FormInput from './FormInput'

interface Props<TFormValues extends FieldValues> extends InputProps {
  errors: FieldErrors<TFormValues>
  fields: FieldsRegistration<TFormValues>[]
}

const FormAutoFieldsCreation = <TFormValues extends FieldValues>({
  fields
}: Props<TFormValues>) => {
  return (
    <>
      {fields.map(field => {
        return (
          <FormInput<TFormValues>
            key={field.name}
            name={field.name}
            label={field.label}
            register={field.register}
            errors={field?.errors}
            watch={field?.watch}
            rules={field?.rules}
          />
        )
      })}
    </>
  )
}
export default FormAutoFieldsCreation
