import { FRONT_EVENTS_PAGINATED } from '@/constants/Routes'
import { Event } from '@/models/calendar/Event'
import baseFetcher from '@/utils/connectionHelpers/swrFetchers'
import {
  Box,
  Center,
  Circle,
  HStack,
  Image,
  LinkBox,
  LinkOverlay,
  Spinner,
  VStack,
  useBreakpointValue
} from '@chakra-ui/react'
import { arrayRange } from '@coteries/utils'
import Link from 'next/link'
import { useMemo, useState } from 'react'
import useSWR from 'swr'

const EventCard = ({ event }: { event: Event }) => (
  <LinkBox
    flex={{ base: '1 0 100%', lg: '1 0 33.33%' }}
    h={{ base: '200px', lg: '300px' }}
    p='2'
    display='inline-block'
    overflow='hidden'>
    <LinkOverlay href={`/events/details?id=${event.id}`} as={Link} target='_blank'>
      <Image src={event.image} alt={event.title} />
    </LinkOverlay>
  </LinkBox>
)

export type EventsCarouselProps = {}
export const EventsCarousel = ({}: EventsCarouselProps) => {
  const { data, isLoading } = useSWR<{ data: Event[]; items: number }>(
    `${FRONT_EVENTS_PAGINATED(true, '', '', true)}`,
    baseFetcher,
    {
      revalidateOnFocus: false
    }
  )
  const [currentPage, setCurrentPage] = useState(0)
  const eventsPerPage = useBreakpointValue({ base: 1, lg: 3 }) ?? 1
  const nbEvents = useMemo(() => {
    if (data && !isLoading) {
      return data.data.length
    } else return 0
  }, [data, isLoading])

  const nbPages = useMemo(() => {
    return Math.ceil(nbEvents / eventsPerPage)
  }, [nbEvents, eventsPerPage])

  if (isLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    )
  }

  return (
    <VStack align='stretch' p='4'>
      <HStack justify='space-between'>
        <HStack display={{ base: 'none', lg: 'flex' }}>
          {arrayRange(nbPages).map(p => (
            <Circle key={p} size='9px' bg={currentPage === p ? 'black' : 'gray.200'} />
          ))}
        </HStack>
        <HStack
          width={{ base: 'full', lg: 'auto' }}
          justify={{ base: 'space-between', lg: 'start' }}>
          <button
            className='button small icon splide__arrow splide__arrow--prev'
            disabled={currentPage === 0}
            onClick={() => setCurrentPage(p => p - 1)}
            aria-label='Previous slide'>
            <svg
              width='46'
              height='18'
              viewBox='0 0 46 18'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M7.37582 0.2552C7.66811 -0.0645754 8.1643 -0.0868529 8.48407 0.205441C8.80384 0.497734 8.82612 0.993914 8.53383 1.31369L2.56436 7.84439H45.2157C45.6489 7.84439 46.0001 8.19559 46.0001 8.62883C46.0001 9.06206 45.6489 9.41327 45.2157 9.41327H2.56435L8.53383 15.944C8.82612 16.2638 8.80384 16.7599 8.48407 17.0522C8.1643 17.3445 7.66811 17.3223 7.37582 17.0024L0.205556 9.15808C-0.068356 8.85841 -0.068356 8.39925 0.205556 8.09959L7.37582 0.2552Z'
                fill='currentColor'></path>
            </svg>
          </button>
          <button
            className='button small icon button-dark splide__arrow splide__arrow--next'
            disabled={currentPage === nbPages - 1}
            onClick={() => setCurrentPage(p => p + 1)}
            aria-label='Next slide'>
            <svg
              width='46'
              height='18'
              viewBox='0 0 46 18'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M38.6243 0.255234C38.332 -0.0645841 37.8359 -0.0868646 37.5161 0.205468C37.1963 0.497801 37.174 0.994047 37.4663 1.31387L43.4358 7.84544H0.78444C0.351205 7.84544 0 8.19669 0 8.62999C0 9.06328 0.351205 9.41453 0.78444 9.41453H43.4358L37.4663 15.9462C37.174 16.266 37.1963 16.7622 37.5161 17.0545C37.8359 17.3469 38.332 17.3246 38.6243 17.0047L45.7946 9.15931C46.0685 8.85961 46.0685 8.40038 45.7946 8.10068L38.6243 0.255234Z'
                fill='currentColor'></path>
            </svg>
          </button>
        </HStack>
      </HStack>
      <Box width='full' h={{ base: '200px', lg: '300px' }} overflow='hidden' p='2'>
        <HStack
          transform={`translateX(-${currentPage * 100}%)`}
          transition='all .3s ease'
          spacing='0'
          flexWrap='nowrap'>
          {data?.data
            .filter(d => d.image !== '')
            .map(e => (
              <EventCard event={e} key={e.id} />
            ))}
        </HStack>
      </Box>
    </VStack>
  )
}
