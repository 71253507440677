import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { axiosErrorToast } from '@/utils/showToast'

const useErrorToaster = (
  error: AxiosError<any> | null | undefined,
  defaultError: string = 'Unknown error'
) => {
  const [oldError, setOldError] = useState<string | null | undefined>(null)

  useEffect(() => {
    if (oldError === error) {
      return
    } else if (error) {
      axiosErrorToast(error, 'error')
    }

    setOldError(error?.response?.data?.toString() || defaultError)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  return error
}

// noinspection JSUnusedGlobalSymbols
export default useErrorToaster
