import { Button, GridItem } from '@chakra-ui/react'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  disabled?: boolean
  onClick: () => void
}

const GridSelectButton = ({ disabled, onClick }: Props) => {
  return (
    <GridItem m='auto 0' textAlign={['start', null, 'end']} colSpan={[2, null, 1]}>
      <Button
        disabled={disabled}
        variant='parkshareLightButtons'
        rightIcon={<FontAwesomeIcon icon={faArrowRight} />}
        w={['full', null, 'auto']}
        onClick={onClick}>
        Select
      </Button>
    </GridItem>
  )
}

export default GridSelectButton
