import ReactPlayer from 'react-player'
import InjectHtml from '../InjectHtml'

interface Props {
  videoUrl?: string
  contentUnder?: string
}

const DisplayVideoAndContent = ({ videoUrl, contentUnder }: Props) => {
  return (
    <>
      {videoUrl && (
        <div className='margin-bottom-4x'>
          <ReactPlayer url={videoUrl} controls={true} />
        </div>
      )}
      <InjectHtml data={contentUnder} wrapInRichText={true} />
    </>
  )
}

export default DisplayVideoAndContent
