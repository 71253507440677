import { FilterItem } from '@/models/EudonetModels'
import type { ReactNode } from 'react'
import { useEffect, useState } from 'react'
import userStore from '../../../stores/userStore'
import FilterPanel, { TagType } from './FilterPanel'
import FilterTextSearch from './FilterTextSearch'
import FilterTypeButton from './FilterTypeButton'

//A list of filters (e.g. categories, locations)
export interface FilterType {
  name: string
  filterList: FilterItem[]
  memberFilterList?: FilterItem[]
}

//Extends filter type for internal state
export interface FilterTypeCatalogItem extends FilterType {
  open: boolean
}

interface Props {
  filterTypes: FilterType[]
  selectedFilters: FilterItem[]
  addFilter: (filter: FilterItem) => void
  removeFilter: (filter: FilterItem) => void
  additionalButtons?: ReactNode
  searchText?: (queryText: string) => void
  bottomMargin?: boolean
}

const FilterSection = ({
  filterTypes,
  selectedFilters,
  addFilter,
  removeFilter,
  additionalButtons,
  searchText,
  bottomMargin = true
}: Props) => {
  const isKnovaMember = userStore(store => store.isKnovaMember)
  //Internal cache used to track filter types' open state
  const [filterCatalog, setFilterCatalog] = useState<FilterTypeCatalogItem[]>()

  //Setup displayable filter types
  useEffect(() => {
    const data = filterTypes.map(filter => {
      return {
        open: true,
        ...filter
      }
    })
    setFilterCatalog(data)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //Toggle the clicked filter type. Close other types.
  const handleFilterTypeClick = (index: number) => {
    if (!filterCatalog) {
      return
    }

    const newFilterCatalog = filterCatalog.map((item, i) => {
      item.open = i === index ? !item.open : false
      return item
    })

    setFilterCatalog(newFilterCatalog)
  }

  //Render the open list of filters
  const renderOpenFilterList = () => {
    const openType = filterCatalog?.find(item => item.open)

    if (!openType) {
      return <></>
    }

    return (
      <>
        <FilterPanel tagList={openType.filterList} onClick={item => addFilter(item)} />
        {isKnovaMember && openType.memberFilterList && (
          <FilterPanel
            tagList={openType.memberFilterList}
            type={TagType.member}
            onClick={item => addFilter(item)}
          />
        )}
      </>
    )
  }

  return (
    <div
      className={`section-full-w ${bottomMargin && 'margin-bottom-4x'} wf-section anim-onload-1`}>
      {filterCatalog && (
        <>
          <div className='filters-wrapper'>
            <div className='filters-block'>
              <div className='filters' style={{ gridGap: '10px' }}>
                <div className='filters-default'>
                  {filterCatalog.map((filter, i) => (
                    <FilterTypeButton
                      key={filter.name}
                      text={filter.name}
                      opened={filter.open}
                      onClick={() => handleFilterTypeClick(i)}
                    />
                  ))}
                </div>
                <div className='filters-additional'>{additionalButtons}</div>
              </div>
              {searchText && <FilterTextSearch searchText={searchText} />}
            </div>
          </div>
          <div className='filter-panel-wrapper'>
            {renderOpenFilterList()}
            {selectedFilters && selectedFilters.length > 0 && (
              <FilterPanel
                tagList={selectedFilters}
                type={TagType.selected}
                onRemove={removeFilter}
              />
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default FilterSection
