import { FRONT_DATE_FORMAT } from '@/constants/DateConstants'
import { ROUTE_NEWS_DETAIL, ROUTE_SUCCESS_STORY_DETAILS } from '@/constants/Routes'
import { News } from '@/models/calendar/News'
import dayjs from 'dayjs'
import CardImage from '../../shared/CardImage'

interface Props {
  news: News
  vertical?: boolean
  search?: boolean
  additionalClass?: string
  bottom?: React.ReactNode
}

const NewsCard = ({ news, vertical = false, search = false, additionalClass, bottom }: Props) => {
  return (
    <div
      className={`card news-type-anim ${vertical ? 'margin-bottom-4x' : ''} ${
        additionalClass ? additionalClass : ''
      }`}>
      <a
        href={
          news.categoryFilter === 'Success'
            ? ROUTE_SUCCESS_STORY_DETAILS(news.id)
            : ROUTE_NEWS_DETAIL(news.id)
        }
        className='w-inline-block margin-bottom-2x'>
        <div className='separator fill-news-1' />
        <h3 className='card-title-medium'>
          <span className='color-news-1'>
            {dayjs(news.publicationDate).format(FRONT_DATE_FORMAT)}
          </span>{' '}
          {news.title}
        </h3>
        <div className='tag-list color-news-1'>
          {news.categoryFilter && <div className='tag fill-news-1'>{news.categoryFilter}</div>}
          {news.tags?.length > 0 &&
            news.tags.slice(0, 4).map(tag =>
              tag ? (
                <div className='tag outlined' key={tag}>
                  {tag}
                </div>
              ) : (
                <></>
              )
            )}
        </div>
        {!search && <CardImage imgSrc={news.image} fillClass='fill-news-2' />}
      </a>
      {bottom}
    </div>
  )
}

export default NewsCard
