import type { ReactNode } from 'react'

interface Props {
  title?: string
  children: ReactNode
}

const ResultsWrapper = ({ title, children }: Props) => {
  return (
    <div className='section margin-bottom-4x wf-section'>
      <div className='wrapper small'>
        {title && <h5>{title}</h5>}
        {children}
      </div>
    </div>
  )
}

export default ResultsWrapper
