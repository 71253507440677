import { FilterItem } from '@/models/EudonetModels'

export enum TagType {
  default,
  member,
  selected
}

interface Props {
  tagList: FilterItem[]
  type?: TagType
  onClick?: (item: FilterItem) => void
  onRemove?: (item: FilterItem) => void
}

const FilterPanel = ({ tagList, onClick, type = TagType.default, onRemove }: Props) => {
  return (
    <div className={`filter-panel ${type === TagType.member && 'member-events'}`}>
      {type === TagType.member && (
        <div className='members-events-label'>Members events filters</div>
      )}
      {tagList.map(tag => (
        <a
          href='#'
          className={`filters-label ${
            type === TagType.member ? 'members-events' : ''
          } w-inline-block ${type === TagType.selected ? 'dark' : ''}`}
          key={tag.Id}
          onClick={() => onClick && onClick(tag)}>
          <div className='filters-button-text'>{tag.DisplayValue}</div>
          {type === TagType.selected && onRemove && (
            <div className='fa-l' onClick={() => onRemove(tag)}>
              
            </div>
          )}
        </a>
      ))}
    </div>
  )
}

export default FilterPanel
