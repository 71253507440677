interface Props {
  label: string
  enabled: boolean
  setEnabled: (val: boolean) => void
  colored?: boolean
}

const Checkbox = ({ label, enabled, setEnabled, colored }: Props) => {
  return (
    <div className='filters-button'>
      <a
        className={`checkbox w-inline-block ${colored ? 'color-knova' : ''} ${
          enabled ? 'selected' : ''
        }`}
        onClick={() => {
          setEnabled(!enabled)
        }}>
        <div className='checkbox-marker'></div>
      </a>
      <div>{label}</div>
    </div>
  )
}
export default Checkbox
