import { isDev } from '../isDev'

export enum SENTRY_TYPE {
  MESSAGE = 'message',
  EXCEPTION = 'error'
}

export type sentryCategory = 'API' | 'AUTH' | 'UTILS' | 'EMAIL' | 'MODULE' | 'SCRAPER'

export const logSentry = (type: SENTRY_TYPE, message: string, extra: {} | null = null) => {
  //const user = firebase.auth().currentUser

  if (type === SENTRY_TYPE.MESSAGE) {
    if (isDev) {
      console.log(message, extra)
    }
    if (isDev) {
      console.error(message, extra)
    }
  }
}

export const logSentryMessage = (method: string, category: sentryCategory, response: {} | null) => {
  logSentry(SENTRY_TYPE.MESSAGE, method, response)
}
export const logSentryException = (method: string, category: sentryCategory, response?: {}) => {
  logSentry(SENTRY_TYPE.EXCEPTION, method, response)
}
