import { UserServicePermission } from '@/constants/FieldsUser'
import { COOKIE_EMPLOYEE } from '@/constants/RequestConstants'
import {
  ROUTE_COMPANY_DETAILS,
  ROUTE_PORTAL_COLLABORATORS_CREATE,
  ROUTE_ROOT
} from '@/constants/Routes'
import { hasPermissionUser } from '@/utils/apiHelpers/userPermissions'
import {
  Avatar,
  Button,
  Flex,
  HStack,
  Heading,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useDisclosure,
  useTheme
} from '@chakra-ui/react'
import Cookies from 'js-cookie'
import Link from 'next/link'
import { useRouter } from 'next/router'
import shallow from 'zustand/shallow'
import useIsMounted from '../../../hooks/useIsMounted'
import userStore from '../../../stores/userStore'
import CompanyLogo from './CompanyLogo'

const PortalHeading = () => {
  const router = useRouter()
  const theme = useTheme()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [user, company, logout] = userStore(
    state => [state.user, state.company, state.logout],
    shallow
  )

  const handleLogout = async () => {
    await router.push(ROUTE_ROOT)
    logout()
    onClose()
  }

  const isMounted = useIsMounted()

  return (
    isMounted && (
      <Flex w='full' px={[4, 0]} justify='space-between' direction={['column', null, 'row']}>
        <Flex align='center' pt={[4, 0]}>
          <Avatar size='xl' src={user?.picture} />
          <VStack pl={4} align='start' justifyContent='center' h='full'>
            <Heading as='h3' fontSize='36px' m='0'>
              {user?.firstName} {user?.lastName}
            </Heading>
            <Text m='0'>
              {user?.role} {company?.signage && `@${company.signage}`}
            </Text>
            <HStack>
              <Button
                variant='link'
                pl='0'
                minW='0'
                onClick={() => {
                  Cookies.set(COOKIE_EMPLOYEE, JSON.stringify(user), {
                    expires: 1
                  })
                  router.push(ROUTE_PORTAL_COLLABORATORS_CREATE)
                }}
                color={theme.colors.brand.primary}
                fontWeight='400'>
                Edit
              </Button>
              <Button
                variant='link'
                onClick={onOpen}
                color={theme.colors.brand.primary}
                fontWeight='400'>
                Logout
              </Button>
            </HStack>
          </VStack>
        </Flex>
        <VStack spacing='1'>
          <CompanyLogo imageUrl={company?.logo} />
          {hasPermissionUser(user, UserServicePermission.CompanyList_Read) && company && (
            <Button
              variant='link'
              as={Link}
              target='_blank'
              color='black'
              fontSize='11px'
              fontWeight='medium'
              textDecoration='underline'
              href={ROUTE_COMPANY_DETAILS(company.companyId)}>
              View Company Profile
            </Button>
          )}
        </VStack>
        <Modal isOpen={isOpen} onClose={onClose} size='xs'>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Do you want to log out?</ModalHeader>
            <ModalFooter>
              <Button variant='ghost' mr={3} onClick={onClose}>
                Close
              </Button>
              <Button colorScheme='green' onClick={handleLogout}>
                Log out
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    )
  )
}
export default PortalHeading
