import RecentNews from '../../components/news/RecentNews'
import SuccessStories from '../../components/successStory/SuccessStories'
import { NEWS_CATEGORY_FILTER_VALUES } from '@/constants/FieldsNews'
import {
  ROUTE_COMPANY_LIST,
  ROUTE_ECOSYSTEM_NEWS,
  ROUTE_ECOSYSTEM_NEWS_BY_TYPE,
  ROUTE_SUCCESS_STORIES
} from '@/constants/Routes'
import RecentHighlightedNews from '../../components/news/RecentHighlightedNews'
import SuccessStoriesList from '../../components/successStory/SuccessStoriesList'
import NewsList from '../../components/news/NewsList'
import SuccessStoryDetails from '../../components/successStory/SuccessStoryDetails'
import NewsDetails from '../../components/news/NewsDetails'
import { logSentryException } from '../loggingHelpers/sentryHelper'

export const handleNewsModule = (id: string) => {
  switch (id) {
    case 'module_news_home':
      return <RecentHighlightedNews title='Company highlights' moreLink={ROUTE_ECOSYSTEM_NEWS} />
    case 'module_news_garage':
      return (
        <SuccessStories
          title='Related projects'
          moreTitle='View more'
          moreLink={ROUTE_COMPANY_LIST}
        />
      )
    case 'module_news_corporate_partnering':
      return (
        <RecentNews
          title='News'
          moreLink={ROUTE_ECOSYSTEM_NEWS_BY_TYPE('industry collaboration')}
          type={NEWS_CATEGORY_FILTER_VALUES['Industry collaboration']}
        />
      )
    case 'module_success_stories_startup_coaching':
      return (
        <SuccessStories
          title='Coached Startups'
          moreTitle='Success stories'
          moreLink={ROUTE_SUCCESS_STORIES}
          isCoached={true}
        />
      )
    case 'module_success_stories_garage':
      return (
        <>
          <p>hello</p>
          <SuccessStories
            title='Coached Startups'
            moreTitle='Success stories'
            moreLink={ROUTE_SUCCESS_STORIES}
            isCoached={true}
          />
        </>
      )
    case 'module_news_funding':
      return (
        <RecentNews
          title='Related news'
          moreLink={ROUTE_ECOSYSTEM_NEWS_BY_TYPE('financing')}
          type={NEWS_CATEGORY_FILTER_VALUES.Financing}
        />
      )
    case 'module_news_corporate_partnership_program':
      return (
        <RecentNews
          title='News'
          moreLink={ROUTE_ECOSYSTEM_NEWS_BY_TYPE('industry collaboration')}
          type={NEWS_CATEGORY_FILTER_VALUES['Industry collaboration']}
        />
      )
    case 'module_news_societal_activities':
      return (
        <RecentNews
          title='Sustainability news'
          moreLink={ROUTE_ECOSYSTEM_NEWS_BY_TYPE('sustainability')}
          type={NEWS_CATEGORY_FILTER_VALUES.Sustainability}
        />
      )
    case 'module_news_container':
      return <NewsList />
    case 'module_news_details':
      return <NewsDetails />
    case 'module_success_stories_list':
      return <SuccessStoriesList />
    case 'module_success_stories_details':
      return <SuccessStoryDetails />
    default:
      logSentryException('handleNewsModule no supported module', 'MODULE', id)
  }
}
