import ListFetchingHandling from '@/components/shared/dataFetching/ListFetchingHandling'
import { ALLOWED_CORS_SOURCE } from '@/constants/APIConstants'
import { COOKIE_EMPLOYEE } from '@/constants/RequestConstants'
import {
  FRONT_COMPANY_EMPLOYEES,
  FRONT_USER,
  ROUTE_PORTAL_COLLABORATORS_CREATE
} from '@/constants/Routes'
import useErrorToaster from '@/hooks/useErrorToaster'
import { PublicEmployee } from '@/models/company/PublicEmployee'
import userStore from '@/stores/userStore'
import { getCanDeleteEmployee, getCanEditEmployee } from '@/utils/apiHelpers/userPermissions'
import { axiosVercelAPI } from '@/utils/connectionHelpers/axios'
import baseFetcher from '@/utils/connectionHelpers/swrFetchers'
import { showToast } from '@/utils/showToast'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Button,
  GridItem,
  HStack,
  SimpleGrid,
  Text,
  VStack,
  useBoolean,
  useDisclosure,
  useTheme
} from '@chakra-ui/react'
import { FocusableElement } from '@chakra-ui/utils'
import { AxiosError } from 'axios'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { LegacyRef, MutableRefObject, useRef, useState } from 'react'
import useSWR from 'swr'

const CollaboratorsList = () => {
  return <CollaboratorsListContent />
}

const fetcher = async (url: string): Promise<PublicEmployee[]> => {
  const data = await baseFetcher<PublicEmployee[]>(url)
  return data.sort((f, n) => f.lastName.localeCompare(n.lastName))
}

const CollaboratorsListContent = () => {
  const theme = useTheme()
  const user = userStore(state => state.user)
  const cancelRef = useRef<FocusableElement>() as MutableRefObject<FocusableElement>

  const { push } = useRouter()
  const {
    data: employees,
    error: employeesError,
    mutate: employeesUpdate
  } = useSWR<PublicEmployee[], AxiosError>(FRONT_COMPANY_EMPLOYEES, fetcher, {
    revalidateOnFocus: false
  })
  useErrorToaster(employeesError)

  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure()
  const [deleting, { on: onDeletingOn, off: onDeletingOff }] = useBoolean()
  const [employeeToDelete, setEmployeeToDelete] = useState<PublicEmployee | null>()

  const deleteEmployee = async (employeeToDelete: PublicEmployee) => {
    try {
      onDeletingOn()
      const result = await axiosVercelAPI.delete(FRONT_USER(employeeToDelete.encryptedId), {
        data: {
          source: ALLOWED_CORS_SOURCE.PORTAL
        }
      })

      if (result.status >= 200 && result.status <= 299) {
        showToast(
          `${employeeToDelete.lastName} ${employeeToDelete.firstName} has been successfully deleted!`
        )

        await employeesUpdate(
          employees?.filter(
            listedEmployee => listedEmployee.encryptedId !== employeeToDelete.encryptedId
          )
        )
      } else {
        showToast(result?.statusText || 'A problem occurred, please try again later', 'error')
      }
    } catch (e) {
      showToast('A problem occurred, please try again later', 'error')
    } finally {
      onDeleteClose()
      onDeletingOff()
    }
  }

  return (
    <VStack w='full' spacing={5} align='start'>
      <VStack w='full' spacing={0.5}>
        <ListFetchingHandling
          data={employees ? { data: employees, items: employees.length } : undefined}
          error={employeesError}
        />
        {employees
          ?.sort((f, n) => f.lastName.localeCompare(n.lastName))
          ?.map((employee, index) => (
            <SimpleGrid
              gridGap={2}
              columns={[2, 3, 5]}
              key={index}
              py={5}
              px={10}
              bgColor={theme.colors.white}
              w='full'>
              <GridItem>
                <Avatar size='lg' src={employee.picture} />
              </GridItem>
              <GridItem>
                <HStack h='full' align={['start', 'center']}>
                  <Text overflowWrap='anywhere'>
                    {employee.lastName} {employee.firstName}
                  </Text>
                </HStack>
              </GridItem>
              <GridItem colSpan={[2, 1]}>
                <HStack h='full' align='center' justify={['start', 'end', 'center']}>
                  <Text overflowWrap='anywhere'>{employee.email}</Text>
                </HStack>
              </GridItem>
              <GridItem colSpan={[2, 1]}>
                <HStack h='full' align='center' justify={['start', null, 'center']}>
                  <Text overflowWrap='anywhere'>{employee.role}</Text>
                </HStack>
              </GridItem>
              <GridItem colSpan={[2, null, 1]}>
                <HStack h='full' justify={['start', 'end']} align='center'>
                  {user && getCanDeleteEmployee(user, employee) && (
                    <Button
                      variant='link'
                      onClick={() => {
                        setEmployeeToDelete(employee)
                        onDeleteOpen()
                      }}>
                      Delete
                    </Button>
                  )}
                  {user && getCanEditEmployee(user, employee) && (
                    <Button
                      variant='link'
                      onClick={() => {
                        Cookies.set(COOKIE_EMPLOYEE, JSON.stringify(employee), {
                          expires: 1
                        })
                        push(ROUTE_PORTAL_COLLABORATORS_CREATE)
                      }}>
                      Edit
                    </Button>
                  )}
                </HStack>
              </GridItem>
            </SimpleGrid>
          ))}
      </VStack>
      <AlertDialog isOpen={isDeleteOpen} onClose={onDeleteClose} leastDestructiveRef={cancelRef}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Do you want to remove {employeeToDelete?.lastName} {employeeToDelete?.firstName}?
            </AlertDialogHeader>
            <AlertDialogFooter>
              <Button
                ref={cancelRef as LegacyRef<HTMLButtonElement>}
                variant='ghost'
                mr={3}
                onClick={onDeleteClose}>
                Close
              </Button>
              <Button
                isLoading={deleting}
                colorScheme='green'
                onClick={async () => {
                  if (employeeToDelete) {
                    await deleteEmployee(employeeToDelete)
                  }

                  onDeleteClose()
                }}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </VStack>
  )
}

// noinspection JSUnusedGlobalSymbols
export default CollaboratorsList
