import { News } from '@/models/calendar/News'

interface Props {
  news: News
}

const SuccessStoriesKeyFigures = ({ news }: Props) => {
  return (
    <>
      {news.keyFigures1 && news.keyTextFigures1 && (
        <div className='section wf-section'>
          <div className='wrapper'>
            <div className='block-content'>
              <div className='block-content margin-bottom-4x'>
                <div id='counter_container' className='grid-card'>
                  <div className='card-simple'>
                    <div>
                      <div className='count'>{news.keyFigures1}</div>
                      <div className='text'>{news.keyTextFigures1}</div>
                    </div>
                  </div>
                  {news.keyFigures2 && news.keyTextFigures2 && (
                    <div className='card-simple'>
                      <div>
                        <div className='count'>{news.keyFigures2}</div>
                        <div className='text'>{news.keyTextFigures2}</div>
                      </div>
                    </div>
                  )}
                  {news.keyFigures3 && news.keyTextFigures3 && (
                    <div className='card-simple'>
                      <div>
                        <div className='count'>{news.keyFigures3}</div>
                        <div className='text'>{news.keyTextFigures3}</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default SuccessStoriesKeyFigures
