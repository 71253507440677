import { useForm } from 'react-hook-form'

export type SearchFormProps = {}
export const SearchForm = ({}: SearchFormProps) => {
  const { register } = useForm<{ query: string }>()
  return (
    <form action='/search' className='search-2 w-form'>
      <input
        type='search'
        className='search-input w-input'
        maxLength={256}
        {...register('query')}
        placeholder='What are you looking for ?'
      />
      <input type='submit' value='Search' className='search-button w-button' />
    </form>
  )
}
