import { Center, HStack, Spinner, Text } from '@chakra-ui/react'
import type { ReactNode } from 'react'

interface Props<T> {
  listItems: T[]
  card: (item: T, key: number) => ReactNode
  isLoading?: boolean
  loadingText?: string
}

function SimpleListDisplay<T>({ listItems, card, isLoading, loadingText }: Props<T>) {
  return (
    <div className='section anim-onload-1'>
      <div className='wrapper margin-bottom-4x'>
        <div className='block-content'>
          <div className='grid-card anim-success'>
            {listItems && Array.isArray(listItems) && listItems.map((item, key) => card(item, key))}
          </div>
        </div>
      </div>
      {isLoading && (
        <Center pb='5'>
          <HStack align='center'>
            <Text mr='1' mb='0'>
              {loadingText ?? 'Loading more'}
            </Text>
            <Spinner />
          </HStack>
        </Center>
      )}
    </div>
  )
}

// noinspection JSUnusedGlobalSymbols
export default SimpleListDisplay
