import UpcomingEvents from '../../components/events/UpcomingEvents'
import { EVENT_CATEGORY_FILTER_VALUES } from '@/constants/FieldsEvent'
import { ROUTE_ECOSYSTEM_EVENTS_BY_TYPE } from '@/constants/Routes'
import EventsList from '../../components/events/EventsList'
import EventDetails from '../../components/events/EventDetails'
import PastEvents from '../../components/events/PastEvents'
import EventsCalendar from '../../components/events/components/EventsCalendar'
import { logSentryException } from '../loggingHelpers/sentryHelper'

export const handleEventsModule = (id: string) => {
  // This is an initial implementation, it could be improved if components have the same props
  switch (id) {
    case 'module_events_startup_hosting':
      return (
        <UpcomingEvents
          title='Networking events'
          moreLink={ROUTE_ECOSYSTEM_EVENTS_BY_TYPE('networking')}
          eventType={EVENT_CATEGORY_FILTER_VALUES.Networking}
        />
      )
    case 'module_events_coaching_forge':
      return (
        <UpcomingEvents
          title='Upcoming events'
          moreLink={ROUTE_ECOSYSTEM_EVENTS_BY_TYPE('networking')}
          eventType={EVENT_CATEGORY_FILTER_VALUES.Networking}
        />
      )
    case 'module_events_garage':
      return (
        <UpcomingEvents
          title='Upcoming events'
          moreLink={ROUTE_ECOSYSTEM_EVENTS_BY_TYPE('networking')}
          eventType={EVENT_CATEGORY_FILTER_VALUES.Networking}
        />
      )
    case 'module_events_modular_workspace':
      return (
        <UpcomingEvents
          title='Upcoming events'
          moreLink={ROUTE_ECOSYSTEM_EVENTS_BY_TYPE('networking')}
          eventType={EVENT_CATEGORY_FILTER_VALUES.Networking}
        />
      )
    case 'module_events_startup_training':
      return (
        <UpcomingEvents
          title='Training events'
          moreLink={ROUTE_ECOSYSTEM_EVENTS_BY_TYPE('training')}
          eventType={EVENT_CATEGORY_FILTER_VALUES.Training}
        />
      )
    case 'module_events_startup_partnering':
      return (
        <UpcomingEvents
          title='Partnering events'
          moreLink={ROUTE_ECOSYSTEM_EVENTS_BY_TYPE('partnering')}
          eventType={EVENT_CATEGORY_FILTER_VALUES.Partnering}
        />
      )
    case 'module_events_societal_what_we_do':
      return (
        <UpcomingEvents
          title='Sustainability events'
          moreLink={ROUTE_ECOSYSTEM_EVENTS_BY_TYPE('sustainability')}
          eventType={EVENT_CATEGORY_FILTER_VALUES.Sustainability}
        />
      )
    case 'module_events_societal_what_companies':
      return (
        <UpcomingEvents
          title='Sustainability events'
          moreLink={ROUTE_ECOSYSTEM_EVENTS_BY_TYPE('sustainability')}
          eventType={EVENT_CATEGORY_FILTER_VALUES.Sustainability}
        />
      )
    case 'module_events_container':
      return <EventsList />
    case 'module_events_details':
      return <EventDetails />
    case 'module_events_calendar_container':
      return <EventsCalendar />
    case 'module_events_past':
      return <PastEvents />
    default:
      logSentryException('handleEventsModule no supported module', 'MODULE', id)
  }
}
