import { FilterItem } from '@/models/EudonetModels'
import { createFilterListFromEnum } from '@/utils/dataHelpers/filtersHelpers'
import { COMPANY_TYPE_DESC_ID } from './FieldsCatalog'

export const COMPANY_NAME = 301
export const COMPANY_PHONE = 305
export const COMPANY_WEBSITE = 308
export const COMPANY_CATEGORY = 315
export const COMPANY_PUBLISHED = 316
export const COMPANY_HEADER_DESCRIPTION = 317
export const COMPANY_OWNER_CONTRACT = 320
export const COMPANY_NUMBER_OF_EMPLOYEES = 325
export const COMPANY_COMPANY_EMAIL = 326
export const COMPANY_SIZE = 327
export const COMPANY_BOOKAROOM_CONTRACT = 329
export const COMPANY_BUILDING = 337
export const COMPANY_GOOGLE_MAPS_LINK = 339
export const COMPANY_DESCRIPTION = 340
export const COMPANY_USERNAME_PRINTERS = 343
export const COMPANY_CONTACT_PERSON = 349
export const COMPANY_LOCATION = 351
export const COMPANY_MAIN_SECTOR = 352
export const COMPANY_COACHED_COMPANY = 353
export const COMPANY_PRODUCT_PICTURE = 354
export const COMPANY_VIDEO = 355
export const COMPANY_FOUNDING_YEAR = 356
export const COMPANY_TAGS = 357
export const COMPANY_PRODUCT_LEGEND = 358
export const COMPANY_PINNED_FOR_WORKSPACE = 359
export const COMPANY_EPFL_STARTUP = 361
export const COMPANY_SCALEUP_VAUD = 362
export const COMPANY_WHITE_BACKGROUND = 363
export const COMPANY_SIGNAGE = 368
export const COMPANY_LOGO = 375
export const COMPANY_LINK = 3001

export enum COMPANY_TYPE_VALUES {
  Corporate = 3754,
  Services = 4741,
  SME = 4325,
  'Startup' = 3752
}

export enum COMPANY_MAIN_SECTOR_VALUES {
  Cleantech = 5507,
  ICT = 5508,
  Lifesciences = 5509,
  Other = 5510,
  'Precision industry' = 5511,
  Sport = 5512
}

export const getCompanySectorFilters = () => {
  return createFilterListFromEnum(
    COMPANY_MAIN_SECTOR_VALUES,
    'Sector of activity',
    COMPANY_MAIN_SECTOR
  )
}

export const getCompanyTypeFilters = () => {
  return createFilterListFromEnum(COMPANY_TYPE_VALUES, 'Type', COMPANY_TYPE_DESC_ID)
}

export const EIP_LAUSANNE_FILTER = 'The EPFL Innovation Park - Lausanne'
export const BIOTECH_GENEVA_FILTER = 'The Campus Biotech - Geneva'
export const UNLIMITRUST_PRILLY_FILTER = 'The unlimitrust campus - Prilly'

//Hardcoded values since Location values aren't coming form a catalog but require custom code
export const getCompanyLocationFilters = (): FilterItem[] => {
  return [
    {
      Id: 1,
      DBValue: 1,
      DisplayValue: EIP_LAUSANNE_FILTER,
      CatalogName: 'Location',
      CatalogId: 1
    },
    {
      Id: 2,
      DBValue: 2,
      DisplayValue: BIOTECH_GENEVA_FILTER,
      CatalogName: 'Location',
      CatalogId: 2
    },
    {
      Id: 3,
      DBValue: 3,
      DisplayValue: UNLIMITRUST_PRILLY_FILTER,
      CatalogName: 'Location',
      CatalogId: 3
    }
  ]
}
